import React from 'react'
import styles from './style.module.scss'
import { Button, Card, message } from 'antd'
import SearchBar from './SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import JobsList from './JobsList'
import { useNavigate } from 'react-router-dom'
import { get_and_search_jobs } from '../../features/Jobseeker/jobseekerSlice'
import { onSearchJobs } from '../../utils/helpers'

const SearchedJobList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { alljobs, applyJobLoad, isSaveJobDone, loader } = useSelector((state) => state.jobseeker);
    const { userProfile ,onSearchValues} = useSelector((state) => state.global);

    const handleApplyClick = (job) => {
        message.warning('Before Apply need to login first')
       navigate('/login')
      };

    const handleShareClick = (job) => {
        // Handle share logic here
    };

    const handleSaveClick = (job) => {
        navigate('/login')
      };
    
    const onShowAllJobs=()=>{
        dispatch(get_and_search_jobs({navigate:navigate, searchParams:"" }))
    }

    // const onSearchData = () => {
    //     const filteredValues = Object.keys(onSearchValues).reduce((acc, key) => {
    //       // Check if the value exists and is a string before calling trim()
    //       if (onSearchValues[key] && typeof onSearchValues[key] === 'string' && onSearchValues[key].trim() !== "") {
    //         acc[key] = onSearchValues[key];
    //       }
    //       // Optionally handle non-string values here (if necessary)
    //       return acc;
    //     }, {});
      
    //     const searchParams = new URLSearchParams(filteredValues).toString();
    //     dispatch(get_and_search_jobs({ navigate, searchParams }));
    //   };

    return (
        <div>
            <Card className={styles.searchCards}>
                <SearchBar
                    showYearsSelect={false}
                    jobPlaceholder="Search job by name"
                    jobName='searchString'
                    locationPlaceholder="Search job by location"
                    locationName="location"
                    yearsPlaceholder="5 years"
                    locationOptions={[
                        { value: '1', label: 'Not Identified' },
                        { value: '2', label: 'Closed' },
                        { value: '3', label: 'Communicated' },
                    ]}
                    yearsOptions={[
                        { value: '1', label: '1-2 years' },
                        { value: '2', label: '3-5 years' },
                        { value: '3', label: '6+ years' },
                    ]}
                    onSearch={()=>onSearchJobs(onSearchValues , dispatch , navigate, get_and_search_jobs)}
                    searchBtnText="Search"
                />
            </Card>
            <Card className={styles.searchedJobsList} title={alljobs?.length ? `${alljobs?.length} searched Jobs` : 'No searched job found'}
            extra={<Button onClick={onShowAllJobs} className='default-button-style'>Show all jobs</Button>} 
            >
                <JobsList
                    data={alljobs}
                    buttonText="Apply Now"
                    onButtonClick={handleApplyClick}
                    onShareClick={handleShareClick}
                    onSaveClick={handleSaveClick}
                />
            </Card>
        </div>
    )
}

export default SearchedJobList