import { Button, Card, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import moment from 'moment'; // For handling date formatting
import { DeleteOutlined } from '@ant-design/icons';

const AddCertificateModal = ({ isCertificateModalVisible, setIsCertificateModalVisible }) => {
    const [certificateForm] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [certificates, setCertificates] = useState([{ id: Date.now(), name: "", institute: "", obtainedOn: "" }]);
    const { userProfile } = useSelector((state) => state.global);

    const handleCertificateSubmit = (values) => {
        // Format the new certificates from the form
        const formattedCertificates = certificates.map(cert => ({
            name: values[`name_${cert.id}`],
            institute: values[`institute_${cert.id}`],
            obtainedOn: values[`obtainedOn_${cert.id}`]?.format('YYYY-MM-DD'), // Format the date
        }));

        // Combine existing certificates from userProfile with new certificates
        const payload = { certifications: [...userProfile?.certifications, ...formattedCertificates] };

        // Dispatch the combined certificates
        dispatch(update_jobseeker({
            body: payload,
            navigate,
        }));

        // Reset state and form
        setCertificates([{ id: Date.now(), name: "", institute: "", obtainedOn: "" }]); // Reset state
        setIsCertificateModalVisible(false); // Close modal
        certificateForm.resetFields(); // Reset form
    };

    const addNewCertificate = () => {
        setCertificates([...certificates, { id: Date.now(), name: "", institute: "", obtainedOn: "" }]);
    };

    const deleteCertificate = (id) => {
        setCertificates(certificates.filter(cert => cert.id !== id));
    };

    const handleReset = () => {
        setCertificates([{ id: Date.now(), name: "", institute: "", obtainedOn: "" }]);
        certificateForm.resetFields();
        setIsCertificateModalVisible(false);
    };

    return (
        <div>
            <Modal
                title="Add Certificates"
                visible={isCertificateModalVisible}
                onCancel={() => setIsCertificateModalVisible(false)}
                footer={null}
            >
                <Form colon={false} layout="vertical" form={certificateForm} onFinish={handleCertificateSubmit}>
                    <Row gutter={[16, 16]}>
                        {certificates.map((certificate, index) => (
                            <Col span={24} key={certificate.id}>
                                <Card
                                    title={`Certificate ${index + 1}`}
                                    extra={
                                        <Button
                                            danger
                                            type="text"
                                            onClick={() => deleteCertificate(certificate.id)}
                                        >
                                            <DeleteOutlined/>
                                        </Button>
                                    }
                                    style={{ border: "1px solid #f0f0f0" }}
                                >
                                    <Form.Item
                                        label="Name"
                                        name={`name_${certificate.id}`}
                                        rules={[{ required: true, message: "Please enter the certificate name" }]}
                                    >
                                        <Input placeholder="Certificate Name" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Institute"
                                        name={`institute_${certificate.id}`}
                                        rules={[{ required: true, message: "Please enter the institute name" }]}
                                    >
                                        <Input placeholder="Institute Name" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Obtained On"
                                        name={`obtainedOn_${certificate.id}`}
                                        rules={[{ required: true, message: "Please select the date" }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format="YYYY-MM-DD"
                                            placeholder="Select Date"
                                            defaultValue={certificate.obtainedOn ? moment(certificate.obtainedOn) : null}
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Row justify="space-between" style={{ marginTop: "1rem" }}>
                        <Col xs={24}>
                            <Button className='multiAddBtn fullwidth' type="dashed" onClick={addNewCertificate}>
                                + Add New Certificate
                            </Button>
                        </Col>
                    </Row>

                    <Row justify="end" style={{ marginTop: "1rem" }}>
                        <Col>
                            <div className="flex-wrap-row-direction-gap-5">
                                <Button
                                    size="large"
                                    shape="round"
                                    onClick={handleReset}
                                >
                                    Reset & Close
                                </Button>
                                <Button
                                    size="large"
                                    shape="round"
                                    type="primary"
                                    className="default-button-style"
                                    htmlType="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default AddCertificateModal;
