import React, { useEffect, useState } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import { Content } from 'antd/es/layout/layout'
import { Button, Card, Checkbox, Col, Grid, Input, Row, Select } from 'antd'
import styles from './style.module.scss'
import { SearchOutlined } from '@ant-design/icons'
import JobList from './JobList'
import LoggedInSidebar from './LoggedInSidebar'
import FindJobPageLayout from './FindJobPageLayout'
import TopHeaderFindJob from './TopHeaderFindJob'
import { getAuthToken } from '../../utils/authHelpers'
import { useDispatch, useSelector } from 'react-redux'
import ApplicationStatus from './ApplicationStatus'
import FilterredApplyJobs from './FilterredApplyJobs'
import FillteredApplicationStatus from './FillteredApplicationStatus'
import FilterSection from '../../components/CommonElements/FilterSection'
import InterviewRating from './InterviewRating'
import { apply_job_api, get_and_search_jobs, save_job_api } from '../../features/Jobseeker/jobseekerSlice'
import { useNavigate } from 'react-router-dom'
import { get_jobseeker, get_jobseeker_skills } from '../../features/UserInfo/userInfoSlice'
import { setCurrentFilterSection, setCurrentJobData, setCurrentJobStatus } from '../../features/Global/globalSlice'
import GuestUserSearchedJobs from './GuestUserSearchedJobs'
import FilterLayoutSM from '../../components/CommonElements/FilterLayoutSM'

const { useBreakpoint } = Grid;
const FindJobPageWithFilter = () => {
  const screens = useBreakpoint();
  const token = getAuthToken();
  const navigate = useNavigate();
  const { currentFilterSection, userProfile, searchParameters, internalSearchParameter, onSearchValues } = useSelector((state) => state.global)
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [loadingJobId, setLoadingJobId] = useState(null);
  const { alljobs, applyJobLoad, isSaveJobDone, loader } = useSelector((state) => state.jobseeker);

  console.log("searchParameters", searchParameters)

  const handleApplyClick = (job) => {
    // Handle apply logic here
    const payload = {
      jobId: job?._id,
      userId: userProfile?._id
    }
    setLoadingJobId(job?._id);
    dispatch(apply_job_api({ body: payload, navigate: navigate }))
  };

  const handleSaveClick = (job) => {
    const payload = {
      jobId: job?._id,
      userId: userProfile?._id
    }
    dispatch(setCurrentJobData(job))
    dispatch(save_job_api({ body: payload, navigate: navigate }))
  };

  const handleShareClick = (job) => {
    // Handle share logic here
  };

  // const handleFilterChange = (newFilters) => {
  //   setFilters(newFilters);
  //   setIsFilterApplied(
  //     Object.values(newFilters).some((values) => values.length > 0)
  //   );
  // };
  const handleFilters = (filters) => {
    setFilters(filters)
    setIsFilterApplied(
      Object.values(filters)?.some((values) => values?.length > 0)
    );
    // Example: If only jobType is selected, you'll see { jobType: "full-time" }
  };

  // const applyFilters = () => {
  //   const searchParams = new URLSearchParams(filters).toString();
  //   dispatch(get_and_search_jobs({ navigate: navigate, searchParams: searchParams })); // Dispatch the API call with filters
  // };

  const applyFilters = () => {
    // Helper function to parse existing search parameters into an object
    const parseSearchParams = (paramString) => {
      const params = new URLSearchParams(paramString);
      const result = {};
      for (let [key, value] of params.entries()) {
        result[key] = value;
      }
      return result;
    };

    // Parse existing search parameters if available
    const parsedInternalParams = internalSearchParameter
      ? parseSearchParams(internalSearchParameter)
      : {};
    const parsedSearchParams = searchParameters
      ? parseSearchParams(searchParameters)
      : {};
    const parsedonSearchParams = onSearchValues
      ? parseSearchParams(onSearchValues)
      : {};

    // Merge parsed parameters with current filters
    const combinedFilters = {
      ...parsedInternalParams,
      ...parsedSearchParams,
      ...parsedonSearchParams,
      ...filters,
    };

    // Create the final search string
    const searchParams = new URLSearchParams(combinedFilters).toString();
    console.log("Final Search Params:", searchParams); // Debugging purpose
    dispatch(get_and_search_jobs({ navigate: navigate, searchParams: searchParams }));
  };


  const clearFilters = () => {
    setFilters({});
    setIsFilterApplied(false);
  };
  useEffect(() => {
    dispatch(get_and_search_jobs({ navigate, searchParams: searchParameters }));
  }, [searchParameters]);

  const renderFilter = () => {
    return (
      <>
        <FilterSection
          postedAtOptions={[
            { value: '1', label: 'Jack' },
            { value: '2', label: 'Lucy' },
            { value: '3', label: 'Tom' },
          ]}
          jobTypeOptions={[
            { label: 'Full-time', value: 'full-time' },
            { label: 'Part-time', value: 'part-time' },
            { label: 'Internship', value: 'internship' },
            { label: 'Freelance', value: 'freelance' },
          ]}
          experienceOptions={[
            { label: 'Fresher', value: 'fresher' },
            { label: '1+', value: '1+' },
            { label: '2+ to 4+', value: '2+ to 4+' },
            { label: '6+ to 8+', value: '8+ to 10+' },
            { label: '10+ to 15+', value: '15+ to 25+' },
          ]}
          salaryOptions={[
            { label: 'Under $1000', value: 'under_1000' },
            { label: '$1k-$2k', value: '1k-2k' },
            { label: '$3k-$5k', value: '3k-5k' },
            { label: '$6k-$9k', value: '6k-9k' },
          ]}
          showSalaryRange={true}
          showExperience={true}
          onFilterChange={handleFilters}
          isFilterApplied={isFilterApplied}
          applyFilters={applyFilters}
          apiCall={get_and_search_jobs}
          setIsFilterApplied={setIsFilterApplied}
        />
      </>
    )
  }

  return (
    <div>
      <FindJobPageLayout>
        <TopHeaderFindJob title={<h2>Find your dream job</h2>} content={"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor i"} />
        <div className={styles.filterJobNoToken}>

          <Row gutter={[16, 16]}>
            <Col xs={24} lg={5} md={24}>
              {currentFilterSection === 'apply-jobs' &&
                ((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="1" label="Add Filters" children={renderFilter()} /> : renderFilter()
              }
              {/* {currentFilterSection === 'application-status' && <ApplicationStatus />}
                {currentFilterSection === 'interview-rating' && <ApplicationStatus />} */}
            </Col>
            <Col xs={24} lg={19} md={24}>

              <GuestUserSearchedJobs />
              {/* {currentFilterSection === 'application-status' && <FillteredApplicationStatus buttonText="Apply Now"
                onButtonClick={handleApplyClick}
                onShareClick={handleShareClick}
                onSaveClick={handleSaveClick}
                submitload={applyJobLoad}
                loadingJobId={loadingJobId}
                onBack={() => {
                  dispatch(setCurrentFilterSection('apply-jobs'))
                  dispatch(setCurrentJobStatus({}))
                }}
                page="application" />
              }
              {currentFilterSection === 'interview-rating' && <InterviewRating />} */}
            </Col>
          </Row>
        </div>

      </FindJobPageLayout>
    </div>
  )
}

export default FindJobPageWithFilter
