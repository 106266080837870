import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AppSecureStorage from '../../../services/storage/secureStorage';
import { message } from 'antd';
import { setUserProfile } from '../../Global/globalSlice';
import { unauthorizedError } from '../../../utils/helpers';
import organizationInfoService from '../../../services/api/Organization/organizationInfoService';
import { get_organization } from '../OrgAuth/OrgAuthSlice';
const orgInfo = new organizationInfoService();
const storage = new AppSecureStorage();

export const Update_organization = createAsyncThunk(
    "org/Update_organization",
    async ({ body, navigate, onClose }, { dispatch }) => {
        dispatch(setUserProfileLoad(true))
        try {
            const response = await orgInfo.Update_organization(body)
            dispatch(get_organization(navigate))
            message.success("Profile updated Successfully")
            onClose()
            if (response) {
                dispatch(setUserProfileLoad(false));
            }
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setUserProfileLoad(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg);
            }
        }
    }
);

export const remove_organization = createAsyncThunk(
    "org/remove_organization",
    async ( navigate , { dispatch }) => {
        dispatch(setRemoveLoad(true))
        try {
            const response = await orgInfo.Remove_organization()
            message.success("Organization Removed Successfully");
            navigate("/");
            storage.clearStorage();
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setRemoveLoad(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg);
            }
        }
    }
);

export const add_organization_roles = createAsyncThunk(
    "org/add_organization_roles",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setAddRoleLoad(true))
        try {
            const response = await orgInfo.Add_organization_Roles(body)
            dispatch(get_organization(navigate))
            message.success("role added Successfully")
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setAddRoleLoad(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg);
            }
        }
    }
);

const initialState = {
    userProfileLoad: false,
    loading: false,
    removeLoad: false,
    addRoleLoad: false
};

const orgInfoSlice = createSlice({
    name: 'orgInfo',
    initialState,
    reducers: {
        setUserProfileLoad: (state, action) => {
            state.userProfileLoad = action.payload
        },
        setRemoveLoad: (state, action) => {
            state.removeLoad = action.payload
        },
        setAddRoleLoad: (state, action) => {
            state.addRoleLoad = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(Update_organization.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(Update_organization.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(Update_organization.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(remove_organization.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(remove_organization.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(remove_organization.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(add_organization_roles.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(add_organization_roles.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(add_organization_roles.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const
    { setUserProfileLoad, setAddRoleLoad, setRemoveLoad
    } = orgInfoSlice.actions;
export default orgInfoSlice.reducer;
