import React, { useEffect, useState } from 'react';
import { Avatar, List, message } from 'antd';
import VirtualList from 'rc-virtual-list';
import featureImg from '../../assets/Images/featureImg.png'
const fakeDataUrl =
    'https://randomuser.me/api/?results=3&inc=name,gender,email,nat,picture&noinfo';

const dummyData =
{
    "results": [{
        "gender": "male", "name": { "title": "Mr", "first": "Customer Support", "last": "Customer Support" },
        "Description": "Lorem Ipsum has been the industry's standard dummy tex a type e industry's standard dummy tex a type", "picture": { "large": "https://randomuser.me/api/portraits/men/87.jpg", "medium": "https://randomuser.me/api/portraits/med/men/87.jpg", "thumbnail": "https://randomuser.me/api/portraits/thumb/men/87.jpg" }, "nat": "IR"
    }, {
        "gender": "female", "name": { "title": "Mademoiselle", "first": "Audrey", "last": "Best Price Gurentee" },
        "Description": "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type", "picture": { "large": "https://randomuser.me/api/portraits/women/5.jpg", "medium": "https://randomuser.me/api/portraits/med/women/5.jpg", "thumbnail": "https://randomuser.me/api/portraits/thumb/women/5.jpg" }, "nat": "CH"
    }, {
        "gender": "male", "name": { "title": "Mr", "first": "Phoenix", "last": "Many Location" },
        "Description": "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type", "picture": { "large": "https://randomuser.me/api/portraits/men/35.jpg", "medium": "https://randomuser.me/api/portraits/med/men/35.jpg", "thumbnail": "https://randomuser.me/api/portraits/thumb/men/35.jpg" }, "nat": "NZ"
    }]
}

const ContainerHeight = 350;
const EmployeeList = () => {
    const [data, setData] = useState(dummyData.results);
    // const appendData = () => {
    //     fetch(fakeDataUrl)
    //         .then((res) => res.json())
    //         .then((body) => {
    //             setData(data.concat(body.results));
    //             message.success(`${body.results.length} more items loaded!`);
    //         });
    // };
    // useEffect(() => {
    //     appendData();
    // }, []);
    const onScroll = (e) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            // appendData();
        }
    };
    return (
        <List>
            <VirtualList
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemKey="email"
                onScroll={onScroll}
            >
                {(item) => (
                    <List.Item key={item.email}>
                        <List.Item.Meta
                            avatar={<Avatar size={90} shape='square' src={item.picture.large} />}
                            title={<a href="https://ant.design">{item.name.last}</a>}
                            description={item.Description}
                        />
                    </List.Item>
                )}
            </VirtualList>
        </List>
    );
};
export default EmployeeList;