import { API_ROUTES } from "../../../utils/constants";
import _axios from "../index";


export default class jobService {

  create_jobs = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.JOBS.CREATE_JOBS, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Upload_jobs = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.JOBS.UPLOAD_JOB, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

}