import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import FindJobPageLayout from '../FindJobPage/FindJobPageLayout'
import { Content } from 'antd/es/layout/layout'

import { Layout, Avatar, Row, Col, Button, Tag, Typography, Card, Grid, Form, Input, Progress } from 'antd';
import { ArrowDownOutlined, DeleteFilled, EditOutlined, FormOutlined } from '@ant-design/icons';
import UploadResume from './UploadResume';
import PersonalDetails from './PersonalDetails';
import EducationDetails from './EducationDetails';
import ProfessionalDetails from './ProfessionalDetails';
import SkillsSection from './SkillsSection';
import ProfileEditor from '../../components/DialogBoxs/ProfileEditor';
import { useDispatch, useSelector } from 'react-redux';
import { get_jobseeker, setIsEditProfile, update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import { useNavigate } from 'react-router-dom';
import { get_profile_percentage } from '../../features/Jobseeker/jobseekerSlice';
import { formatDOBDate, getLightColorForTag } from '../../utils/helpers';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid

const UserInfo = () => {
  const screens = useBreakpoint()
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const { isProfileEdit } = useSelector((state) => state.userInfo)
  const { userProfile } = useSelector((state) => state.global)
  const { ProfileCompletion } = useSelector((state) => state.jobseeker)
  const initialData = {
    name: userProfile?.name,
    title: userProfile?.designation,
    experience: '3 years 11 months',
    location: userProfile?.currentAddress,
    email: userProfile?.email,
    phone: `${userProfile?.countryCode} - ${userProfile?.mobile}`,
    jobType: 'Full-time',
    skills: userProfile?.skills,
    visibility: 'visible'
  };
  const [activeDetail, setActiveDetail] = useState('personal')
  const [isTopProfileEdit, setIsTopProfileEdit] = useState(false);

  const onChangeDetails = (status) => {
    setActiveDetail(status)
  }

  const getColorForTag = (index) => {
    // Generate a light color based on the index of the skill
    const hue = (index * 137) % 360;  // Use a unique hue for each skill, 137 is a prime number for diversity
    const saturation = 60;  // Keep saturation moderate to avoid overly saturated colors
    const lightness = 80 + (index % 10);  // Ensure lightness stays high for a pastel-like effect

    // Return the color in HSL format
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const showModal = () => {
    setIsTopProfileEdit(true);
    form.setFieldsValue(initialData); // Set initial form values
  };

  const onEditClick = () => {
    dispatch(setIsEditProfile(true))
  }
  const onSaveProfile = () => {
    dispatch(setIsEditProfile(false))
    dispatch(update_jobseeker({ body: userProfile, navigate: navigate }))
  }

  useEffect(() => {
    dispatch(get_jobseeker(navigate))
    dispatch(get_profile_percentage(navigate))
  }, [])

  console.log("profilecomp", ProfileCompletion)
  return (
    <FindJobPageLayout>
      <div className={!screens.sm || !screens.md ? styles.userInfoDivSm : `topRow`}>
        <Content className={!screens.sm || !screens.md ? styles.userInfoWrapperSm : styles.userInfoWrapper}>
          {/* Header Section */}
          <Card className={styles.userInfoCrd}>
            <div
              style={{
                // background: 'url(https://your-background-image-url)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                // padding: '40px 20px',
                borderRadius: '8px 8px 0 0',
                position: 'relative',
              }}
            >
              {/* Profile Image */}
              <header
                style={{
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: 'center',
                  height: '100px',
                  textAlign: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%, 30px 30px, 30px 30px, 30px 30px', // Adjust the size of circles
                  backgroundBlendMode: 'overlay',
                  background: `
                    linear-gradient(45deg,  #0D3621 , #231F20 , #280201), /* Linear gradient with 3 colors */
                    radial-gradient(circle at 10px 10px, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0, /* Small circles */
                    radial-gradient(circle at 20px 20px, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0, /* Small circles */
                    radial-gradient(circle at 30px 30px, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0 /* Small circles */
                  `,
                  color: 'white',
                  borderRadius: '10px',
                  fontSize: '24px',
                  fontWeight: 400
                }}
              >
                {userProfile?.designation}
              </header>
              <div className={styles.profileWrapper}>
                <Avatar
                  size={100}
                  className={styles.userProfilepic}
                  src={userProfile?.avatar}
                  style={{
                    position: 'absolute',
                    bottom: '-50px',
                    left: '20px',
                    border: '4px solid white',
                  }}
                />
                <Progress
                  type="circle"
                  percent={ProfileCompletion?.percentage || 0}
                  width={100}
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                  }}
                  trailColor="#f5f5f5"
                  className={`${styles.ProfileCompletionDiv}`}
                />
              </div>

            </div>

            {/* Main Content */}
            <Row justify="start" style={{ paddingTop: '60px', paddingBottom: '20px', padding: '20px', lineHeight: '30px' }}>
              {/* Left Column */}
              <Col xs={24} sm={12} md={13} lg={13}>
                <Title level={3} style={{ marginBottom: '0' }}>
                  {userProfile?.name}
                </Title>
                <Text>Designation : <Tag color='orange'>{userProfile?.designation || 'NA'}</Tag>
                  {/* | 3 years 11 months */}
                </Text>
                <br />
                <Text>Permanent Address : <Tag color='purple'>{userProfile?.permanentAddress || 'NA'}</Tag></Text>
                <br />
                <Text>Email : <Tag color='green-inverse'> {userProfile?.email || 'N/A'}</Tag> </Text>
                <br />
                {/* <Text strong>Job type</Text> <Button shape='round' className='default-button-style' size='small' color="green">Full-time</Button> */}
              </Col>

              {/* Right Column */}
              <Col xs={24} sm={12} md={8} lg={8}>
                <Title level={5}>{userProfile?.countryCode ? <>Country Code : <Tag color='cyan-inverse'>{userProfile?.countryCode}</Tag></> : 'Country code : NA'}</Title>
                <Title level={5}>{userProfile?.mobile ? <> Mobile number : <Tag color='cyan-inverse'>{userProfile?.mobile}</Tag></> : 'Mobile : NA'}</Title>
                <Title level={5}>{userProfile?.workPermit?.length > 0 ? <>Work Permit : <div>{userProfile?.workPermit?.map((permit, index) => <Tag style={{ backgroundColor: getLightColorForTag(index) }}>{permit}</Tag>)}</div></> : 'Work permit : N/A'}</Title>
                {/* {userProfile?.skills?.length > 0 && <Title level={5}>Top key skills</Title>}
                {userProfile?.skills?.length > 0 &&
                  userProfile?.skills?.map((skill, index) => {
                    return (
                      <div className='flex-wrap-row-direction-gap-5' style={{ marginBottom: '10px' }}>
                        <Button
                          style={{ backgroundColor: getColorForTag(index), color: 'black' }}
                          size='small'
                          shape='round'>
                          {skill?.name}
                        </Button>
                      </div>
                    )
                  })
                } */}


                {/* {userProfile?.visibility ?
                  <>
                    <Title level={5}>Visibility</Title>
                    <Button className='default-button-style' shape='round' type="primary" size="small" style={{ marginRight: '8px' }}>
                      Profile visible to recruiters
                    </Button>
                    <Button shape='round' size="small">Keep it private</Button>
                  </> : <Title level={5}>Visibility : NA</Title>} */}

              </Col>

              {/* Edit Profile Button */}
              <Col xs={24} sm={24} md={2} lg={2} style={{ textAlign: 'right' }}>
                <Button
                  type="default"
                  shape="round"
                  icon={<EditOutlined />}
                  size="middle"
                  style={{ position: 'relative', bottom: '10px' }}
                  className='dark-bg'
                  onClick={showModal}
                >
                  Edit Profile
                </Button>
              </Col>
            </Row>
          </Card>
          <Card className={styles.pdfSection}>
            <h3>Resume</h3>
            <div className='flex-space-between'>
              <div>Product design pdf
                <p>Latest update : dd/mm/yy</p>
              </div>
              <div className='flex-wrap-row-direction-gap-5'>
                <Avatar icon={<ArrowDownOutlined />} />
                <Avatar icon={<DeleteFilled />} />
              </div>
            </div>
            <Card className={styles.pdfSectionCard}>
              <UploadResume />
            </Card>
          </Card>
          <div className='flex-space-between'>
            <div className={`flex-wrap-row-direction-gap-5 ${styles.detailsBtn}`}>
              <Button className={activeDetail === 'personal' && 'default-button-style'} onClick={() => {
                onChangeDetails('personal')
              }}>Personal Details</Button>
              <Button className={activeDetail === 'education' && 'default-button-style'} onClick={() => {
                onChangeDetails('education')
              }}>Education Details</Button>
              <Button className={activeDetail === 'professional' && 'default-button-style'} onClick={() => {
                onChangeDetails('professional')
              }}>Professional details</Button>
              <Button className={activeDetail === 'skills' && 'default-button-style'} onClick={() => {
                onChangeDetails('skills')
              }}>Skills</Button>
            </div>
            {/* <div>
              <Card className='border-none bg-transparent'>
                <div className='flex-wrap-row-direction-gap-5'>
                  <Button size='middle' shape='round' >Upload Profile</Button>
                  {
                    isProfileEdit ?
                      <Button onClick={onSaveProfile} className='default-button-style'>Save</Button>
                      :
                      <Avatar onClick={onEditClick} icon={<FormOutlined />} />
                  }
                </div>
              </Card>
            </div> */}
          </div>
          {activeDetail === 'personal' ? <PersonalDetails onNext={() => onChangeDetails('education')} /> : activeDetail === 'education' ?
            <EducationDetails onNext={() => onChangeDetails('professional')} /> : activeDetail === 'professional' ? <ProfessionalDetails onNext={() => onChangeDetails('skills')} /> : activeDetail === 'skills' && <SkillsSection />}
        </Content>
        {/* edit profile popup */}
        <ProfileEditor isTopProfileEdit={isTopProfileEdit} setIsTopProfileEdit={setIsTopProfileEdit} />
      </div>
    </FindJobPageLayout>
  )
}

export default UserInfo