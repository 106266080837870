import axios from "axios";
import { API_ROUTES } from "../../utils/constants";
import { getAuthToken } from "../../utils/authHelpers";

// Set up the base URL for all axios requests
const axiosInstance = axios.create({
  baseURL: API_ROUTES.BASE_URL,
  headers: {
    "Accept": "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "User-Agent": "axios/1.7.7",
    "Accept-Encoding": "gzip, compress, deflate, br",
  },
});


// Add a request interceptor to dynamically set the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken(); // Fetch the token dynamically
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers = {
      ...config.headers,
      Origin: window.location.origin,
      Referer: window.location.href,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

// Handle different HTTP methods using axiosInstance
const _axios = {
  get: async (url, headers = {}) => {
    return axiosInstance.get(url, { headers });
  },
  put: async (url, data = {}, options = {}) => {
    return axiosInstance.put(url, data, options);
  },
  patch: async (url, data = {}, options = {}) => {
    return axiosInstance.patch(url, data, options);
  },
  post: async (url, data = {}, options = {}) => {
    return axiosInstance.post(url, data, options);
  },
  delete: async (url, data = {}, options = {}) => {
    return axiosInstance.delete(url, { data, ...options });
  },
};

export default _axios;
