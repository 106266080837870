import { API_ROUTES } from "../../utils/constants";
import _axios from "./index"; // Import the simplified _axios module

export default class AuthService {
  login = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.LOGIN_AUTH, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  SignUp = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.SIGN_UP_AUTH, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  GoogleAuthLogin = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.GOOGLE_LOGIN, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  GenerateOTP = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.GENERATE_OTP, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  VerifyOTP = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.VERIFY_OTP, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  logout = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.LOGOUT_AUTH, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Send_reset_password_link = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.RESET_PASSWORD_APPLY, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Change_password = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.CHANGE_PASSWORD, body);
      return response;
    } catch (err) {
      throw err;
    }
  };
}
