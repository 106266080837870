import React, { useState } from 'react'
import FindJobPageLayout from '../../pages/FindJobPage/FindJobPageLayout'
import { Button, Card, Col, Divider, Grid, Row } from 'antd'
import styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FilterSection from './FilterSection'
import SearchedJobList from './SearchedJobList';
import { get_and_search_jobs } from '../../features/Jobseeker/jobseekerSlice'
import FilterLayoutSM from './FilterLayoutSM'

const { useBreakpoint } = Grid;

const SearchedJobs = () => {
    const screens = useBreakpoint();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [filters, setFilters] = useState({});
    const { searchParameters, internalSearchParameter, onSearchValues } = useSelector((state) => state.global)

    const applyFilters = () => {
        // Helper function to parse existing search parameters into an object
        const parseSearchParams = (paramString) => {
            const params = new URLSearchParams(paramString);
            const result = {};
            for (let [key, value] of params.entries()) {
                result[key] = value;
            }
            return result;
        };

        // Parse existing search parameters if available
        const parsedInternalParams = internalSearchParameter
            ? parseSearchParams(internalSearchParameter)
            : {};
        const parsedSearchParams = searchParameters
            ? parseSearchParams(searchParameters)
            : {};
        const parsedonSearchParams = onSearchValues
            ? parseSearchParams(onSearchValues)
            : {};

        // Merge parsed parameters with current filters
        const combinedFilters = {
            ...parsedInternalParams,
            ...parsedSearchParams,
            ...parsedonSearchParams,
            ...filters,
        };

        // Create the final search string
        const searchParams = new URLSearchParams(combinedFilters).toString();
        console.log("Final Search Params:", searchParams); // Debugging purpose
        dispatch(get_and_search_jobs({ navigate: navigate, searchParams: searchParams }));
    };

    const clearFilters = () => {
        setFilters({});
        setIsFilterApplied(false);
    };

    const handleFilters = (filters) => {
        setFilters(filters)
        setIsFilterApplied(
            Object.values(filters)?.some((values) => values?.length > 0)
        );
        // Example: If only jobType is selected, you'll see { jobType: "full-time" }
    };

    const onClearFilter = () => {
        setFilters({})
        const searchParams = new URLSearchParams(filters).toString();
        dispatch(get_and_search_jobs({ navigate: navigate, searchParams: searchParams }));
    }

    const renderFilter = () => {
        return (
            <>
                <FilterSection
                    postedAtOptions={[
                        { value: '1', label: 'Jack' },
                        { value: '2', label: 'Lucy' },
                        { value: '3', label: 'Tom' },
                    ]}
                    jobTypeOptions={[
                        { label: 'Full-time', value: 'full-time' },
                        { label: 'Part-time', value: 'part-time' },
                        { label: 'Internship', value: 'internship' },
                        { label: 'Freelance', value: 'freelance' },
                    ]}
                    experienceOptions={[
                        { label: 'Fresher', value: 'fresher' },
                        { label: '1+', value: '1+' },
                        { label: '2+ to 4+', value: '2+ to 4+' },
                        { label: '6+ to 8+', value: '8+ to 10+' },
                        { label: '10+ to 15+', value: '15+ to 25+' },
                    ]}
                    salaryOptions={[
                        { label: 'Under $1000', value: 'under_1000' },
                        { label: '$1k-$2k', value: '1k-2k' },
                        { label: '$3k-$5k', value: '3k-5k' },
                        { label: '$6k-$9k', value: '6k-9k' },
                    ]}
                    showSalaryRange={true}
                    showExperience={true}
                    onFilterChange={handleFilters}
                    isFilterApplied={isFilterApplied}
                    applyFilters={applyFilters}
                    apiCall={get_and_search_jobs}
                    setIsFilterApplied={setIsFilterApplied}
                />
            </>
        )
    }
    return (
        <FindJobPageLayout>
            <Row gutter={[16, 16]} justify={'center'} className='topRow'>
                <Col xs={24} lg={5} md={24}>
                    {((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="1" label="Add Filters" children={renderFilter()} /> : renderFilter()}
                </Col>
                <Col xs={24} lg={19} md={24}>
                    <SearchedJobList />
                </Col>
            </Row>
        </FindJobPageLayout>
    )
}

export default SearchedJobs