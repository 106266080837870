import { Button, Result, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const navigate = useNavigate()
    const backToHome=()=>{
        navigate('/')
    }
    return (
        <Result
            status="404"
            title="404"
            subTitle={<Typography.Title level={4}>Sorry, the page you visited does not exist.</Typography.Title>}
            extra={<Button onClick={backToHome} className='default-button-style' type="primary">Back To Home</Button>}
        />
    )
}

export default PageNotFound