import React, { useState } from "react";
import { Card, Calendar, Modal } from "antd";

const CustomCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(null); // State to store the selected date
  const [isModalOpen, setIsModalOpen] = useState(false); // State to toggle the modal view

  // Handle date click
  const handleDateClick = (date) => {
    setSelectedDate(date);
    setIsModalOpen(true);
  };

  const dateCellRender = (date) => {
    const day = date.date();
    const isHighlighted = [2, 9, 10, 17, 27].includes(day); // Highlight specific dates

    return (
      <div
        style={{
          position: "relative",
          minWidth: "24px",
          height: "50px",
          fontWeight: "normal",
          border: "1px solid gray",
          backgroundColor: isHighlighted ? "orange" : "#fff",
          color: isHighlighted ? "white" : "#595959",
          fontSize: "12px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          padding: "5px",
          borderRadius: "5px",
          cursor: "pointer", // Add pointer cursor for better UX
        }}
        onClick={() => handleDateClick(date)} // Handle click
      >
        <div>Lorem ipsum dolor</div> {/* Custom text */}
      </div>
    );
  };

  return (
    <>
      <Card
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "16px",
        }}
      >
        <Calendar
          fullscreen={false}
          headerRender={() => null}
          dateCellRender={dateCellRender}
          className="custom-calendar"
        />
      </Card>

      {/* Modal to show detailed view */}
      <Modal
        title={selectedDate ? selectedDate.format("dddd, MMMM DD") : ""}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={800}
      >
        <div style={{ height: "400px", overflowY: "auto" }}>
          <h3>GMT+05:30</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {[...Array(12)].map((_, idx) => (
              <div
                key={idx}
                style={{
                  borderBottom: "1px solid #e8e8e8",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>{`${idx + 8} AM`}</div>
                {idx === 9 && (
                  <div
                    style={{
                      backgroundColor: "#1890ff",
                      color: "white",
                      padding: "4px 8px",
                      borderRadius: "4px",
                    }}
                  >
                    No Title (5 - 6 PM)
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomCalendar;
