import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { Button, Card, Col, Input, Row, Select, Spin } from 'antd'
import JobList from './JobList'
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import axios from 'axios'
import JobsList from '../../components/CommonElements/JobsList'
import { JobsData } from '../../components/dummyData/DummyData'
import SearchBar from '../../components/CommonElements/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { apply_job_api, get_and_search_jobs, get_saved_jobs, save_job_api, setIsSavejobdone, unsave_job_api } from '../../features/Jobseeker/jobseekerSlice'
import SuccessSaveJobPopup from '../../components/DialogBoxs/SuccessSaveJobPopup'
import { setCurrentJobData } from '../../features/Global/globalSlice'
import { onSearchJobs } from '../../utils/helpers'
import ScreenFreezLoader from '../../components/CommonElements/ScreenFreezLoader'


const FilterredApplyJobs = () => {
    const [jobs, setJobs] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loadingJobId, setLoadingJobId] = useState(null);
    const { alljobs, applyJobLoad, isSaveJobDone,loader } = useSelector((state) => state.jobseeker)
    const { userProfile , onSearchValues} = useSelector((state) => state.global)
    const [isJobApplied, setIsJobApplied ] = useState(null)
    // const [isSaveJobDone, setIsSavejobdone]=useState(false)


    useEffect(() => {
        // Fetch data from an API
        if (userProfile?._id) {
            dispatch(get_saved_jobs({ userId: userProfile?._id, navigate: navigate }))
          }
        dispatch(get_and_search_jobs({ navigate: navigate }))
    }, [userProfile]);

    const handleApplyClick = (job) => {
        // Handle apply logic here
        const payload = {
            jobId: job?._id,
            userId: userProfile?._id
        }
        setLoadingJobId(job?._id);
        dispatch(apply_job_api({ body: payload, navigate: navigate }))
    };

    const handleShareClick = (job) => {
        // Handle share logic here
    };

    const handleSaveClick = (job) => {
        const payload = {
            jobId: job?._id,
            userId: userProfile?._id
        }
        dispatch(setCurrentJobData(job))
        dispatch(save_job_api({ body: payload, navigate: navigate }))
    };
    const handleUnSaveClick = (job) => {
        const payload = {
            jobId: job?._id,
            userId: userProfile?._id
        }
        dispatch(setCurrentJobData(job))
        dispatch(unsave_job_api({ body: payload, navigate: navigate }))
    };

    return (
        <div>
            <div className={styles.cardStyleJob}>
                <Card className={styles.searchCards}>
                    <SearchBar
                        showYearsSelect={false}
                        jobPlaceholder="Search job by name"
                        jobName='searchString'
                        locationPlaceholder="Search job by location"
                        locationName="location"
                        yearsPlaceholder="5 years"
                        locationOptions={[
                            { value: '1', label: 'Not Identified' },
                            { value: '2', label: 'Closed' },
                            { value: '3', label: 'Communicated' },
                        ]}
                        yearsOptions={[
                            { value: '1', label: '1-2 years' },
                            { value: '2', label: '3-5 years' },
                            { value: '3', label: '6+ years' },
                        ]}
                        onSearch={()=>onSearchJobs(onSearchValues , dispatch , navigate , get_and_search_jobs)}
                        searchBtnText="Search"
                    />

                </Card>
                <div className={styles.counterResults}> {alljobs.length ? `${alljobs?.length} jobs results` : 'No Jobs Found'}</div>
            </div>
            <div className={styles.cardStyleJob}>
                {loader && <div className='text-center'><Spin/></div>}
                <JobsList
                    data={alljobs}
                    buttonText="Apply Now"
                    onButtonClick={handleApplyClick}
                    onShareClick={handleShareClick}
                    onSaveClick={handleSaveClick}
                    onUnSaveClick={handleUnSaveClick}
                    submitload={applyJobLoad}
                    loadingJobId={loadingJobId}
                    page="application"
                />
            </div>
            <SuccessSaveJobPopup visible={isSaveJobDone} onClose={()=> dispatch(setIsSavejobdone(false))} />
        </div>
    )
}

export default FilterredApplyJobs