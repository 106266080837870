import { Button, Card, Checkbox, Divider, Radio, Select, Slider } from "antd";
import { useState } from "react";
import styles from './style.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { get_and_search_jobs } from "../../features/Jobseeker/jobseekerSlice";
import { parseSearchParams } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

const FilterSection = ({
  postedAtOptions = [],
  jobTypeOptions = [],
  showSalaryRange = true,
  showExperience = true,
  onFilterChange,
  isFilterApplied,
  applyFilters,
  apiCall,
  userId,
  setIsFilterApplied
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedJobType, setSelectedJobType] = useState("");
  const [experienceRange, setExperienceRange] = useState([0, 0]); 
  const [salaryRange, setSalaryRange] = useState([0, 0]);
  const { internalSearchParameter, searchParameters, onSearchValues } = useSelector((state) => state.global);
  const handleJobTypeChange = (e) => {
    const value = e.target.value;
    setSelectedJobType(value);
    handleFilterChange({
      jobType: value,
      experienceMin: experienceRange[0],
      experienceMax: experienceRange[1],
      salaryMin: salaryRange[0],
      salaryMax: salaryRange[1],
    });
  };

  const handleExperienceChange = (value) => {
    setExperienceRange(value);
    handleFilterChange({
      jobType: selectedJobType,
      experienceMin: value[0],
      experienceMax: value[1],
      salaryMin: salaryRange[0],
      salaryMax: salaryRange[1],
    });
  };

  const handleSalaryChange = (value) => {
    setSalaryRange(value);
    handleFilterChange({
      jobType: selectedJobType,
      experienceMin: experienceRange[0],
      experienceMax: experienceRange[1],
      salaryMin: value[0],
      salaryMax: value[1],
    });
  };

  const handleFilterChange = (filterValues) => {
    const filteredValues = Object.fromEntries(
      Object.entries(filterValues).filter(([key, value]) => value !== "")
    );
    onFilterChange(filteredValues);
  };

  const onClearFilter = () => {
    setSelectedJobType("");
    setExperienceRange([0, 0]);
    setSalaryRange([0, 0]);
    const parsedInternalParams = internalSearchParameter
      ? parseSearchParams(internalSearchParameter)
      : {};
    const parsedSearchParams = searchParameters
      ? parseSearchParams(searchParameters)
      : {};
    const parsedonSearchParams = onSearchValues
      ? parseSearchParams(onSearchValues)
      : {};
    const cleanSearchParams = Object.fromEntries(
      Object.entries({
        ...parsedInternalParams,
        ...parsedSearchParams,
        ...parsedonSearchParams,
      }).filter(
        ([key, value]) =>
          value !== undefined &&
          value !== null &&
          value !== "" &&
          value !== "undefined" 
      )
    );
    const combinedFilters = {
      ...cleanSearchParams,
      // ...(selectedJobType && { jobType: selectedJobType }),
      // ...(experienceRange[0] !== 0 && experienceRange[1] !== 0 && {
      //   experienceMin: experienceRange[0],
      //   experienceMax: experienceRange[1],
      // }),
      // ...(salaryRange[0] !== 0 && salaryRange[1] !== 0 && {
      //   salaryMin: salaryRange[0],
      //   salaryMax: salaryRange[1],
      // }),
    };

    const searchParams = new URLSearchParams(combinedFilters).toString();
    console.log("Clear Filters Search Params:", searchParams); // Debugging
    setIsFilterApplied(false)

    if (apiCall && typeof apiCall === "function") {
      if (userId) {
        // API requires userId
        dispatch(apiCall({ navigate, userId, searchParams }));
      } else {
        // API does not require userId
        dispatch(apiCall({ navigate, searchParams }));
      }
    } else {
      console.error("Invalid API call function provided!");
    }
  };


  const isApplyButtonVisible =
    selectedJobType !== "" ||
    experienceRange[0] !== 0 || experienceRange[1] !== 0 ||
    salaryRange[0] !== 0 || salaryRange[1] !== 0;

  return (
    <Card className={styles.topCardWrapper} >
      <div>
        {isFilterApplied || isApplyButtonVisible ?
          <div className={` ${styles.filterHeader} cardContents`}>
            <div> <h3>Filter</h3></div>
            <div className={styles.additionalbtns}>
              <Button size='small' className='default-button-style' type="primary" onClick={applyFilters}>
                Apply
              </Button>
              <Button size='small' danger type="primary" onClick={onClearFilter}>
                Clear
              </Button>
            </div>
          </div>
          :
          <div className='flex-space-between cardContents'>
            <div> <h3>Filter</h3></div>
            {/* <div className='flex-wrap-row-direction-gap-5'>
              <Button danger type="text" onClick={onClearFilter}>
                Clear
              </Button>
            </div> */}
          </div>
        }
        <Divider className='noMarginhr' />
      </div>
      <div className='cardContents'>
        <div>
          <div className="scrollable-div">
            {/* Posted At Filter */}
            {/* {postedAtOptions?.length > 0 && (
          <>
            <h3>Posted At</h3>
            <Select
              showSearch
              className="fullwidth"
              placeholder="Select a person"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={postedAtOptions}
            />
            <Divider />
          </>
        )} */}

            {/* Job Type Filter */}
            <h3>Job Type</h3>
            <Radio.Group onChange={handleJobTypeChange} value={selectedJobType}>
              {jobTypeOptions.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
            <Divider />

            {/* Experience Range Filter */}
            <h3>Experience (Years)</h3>
            {showExperience && (
              <div>
                <Slider
                  range
                  min={0}
                  max={30} // Adjust this based on your needs
                  defaultValue={[0, 10]}
                  onChange={handleExperienceChange}
                  value={experienceRange}
                  className={styles.sliderStyle}
                />
                <div>
                  Selected: {experienceRange[0]} - {experienceRange[1]} years
                </div>
              </div>
            )}
            <Divider />

            {/* Salary Range Filter */}
            <h3>Salary Range</h3>
            {showSalaryRange && (
              <div>
                <Slider
                  range
                  min={0}
                  max={200000} // Adjust this based on your needs
                  step={5000}
                  defaultValue={[0, 50000]}
                  onChange={handleSalaryChange}
                  value={salaryRange}
                  className={styles.sliderStyle}
                />
                <div>
                  Selected: ${salaryRange[0]} - ${salaryRange[1]}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FilterSection;
