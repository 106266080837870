import React, { useState } from 'react';
import { Row, Col, Input, Select, Button, Image, Form, Avatar } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import locationImg from '../../assets/Images/location.svg'
import expIcon from '../../assets/Images/inactive-adduser.svg'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_and_search_jobs, setAllJobs } from '../../features/Jobseeker/jobseekerSlice';
import { setOnsearchValues } from '../../features/Global/globalSlice';
const SearchBar = ({
  showYearsSelect = true, // Show or hide the years select dropdown
  showLocationSelect = true, // Show or hide the location select dropdown
  jobPlaceholder = "Product Designer", // Placeholder for the job input
  locationPlaceholder = "Delhi, India", // Placeholder for location select
  yearsPlaceholder = "Years", // Placeholder for years select
  jobOptions = [], // Options for job input field
  locationOptions = [], // Options for location dropdown
  yearsOptions = [], // Options for years dropdown
  onSearch, // Callback when the "Search" button is clicked
  searchBtnText,
  jobName = "",
  locationName = "",
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClearVisible, setIsClearVisible] = useState(false);

  // const onSearchData = (values) => {
  //   const searchParams = new URLSearchParams(values).toString();
  //   dispatch(get_and_search_jobs({ navigate, searchParams }));
  // };

  // const onSearchData = (values) => {
  //   const filteredValues = Object.keys(values).reduce((acc, key) => {
  //     if (values[key] && values[key].trim() !== "") {
  //       acc[key] = values[key];
  //     }
  //     return acc;
  //   }, {});
  //   const searchParams = new URLSearchParams(filteredValues).toString();
  //   dispatch(get_and_search_jobs({ navigate, searchParams }));
  // };


  const onClear = () => {
    form.resetFields(); // Reset all form fields
    dispatch(get_and_search_jobs({ navigate, searchParams: "" }));
    setIsClearVisible(false); // Hide the clear button
  };

  const onValuesChange = (_, allValues) => {
    const hasValues = Object.values(allValues).some((value) => value && value.trim() !== "");
    dispatch(setOnsearchValues(allValues))
    setIsClearVisible(hasValues);
  };

  return (
    <div>
      <Form form={form} onFinish={onSearch} onValuesChange={onValuesChange}>
        <Row gutter={[16, 16]} className='fullwidth'>
          <Col xs={24} lg={isClearVisible ? 8 : 9} md={isClearVisible ? 8 : 9}>
            <Form.Item name={jobName}>
              <Input
                className={styles.borderBottom}
                size="middle"
                placeholder={jobPlaceholder}
                prefix={<SearchOutlined />}
                options={jobOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={isClearVisible ? 8 : 9} md={isClearVisible ? 8 : 9}>
            {/* <Form.Item name={locationName} colon={false} >
              <Input prefix={<Image preview={false}  src={locationImg} />}  className={styles.borderBottom} placeholder={locationPlaceholder} />
            </Form.Item> */}
            <Form.Item name={locationName}>
              <Input
                className={styles.borderBottom}
                size="middle"
                placeholder={locationPlaceholder}
                prefix={<Image src={locationImg} />}
                options={jobOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={isClearVisible ? 4 : 6} md={isClearVisible ? 4 : 6}>
            <Form.Item>
              <Button
                size="middle"
                className={`default-button-style fullwidth ${styles.searchBarBTNS}`}
                onClick={onSearch} // Triggers the search callback
                htmlType='submit'
              >
                {/* Search my job */}
                {searchBtnText}
              </Button>
            </Form.Item>
          </Col>
          {isClearVisible && <Col xs={24} lg={4} md={4}>
            <Form.Item>
              <Button
                danger
                size="middle"
                className={`fullwidth ${styles.searchBarBTNS}`}
                onClick={onClear} // Triggers the search callback
                htmlType='submit'
              >
                {/* Search my job */}
                Clear
              </Button>
            </Form.Item>
          </Col>}
        </Row>
      </Form>
    </div>
  );
};

export default SearchBar;
