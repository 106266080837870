import { Button, Card, Col, message, Row, Tag } from 'antd'
import React, { useState } from 'react'
import styles from './style.module.scss'
import AddProfessionalDetailsModal from './AddProfesstionalDetailsModal';
import AddExperienceModal from './AddExperianceModal';
import { useDispatch, useSelector } from 'react-redux';
import { getDate } from '../../utils/helpers';
import { PlusOutlined } from '@ant-design/icons';
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import { useNavigate } from 'react-router-dom';

const ProfessionalDetails = ({ onNext }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [professionalDetails, setProfessionalDetails] = useState([]);
    const { userProfile } = useSelector((state) => state.global);

    const toggleShowMore = () => {
        setIsExpanded(!isExpanded);
    };


    const handleAddProfessionalDetails = (details) => {
        setProfessionalDetails([...professionalDetails, details]);
        setIsModalVisible(false); // Close modal after adding
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleAddClick = () => {
        setIsModalVisible(true);
    };

    const onRemoveExperiance= (id) => {
        const updatedExp = userProfile?.experiences?.filter((item) => item?._id !== id);
        const payload = {
            experiences: updatedExp,
        };
        dispatch(update_jobseeker({ body: payload, navigate }));
        message.success('Experiance removed successfully!');
    };

    return (
        <div>
            <Card className={styles.professWrapper}>
                <div className='flex-space-between'>
                    <h3>Employment details</h3>
                    {/* {userProfile?.experiences.length &&} */}
                    <div className='flex-wrap-row-direction-gap-5'>
                        {!userProfile?.experiences?.length ? null : <div><Button className='dark-bg' onClick={handleAddClick}>Add</Button></div>}
                        <Button onClick={onNext} size='middle' className='dark-bg'>Next</Button>
                    </div>
                </div>

                {!userProfile?.experiences?.length ?
                    <Card>
                        <Row justify={'center'}>
                            <Col xs={24} md={12} lg={12}>
                                <Button type='dashed' shape='round' onClick={handleAddClick} className='fullwidth noDataAddBtn' size='large' icon={<PlusOutlined />}>
                                    Add your Experience
                                </Button></Col>
                        </Row>
                    </Card>
                    :
                    <>

                        {userProfile?.experiences?.map((exp) => {
                            return (
                                <div>
                                    {/* <div className={styles.proffTitle}>ABC PVT LTD Gurgram , India</div> */}
                                    <div className={`flex-space-between ${styles.removeBtn}`}>
                                        <div className={styles.proffTitle} >{exp?.company}</div>
                                        <Button onClick={()=>{
                                            onRemoveExperiance(exp?._id)
                                        }} shape='round' size='small' className='dark-bg'>Remove</Button>
                                    </div>
                                    <Card className={styles.educatinCards}>
                                        <Row>
                                            <Col xs={24} lg={18} md={18}>
                                                <div className='flex-space-between'>
                                                    <div>
                                                        <div className='font-w-700'>{exp?.title}</div>
                                                        <div>
                                                            <Row>
                                                                <Col xs={24} lg={22} md={22}>
                                                                    <div className={isExpanded ? null : styles.textContainer}>
                                                                        {exp?.description} <span className={styles.showMore} onClick={toggleShowMore}>
                                                                            {isExpanded ? 'less...' : 'more...'}
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={24} lg={6} md={6}>
                                                <div className='text-right'>
                                                    <Tag color='#198b50'>{getDate(exp?.from)} - {getDate(exp?.till)}</Tag>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )
                        })}
                    </>
                }

            </Card>
            {/* <AddProfessionalDetailsModal
                visible={isModalVisible}
                onCancel={handleModalClose}
                onAdd={handleAddProfessionalDetails}
            /> */}
            <AddExperienceModal
                isExperienceModalVisible={isModalVisible}
                setIsExperienceModalVisible={setIsModalVisible}
            />
        </div>
    )
}

export default ProfessionalDetails