import React from 'react';
import { Row, Col, Input, Button, Avatar } from 'antd';
import { FacebookOutlined, LinkedinOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import Logo from '../CommonElements/Logo';

const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerContent}>
                <Row gutter={[16, 16]}>
                    {/* Left Side - Logo & Description */}
                    <Col xs={24} sm={12} md={6}>
                        <div className={styles.footerLogo}>
                            <Logo />
                            <p className={styles.description}>
                                Discover 5000+ Opportunities for Your Next Career Move!
                            </p>
                            <div className={styles.socialIcons}>
                                <Avatar className={styles.footerSocialIcon} shape='circle' size={30} icon={<FacebookOutlined />} />
                                <Avatar className={styles.footerSocialIcon} shape='circle' size={30} icon={<LinkedinOutlined />} />
                                <Avatar className={styles.footerSocialIcon} shape='circle' size={30} icon={<TwitterOutlined />} />
                                <Avatar className={styles.footerSocialIcon} shape='circle' size={30} icon={<InstagramOutlined />} />
                            </div>
                        </div>
                    </Col>

                    {/* About Us Section */}
                    <Col xs={24} sm={12} md={6}>
                        <h3 className={styles.footerHeading}>About Us</h3>
                        <ul className={styles.footerLinks}>
                            <li>Companies</li>
                            <li>Companies</li>
                            <li>Companies</li>
                            <li>Companies</li>
                            <li>Companies</li>
                            <li>Companies</li>
                        </ul>
                    </Col>

                    {/* Resources Section */}
                    <Col xs={24} sm={12} md={6}>
                        <h3 className={styles.footerHeading}>Resources</h3>
                        <ul className={styles.footerLinks}>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                        </ul>
                    </Col>

                    {/* Get Job Notifications Section */}
                    <Col xs={24} sm={12} md={6}>
                        <h3 className={styles.footerHeading}>Get Job Notifications</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonumy eirmod tempor</p>
                        {/* <Input
                            placeholder="Enter your email"
                            className={styles.emailInput}
                        />
                        <Button type="primary" className={`default-button-style ${styles.subscribeButton}`}>Subscribe</Button> */}
                    </Col>
                </Row>
            </div>

            <div className={styles.footerBottom}>
                <p>2024 © Eployr. All rights reserved.</p>
                <p>Images designed by FreePik</p>
            </div>
        </div>
    );
};

export default Footer;
