import React, { useState, useEffect } from 'react';
import { Avatar, Button, Card, Col, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ArrowRightOutlined, MailFilled } from '@ant-design/icons';
import styles from './style.module.scss';
import default_mail from '../../assets/Images/default_mail.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from '../../utils/authHelpers';
import { setSearchParameters } from '../../features/Global/globalSlice';
import { get_and_search_jobs } from '../../features/Jobseeker/jobseekerSlice';

const JobListing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = getAuthToken();

    // const fetchedCategories = [
    //     { id: 1, title: 'Web Developer', description: 'Build and maintain websites and web applications.', type: 'Technology', categories: ['Development', 'Software'] },
    //     { id: 2, title: 'Product Manager', description: 'Oversee product development and ensure success.', type: 'Management', categories: ['Management', 'Product'] },
    //     { id: 3, title: 'Design Market', description: 'Explore opportunities in the design industry.', type: 'Design Market', categories: ['Design', 'Marketing'] },
    //     { id: 4, title: 'Software Engineer', description: 'Develop, test, and maintain software systems.', type: 'Engineer', categories: ['Engineering', 'Software'] },
    //     { id: 5, title: 'UI/UX Designer', description: 'Create user-friendly and visually appealing designs.', type: 'Design Market', categories: ['Design', 'User Experience'] },
    //     { id: 6, title: 'Frontend Developer', description: 'Specialize in building user-facing interfaces.', type: 'Technology', categories: ['Frontend', 'Development'] },
    //     { id: 7, title: 'Backend Developer', description: 'Work on server-side logic and database management.', type: 'Technology', categories: ['Backend', 'Development'] },
    //     { id: 8, title: 'Full Stack Developer', description: 'Handle both frontend and backend development tasks.', type: 'Technology', categories: ['Technology', 'Development'] },
    //     { id: 9, title: 'Marketing Specialist', description: 'Develop marketing strategies to promote products.', type: 'Non-Tech' },
    //     { id: 10, title: 'Content Writer', description: 'Create compelling content for blogs, articles, and websites.', type: 'Non-Tech' },
    //     { id: 11, title: 'HR Manager', description: 'Manage recruitment, employee relations, and HR policies.', type: 'Non-Tech' },
    //     { id: 12, title: 'Customer Support', description: 'Provide support and resolve customer inquiries.', type: 'Non-Tech' },
    // ]

    const fetchedCategories = [
        { id: 1, title: 'Web Developer', description: 'Build and maintain websites and web applications.', type: 'Technology', categories: ['Frontend', 'Backend', 'Development'] },
        { id: 2, title: 'Product Manager', description: 'Oversee product development and ensure success.', type: 'Management', categories: ['Project Management', 'Product Design', 'Agile'] },
        { id: 3, title: 'Design Market', description: 'Explore opportunities in the design industry.', type: 'Design Market', categories: ['Graphics', 'Visual Design', 'Branding'] },
        { id: 4, title: 'Software Engineer', description: 'Develop, test, and maintain software systems.', type: 'Engineer', categories: ['Development', 'Testing', 'System Design'] },
        { id: 5, title: 'UI/UX Designer', description: 'Create user-friendly and visually appealing designs.', type: 'Design Market', categories: ['User Experience', 'Interface Design', 'Prototyping'] },
        { id: 6, title: 'Frontend Developer', description: 'Specialize in building user-facing interfaces.', type: 'Technology', categories: ['Frontend', 'UI Development', 'React'] },
        { id: 7, title: 'Backend Developer', description: 'Work on server-side logic and database management.', type: 'Technology', categories: ['Backend', 'Database Management', 'APIs'] },
        { id: 8, title: 'Full Stack Developer', description: 'Handle both frontend and backend development tasks.', type: 'Technology', categories: ['Frontend', 'Backend', 'Full Stack'] },
        // Non-Tech roles
        { id: 9, title: 'Marketing Specialist', description: 'Develop marketing strategies to promote products.', type: 'Non-Tech', categories: ['Digital Marketing', 'SEO', 'Campaign Management'] },
        { id: 10, title: 'Content Writer', description: 'Create compelling content for blogs, articles, and websites.', type: 'Non-Tech', categories: ['Copywriting', 'Content Strategy', 'Editing'] },
        { id: 11, title: 'HR Manager', description: 'Manage recruitment, employee relations, and HR policies.', type: 'Non-Tech', categories: ['Recruitment', 'Employee Engagement', 'Compliance'] },
        { id: 12, title: 'Customer Support', description: 'Provide support and resolve customer inquiries.', type: 'Non-Tech', categories: ['Customer Service', 'Problem Solving', 'Communication'] },
    ];


    const onSearchByCategory = (categoryTitle) => {
        const searchParams = `searchString=${categoryTitle}`;
        const navigationPage = token ? '/searched-jobs' : '/find-job';

        // Dispatch actions with properly constructed payload
        dispatch(setSearchParameters(searchParams));
        dispatch(get_and_search_jobs({ navigate, searchParams }));

        // Navigate to the appropriate page
        navigate(navigationPage);
    };


    return (
        <Content className={styles.JobListingWrapper}>
            <Row justify="center">
                <Col xs={24} xl={18} lg={18}>
                    <div className='flex-space-between'>
                        <h1>Feature Job Listing</h1>
                    </div>
                    <Row gutter={[16, 16]} className={styles.searchJobRow}>
                        {fetchedCategories.map((job) => (
                            <Col onClick={() => onSearchByCategory(job.title)} key={job.id} xs={24} xl={6} lg={6} md={6}>
                                <Card className={styles.jobCard}>
                                    <div className={styles.jobListingCards}>
                                        {/* <Avatar icon={<MailFilled />} className={styles.mailIcon} size={60} /> */}
                                        <Button className={`default-button-style border-none ${styles.jobTypebtn}`}>Full time</Button>
                                    </div>
                                    <h3>{job.title}</h3>
                                    <div>{job.location}</div>
                                    <p>{job.description}</p>
                                    <div className='margin-top-10'>
                                        {job.categories.map((category, index) => (
                                            <div className='margin-top-10'>
                                                <Button key={index} shape='round' size='small'>
                                                    {category}
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Content>
    );
};

export default JobListing;
