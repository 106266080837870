import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import HomePage from '../Homepage/HomePage'
import styles from './style.module.scss'
import { Content } from 'antd/es/layout/layout'
import EmployeeSection from '../Homepage/EmployeeSection'
import EmployeCategory from '../Homepage/EmployeCategory'
import JobListing from '../Homepage/JobListing'
import FeedbackList from '../Homepage/FeedbackList'
import Footer from '../../components/Footer/Footer'

const LandingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            <HomePage />
            <EmployeeSection />
            <EmployeCategory />
            <JobListing />
            <FeedbackList />
            <Footer />
        </>

    )
}

export default LandingPage