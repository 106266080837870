import React from 'react';
import { Modal, Progress } from 'antd';
import styles from './style.module.scss';

const VerificationSuccess = ({ isModalOpen, setIsModalOpen, message }) => {
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal
                centered
                width={300}
                footer={false}
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}  // This removes the top-right close icon
            >
                <div className={styles.modalContent}>
                    <div className={styles.progressWrapper}>
                        <Progress type="circle" percent={100} />
                    </div>
                    <div className={`${styles.messageStyle} text-center`}>
                        {message}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default VerificationSuccess;
