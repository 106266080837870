import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../../services/api/authService';
import AppSecureStorage from '../../services/storage/secureStorage';
import { toast } from 'react-toastify'
import { message } from 'antd'
import { get_jobseeker, setIsEmailVerify, setIsMobileVerify } from '../UserInfo/userInfoSlice';
import { setUserType } from '../Global/globalSlice';

const auth = new AuthService();
const storage = new AppSecureStorage();

const initialState = {
  user: null,
  isLoginPage: true,
  loading: false,
  signupLoading: false,
  isGenerateOTP: false,
  signupStep: 0,
  genrateOTPload: false,
  verifyOTPLoad: false,
  authDetails: {},
  passresetlinkload:false,
  passwordResetload:false,
  isNewPassword:false,
  isForgetpass:false,
  // userType: 'employee',
  // userType:localStorage.getItem('userType') || 'employee',
  googleLoad: false
};

export const signup_user = createAsyncThunk(
  "auth/signup_user",
  async ({ body }, { dispatch }) => {
    dispatch(setSignUpLoading(true))
    try {
      const response = await auth.SignUp(body)
      storage.setItem('token', response.data?.token)
      message.success(response.data.message)
      dispatch(setSignUpLoading(false))
      dispatch(setIsGenerateOTP(true))
      return response.data
    } catch (error) {
      dispatch(setSignUpLoading(false))
      error?.response?.data?.error && message.error(error?.response?.data?.error)
      const [{ msg }] = error.response.data
      message.error(msg)
    }
  }
);

export const login_user = createAsyncThunk(
  "auth/login_user",
  async ({ body, navigate }, { dispatch }) => {
    dispatch(setLoading(true))
    try {
      const response = await auth.login(body)
      const { data } = response;
      storage.setItem('token', data?.token)
      dispatch(setLoading(false))
      dispatch(get_jobseeker(navigate))
      dispatch(setUserType('employee'))
      storage.setItem('currentUser', 'employee')
      navigate('/find-job/application-status')
      message.success(response.data.msg)
      return response.data
    } catch (error) {
      error?.response?.data?.error && message.error(error?.response?.data?.error)
      const [{ msg }] = error.response.data
      dispatch(setLoading(false))
      message.error(msg)
    }
  }
);

export const Google_Auth_Login = createAsyncThunk(
  "auth/Google_Auth_Login",
  async ({ body, navigate }, { dispatch }) => {
    dispatch(setGoogleLoad(true))
    try {
      const response = await auth.GoogleAuthLogin(body)
      storage.setItem('token', response.data?.token)
      response?.data?.msg && message.success(response.data.msg)
      response.data.message && message.success(response.data.message)
      if (response) {
        dispatch(setGoogleLoad(false))
        navigate('/find-job/application-status')
      }
      return response.data
    } catch (error) {
      dispatch(setGoogleLoad(false))
      const [{ msg }] = error.response.data
      message.error(msg)
    }
  }
);


export const genrate_login_otp = createAsyncThunk(
  "auth/genrate_login_otp",
  async ({ body, navigate, currentStep, isMobile = false, isEmail = false }, { dispatch }) => {
    dispatch(setGenerateOTPLoad(true))
    try {
      const response = await auth.GenerateOTP(body)
      const { data } = response;
      message.success("OTP sent on your given mobile number and email")
      // storage.setItem('token', data?.token)
      // if(data){
      //   navigate('/')
      // }
      dispatch(setGenerateOTPLoad(false))
      dispatch(setSignupStep(1))
      dispatch(setIsGenerateOTP(false))
      if (isEmail) {
        dispatch(setIsEmailVerify(true))
      }
      if (isMobile) {
        dispatch(setIsMobileVerify(true))
      }
      if (currentStep < 1) {
        dispatch(setSignupStep(currentStep + 1));
      }
      return response.data
    } catch (error) {
      dispatch(setGenerateOTPLoad(false))
      error?.response?.data?.error && message.error(error?.response?.data?.error)
      // message.error(error.)
    }
  }
);

export const verify_otp = createAsyncThunk(
  "auth/verify_otp",
  async ({ body, navigate, isSignup }, { dispatch }) => {
    dispatch(setVerifyOTPLoad(true))
    try {
      const response = await auth.VerifyOTP(body)
      const { data } = response;
      message.success(data.message)
      // storage.setItem('token', data?.token)
      dispatch(setVerifyOTPLoad(false))
      dispatch(setIsEmailVerify(false))
      dispatch(setIsMobileVerify(false))
      if (isSignup) {
        if (data) {
          navigate('/find-job/application-status')
        }
      }
      return response.data
    } catch (error) {
      dispatch(setVerifyOTPLoad(false))
      error?.response?.data?.error && message.error(error?.response?.data?.error)
      const [{ msg }] = error.response.data
      message.error(msg)
      // message.error(error.)
    }
  }
);

export const send_password_reset_link = createAsyncThunk(
  "auth/send_password_reset_link",
  async ({ body, navigate }, { dispatch }) => {
    dispatch(setpasswordrestlinkload(true))
    try {
      const response = await auth.Send_reset_password_link(body)
      const { data } = response;
      message.success(data.msg)
      // storage.setItem('token', data?.token)
      dispatch(setpasswordrestlinkload(false))
      dispatch(setIsNewPassword(true))
      return response.data
    } catch (error) {
      dispatch(setpasswordrestlinkload(false))
      error?.response?.data?.error && message.error(error?.response?.data?.error)
      error?.response?.data?.error && message.error(error?.response?.data?.error)
      const [{ msg }] = error.response.data
      message.error(msg)
      // message.error(error.)
    }
  }
);

export const change_password = createAsyncThunk(
  "auth/Change_password",
  async ({ body, navigate, isSignup }, { dispatch }) => {
    dispatch(setchangepasswordload(true))
    try {
      const response = await auth.Change_password(body)
      const { data } = response;
      message.success(data.msg)
      // storage.setItem('token', data?.token)
      dispatch(setchangepasswordload(false))
      dispatch(setIsForgetPass(false))
      dispatch(setIsNewPassword(false))
      return response.data
    } catch (error) {
      dispatch(setIsNewPassword(false))
      dispatch(setchangepasswordload(false))
      error?.response?.data?.error && message.error(error?.response?.data?.error)
      const [{ msg }] = error.response.data
      message.error(msg)
      // message.error(error.)
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoginPage: (state, action) => {
      state.isLoginPage = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.user = action.payload
    },
    setSignUpLoading: (state, action) => {
      state.signupLoading = action.payload
    },
    setIsGenerateOTP: (state, action) => {
      state.isGenerateOTP = action.payload
    },
    setSignupStep: (state, action) => {
      state.signupStep = action.payload
    },
    setGenerateOTPLoad: (state, action) => {
      state.genrateOTPload = action.payload
    },
    setVerifyOTPLoad: (state, action) => {
      state.verifyOTPLoad = action.payload
    },
    setAuthDetails: (state, action) => {
      state.authDetails = action.payload
    },
    // setUserType: (state, action) => {
    //   state.userType = action.payload
    // },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setGoogleLoad: (state, action) => {
      state.googleLoad = action.payload
    },
    setpasswordrestlinkload: (state, action) => {
      state.passresetlinkload = action.payload
    },
    setchangepasswordload: (state, action) => {
      state.passwordResetload = action.payload
    },
    setIsNewPassword: (state, action) => {
      state.isNewPassword = action.payload
    },
    setIsForgetPass: (state, action) => {
      state.isForgetpass = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signup_user.pending, (state, action) => {
      state.loading = true;
    })
      .addCase(signup_user.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(signup_user.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(login_user.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(login_user.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(login_user.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(genrate_login_otp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(genrate_login_otp.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(genrate_login_otp.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(verify_otp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verify_otp.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(verify_otp.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(Google_Auth_Login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(Google_Auth_Login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(Google_Auth_Login.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(send_password_reset_link.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(send_password_reset_link.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(send_password_reset_link.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(change_password.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(change_password.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(change_password.rejected, (state, action) => {
        state.loading = false;
      })
  }
});

export const { setIsLoginPage, setLoggedInUser, setSignUpLoading,
  setIsGenerateOTP, setSignupStep, setGenerateOTPLoad, setVerifyOTPLoad, setAuthDetails,setchangepasswordload,setpasswordrestlinkload,
  setLoading, setGoogleLoad , setIsNewPassword , setIsForgetPass} = authSlice.actions;
export default authSlice.reducer;
