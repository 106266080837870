import { Avatar, Button, Card, Col, Grid, Image, Row, Typography } from 'antd'
import React, { useState } from 'react'
import styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import userCheckIcon from '../../../assets/Images/userChecked.svg'
import fileIcon from '../../../assets/Images/fileIcon.svg'
import fileCancel from '../../../assets/Images/fileCancel.svg';
import { getAuthToken } from '../../../utils/authHelpers'
import { setCurrentFilterSection } from '../../../features/Global/globalSlice'
import CreateJobModal from '../Dashboard/CreateJobModal'
import CreateJobWithBoost from '../Dashboard/CreateJobWithBoost'
import { FireFilled, FireOutlined, PlusOutlined } from '@ant-design/icons'
import FilterLayoutSM from '../../../components/CommonElements/FilterLayoutSM'
const { useBreakpoint } = Grid;

const OrgTopHeader = () => {
    const token = getAuthToken()
    const screens = useBreakpoint()
    const { currentFilterSection, global } = useSelector((state) => state.global)
    const dispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isBoostVisible, setIsBoostVisible] = useState(false);

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const showBoostModal = () => setIsBoostVisible(true);
    const hideBoostModal = () => setIsBoostVisible(false);
    const onFilterSectionChange = (status) => {
        dispatch(setCurrentFilterSection(status))
    }

    const renderStatus = ()=>{
        return (
            <>
             <Row gutter={[16, 16]} className={styles.topRowWrapper}>
                    <Col lg={6} md={6} xs={24}>
                        <Card className={styles.totalCard}>
                            <div className='flex-space-between'>
                                <div>
                                    <Image preview={false} height={30} src={fileIcon} className={styles.countStyle} size={50} shape='square' />
                                    <div className={styles.totalTitle}>Open Jobs</div>
                                </div>
                                <div>
                                    <div className={styles.countStyle} shape='square'>100</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={6} md={6} xs={24}>
                        <Card className={styles.totalCard}>
                            <div className='flex-space-between'>
                                <div>
                                    <Image preview={false} height={30} src={userCheckIcon} className={styles.countStyle} size={50} shape='square' />
                                    <div className={styles.totalTitle}>Hold Jobs</div>
                                </div>
                                <div>
                                    <div className={styles.countStyle} shape='square'>50</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={6} md={6} xs={24}>
                        <Card className={styles.totalCard}>
                            <div className='flex-space-between'>
                                <div>
                                    <Image preview={false} height={30} src={fileCancel} className={styles.countStyle} size={50} shape='square' />
                                    <div className={styles.totalTitle}>Draft Jobs</div>
                                </div>
                                <div>
                                    <div className={styles.countStyle} shape='square'>30</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={6} md={6} xs={24}>
                        <Card className={styles.totalCard}>
                            <div className='flex-space-between'>
                                <div>
                                    <Image preview={false} height={30} src={fileCancel} className={styles.countStyle} size={50} shape='square' />
                                    <div className={styles.totalTitle}>Close Jobs</div>
                                </div>
                                <div>
                                    <div className={styles.countStyle} shape='square'>10</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <div className={styles.topRow}>
            {((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="2" label="Show status" children={renderStatus()} /> : renderStatus()}
                <div className={`flex-wrap-row-direction-gap-15 fullwidth ${styles.filtersBtn}`}>
                    <Button iconPosition='end' icon={<PlusOutlined />} onClick={showModal} className={`default-button-style  ${screens.lg ? styles.createjobbtn : ''}`} size='large'>Create New Job</Button>
                    <Button iconPosition='end' icon={<FireFilled className={styles.fireIcon} />} onClick={showBoostModal} className={`default-button-style  ${screens.lg ? styles.createjobbtn : ''}`} size='large'>Create Job with Boost</Button>
                </div>
            </div>
            <CreateJobModal visible={isModalVisible} onClose={hideModal} />
            <CreateJobWithBoost visible={isBoostVisible} onClose={hideBoostModal} />
        </>
    )
}

export default OrgTopHeader