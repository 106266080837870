import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, Radio, Row, Col, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import styles from './style.module.scss';
import { get_profile_percentage } from '../../features/Jobseeker/jobseekerSlice';
import { CalendarOutlined } from '@ant-design/icons';

const { Option } = Select;

const ProfileEditor = ({ setIsTopProfileEdit, isTopProfileEdit }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userProfile } = useSelector((state) => state.global);

    const [experienceType, setExperienceType] = useState('');
    // Initialize form fields
    const initialValues = {
        name: userProfile?.name || '',
        designation: userProfile?.title || '',
        visibility: userProfile?.visibility || 'visible',
        candidateType: userProfile?.candidateType || 'fresher',
        yearsOfExperience: userProfile?.experience?.year || '',
        monthsOfExperience: userProfile?.experience?.month || '',
        currentAddress: userProfile?.currentAddress || '',
        permanentAddress: userProfile?.permanentAddress || '',
        email: userProfile?.email || '',
        dob: userProfile?.dob || '',
        gender: userProfile?.gender || '',
        mobile: userProfile?.mobile || '',
        designation: userProfile?.designation || '',
        workPermit:userProfile?.workPermit || '',
        countryCode:userProfile?.countryCode || ''
    };

    form.setFieldsValue(initialValues);

    const handleCancel = () => {
        setIsTopProfileEdit(false);
    };

    const onFinish = (values) => {
        console.log("values", values)
        const {
            name, designation, visibility, candidateType,
            yearsOfExperience, monthsOfExperience,
            currentAddress, permanentAddress, email, countryCode,
            dob, gender, mobile,workPermit
        } = values;

        // let experiencePayload = candidateType;
        // if (candidateType === 'experianced') {
        //     experiencePayload = {
        //         year: yearsOfExperience,
        //         month: monthsOfExperience
        //     };
        // }

        const payload = {
            // name,
            designation: designation,
            // visibility,
            candidateType,
            // experience: experiencePayload,
            currentAddress,
            permanentAddress,
            email,
            countryCode,
            workPermit,
            // dob,
            // gender,
            mobile
        };

        dispatch(update_jobseeker({ body: payload, navigate }));
        dispatch(get_profile_percentage(navigate))
        setIsTopProfileEdit(false);
    };

    const handleExperienceChange = (value) => {
        setExperienceType(value); // Track the selected experience type
    };

    return (
        <Modal
            title="Edit Profile"
            visible={isTopProfileEdit}
            onCancel={handleCancel}
            footer={
                <div className={styles.modalFooter}>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={() => form.submit()} className='default-button-style'>Save</Button>
                </div>
            }
            centered
            width={1000}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    {/* <Col xs={24} lg={12} md={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter your name' }]}
                        >
                            <Input disabled={Boolean(userProfile?.name)} />
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} lg={12} md={12}>
                        <Form.Item
                            name="designation"
                            label="Designation"
                            rules={[{ required: true, message: 'Please enter your designation' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} lg={12} md={12}>
                        <Form.Item name="visibility" label="Visibility">
                            <Radio.Group>
                                <Radio value="visible">Profile visible to recruiters</Radio>
                                <Radio value="private">Keep it private</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} lg={12} md={12}>
                        {/* <Form.Item
                            name="candidateType"
                            label="Experience Type"
                            rules={[{ required: true, message: 'Please select your experience type' }]}
                        >
                            <Select onChange={(val) => setExperienceType(val)}>
                                <Option value="fresher">Fresher</Option>
                                <Option value="experianced">Experienced</Option>
                                <Option value="gap">Gap</Option>
                            </Select>
                        </Form.Item> */}
                        <Form.Item
                            name="candidateType"
                            label="Candidate Type"
                            rules={[{ required: true, message: 'Please select experience!' }]}
                        >
                            <Select placeholder="Select candidate type">
                                <Select.Option value="fresher">Fresher</Select.Option>
                                <Select.Option value="experienced">Experienced</Select.Option>
                                <Select.Option value="gap">Gap</Select.Option>
                                {/* <Select.Option value="Behance">Behance</Select.Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* {experienceType === 'experienced' && (
                        <Form.Item name="experienceDetails" label="Experience">
                            <Input.Group compact>
                                <Form.Item name="years" noStyle>
                                    <Input style={{ width: '50%' }} placeholder="Years" />
                                </Form.Item>
                                <Form.Item name="months" noStyle>
                                    <Input style={{ width: '50%' }} placeholder="Months" />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    )} */}
                    <Col xs={24} lg={12} md={12}>
                        <Form.Item
                            name="currentAddress"
                            label="Current Address"
                            rules={[{ required: true, message: 'Please enter your current address' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                        <Form.Item
                            name="permanentAddress"
                            label="Permanent Address"
                            rules={[{ required: true, message: 'Please enter your permanent address' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                        <Form.Item
                            label="Work Permit"
                            name="workPermit"
                            rules={[{ required: true, message: 'Please select your work permit' }]}
                        >
                            <Select size='large' mode="multiple" placeholder="Select work permits">
                                <Option value="Indian">Indian</Option>
                                <Option value="Other">Other</Option>
                                <Option value="US">US</Option>
                                <Option value="UK">UK</Option>
                                <Option value="Canada">Canada</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} lg={12} md={12}>
                        <Form.Item
                            name="dob"
                            label="Date of Birth"
                            rules={[{ required: true, message: 'Please enter your date of birth' }]}
                        >
                            <Input type="date" />
                        </Form.Item>
                    </Col> */}
                    {/* <Col xs={24} lg={12} md={12}>
                        <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[{ required: true, message: 'Please select your gender' }]}
                        >
                            <Select>
                                <Option value="male">Male</Option>
                                <Option value="female">Female</Option>
                                <Option value="other">Other</Option>
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} lg={12} md={12}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={7} lg={7}>
                                <Form.Item name="countryCode"
                                    label="Country Code"
                                    rules={[{ required: true, message: 'Please enter country code' }]}
                                >
                                    <Input placeholder='country code' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={17} lg={17}>
                                <Form.Item
                                    name="mobile"
                                    label="Mobile"
                                    rules={[{ required: true, message: 'Please enter your mobile number' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ProfileEditor;
