import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Radio, Divider, message, Grid, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import Logo from '../CommonElements/Logo';
import AppSecureStorage from '../../services/storage/secureStorage';
import { useDispatch, useSelector } from 'react-redux';
import { genrate_login_otp, Google_Auth_Login, login_user, setAuthDetails, setIsForgetPass, setSignupStep } from '../../features/Auth/authSlice';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import GoogleAuth from './GoogleAuth';
import { org_login } from '../../features/Organization/OrgAuth/OrgAuthSlice';
import { setUserType } from '../../features/Global/globalSlice';
import { GoogleLogin } from '@react-oauth/google';
import { getCurrentUser } from '../../utils/authHelpers';
import IsForgetPasswordLink from './IsForgetPasswordLink';

const storage = new AppSecureStorage();

const { useBreakpoint } = Grid;

const Login = () => {
    const currentUser = getCurrentUser()
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [value, setValue] = useState('employee');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isSendOtp, setIsSendOtp] = useState(false);
    const { genrateOTPload, isGenerateOTP, loading } = useSelector((state) => state.auth);
    const { userType } = useSelector((state) => state.global)

    const onChange = (e) => {
        dispatch(setUserType(e.target.value))
        setValue(e.target.value);
    };

    const onFinish = (values) => {
        const payload = {
            email: values?.email,
            password: values.password
        }
        dispatch(login_user({ body: payload, navigate: navigate }));
    };

    const goToSignupPage = () => {
        dispatch(setSignupStep(0))
        dispatch(setUserType("employee"))
        navigate('/sign-up');
    };
    const goToOtpScreen = () => {
        // Validate the phone number before navigating
        dispatch(setUserType('employee'))
        const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`; // Get the first part as country code
        const mobile = phoneNumber.slice(-10); // Get the last 10 digits as the mobile number
        if (!phoneNumber || phoneNumber.length < 10) {
            message.error('Please enter a valid phone number');
            return;
        }
        dispatch(setAuthDetails({ phoneNumber: mobile, countryCode: countryCode }))
        navigate('/otp-verification');
    };

    const onGenerateOTP = () => {
        // Validate the mobile number
        if (!phoneNumber) {
            message.error('Please enter your phone number');
            return;
        }

        // Extract the country code and mobile number correctly
        const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`; // Get the first part as country code
        const mobile = phoneNumber.slice(-10); // Get the last 10 digits as the mobile number

        // Prepare payload for OTP generation
        const payload = {
            mobile,
            countryCode
        };

        dispatch(genrate_login_otp({ body: payload, navigate: navigate, currentStep: 0 }));

        if (isGenerateOTP) {
            navigate('/otp-verification');
        }
    };

    const handleGoogleLoginSuccess = (response) => {
        const payload = {
            clientId: response?.clientId,
            credentials: response?.credential
        }
        // Send response token to the backend to verify and log the user in
        dispatch(Google_Auth_Login({ body: payload, navigate: navigate }))
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const goToOtpVerification = () => {
        // dispatch(setUserType(value))
        const data = form.getFieldsValue()
        const payload = {
            email: data?.companyEmail,
            password: data?.orgpassword
        }
        dispatch(org_login({ body: payload, navigate: navigate }));
    }


    return (
        <AuthLayout>
            <div
            // className={styles.loginFormSection}
            >
                {/* <Logo />
                <h2>Login</h2>

                <div>
                    <Form.Item colon={false} label="Continue as">
                        <Radio.Group onChange={onChange} value={userType}>
                            <Radio value={'employee'}>Employee</Radio>
                            <Radio value={'organization'}>Organization</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div> */}

                <Form
                    name="login_email"
                    // className={styles.loginForm}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    form={form}
                >
                    <Form.Item
                        name={userType === 'organization' ? 'companyEmail' : 'email'}
                        rules={[
                            { required: true, message: 'Please input your Email!' },
                            { type: 'email', message: 'The input is not valid E-mail!' }
                        ]}
                        className="fullwidth"
                    >
                        <Input size='large' prefix={<UserOutlined />} placeholder={userType === 'organization' ? 'Enter company code / company email' : 'Email'} />
                    </Form.Item>

                    <Form.Item
                        name={userType === 'organization' ? 'orgpassword' : 'password'}
                        rules={[{ required: true, message: 'Please input your Password!' }]}>
                        <Input.Password size='large' prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item>

                    <div className='flex-space-between'>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        {userType === 'employee' && 
                        <div onClick={() => dispatch(setIsForgetPass(true))} className={`add-cursor error-text ${styles.loginFormForgot}`} >
                            Forgot password
                        </div>
                         }

                    </div>

                    <Form.Item>
                        {/* <Button shape='round' size='large' type="primary" htmlType="submit" className={`default-button-style ${styles.loginFormButton}`}>
                            Login
                        </Button> */}
                        {userType === 'organization' ?
                            <Button onClick={goToOtpVerification} shape='round' size='large' type="primary" className={`default-button-style ${styles.loginFormButton}`}>
                                Login
                            </Button>
                            :
                            <Button loading={loading} shape='round' size='large' type="primary" htmlType="submit" className={`default-button-style ${styles.loginFormButton}`}>
                                Login
                            </Button>}
                    </Form.Item>
                    <div className='text-center'>
                        <p>
                            Don’t have an account? <span onClick={goToSignupPage} className={styles.backtosignup}>Click here to signup</span>
                        </p>
                    </div>

                    {userType === 'organization' ?
                        null
                        :
                        <>
                            <Divider style={{ borderColor: '#000' }}>OR</Divider>
                        </>
                    }

                </Form>

                {userType === 'organization' ?
                    null
                    :
                    <>
                        <Form
                            // className={styles.loginForm} 
                            name="login_phone" >
                            <p className="text-center">Sign up via Google</p>
                            <div className={`${styles.googleAuthWrapper} custom-google-button`}>
                                <div className={styles.googleAuthBtnUP}>
                                    <GoogleLogin
                                        onSuccess={handleGoogleLoginSuccess}
                                        onError={handleGoogleLoginFailure}
                                        shape="circle"
                                        className="googleAuth"
                                        theme="outline"
                                        // useOneTap
                                        width={300}
                                        size='large'
                                    />
                                </div>
                            </div>
                        </Form>
                    </>
                }
            </div>
            <IsForgetPasswordLink />
        </AuthLayout>
    );
};

export default Login;
