import React, { useEffect, useState } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import { Content } from 'antd/es/layout/layout'
import { Button, Card, Checkbox, Col, Grid, Input, Row, Select } from 'antd'
import styles from './style.module.scss'
import { SearchOutlined } from '@ant-design/icons'
import JobList from './JobList'
import LoggedInSidebar from './LoggedInSidebar'
import FindJobPageLayout from './FindJobPageLayout'
import TopHeaderFindJob from './TopHeaderFindJob'
import { getAuthToken } from '../../utils/authHelpers'
import { useDispatch, useSelector } from 'react-redux'
import ApplicationStatus from './ApplicationStatus'
import FilterredApplyJobs from './FilterredApplyJobs'
import FillteredApplicationStatus from './FillteredApplicationStatus'
import FilterSection from '../../components/CommonElements/FilterSection'
import InterviewRating from './InterviewRating'
import { apply_job_api, get_and_search_jobs, get_profile_percentage, save_job_api, unsave_job_api } from '../../features/Jobseeker/jobseekerSlice'
import { useNavigate } from 'react-router-dom'
import { get_jobseeker, get_jobseeker_skills } from '../../features/UserInfo/userInfoSlice'
import { setCurrentFilterSection, setCurrentJobData, setCurrentJobStatus } from '../../features/Global/globalSlice'
import { applyFiltersAllPages } from '../../utils/helpers'
import FilterLayoutSM from '../../components/CommonElements/FilterLayoutSM'
import ScreenFreezLoader from '../../components/CommonElements/ScreenFreezLoader'

const { useBreakpoint } = Grid;

const FindJobApplicationPage = () => {
  const screens = useBreakpoint();
  const token = getAuthToken()
  const navigate = useNavigate()
  const { currentFilterSection, userProfile, internalSearchParameter, searchParameters, onSearchValues } = useSelector((state) => state.global)
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [loadingJobId, setLoadingJobId] = useState(null);
  const { alljobs, applyJobLoad, isSaveJobDone, loader } = useSelector((state) => state.jobseeker);

  const handleApplyClick = (job) => {
    // Handle apply logic here
    const payload = {
      jobId: job?._id,
      userId: userProfile?._id
    }
    setLoadingJobId(job?._id);
    dispatch(apply_job_api({ body: payload, navigate: navigate }))
  };

  const handleSaveClick = (job) => {
    const payload = {
      jobId: job?._id,
      userId: userProfile?._id
    }
    dispatch(setCurrentJobData(job))
    dispatch(save_job_api({ body: payload, navigate: navigate }))
  };

  const handleUnSaveClick = (job) => {
    const payload = {
      jobId: job?._id,
      userId: userProfile?._id
    }
    dispatch(setCurrentJobData(job))
    dispatch(unsave_job_api({ body: payload, navigate: navigate }))
  };

  const handleShareClick = (job) => {
    // Handle share logic here
  };

  // const handleFilterChange = (newFilters) => {
  //   setFilters(newFilters);
  //   setIsFilterApplied(
  //     Object.values(newFilters).some((values) => values.length > 0)
  //   );
  // };
  const handleFilters = (filters) => {
    setFilters(filters)
    setIsFilterApplied(
      Object.values(filters)?.some((values) => values?.length > 0)
    );
    // Example: If only jobType is selected, you'll see { jobType: "full-time" }
  };

  const applyFilters = () => {
    applyFiltersAllPages(internalSearchParameter, searchParameters, onSearchValues, filters, dispatch, navigate, get_and_search_jobs)
  };

  const clearFilters = () => {
    setFilters({});
    setIsFilterApplied(false);
  };
  useEffect(() => {
    dispatch(get_jobseeker(navigate))
    if(token){
      dispatch(get_profile_percentage(navigate))
    }
    dispatch(get_jobseeker_skills(navigate))
  }, [])

  const renderFilter = () => {
    return (
      <>
        <FilterSection
          postedAtOptions={[
            { value: '1', label: 'Jack' },
            { value: '2', label: 'Lucy' },
            { value: '3', label: 'Tom' },
          ]}
          jobTypeOptions={[
            { label: 'Full-time', value: 'full-time' },
            { label: 'Part-time', value: 'part-time' },
            { label: 'Internship', value: 'internship' },
            { label: 'Freelance', value: 'freelance' },
          ]}
          experienceOptions={[
            { label: 'Fresher', value: 'fresher' },
            { label: '1+', value: '1+' },
            { label: '2+ to 4+', value: '2+ to 4+' },
            { label: '6+ to 8+', value: '8+ to 10+' },
            { label: '10+ to 15+', value: '15+ to 25+' },
          ]}
          salaryOptions={[
            { label: 'Under $1000', value: 'under_1000' },
            { label: '$1k-$2k', value: '1k-2k' },
            { label: '$3k-$5k', value: '3k-5k' },
            { label: '$6k-$9k', value: '6k-9k' },
          ]}
          showSalaryRange={true}
          showExperience={true}
          onFilterChange={handleFilters}
          isFilterApplied={isFilterApplied}
          applyFilters={applyFilters}
          apiCall={get_and_search_jobs}
          setIsFilterApplied={setIsFilterApplied}
        />
      </>
    )
  }
  return (
    <div>
      <FindJobPageLayout>
        <ScreenFreezLoader loading={applyJobLoad} text='Job Applying Please wait' />
        <TopHeaderFindJob title={<h2>Find your dream job</h2>} content={"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor i"} />
        <div className={token ? styles.filterJobWrapper : styles.filterJobNoToken}>

          <Row gutter={[16, 16]} justify={'center'}>
            <Col xs={24} lg={5} md={24}>
              {currentFilterSection === 'apply-jobs' &&
                ((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="1" label="Add Filters" children={renderFilter()} /> : renderFilter()

              }
              {currentFilterSection === 'application-status' && <ApplicationStatus />}
              {currentFilterSection === 'interview-rating' && <ApplicationStatus />}

            </Col>
            <Col xs={24} lg={19} md={24}>
              {currentFilterSection === 'apply-jobs' && <FilterredApplyJobs />}
              {currentFilterSection === 'application-status' && <FillteredApplicationStatus buttonText="Apply Now"
                onButtonClick={handleApplyClick}
                onShareClick={handleShareClick}
                onSaveClick={handleSaveClick}
                onUnSaveClick={handleUnSaveClick}
                submitload={applyJobLoad}
                loadingJobId={loadingJobId}
                onBack={() => {
                  dispatch(setCurrentFilterSection('apply-jobs'))
                  dispatch(setCurrentJobStatus({}))
                }}
                page="application" />
              }
              {currentFilterSection === 'interview-rating' && <InterviewRating
                buttonText="Apply Now"
                onButtonClick={handleApplyClick}
                onShareClick={handleShareClick}
                onSaveClick={handleSaveClick}
                onUnSaveClick={handleUnSaveClick}
                submitload={applyJobLoad}
                loadingJobId={loadingJobId}
                onBack={() => {
                  dispatch(setCurrentFilterSection('apply-jobs'))
                  // dispatch(setCurrentJobStatus({}))
                }}
                page="application"
              />}
            </Col>
          </Row>
        </div>

      </FindJobPageLayout>
    </div>
  )
}

export default FindJobApplicationPage
