import React, { useState } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import { Content } from 'antd/es/layout/layout'
import { Avatar, Button, Card, Checkbox, Col, Grid, Image, Input, Row, Select } from 'antd'
import styles from './style.module.scss'
import { getAuthToken } from '../../utils/authHelpers'
import { useDispatch, useSelector } from 'react-redux'
import TopHeaderFindJob from '../FindJobPage/TopHeaderFindJob'
import FindJobPageLayout from '../FindJobPage/FindJobPageLayout'
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import Meta from 'antd/es/card/Meta'
import bookmarkImg from '../../assets/Images/active-bookmark.svg'
import { setBlogStatus } from '../../features/Global/globalSlice'
import FilterSection from '../../components/CommonElements/FilterSection'
import SearchBar from '../../components/CommonElements/SearchBar'
import { get_and_search_jobs } from '../../features/Jobseeker/jobseekerSlice'
import { useNavigate } from 'react-router-dom'
import FilterLayoutSM from '../../components/CommonElements/FilterLayoutSM'

const { useBreakpoint } = Grid;
const Blogs = () => {
  const screens = useBreakpoint();
  const token = getAuthToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentFilterSection, blogsStatus, searchParameters, internalSearchParameter, onSearchValues } = useSelector((state) => state.global);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState({});

  const applyFilters = () => {
    // Helper function to parse existing search parameters into an object
    const parseSearchParams = (paramString) => {
      const params = new URLSearchParams(paramString);
      const result = {};
      for (let [key, value] of params.entries()) {
        result[key] = value;
      }
      return result;
    };

    // Parse existing search parameters if available
    const parsedInternalParams = internalSearchParameter
      ? parseSearchParams(internalSearchParameter)
      : {};
    const parsedSearchParams = searchParameters
      ? parseSearchParams(searchParameters)
      : {};
    const parsedonSearchParams = onSearchValues
      ? parseSearchParams(onSearchValues)
      : {};

    // Merge parsed parameters with current filters
    const combinedFilters = {
      ...parsedInternalParams,
      ...parsedSearchParams,
      ...parsedonSearchParams,
      ...filters,
    };

    // Create the final search string
    const searchParams = new URLSearchParams(combinedFilters).toString();
    console.log("Final Search Params:", searchParams); // Debugging purpose
    dispatch(get_and_search_jobs({ navigate: navigate, searchParams: searchParams }));
  };

  const onChangeStaus = (status) => {
    dispatch(setBlogStatus(status))
  }

  const renderFilter = () => {
    return (
      <>
        <FilterSection
          postedAtOptions={[
            { value: '1', label: 'Jack' },
            { value: '2', label: 'Lucy' },
            { value: '3', label: 'Tom' },
          ]}
          jobTypeOptions={[
            { label: 'Full-time', value: 'full-time' },
            { label: 'Part-time', value: 'part-time' },
            { label: 'Internship', value: 'internship' },
            { label: 'Freelance', value: 'freelance' },
          ]}
          experienceOptions={[
            { label: 'Fresher', value: 'fresher' },
            { label: '1+', value: '1+' },
            { label: '2+ to 4+', value: '2+ to 4+' },
            { label: '6+ to 8+', value: '8+ to 10+' },
            { label: '10+ to 15+', value: '15+ to 25+' },
          ]}
          salaryOptions={[
            { label: 'Under $1000', value: 'under_1000' },
            { label: '$1k-$2k', value: '1k-2k' },
            { label: '$3k-$5k', value: '3k-5k' },
            { label: '$6k-$9k', value: '6k-9k' },
          ]}
          showSalaryRange={true}
          showExperience={true}
          isFilterApplied={isFilterApplied}
          applyFilters={applyFilters}
          setIsFilterApplied={setIsFilterApplied}
        />
      </>
    )
  }

  return (
    <div>
      <FindJobPageLayout>
        <div className={token ? styles.filterJobWrapper : styles.filterJobNoToken}>
          <Button onClick={() => { onChangeStaus('new') }} className='default-button-style'>New Blogs</Button>
          <Button onClick={() => { onChangeStaus('saved') }} className='default-button-style'>Saved Blogs</Button>
        </div>

        <div
          // className={token ? styles.filterJobWrapper : styles.filterJobNoToken}
          className={styles.recomendJobBox}
        >

          <Row justify={'center'}>
            <Col xs={24} lg={5} md={24}>
              {((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="1" label="Add Filters" children={renderFilter()} /> : renderFilter()}
            </Col>
            <Col xs={24} lg={19} md={24}>
              {/* <FilterredApplyJobs /> */}
              <div className={styles.cardStyleJob}>
                <Card className={styles.searchCards}>
                  <SearchBar
                    showYearsSelect={false}
                    showLocationSelect={false}
                    jobPlaceholder={blogsStatus === 'new' ? 'Atrifical Inteligency' : 'Search'}
                    locationPlaceholder="Delhi, India"
                    yearsPlaceholder="5 years"
                    locationOptions={[
                      { value: '1', label: 'Not Identified' },
                      { value: '2', label: 'Closed' },
                      { value: '3', label: 'Communicated' },
                    ]}
                    yearsOptions={[
                      { value: '1', label: '1-2 years' },
                      { value: '2', label: '3-5 years' },
                      { value: '3', label: '6+ years' },
                    ]}
                    onSearch={() => console.log('Search button clicked')}
                    searchBtnText="Search Blogs"
                  // apiCall={get_saved_jobs}
                  // userId={userProfile?._id}
                  />
                </Card>
                <h3>{blogsStatus === 'new' ? '50 searched results' : 'All Saved Blogs'}</h3>
              </div>
              <div className={styles.cardStyleJob}>
                <Card>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12} md={12}>
                      <Card
                        hoverable
                        cover={<Image height={300} alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                      >
                        <Meta title={
                          <div className={styles.blogsTitle}>
                            <Button shape='round'>Artificial Inteligency</Button>
                            <Button shape='round'>Save Blog <Image src={bookmarkImg} /></Button>
                          </div>} description="Reference site about Lorem Ipsum, giving..." />
                        <div className={styles.blogFooter}>
                          <div>4 min read</div>
                          <div>|</div>
                          <div>15 / 08/ 2022</div>
                          <div></div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                      <Card
                        hoverable
                        cover={<Image height={300} alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                      >
                        <Meta title={
                          <div className={styles.blogsTitle}>
                            <Button shape='round'>Artificial Inteligency</Button>
                            <Button shape='round'>Save Blog <Image src={bookmarkImg} /></Button>
                          </div>} description="Reference site about Lorem Ipsum, giving..." />
                        <div className={styles.blogFooter}>
                          <div>4 min read</div>
                          <div>|</div>
                          <div>15 / 08/ 2022</div>
                          <div></div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </FindJobPageLayout>
    </div>
  )
}

export default Blogs
