import React, { useState } from "react";
import { Table, Input, Button, Modal, Form, Select, Card, Tag, Row, Col, message } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { update_jobseeker } from "../../features/UserInfo/userInfoSlice";
import styles from "./style.module.scss";

const { Option } = Select;

const SkillsSection = () => {
  const dispatch = useDispatch();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [skills, setSkills] = useState([]);
  const [editingSkill, setEditingSkill] = useState(null);
  const { userProfile } = useSelector((state) => state.global);
  const { employeeSkills } = useSelector((state) => state.userInfo);

  const columns = [
    { title: "S.no", dataIndex: "key", key: "key", render: (text, record, index) => index + 1 },
    { title: "Domain Name", dataIndex: "domain", key: "domain" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "Proficiency",
      dataIndex: "proficiency",
      key: "proficiency",
      render: (proficiency) => {
        let color = "";
        switch (proficiency) {
          case "Expert":
            color = "green";
            break;
          case "Intermediate":
            color = "blue";
            break;
          case "Beginner":
            color = "orange";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{proficiency}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditSkill(index)}
          />
          <Button
            icon={<DeleteOutlined style={{ color: "red" }} />}
            type="text"
            onClick={() => handleDeleteSkill(index)}
            style={{ marginLeft: "8px" }}
          />
        </>
      ),
    },
  ];

  const handleAddNewSkill = () => {
    setSkills([...skills, { key: Date.now(), name: "", domain: "", type: "", proficiency: "", experience: "" }]);
  };

  const handleSkillChange = (index, field, value) => {
    setSkills((prevSkills) => {
      const updatedSkills = [...prevSkills];
      updatedSkills[index] = { ...updatedSkills[index], [field]: value };
      return updatedSkills;
    });
  };


  const handleSkillSelect = (index, skillId) => {
    if (skillId === "other") {
      setSkills((prevSkills) => {
        const updatedSkills = [...prevSkills];
        updatedSkills[index] = {
          ...updatedSkills[index],
          isOther: true,
          name: "",
          domain: "",
          type: "",
        };
        return updatedSkills;
      });
    } else {
      const selectedSkill = employeeSkills?.data?.find((skill) => skill._id === skillId);
      if (selectedSkill) {
        setSkills((prevSkills) => {
          const updatedSkills = [...prevSkills];
          updatedSkills[index] = {
            ...updatedSkills[index],
            isOther: false,
            name: selectedSkill.name,
            domain: selectedSkill.domain,
            type: selectedSkill.type,
          };
          return updatedSkills;
        });
      }
    }
  };


  const handleDeleteSkillInModal = (index) => {
    setSkills((prevSkills) => prevSkills.filter((_, i) => i !== index));
  };

  const handleDeleteSkill = (index) => {
    const updatedSkills = userProfile?.skills.filter((_, i) => i !== index);
    dispatch(update_jobseeker({ body: { skills: updatedSkills } }));
  };

  const handleEditSkill = (index) => {
    const skillToEdit = userProfile?.skills[index];
    setEditingSkill(index);
    setSkills([skillToEdit]);
    setIsModalVisible(true);
  };

  const handleSave = () => {
    if (skills.some((skill) => !skill.name || !skill.proficiency || !skill.experience)) {
      return message.error("Please fill in all required fields.");
    }

    if (editingSkill !== null) {
      const updatedSkills = [...userProfile?.skills];
      updatedSkills[editingSkill] = skills[0];
      dispatch(update_jobseeker({ body: { skills: updatedSkills } }));
    } else {
      const payload = { skills: [...userProfile?.skills, ...skills] };
      dispatch(update_jobseeker({ body: payload }));
    }

    setIsModalVisible(false);
    setSkills([]);
    setEditingSkill(null);
  };

  const handleReset = () => {
    setSkills([]);
    setIsModalVisible(false);
    setEditingSkill(null);
  };

  return (
    <>
      <div className={styles.jobStatusContainer}>
        <Card
          className={styles.recommentTableHeading}
          title={
            <div className="flex-space-between">
              <div>Skills</div>
              {!userProfile?.skills?.length ? null :
                <Button onClick={() => setIsModalVisible(true)} size="middle" className="dark-bg">
                  Add Skill
                </Button>
              }
            </div>
          }
        />

        {!userProfile?.skills?.length ?
          <Card>
            <Row justify={'center'}>
              <Col xs={24} md={12} lg={12}>
                <Button type='dashed' shape='round' onClick={() => setIsModalVisible(true)} className='fullwidth noDataAddBtn' size='large' icon={<PlusOutlined />}>
                  Add Skills
                </Button></Col>
            </Row>
          </Card>
          :
          <>
            {!userProfile?.skills?.length ? null :
              <Table
                columns={columns}
                dataSource={userProfile?.skills}
                expandable={{
                  expandedRowKeys,
                  onExpand: (expanded, record) =>
                    setExpandedRowKeys((prevKeys) =>
                      expanded ? [...prevKeys, record.key] : prevKeys.filter((key) => key !== record.key)
                    ),
                  expandIcon: () => null,
                }}
                pagination={{ pageSize: 5 }}
              />
            }
          </>
        }
      </div>

      <Modal
        title={editingSkill !== null ? "Edit Skill" : "Add Skills"}
        visible={isModalVisible}
        onCancel={handleReset}
        footer={null}
      >
        {skills.map((skill, index) => (
          <div
            key={skill.key}
            style={{
              marginBottom: "15px",
              border: "1px solid #f0f0f0",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <div className="flex-space-between">
              <span>Skill {index + 1}</span>
              <Button
                icon={<DeleteOutlined style={{ color: "red" }} />}
                onClick={() => handleDeleteSkillInModal(index)}
              />
            </div>
            <Form layout="vertical">
              <Form.Item label="Skill">
                <Select
                  showSearch
                  placeholder="Search and select a skill"
                  onChange={(value) => handleSkillSelect(index, value)}
                  value={skill.isOther ? "other" : skill.name}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {employeeSkills?.data?.map((skill) => (
                    <Option key={skill._id} value={skill._id}>
                      {skill.name}
                    </Option>
                  ))}
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              {skill.isOther && (
                <>
                  <Form.Item label="Skill Name" required>
                    <Input
                      placeholder="Enter skill name"
                      value={skill?.name}
                      onChange={(e) => handleSkillChange(index, "name", e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Domain" >
                    <Input
                      placeholder="Enter domain"
                      value={skill?.domain}
                      onChange={(e) => handleSkillChange(index, "domain", e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Type">
                    <Input
                      placeholder="Enter type"
                      value={skill?.type}
                      onChange={(e) => handleSkillChange(index, "type", e.target.value)}
                    />
                  </Form.Item>
                </>
              )}
              {!skill.isOther && <>
                <Form.Item label="Domain">
                  <Input
                    value={skill.domain}
                    onChange={(e) => handleSkillChange(index, "domain", e.target.value)}
                    disabled={!skill.isOther}
                    placeholder="Enter domain"
                  />
                </Form.Item>
                <Form.Item label="Type">
                  <Input
                    value={skill.type}
                    onChange={(e) => handleSkillChange(index, "type", e.target.value)}
                    disabled={!skill.isOther}
                    placeholder="Enter type"
                  />
                </Form.Item>
                </>
                }
              <Form.Item label="Proficiency" required>
                <Select
                  placeholder="Select proficiency"
                  value={skill?.proficiency}
                  onChange={(value) => handleSkillChange(index, "proficiency", value)}
                >
                  <Option value="Beginner">Beginner</Option>
                  <Option value="Intermediate">Intermediate</Option>
                  <Option value="Expert">Expert</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Experience" required>
                <Input
                  placeholder="e.g., 5 years"
                  value={skill?.experience}
                  onChange={(e) => handleSkillChange(index, "experience", e.target.value)}
                />
              </Form.Item>
            </Form>
          </div>
        ))}
        {editingSkill === null && (
          <Button
            icon={<PlusOutlined />}
            type="dashed"
            onClick={handleAddNewSkill}
            block
            className={`${styles.addSkillsBtn} fullwidth`}
          >
            Add Skill
          </Button>
        )}
        <Form.Item>
          <Row justify="end" style={{ marginTop: "15px" }}>
            <Col>
              <div className="flex-wrap-row-direction-gap-5">
                <Button size="large" shape="round" onClick={handleReset}>
                  Reset & Close
                </Button>
                <Button className="default-button-style" size="large" shape="round" type="primary" onClick={handleSave}>
                  {editingSkill !== null ? "Update Skills" : "Save Skills"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form.Item>
      </Modal>
    </>
  );
};

export default SkillsSection;