import { SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Drawer, Form, Grid, Input, Row } from 'antd';
import React, { useState } from 'react';
import styles from './style.module.scss';
import { get_and_search_jobs } from '../../features/Jobseeker/jobseekerSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSearchParameters } from '../../features/Global/globalSlice';
import { getAuthToken } from '../../utils/authHelpers';

const { useBreakpoint } = Grid;

const GlobalSearch = () => {
    const screens = useBreakpoint();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = getAuthToken();
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('top');
    const [form] = Form.useForm()

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    // const onSearchData = (values) => {
    //     const navigationPage = token ? '/searched-jobs' : '/find-job';
    //     const searchParams = new URLSearchParams(values).toString();
    //     console.log('searchparams', searchParams);
    //     dispatch(setSearchParameters(searchParams));
    //     dispatch(get_and_search_jobs({ navigate, searchParams }));
    //     navigate(navigationPage);
    //     form.resetFields()
    //     setOpen(false);
    // };

    const onSearchData = (values) => {
        const filteredValues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => value && value.trim() !== "")
        );
        const searchParams = new URLSearchParams(filteredValues).toString();
        console.log('searchParams:', searchParams);
        if (Object.keys(filteredValues).length > 0) {
            const navigationPage = token ? '/searched-jobs' : '/find-job';
            dispatch(setSearchParameters(searchParams));
            dispatch(get_and_search_jobs({ navigate, searchParams }));
            navigate(navigationPage);
        } else {
            console.warn("No valid search parameters provided.");
        }
        form.resetFields();
        setOpen(false);
    };
    

    return (
        <Form >
            <div>
                <Input
                    onClick={showDrawer}
                    className="fullwidth"
                    size="middle"
                    suffix={
                        <Avatar
                            onClick={showDrawer}
                            className="default-button-style"
                            size={25}
                            icon={<SearchOutlined />}
                        />
                    }
                    placeholder="Search Jobs here"
                />
            </div>
            <div>
                <Drawer
                    title="Search Jobs"
                    placement={placement}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key={placement}
                    height={screens.lg || screens.md ? '20%' : '40%'}
                    className={styles.antDrawerHeader}
                >
                    <Form form={form} onFinish={onSearchData}>
                        <Row justify={'center'} gutter={[16, 16]}>
                            <Col xs={24} lg={9} md={9}>
                                <Form.Item
                                    name="searchString"
                                    // rules={[{ required: true, message: 'Please enter a designation or company name' }]}
                                >
                                    <Input
                                        size="large"
                                        className="fullwidth"
                                        placeholder="Search Designation / Company etc..."
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={9} md={9}>
                                <Form.Item
                                    name="location"
                                    // rules={[{ required: true, message: 'Please enter a location' }]}
                                >
                                    <Input size="large" className="fullwidth" placeholder="Search by Location" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={3} md={3}>
                                <Button
                                    htmlType="submit"
                                    shape="round"
                                    size="large"
                                    className="fullwidth default-button-style"
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div>
        </Form>
    );
};

export default GlobalSearch;
