import React, { useEffect, useState } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import { Content } from 'antd/es/layout/layout'
import { Button, Card, Checkbox, Col, Form, Grid, Input, Modal, Row, Select } from 'antd'
import styles from './style.module.scss'
import { getAuthToken } from '../../utils/authHelpers'
import { useDispatch, useSelector } from 'react-redux'
import TopHeaderFindJob from '../FindJobPage/TopHeaderFindJob'
import FindJobPageLayout from '../FindJobPage/FindJobPageLayout'
import FilterSection from '../../components/CommonElements/FilterSection'
import SearchBar from '../../components/CommonElements/SearchBar'
import JobsList from '../../components/CommonElements/JobsList'
import { JobsData } from '../../components/dummyData/DummyData'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { get_and_search_jobs, get_reffered_jobs, get_saved_jobs, save_job_api, send_reffer_job_api, unsave_job_api } from '../../features/Jobseeker/jobseekerSlice'
import FormItem from 'antd/es/form/FormItem'
import { get_jobseeker } from '../../features/UserInfo/userInfoSlice'
import { applyFiltersAllPages, onSearchJobs } from '../../utils/helpers'
import FilterLayoutSM from '../../components/CommonElements/FilterLayoutSM'
import { setCurrentJobData } from '../../features/Global/globalSlice'

const {useBreakpoint} = Grid;
const RefrencePeople = () => {
  const screens = useBreakpoint();
  const token = getAuthToken()
  const { currentFilterSection, userProfile, internalSearchParameter, searchParameters, onSearchValues } = useSelector((state) => state.global)
  const { refferedJobs, alljobs, sendRefferLoad } = useSelector((state) => state.jobseeker)
  const [isRecommendF, setIsRecommendFriend] = useState(false)
  const [jobs, setJobs] = useState({});
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState({});

  const handleFilters = (filters) => {
    setFilters(filters)
    setIsFilterApplied(
      Object.values(filters)?.some((values) => values?.length > 0)
    );
    // Example: If only jobType is selected, you'll see { jobType: "full-time" }
  };

  const applyFilters = () => {
    applyFiltersAllPages(internalSearchParameter, searchParameters, onSearchValues, filters, dispatch, navigate, get_reffered_jobs, userProfile?._id)
  };

  useEffect(() => {
    // Fetch data from an API
    if (userProfile?._id) {
      dispatch(get_reffered_jobs({ navigate: navigate, userId: userProfile?._id, searchParams: "" }));
      dispatch(get_saved_jobs({ userId: userProfile?._id, navigate: navigate }))
    }
  }, [userProfile]);

  const handleApplyClick = (job) => {
    console.log('Apply clicked for', job);
    setJobs(job)
    setIsRecommendFriend(true)
    // Handle apply logic here
  };

  const onCloseRecoomendFirnd = () => {
    setIsRecommendFriend(false)
  }

  const handleShareClick = (job) => {
    console.log('Share clicked for', job);
    // Handle share logic here
  };

  const handleSaveClick = (job) => {
    const payload = {
      jobId: job?._id,
      userId: userProfile?._id
    }
    dispatch(setCurrentJobData(job))
    dispatch(save_job_api({ body: payload, navigate: navigate }))
  };

  const handleUnSaveClick = (job) => {
    const payload = {
        jobId: job?._id,
        userId: userProfile?._id
    }
    dispatch(setCurrentJobData(job))
    dispatch(unsave_job_api({ body: payload, navigate: navigate }))
};

  const onRecommendFreindSubmit = (values) => {
    const payload = {
      userId: userProfile?._id,
      jobId: jobs?._id,
      targetUserId: values?.targetUserId
    }
    dispatch(send_reffer_job_api({ body: payload, navigate: navigate, onclose: onCloseRecoomendFirnd }))
  }

  useEffect(() => {
    dispatch(get_jobseeker(navigate))
  }, [])

  const renderFilter = () => {
    return (
      <>
        <FilterSection
          postedAtOptions={[
            { value: '1', label: 'Jack' },
            { value: '2', label: 'Lucy' },
            { value: '3', label: 'Tom' },
          ]}
          jobTypeOptions={[
            { label: 'Full-time', value: 'full-time' },
            { label: 'Part-time', value: 'part-time' },
            { label: 'Internship', value: 'internship' },
            { label: 'Freelance', value: 'freelance' },
          ]}
          experienceOptions={[
            { label: 'Fresher', value: 'fresher' },
            { label: '1+', value: '1+' },
            { label: '2+ to 4+', value: '2+ to 4+' },
            { label: '6+ to 8+', value: '8+ to 10+' },
            { label: '10+ to 15+', value: '15+ to 25+' },
          ]}
          salaryOptions={[
            { label: 'Under $1000', value: 'under_1000' },
            { label: '$1k-$2k', value: '1k-2k' },
            { label: '$3k-$5k', value: '3k-5k' },
            { label: '$6k-$9k', value: '6k-9k' },
          ]}
          showSalaryRange={true}
          showExperience={true}
          onFilterChange={handleFilters}
          isFilterApplied={isFilterApplied}
          applyFilters={applyFilters}
          apiCall={get_reffered_jobs}
          userId={userProfile?._id}
          setIsFilterApplied={setIsFilterApplied}
        />
      </>
    )
  }

  return (
    <div>
      <FindJobPageLayout>
        <div className={token ? styles.filterJobWrapper : styles.filterJobNoToken}>
          <Button className='default-button-style'>Job Opening</Button>
          <Button className='default-button-style'>Recommended Jobs</Button>
        </div>

        <div
          // className={token ? styles.filterJobWrapper : styles.filterJobNoToken}
          className={styles.recomendJobBox}
        >

          <Row justify={'center'}>
            <Col xs={24} lg={5} md={24}>
            {((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="1" label="Add Filters" children={renderFilter()} /> : renderFilter()}
            </Col>
            <Col xs={24} lg={19} md={24}>

              <div className={styles.cardStyleJob}>
                <Card className={styles.searchCards}>
                  <SearchBar
                    showYearsSelect={false}
                    jobPlaceholder="Search job by name"
                    jobName='searchString'
                    locationPlaceholder="Search job by location"
                    locationName="location"
                    yearsPlaceholder="5 years"
                    locationOptions={[
                      { value: '1', label: 'Not Identified' },
                      { value: '2', label: 'Closed' },
                      { value: '3', label: 'Communicated' },
                    ]}
                    yearsOptions={[
                      { value: '1', label: '1-2 years' },
                      { value: '2', label: '3-5 years' },
                      { value: '3', label: '6+ years' },
                    ]}
                    onSearch={() => onSearchJobs(onSearchValues, dispatch, navigate, get_reffered_jobs, userProfile?._id)}
                    searchBtnText="Search"
                  />
                </Card>
                <div className={styles.counterResults}> {refferedJobs?.length ? `${refferedJobs?.length} jobs results` : 'No Jobs Found'}</div>
                {/* <Card className={styles.listCardStyle}> */}
                <JobsList
                  data={refferedJobs}
                  buttonText="Recommended Friend"
                  onButtonClick={handleApplyClick}
                  onShareClick={handleShareClick}
                  onSaveClick={handleSaveClick}
                  onUnSaveClick={handleUnSaveClick}
                />
                {/* </Card> */}
              </div>
            </Col>
          </Row>

        </div>
        <Modal centered footer={false} title="Recommend Your Friend" open={isRecommendF} onOk={onCloseRecoomendFirnd} onCancel={onCloseRecoomendFirnd}>
          <Form onFinish={onRecommendFreindSubmit} colon={false} layout='vertical'>
            <FormItem label="Enter your friend id" name={"targetUserId"} rules={[{ required: true, message: 'please enter your friend id' }]}>
              <Input placeholder='Ex : 6738a51af5003ece86c52645' />
            </FormItem>
            <FormItem>
              <Row justify={'end'}>
                <Col>
                  <div className="flex-wrap-row-direction-gap-5">
                    <Button onClick={onCloseRecoomendFirnd} danger>Cancel</Button>
                    <Button loading={sendRefferLoad} className='dufault-button-style' htmlType='submit'>Send</Button>
                  </div>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Modal>
      </FindJobPageLayout>
    </div>
  )
}

export default RefrencePeople
