import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Layout, Grid, theme } from 'antd';
import AppHeader from '../../components/Header/Header';
import LoggedInSidebar from './LoggedInSidebar';
import { getAuthToken } from '../../utils/authHelpers';
import Footer from '../../components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import {  setCollapsedData } from '../../features/Global/globalSlice';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const FindJobPageLayout = ({ children }) => {
    // const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch()
    const {collapsedData} = useSelector((state)=>state.global)
    const logintoken = getAuthToken();
    // const {
    //     token: { colorBgContainer, borderRadiusLG },
    // } = theme.useToken();
    
    // Breakpoint to manage screen size
    const screens = useBreakpoint();

    return (
        <div>
            {/* <AppHeader /> */}
            <Layout className='default-bg'>
               {/* {token &&  <LoggedInSidebar collapsed={collapsed} setCollapsed={setCollapsed} />} */}
                <Layout
                    style={{
                        paddingLeft: logintoken ? collapsedData || !screens.md ? '85px' : '220px' : '0px', 
                        // paddingLeft: token ? (collapsedData ? '105px' : '220px') : '0px',
                        transition: 'padding-left 0.2s ease',
                    }}
                >
                    {/* {!screens.md && (
                        <Button
                            type="primary"
                            onClick={() => dispatch(setCollapsedData(!collapsedData))}
                            style={{ margin: '10px' }}
                        >
                            {collapsedData ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                    )} */}

                    <Content
                        style={{
                            padding: '0px 0px',
                            minHeight: 280,
                            background: '#fafafa',
                            border:'none'
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
            {!logintoken && <Footer/> }
        </div>
    );
};

export default FindJobPageLayout;
