import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react'

const AddUPI = ({ setStep, onNext }) => {
    const handlePaymentContinue = () => {
        onNext()
    };
    return (
        <div>
            <Form layout='vertical' colon={false}
                onFinish={(values) => {
                    console.log("UPI Payment Data:", values);
                    handlePaymentContinue();
                }}
            >
                <Form.Item
                    label="Enter UPI ID"
                    name="upiId"
                    rules={[{ required: true, message: "Please enter your UPI ID!" }]}
                >
                    <Input placeholder="e.g., user@bank" />
                </Form.Item>
                <Row justify="end" style={{ marginTop: '20px' }}>
                    <Col xs={24} md={6} lg={6}>
                        <Button icon={<ArrowRightOutlined/>} iconPosition='end' shape='round' className='fullwidth default-button-style' type="primary" htmlType="submit">
                            Continue
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddUPI