import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AddSocialLinks = ({ isModalVisible, setIsModalVisible, currentSociallink }) => {
    const [socialLinks, setSocialLinks] = useState({});
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleAddSocialLink = (values) => {
        // Store the platform as key and URL as value
        setSocialLinks((prevLinks) => {
            const updatedLinks = { ...prevLinks, [values.platform]: values.url };

            // Log the updated socialLinks object to the console
            console.log("Updated Social Links:", updatedLinks);

            // Dispatch the update_jobseeker action to call the API
            dispatch(update_jobseeker({
                body: updatedLinks,
                navigate: navigate,
            }));

            return updatedLinks;
        });

        // Close the modal and reset the form
        setIsModalVisible(false);
        form.resetFields();
    };



    const handleReset = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (currentSociallink) {
            form.setFieldsValue({
                platform: currentSociallink, // Adjust as per the structure of currentSociallink
            });
        }
    }, [currentSociallink, form]);

    return (
        <div>
            <Modal
                title="Add Social Link"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <Form form={form} onFinish={handleAddSocialLink} layout="vertical">
                    <Form.Item
                        name="platform"
                        label="Social Platform"
                        rules={[{ required: true, message: 'Please select a platform!' }]}
                    >
                        <Select placeholder="Select a social platform">
                            <Select.Option value="linkedin">LinkedIn</Select.Option>
                            <Select.Option value="github">GitHub</Select.Option>
                            {/* <Select.Option value="Behance">Behance</Select.Option> */}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="url"
                        label="URL"
                        rules={[
                            { required: true, message: 'Please enter the URL!' },
                            { type: 'url', message: 'Please enter a valid URL!' },
                        ]}
                    >
                        <Input placeholder="Enter the URL for the selected platform" />
                    </Form.Item>

                    <Form.Item>
                        <Row justify={'end'}>
                            <div className="flex-wrap-row-direction-gap-5">
                                {/* <Col>
                                    <Button
                                        size="large"
                                        shape="round"
                                        onClick={handleReset}
                                    >
                                        Reset & Close
                                    </Button></Col> */}
                                <Col>
                                    <Button
                                        size="large"
                                        shape="round"
                                        type="primary"
                                        htmlType="submit"
                                        className='default-button-style'
                                    >
                                        Save
                                    </Button></Col>
                            </div>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AddSocialLinks;
