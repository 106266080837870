import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AppSecureStorage from '../../../services/storage/secureStorage';
import { message } from 'antd';
import { unauthorizedError } from '../../../utils/helpers';
import jobService from '../../../services/api/Organization/JobService';
import { setUserProfile } from '../../Global/globalSlice';
import { get_and_search_jobs } from '../../Jobseeker/jobseekerSlice';

const jobservice = new jobService();
const storage = new AppSecureStorage();


export const create_job = createAsyncThunk(
    "job/create_job",
    async ({ body, navigate, onClose }, { dispatch }) => {
        dispatch(setCreateJobLoad(true))
        try {
            const response = await jobservice.create_jobs(body)
            const { data } = response;
            dispatch(setCreateJobLoad(false))
            dispatch(get_and_search_jobs({navigate, searchParams:""}))
            onClose()
            message.success("Job created SucessFully")
            return response.data
        } catch (error) {
            dispatch(setCreateJobLoad(false))
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setCreateJobLoad(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data;
                message.error(msg);
            }
        }
    }
);

export const upload_job = createAsyncThunk(
    "job/upload_job",
    async ({ body, navigate, onClose }, { dispatch }) => {
        dispatch(setUploadJobLoad(true))
        try {
            const response = await jobservice.Upload_jobs(body)
            const { data } = response;
            dispatch(setUploadJobLoad(false))
            onClose()
            message.success("Job uploaded SucessFully")
            return response.data
        } catch (error) {
            dispatch(setUploadJobLoad(false))
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setUploadJobLoad(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data;
                message.error(msg);
            }
        }
    }
);

const initialState = {
    createJobLoad: false,
    uploadJobLoad: false
};

const jobAuthSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setCreateJobLoad: (state, action) => {
            state.createJobLoad = action.payload
        },
        setUploadJobLoad: (state, action) => {
            state.uploadJobLoad = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(create_job.pending, (state, action) => {
            state.loading = true;
        })
            .addCase(create_job.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
            })
            .addCase(create_job.rejected, (state, action) => {
                state.user = action.payload;
                state.loading = false;
            })

            .addCase(upload_job.pending, (state, action) => {
                state.loading = false;
            })
            .addCase(upload_job.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(upload_job.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const
    { setCreateJobLoad, setUploadJobLoad } = jobAuthSlice.actions;
export default jobAuthSlice.reducer;
