import { API_ROUTES } from "../../../utils/constants";
import _axios from "../index";


export default class organizationInfoService {

  Update_organization = async (body) => {
    try {
      const response = await _axios.patch(API_ROUTES.ORGANIZATION.UPDATE_ORGANIZATION, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Remove_organization = async () => {
    try {
      const response = await _axios.delete(API_ROUTES.ORGANIZATION.REMOVE_ORGANIZATION);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Add_organization_Roles = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.ORGANIZATION.ADD_ORG_ROLES, body);
      return response;
    } catch (err) {
      throw err;
    }
  };
}