import { Form, Modal, Radio, Row, Upload, Button, message, Col } from 'antd';
import { ArrowRightOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import CreateBoostJobDetails from './CreateBoostJob';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentBoostJobData } from '../../../features/Global/globalSlice';
import styles from './style.module.scss'
import AddUPI from './Payments/AddUPI';
import AddCreditDebitCard from './Payments/AddCreditDebitCard';
import AddQRScanner from './Payments/AddQRScanner';
import { API_ROUTES } from '../../../utils/constants';
import _axios from '../../../services/api';
import { getAuthToken } from '../../../utils/authHelpers';

const CreateJobWithBoost = ({ visible, onClose }) => {
  const token = getAuthToken()
  const dispatch = useDispatch();
  const [gdType, setGDType] = useState('upload');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('upi');
  const { currentBoostJobData } = useSelector((state) => state.global);
  console.log("currentBoostJobData", currentBoostJobData, "uploadedFile", uploadedFile);
  const [paymentHrs, setPaymentHrs] = useState('1hr')

  const handleCloseModal = () => {
    onClose()
    dispatch(setCurrentBoostJobData(null))
    setStep(1)
    setUploadedFile(null)
    setGDType('upload')
    setPaymentHrs('1hr')
    setPaymentMethod('upi')
  }

  const onChange = (e) => {
    setGDType(e.target.value);
    dispatch(setCurrentBoostJobData(null))
    setUploadedFile(null);
  };

  // const handleUpload = ({ file }) => {
  //   if (file.type !== 'application/pdf') {
  //     message.error('Only PDF files are allowed!');
  //     return;
  //   }

  //   setUploadedFile(file);
  //   message.success(`${file.name} file uploaded successfully.`);
  // };

  const handleUpload = async ({ file }) => {
    const formData = new FormData();
    formData.append('file', file); // Attach the file to the FormData object

    try {
      setUploadedFile(file);
      const response = await _axios.post(
        API_ROUTES.JOBS.UPLOAD_JOB,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header with token
            "Content-Type": "multipart/form-data", // Correct Content-Type for FormData
          },
        }
      );
      // Set the uploaded file state
      message.success('File uploaded successfully.');
      // return response;
    } catch (err) {
      message.error('File upload failed.');
      throw err;
    }
  };

  const handlePaymentSelect = (e) => {
    console.log("e", e)
    setPaymentMethod(e.target.value);
  };

  const handleNext = () => {
    setStep(2)
    message.info('Proceeding to the next step...');
  };

  const onPaymentHrsChange = (e) => {
    setPaymentHrs(e.target.value);
  };

  const handleSubmit = () => {
    handleCloseModal()
    setStep(1)
    dispatch(setCurrentBoostJobData(null))
    setUploadedFile(null)
  }

  return (
    <Modal
      title="Create Job with Boost"
      visible={visible}
      footer={null}
      centered
      onCancel={() => handleCloseModal()}
      width={1000}
    >
      <>
        {step === 1 && (
          <>
            <Row justify="start">
              <Form.Item layout="vertical" colon={false} label="Do you want to ">
                <Radio.Group onChange={onChange} value={gdType}>
                  <Radio value="upload">Upload GD</Radio>
                  <Radio value="write">Write GD</Radio>
                </Radio.Group>
              </Form.Item>
            </Row>

            {gdType === 'upload' ? (
              <Row gutter={[16, 16]} >
                <Col xs={24}>
                  <Upload
                    accept=".pdf"
                    maxCount={1}
                    beforeUpload={() => false} // Prevent automatic upload
                    onChange={(info) => {
                      const file = info.fileList[0]?.originFileObj; // Extract the file from the fileList
                      if (file) {
                        handleUpload({ file }); // Call handleUpload with the correct file object
                      }
                    }}
                    className={`fullwidth ${styles.uploadGDbtn}`}
                  >
                    <Button className='display-block fullwidth' icon={<UploadOutlined />}>
                      Upload PDF
                    </Button>
                  </Upload>
                  {uploadedFile && (
                    <p style={{ marginTop: '10px' }}>Uploaded File: {uploadedFile.name}</p>
                  )}
                </Col>
              </Row>
            ) : (
              <CreateBoostJobDetails onNext={() => setStep(2)} />
            )}
            {uploadedFile && (
              <Row justify="end" style={{ marginTop: '20px' }}>
                <Col xs={24} md={6} lg={6}>
                  <Button iconPosition='end' icon={<ArrowRightOutlined />} shape='round' className='fullwidth default-button-style' type="primary" onClick={handleNext}>
                    Next
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}
      </>
      {step === 2 && (
        <>
          <Form.Item
            label="Which payment method do you want to pay with?"
            // name="paymentMethod"
            layout='vertical'
            colon={false}
            rules={[{ required: true, message: "Please select a payment method!" }]}
          >
            <Radio.Group value={paymentMethod} onChange={handlePaymentSelect}>
              <Radio value="upi">UPI</Radio>
              <Radio value="card">Credit/Debit Card</Radio>
              <Radio value="qr">QR Code</Radio>
            </Radio.Group>
          </Form.Item>
          <div>
            {paymentMethod === 'upi' && (
              <>
                <AddUPI setStep={setStep} onNext={() => setStep(3)} />
              </>
            )}
            {paymentMethod === 'card' && (
              <>
                <AddCreditDebitCard setStep={setStep} onNext={() => setStep(3)} />
              </>
            )}
            {paymentMethod === 'qr' && (
              <>
                <AddQRScanner setStep={setStep} onNext={() => setStep(3)} />
              </>
            )}
          </div>
        </>
      )
      }
      {step === 3 && (
        <>
          <Form.Item layout="vertical" colon={false} label="how long do you need to make the payment">
            <Radio.Group onChange={onPaymentHrsChange} value={paymentHrs}>
              <Radio value="1hr"> 1 Hours</Radio>
              <Radio value="2hr"> 2 Hours</Radio>
              <Radio value="12hr"> 12 Hours</Radio>
              <Radio value="24hr"> 24 Hours</Radio>
            </Radio.Group>
          </Form.Item>
          <Row justify={'end'}>
            <Col xs={24} md={6} lg={6}>
              <Button onClick={handleSubmit} size='large' shape='round' className='fullwidth default-button-style'>Submit</Button>
            </Col>
          </Row>
        </>
      )}
    </Modal >
  );
};

export default CreateJobWithBoost;