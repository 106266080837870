import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import styles from './style.module.scss';
import { CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const StepsVerification = () => {
  const {signupStep} = useSelector((state)=>state.auth)
  return (
    <div className={styles.stepper}>
      <Button
        icon={<CheckOutlined className={signupStep >= 0 ? styles.stepperIconActive : ''} />}
        className={`${styles.stepperButton} ${signupStep >= 0 ? styles.activeStepper : ''}`}
        shape="circle"
        size='small'
      />
      
      <Divider 
        className={styles.stepDivider} 
        style={{
          width: signupStep >= 1 ? '100%' : '0%', // Animate the divider based on step
          backgroundColor: signupStep >= 1 ? 'green' : '#e0e0e0', // Change color
        }}
      />
      
      <Button
        icon={signupStep >= 1 ? <CheckOutlined className={styles.stepperIconActive} /> : null}
        className={`${styles.stepperButton} ${signupStep >= 1 ? styles.activeStepper : ''}`}
        shape="circle"
        size='small'
        // onClick={next}
      />
    </div>
  );
};

export default StepsVerification;
