// import { MenuFoldOutlined, UploadOutlined, VideoCameraOutlined } from '@ant-design/icons';
// import { Avatar, Button, Menu, Image, Grid } from 'antd';
// import Sider from 'antd/es/layout/Sider';
// import React, { useEffect, useState, useCallback, memo } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import styles from './sidebar.module.scss';
// import dashboardIcon from '../../assets/Images/inactive-dashboard.svg';
// import activedashboardIcon from '../../assets/Images/active-dashboard.svg';
// import inactiveUserInfoIcon from '../../assets/Images/user-gear.svg';
// import activeUserInfoIcon from '../../assets/Images/active-user.svg';
// import inactivebriefcaseIcon from '../../assets/Images/inactive-briefcase.svg';
// import activebriefcaseIcon from '../../assets/Images/active-recommended.svg';

// import inactivebookmarkIcon from '../../assets/Images/inactive-bookmark.svg';
// import activebookmarkIcon from '../../assets/Images/bookmarkActive.svg';

// import inactiveblogIcon from '../../assets/Images/blogsicon.svg';
// import activeblogIcon from '../../assets/Images/active-blogs.svg';

// import inactiveReffPeople from '../../assets/Images/reffrence-people.svg';
// import activeReffPeople from '../../assets/Images/active-reff-people.svg';

// import inactiveSetting from '../../assets/Images/inactive-setting.svg';
// import activeSetting from '../../assets/Images/active-setting.svg';

// import logoutIcon from '../../assets/Images/log-out.svg';

// import AppSecureStorage from '../../services/storage/secureStorage';
// import { useDispatch } from 'react-redux';
// import { setLoggedInUser } from '../../features/Auth/authSlice';
// import { getAuthToken } from '../../utils/authHelpers';

// const storage = new AppSecureStorage();

// const LoggedInSidebar = ({ collapsed, setCollapsed }) => {
//     const token = getAuthToken();
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location = useLocation();
//     const [activeKey, setActiveKey] = useState('1');
//     const { useBreakpoint } = Grid;
//     const screens = useBreakpoint();
//     const [isSmallsc, setIsSmallsc]=useState(false)

//     useEffect(() => {
//         const pathToKeyMap = {
//             '/find-job/application-status': '1',
//             '/find-job': '1',
//             '/find-job/user-info': '2',
//             '/find-job/recommended-jobs': '3',
//             '/find-job/save-jobs': '4',
//             '/find-job/blogs': '5',
//             '/find-job/reference-people': '6',
//             '/find-job/settings': '7',
//         };

//         const currentPath = token ? '/find-job/application-status' : '/find-job';
//         const currentKey = pathToKeyMap[location.pathname] || pathToKeyMap[currentPath] || '1';

//         setActiveKey(currentKey);
//     }, [location.pathname, token]);

//     const handleMenuClick = useCallback((key, goToPage) => {
//         setActiveKey(key);
//         goToPage();

//         // Sidebar collapse/expand ka state yahin check karenge
//         // Agar sidebar pehle se open hai, to collapse state change na ho
//         if (screens.md && collapsed) {
//             setCollapsed(false);  // Sidebar open ho agar close hai to, aur agar pehle se open hai to kuch nahi karega
//         }
//     }, [collapsed, screens.md]);


//     useEffect(() => {
//         if (!screens.md) {
//             setCollapsed(true);
//         } else {
//             setCollapsed(false);
//         }
//     }, [screens.md]);

//     useEffect(() => {
//         if (screens.lg || screens.xl || screens.xxl) {
//             setCollapsed(false);
//             setIsSmallsc(false)
//         } else {
//             setCollapsed(true);
//             setIsSmallsc(true)
//         }
//     }, [screens]);

//     const handleLogout = useCallback(() => {
//         dispatch(setLoggedInUser(null));
//         storage.clearStorage();
//         navigate('/');
//     }, [dispatch, navigate]);

//     const menuItems = [
//         {
//             key: '1',
//             icon: <VideoCameraOutlined />,
//             label: 'Application status',
//             goToPage: () => navigate('/find-job/application-status'),
//             img: activeKey === '1' ? activedashboardIcon : dashboardIcon,
//         },
//         {
//             key: '2',
//             icon: <VideoCameraOutlined />,
//             label: 'User info',
//             goToPage: () => navigate('/find-job/user-info'),
//             img: activeKey === '2' ? activeUserInfoIcon : inactiveUserInfoIcon,
//         },
//         {
//             key: '3',
//             icon: <UploadOutlined />,
//             label: 'Recommend job',
//             goToPage: () => navigate('/find-job/recommended-jobs'),
//             img: activeKey === '3' ? activebriefcaseIcon : inactivebriefcaseIcon,
//         },
//         {
//             key: '4',
//             icon: <UploadOutlined />,
//             label: 'Save job',
//             goToPage: () => navigate('/find-job/save-jobs'),
//             img: activeKey === '4' ? activebookmarkIcon : inactivebookmarkIcon,
//         },
//         {
//             key: '5',
//             icon: <UploadOutlined />,
//             label: 'Blogs',
//             goToPage: () => navigate('/find-job/blogs'),
//             img: activeKey === '5' ? activeblogIcon : inactiveblogIcon,
//         },
//         {
//             key: '6',
//             icon: <UploadOutlined />,
//             label: 'Reference people',
//             goToPage: () => navigate('/find-job/reference-people'),
//             img: activeKey === '6' ? activeReffPeople : inactiveReffPeople,
//         },
//         {
//             key: '7',
//             icon: <UploadOutlined />,
//             label: 'Setting',
//             goToPage: () => navigate('/find-job/settings'),
//             img: activeKey === '7' ? activeSetting : inactiveSetting,
//         },
//     ];

//     return (
//         <div style={{ height: '88vh', position: 'fixed', marginTop: '25px', padding: '10px 20px' }}>
//             <Sider
//                 // trigger={null}
//                 // collapsible={false}
//                 collapsed={screens.lg || screens.xl || screens.xxl ? collapsed : true}
//                 style={{ height: '100%', marginTop: '45px', padding: '10px 20px', backgroundColor: '#231f20', borderRadius: '10px' }}
//             >
//                 <div className="demo-logo-vertical" />
//                 <Menu
//                     className={styles.sidebarMenus}
//                     theme="dark"
//                     mode="inline"
//                     selectedKeys={[activeKey]}
//                     style={{ height: '100%', backgroundColor: '#231f20' }}
//                 >
//                     {menuItems.map((ele) => (
//                         <Menu.Item
//                             onClick={() => handleMenuClick(ele.key, ele.goToPage)}
//                             key={ele.key}
//                             className={
//                                 collapsed && activeKey === ele.key
//                                     ? styles.menuCenter
//                                     : activeKey === ele.key
//                                         ? styles.activeMenu
//                                         : styles.inactiveMenu
//                             }
//                         >
//                             <div className={styles.flexMenues}>
//                                 <div
//                                     className={
//                                         collapsed && activeKey === ele.key
//                                             ? styles.menuItemsCenter
//                                             : activeKey === ele.key
//                                                 ? styles.activeImage
//                                                 : styles.inactiveImage
//                                     }
//                                 >
//                                     <Image
//                                         src={ele.img}
//                                         width={20}
//                                         height={15}
//                                         preview={false}
//                                         className={styles.sidebarMenuIcons}
//                                     />
//                                 </div>
//                                 <div  className={activeKey === ele.key ? styles.activeLabel : styles.inactiveLabel}>
//                                     {!collapsed && ele.label}
//                                 </div>
//                             </div>
//                         </Menu.Item>
//                     ))}
//                 </Menu>

//                     <Button
//                         size='large'
//                         iconPosition="end"
//                         icon={<Avatar size={'large'} shape='square' className={styles.logoutBtnIcon} src={logoutIcon} />}
//                         className={collapsed ? styles.logoutBtnCenter : styles.logoutBtn}
//                         // onClick={handleLogout}
//                         onClick={()=>setCollapsed(!collapsed)}
//                     >
//                             {!collapsed && <div >Logout</div>}
//                     </Button>

//             </Sider>
//         </div>
//     );
// };

// export default memo(LoggedInSidebar);




import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Avatar, Button, Calendar, Image, Menu } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LineChartOutlined,
    AliwangwangOutlined,
    DeploymentUnitOutlined,
    UsergroupAddOutlined,
    ApartmentOutlined,
    FileTextOutlined,
    ProfileFilled,
    MoneyCollectOutlined,
    UploadOutlined,
    VideoCameraOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import "./sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import AppSecureStorage from "../../services/storage/secureStorage";
import dashboardIcon from '../../assets/Images/inactive-dashboard.svg';
import activedashboardIcon from '../../assets/Images/active-dashboard.svg';
import inactiveUserInfoIcon from '../../assets/Images/user-gear.svg';
import activeUserInfoIcon from '../../assets/Images/active-user.svg';
import inactivebriefcaseIcon from '../../assets/Images/inactive-briefcase.svg';
import activebriefcaseIcon from '../../assets/Images/active-recommended.svg';

import inactivebookmarkIcon from '../../assets/Images/inactive-bookmark.svg';
import activebookmarkIcon from '../../assets/Images/bookmarkActive.svg';

import inactiveblogIcon from '../../assets/Images/blogsicon.svg';
import activeblogIcon from '../../assets/Images/active-blogs.svg';

import inactiveReffPeople from '../../assets/Images/reffrence-people.svg';
import activeReffPeople from '../../assets/Images/active-reff-people.svg';

import inactiveSetting from '../../assets/Images/inactive-setting.svg';
import activeSetting from '../../assets/Images/active-setting.svg';

import logoutIcon from '../../assets/Images/log-out.svg';
import { setCollapsedData, setCurrentFilterSection, setUserProfile } from "../../features/Global/globalSlice";
import styles from './sidebar.module.scss';
import { getCurrentUser } from "../../utils/authHelpers";
const storage = new AppSecureStorage();

const Sidebar = ({ collapsed, setCollapsed }) => {
    const dispatch = useDispatch();
    //   const [collapsed, setCollapsed] = useState(false);
    let currentUser = getCurrentUser()
    const {collapsedData, currentFilterSection} = useSelector((state)=>state.global)
    const [openKeys, setOpenKeys] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState('1');
    const [isChild, setIsChild]= useState(false)


    const toggle = useCallback(() => {
        setCollapsed((prevCollapsed) => {
            const newCollapsed = !prevCollapsed;
            dispatch(setCollapsedData(newCollapsed));
            return newCollapsed;
        });
    }, [dispatch]);

    const toggleChild = () => {
        setIsChild((prevIsChild) => !prevIsChild);
    };

    useEffect(() => {
        const selectedKey = getSelectedKey();
        setActiveKey(selectedKey);
    }, [location]);

     // Effect to handle responsive sidebar behavior on window resize
     useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setCollapsed(false);
            } else {
                setCollapsed(true);
            }
        };

        window.addEventListener("resize", handleResize);

        // Check initial screen size
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setCollapsed]);

    console.log("currentUser", currentUser)


    const handleLogout = () => {
        storage.clearStorage();
        navigate('/');
        dispatch(setUserProfile({}))
    };

    const menuItems = useMemo(() => {
        return [
            {
                key: '1',
                icon: <VideoCameraOutlined />,
                label: 'Application status',
                path: '/find-job/application-status',
                goToPage: () => navigate('/find-job/application-status'),
                img: activeKey === '1' ? activedashboardIcon : dashboardIcon,
            },
            isChild && {
                key: '8',
                icon: <VideoCameraOutlined />,
                label: currentFilterSection === 'apply-jobs' || activeKey === 8 ? <div className="text-white font-w-700">Apply Jobs</div> : 'Apply Jobs',
                // No path provided
                goToPage: () => {
                    dispatch(setCurrentFilterSection('apply-jobs'));
                    setActiveKey('8');
                    navigate('/find-job/application-status') // Set activeKey directly
                },
                img: currentFilterSection === 'apply-jobs' ? activedashboardIcon : dashboardIcon,
            },
            isChild && {
                key: '9',
                icon: <VideoCameraOutlined />,
                label: currentFilterSection === 'interview-rating' || activeKey === 9 ? <div className="text-white font-w-700">Interview Rating</div> : 'Interview Rating',
                // No path provided
                goToPage: () => {
                    dispatch(setCurrentFilterSection('interview-rating'));
                    setActiveKey('9'); // Set activeKey directly
                    navigate('/find-job/application-status')
                },
                img: currentFilterSection === 'interview-rating' ? activeUserInfoIcon : inactiveUserInfoIcon,
            },
            {
                key: '2',
                icon: <VideoCameraOutlined />,
                label: 'User info',
                goToPage: () => navigate('/find-job/user-info'),
                path: '/find-job/user-info',
                img: activeKey === '2' ? activeUserInfoIcon : inactiveUserInfoIcon,
            },
            {
                key: '3',
                icon: <UploadOutlined />,
                label: 'Recommend job',
                goToPage: () => navigate('/find-job/recommended-jobs'),
                path: '/find-job/recommended-jobs',
                img: activeKey === '3' ? activebriefcaseIcon : inactivebriefcaseIcon,
            },
            {
                key: '4',
                icon: <UploadOutlined />,
                label: 'Save job',
                goToPage: () => navigate('/find-job/save-jobs'),
                path: '/find-job/save-jobs',
                img: activeKey === '4' ? activebookmarkIcon : inactivebookmarkIcon,
            },
            {
                key: '5',
                icon: <UploadOutlined />,
                label: 'Blogs',
                goToPage: () => navigate('/find-job/blogs'),
                path: '/find-job/blogs',
                img: activeKey === '5' ? activeblogIcon : inactiveblogIcon,
            },
            {
                key: '6',
                icon: <UploadOutlined />,
                label: 'Reference people',
                goToPage: () => navigate('/find-job/reference-people'),
                path: '/find-job/reference-people',
                img: activeKey === '6' ? activeReffPeople : inactiveReffPeople,
            },
            {
                key: '7',
                icon: <Calendar />,
                label: 'Calender',
                goToPage: () => navigate('/find-job/calender'),
                path: '/find-job/calender',
                img: activeKey === '7' ? activeReffPeople : inactiveReffPeople,
            },
            {
                key: '8',
                icon: <UploadOutlined />,
                label: 'Setting',
                goToPage: () => navigate('/find-job/setting'),
                path: '/find-job/setting',
                img: activeKey === '8' ? activeSetting : inactiveSetting,
            },
        ].filter(Boolean);
    }, [activeKey, isChild]);

    const OrgmenuItems = useMemo(() => {
        return [
            {
                key: '1',
                icon: <VideoCameraOutlined />,
                label: 'Dashboard',
                path: '/dashboard',
                goToPage: () => navigate('/dashboard'),
                img: activeKey === '1' ? activedashboardIcon : dashboardIcon,
            },
            {
                key: '2',
                icon: <VideoCameraOutlined />,
                label: 'Organization Info',
                goToPage: () => navigate('/organization-info'),
                path: '/organization-info',
                img: activeKey === '2' ? activeUserInfoIcon : inactiveUserInfoIcon,
            },
            {
                key: '3',
                icon: <UploadOutlined />,
                label: 'Job Status',
                goToPage: () => navigate('/job-status'),
                path: '/job-status',
                img: activeKey === '3' ? activebriefcaseIcon : inactivebriefcaseIcon,
            },
            {
                key: '4',
                icon: <UploadOutlined />,
                label: 'Recommend Candidate',
                goToPage: () => navigate('/recommend-candidate'),
                path: '/recommend-candidate',
                img: activeKey === '4' ? activebookmarkIcon : inactivebookmarkIcon,
            },
            {
                key: '5',
                icon: <UploadOutlined />,
                label: 'Analytics',
                goToPage: () => navigate('/analytics'),
                path: '/analytics',
                img: activeKey === '5' ? activeblogIcon : inactiveblogIcon,
            },
            {
                key: '7',
                icon: <UploadOutlined />,
                label: 'Setting',
                goToPage: () => navigate('/organization-settings'),
                path: '/organization-settings',
                img: activeKey === '7' ? activeSetting : inactiveSetting,
            },
        ].filter(Boolean);
    }, [activeKey, isChild]);

    const handleMenuClick = useCallback((key, goToPage) => {
        setActiveKey(key);
        goToPage();
    }, []);

    let sidebarMenus = currentUser === "organization" ? OrgmenuItems : menuItems

    const renderMenuItems = (items) => {
        
        return items.map((item) => {
            if (item.children) {
                return (
                    <Menu.SubMenu 
                    className={
                        collapsed && activeKey === item.key
                            ? styles.menuCenter
                            : activeKey === item.key
                                ? styles.activeMenu
                                : styles.inactiveMenu
                    }
                     key={item.key} title={<span>
                        <img src={item.img} alt="" className="menu-icon" /> {item.label}</span>}>
                        {renderMenuItems(item.children)}
                    </Menu.SubMenu>
                );
            }
            return (
                <Menu.Item
                    onClick={() => {
                        setActiveKey(item.key);
                        navigate(item.path);
                    }}
                    key={item.key}
                    title={collapsed && item.label}
                    
                >
                    <Menu.Item
                        onClick={() => handleMenuClick(item.key, item.goToPage)}
                        title={''}
                        key={item.key}
                        style={{
                         
                            width:' 100%',
                            minWidth:' 170px'}}
                        className={
                            collapsed && activeKey === item.key
                                ? styles.menuCenter
                                : activeKey === item.key
                                    ? styles.activeMenu
                                    : styles.inactiveMenu
                        }
                    >
                        <div className={styles.flexMenues}>
                            <div
                                className={
                                    collapsed ? styles.collapsedImgWrapper : styles.nocollapsedImgWrapper
                                }
                            >
                                <Image
                                    src={item.img}
                                    width={35}
                                    height={45}
                                    preview={false}
                                    className={
                                        activeKey === item.key
                                            ? !collapsed
                                                ? styles.nocolactiveImage // Not collapsed and active
                                                : styles.collActiveImg // Collapsed and active
                                            : styles.nocolInactiveImage // Inactive, regardless of collapsed state
                                    }
                                />
                            </div>
                            {/* Only show the label if not collapsed */}
                            
                            {!collapsed && (
                                <div className={activeKey === item.key ? styles.activeLabel : styles.inactiveLabel}>
                                    {item.label} 
                                    {item.path === '/find-job/application-status' && <Avatar onClick={toggleChild} style={{backgroundColor:'transparent' , paddingLeft:'2px', zIndex:1, width:'30px' }} size={10} icon={activeKey === item.key && item.path === '/find-job/application-status' ? <DownOutlined className="text-white" /> : <DownOutlined  className="light-text" />} />}
                                </div>
                            )}
                        </div>
                    </Menu.Item>
                </Menu.Item>
            );
        });
    };

    // const getSelectedKey = () => {
    //     const findActiveKey = (items) => {
    //         for (let item of items) {
    //             if (item.path === location?.pathname) {
    //                 return item.key;
    //             }
    //             if (item.children) {
    //                 const childKey = findActiveKey(item.children);
    //                 if (childKey) return childKey;
    //             }
    //         }
    //         return null;
    //     };
    //     return findActiveKey(sidebarMenus) || "1";
    // };

    const getSelectedKey = () => {
        const findActiveKey = (items) => {
          for (let item of items) {
            if (item.path === location?.pathname) {
              return item.key;
            }
            if (item.children) {
              const childKey = findActiveKey(item.children);
              if (childKey) return childKey;
            }
          }
          return null;
        };
      
        // If the location is '/searched-jobs', do not return a selected key
        if (location?.pathname === "/searched-jobs") {
          return null; // Or handle it with a special case as needed
        }
      
        // Default to '1' if no active key is found
        return findActiveKey(sidebarMenus) || "1";
      };
      

    const handleOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className="sider-navbar"
            breakpoint="md"
            collapsedWidth={80}
            onBreakpoint={(broken) => {
                if (broken) {
                    setCollapsed(true);
                    setCollapsedData(true);
                }
            }}
        >
            <div className="logo" />
            {/* {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                    className: `trigger ${collapsed ? "collapsed" : "expanded"}`,
                    onClick: toggle,
                }
            )} */}
            {/* {collapsed ? null : (
                React.createElement(
                    MenuFoldOutlined,
                    {
                        className: `trigger ${collapsed ? "collapsed" : "expanded"}`,
                        onClick: toggle,
                    }
                )
            )} */}

            <div className="menu-wrapper">
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[getSelectedKey()]}
                    openKeys={openKeys}
                    onOpenChange={handleOpenChange}
                    className="menu-container"
                >
                    {renderMenuItems(sidebarMenus)}
                </Menu>
            </div>
            <div className="logout-menu">
                <Menu
                    theme="dark"
                    mode="inline"
                    style={{ position: "absolute", bottom: 0, width: "100%" }}
                >
                    {/* <Menu.Item key="a0" icon={<UserOutlined />} onClick={handleLogout}>
                        Logout
                    </Menu.Item> */}
                    <Button
                        size='large'
                        classNames={'fullwidth'}
                        iconPosition="end"
                        icon={<Avatar size={'large'} shape='square' className={styles.logoutBtnIcon} src={logoutIcon} />}
                        className={collapsed ? styles.logoutBtnCenter : styles.logoutBtn}
                        onClick={handleLogout}
                    // onClick={() => setCollapsed(!collapsed)}
                    >
                        {!collapsed && <div >Logout</div>}
                    </Button>
                </Menu>
            </div>

        </Sider>
    );
};

export default Sidebar;


