import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Input, Row, Select, Button, DatePicker, Divider, Modal, message, Space } from 'antd';
import styles from '../style.module.scss';
import { CalendarOutlined, CopyOutlined, EditOutlined, ExportOutlined, SaveFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { genrate_login_otp_user_Info, setIsEmailVerify, setverifyType, verify_otp__user_Info } from '../../../../features/UserInfo/userInfoSlice';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
// import AddLanguage from './AddLanguage';
import dayjs from 'dayjs';
import { formatDOBDate } from '../../../../utils/helpers';
import { setUserProfile } from '../../../../features/Global/globalSlice';
import TextArea from 'antd/es/input/TextArea';
import { Update_organization } from '../../../../features/Organization/OrganizationInfo/OrganizationInfoSlice';
// import AddSocialLinks from './AddSocialLinks';

const { Option } = Select;

const AboutCompany = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isEmailVerify, isMobileVerify, verifyLoading, isGenerateOTPLoad, verifyType, isOtpSubmit } = useSelector((state) => state.userInfo);
    const { userProfile } = useSelector((state) => state.global)
    const { verifyOTPLoad, genrateOTPload } = useSelector((state) => state.auth);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailLoader, setEmailLoader] = useState(genrateOTPload);
    const [mobileLoader, setMobileLoader] = useState(genrateOTPload);
    const [countdown, setCountdown] = useState(300); // 5 minutes = 300 seconds
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [languages, setLanguages] = useState([]);
    const [isVisible, setIsVisivle] = useState(false)
    const [defaultCountry, setDefaultCountry] = useState('in');
    const [isEdit, setIsEdit] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false)


    const onEditfield = () => {
        setIsEdit(true)
    }

    useEffect(() => {
        if (userProfile?.mobile) {
            const mobile = userProfile.mobile;
            const countryCode = userProfile.countryCode || '+91';

            setPhoneNumber(mobile);
            setDefaultCountry(countryCode.slice(1).toLowerCase());
        }
    }, [userProfile]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else {
            setIsResendDisabled(false);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const onValuesChange = (changedValues, allValues) => {
        const { dob, ...rest } = allValues;
        const userData = {
            ...rest,
            dob: dob ? dayjs(dob).format('YYYY-MM-DD') : dob,
        };
        dispatch(setUserProfile(userData));
    };

    const onEmailVerify = () => {
        const { email } = form.getFieldsValue();
        if (!email) {
            message.error('Please enter your email');
            return;
        }
        const payload = { email };
        dispatch(setverifyType('email'))
        dispatch(genrate_login_otp_user_Info({ body: payload, navigate, isMobile: false, isEmail: true }));
        setCountdown(300);
        setIsResendDisabled(true);
    };

    const onMobileVerify = () => {
        const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`;
        const mobile = phoneNumber.slice(-10);
        if (!mobile) {
            message.error('Please enter your mobile number');
            return;
        }
        const payload = { mobile, countryCode };
        dispatch(setverifyType('mobile'))
        dispatch(genrate_login_otp_user_Info({ body: payload, navigate, currentStep: 0, isMobile: true, isEmail: false }));
        setCountdown(300); // Reset countdown to 5 minutes
        setIsResendDisabled(true);
    };

    const onSubmitOTP = () => {
        const { emailOtp, mobileOtp } = form.getFieldsValue();
        const mobileOtpPayload = { mobileOtp };
        const payload = { emailOtp };
        dispatch(verify_otp__user_Info({ body: isEmailVerify ? payload : mobileOtpPayload, navigate }));
    };

    const onResendOTP = () => {
        isEmailVerify ? onEmailVerify() : onMobileVerify();
    };

    useEffect(() => {
        const data = formatDOBDate(userProfile?.dob)
        const formattedDate = dayjs(userProfile?.dob).format('YYYY-MM-DD');
        if (userProfile) {
            form.setFieldsValue({
                name: userProfile.name,
                email: userProfile.email,
                dob: dayjs(formattedDate),
                mobile: userProfile.mobile,
            });
        }
    }, [userProfile, form]);

    const onClose = () => {
        setIsVisivle(false)
    }

    const onSave = () => {

    }

    const onOpenLanguage = () => {
        setIsVisivle(true)
    }

    const showAddSocial = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        form.setFieldsValue({
            name: userProfile?.name,
            email: userProfile?.email,
            website: userProfile?.website,
            phone: userProfile?.phone,
            numberOfEmployees: userProfile?.numberOfEmployees,
            description: userProfile?.description,
            type: userProfile?.type
        })
        setPhoneNumber(userProfile?.phone)
    }, [form, userProfile]);

    const onUpdateProfile = () => {
        form.validateFields()
            .then((values) => {
                const { name, email, type, website, domain, numberOfEmployees, description } = values;
                const payload = {
                    name,
                    type,
                    email,
                    phone: phoneNumber,
                    website,
                    description,
                    domain,
                    numberOfEmployees
                };

                // Dispatch API call
                dispatch(Update_organization({ body: payload, navigate, onClose: onClose }));
                setIsEdit(false);
            })
            .catch((error) => {
                console.error('Validation Failed:', error);
            });
    };

    return (
        <div>
            <Card>
                <div>
                    <div className='flex-space-between'>
                        <div>
                            <h3>Company Details</h3>
                        </div>
                        <div>
                            {
                                isEdit ?
                                    <Button
                                        type="default"
                                        shape="round"
                                        icon={<SaveFilled />}
                                        size="large"
                                        style={{ position: 'relative', bottom: '10px' }}
                                        className='dark-bg'
                                        onClick={onUpdateProfile}
                                    >
                                        Save
                                    </Button>
                                    :
                                    <Button
                                        type="default"
                                        shape="round"
                                        icon={<EditOutlined />}
                                        size="large"
                                        style={{ position: 'relative', bottom: '10px' }}
                                        className='dark-bg'
                                        onClick={onEditfield}
                                    >
                                        Edit Profile
                                    </Button>
                            }
                        </div>
                    </div>
                    <Form
                        form={form}
                        layout="vertical"
                        onValuesChange={onValuesChange}
                    >
                        <Row gutter={[24, 24]}>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    label="Company Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please enter your company name' }]}
                                >
                                    <Input disabled size='large' suffix={<CalendarOutlined />}
                                        placeholder="Company Name pvt ltd."
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    label="Company Email"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Please enter your email' },
                                        { type: 'email', message: 'Please enter a valid email' },
                                    ]}

                                >
                                    <Input  size='large' disabled 
                                    // suffix={
                                    //     userProfile?.isEmailVerified ? <Button type='text' className='green-text'>verified</Button> :
                                    //         <Button loading={verifyType === 'email'} onClick={onEmailVerify} size='middle' shape='round' danger type='primary'>Verify</Button>
                                    // } 
                                    placeholder="amitkumar@.com" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[24, 24]}>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    label="Website Url"
                                    name="website"
                                    rules={[
                                        { required: true, message: 'Please enter your company url' },
                                        { type: 'url', message: 'Please enter a valid company url' },
                                    ]}

                                >
                                    <Input disabled={isEdit ? false : true} suffix={
                                        <Button iconPosition='end' icon={<CopyOutlined />} type='text' className='green-text'>Copy</Button>
                                    } placeholder="www.company.com" />
                                </Form.Item>

                            </Col>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    name="phone"
                                    className='fullwidth'
                                    label="Phone Number"
                                    rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                                >
                                    <div className="phone-input-wrapper">
                                        <PhoneInput
                                            country={defaultCountry}
                                            value={userProfile?.mobile ? userProfile?.mobile : phoneNumber}
                                            onChange={(phone) => setPhoneNumber(phone)}
                                            inputStyle={{ width: '100%' }}
                                            enableSearch={true}
                                            disabled={isEdit ? false : true}
                                        />
                                        {/* {userProfile?.isMobileVerified ?
                                            <Button type='text' className='green-text'>verified</Button>
                                            :
                                            <Button
                                                className="verify-button"
                                                onClick={onMobileVerify}
                                                size='middle'
                                                shape='round'
                                                danger
                                                type='primary'
                                                loading={verifyType === 'mobile'}
                                            >
                                                Verify
                                            </Button>
                                        } */}

                                    </div>
                                </Form.Item>


                            </Col>
                        </Row>
                        {/* <Row gutter={[24, 24]}>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    style={{ width: '100%' }}
                                    label={'Password'}
                                    name="password"
                                    rules={[{ required: true, message: 'Please enter your password' }]}
                                >
                                   
                                    <Input.Password size='large' placeholder="********" />
                                </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    label="Date of origin"
                                    name="dob"
                                    rules={[{ required: true, message: 'Please select your date of birth' }]}
                                >
                                    <DatePicker disabled={isEdit ? false : true} size='large' suffixIcon={<CalendarOutlined />} placeholder="dd/mm/yyyy" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row> */}

                        {/* <Row gutter={[24, 24]}>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    label="Company Permit"
                                    name="companyPermit"
                                    rules={[{ required: true, message: 'Please select your company permit' }]}
                                >
                                    <Select disabled={isEdit ? false : true} size='large' mode="multiple" placeholder="Select company permits">
                                        <Option value="Indian">Indian</Option>
                                        <Option value="Other">Other</Option>
                                        <Option value="US">US</Option>
                                        <Option value="UK">UK</Option>
                                        <Option value="Canada">Canada</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    label="Headquater address"
                                    name="headquaterAddress"
                                    rules={[{ required: true, message: 'Please enter your address' }]}
                                >
                                    <Input disabled={isEdit ? false : true} size='large' placeholder="New Delhi, India, 110001" />
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Row gutter={[24, 24]}>
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    label="Number of employees"
                                    name="numberOfEmployees"
                                    rules={[{ required: true, message: 'Please enter your address' }]}
                                >
                                    <Input disabled={isEdit ? false : true} size='large' placeholder="number of employees" />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                    name="type"
                                    label="Company Type"
                                    rules={[{ required: true, message: 'Please enter company type' }]}
                                >
                                    <Select disabled={isEdit ? false : true} size='large' placeholder="Select your company type">
                                        <Option value="small">Small</Option>
                                        <Option value="middle">Middle</Option>
                                        <Option value="large">Large</Option>
                                    </Select>
                                </Form.Item>
                            </Col> */}
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ required: true, message: 'Please enter your address' }]}
                                >
                                    <TextArea rows={5} disabled={isEdit ? false : true} size='large' placeholder="description" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />

                        {/* <div>
                            <Card className={styles.cardJobDescp} title={"Mission"} extra={<Button size='middle' shape='default' className='dark-bg' >Add / Edit</Button>}>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, More…
                            </Card>
                            <Card className={styles.cardJobDescp} title={"Vission"} extra={<Button size='middle' shape='default' className='dark-bg' >Add / Edit</Button>}>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, More…
                            </Card>
                            <Card className={styles.cardJobDescp} title={"Values"} extra={<Button size='middle' shape='default' className='dark-bg' >Add / Edit</Button>}>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, More…
                            </Card>
                        </div>
                        <Divider /> */}

                    </Form>
                </div>
            </Card>
        </div>
    );
};

export default AboutCompany;
