import React, { useState, useRef } from 'react';
import { Input, Button, Select, Upload, message, Space, Form, Divider, Flex, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import { UploadOutlined } from '@ant-design/icons';
import { genrate_login_otp, signup_user, verify_otp } from '../../features/Auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const SignupOtp = ({ userValues, setUserValues }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { verifyOTPLoad } = useSelector((state) => state.auth)
    const [form] = Form.useForm();

    const goToSignInPage = () => {
        navigate('/login');
    };

    const onVerifyOTP = () => {
        const { emailOtp,mobileOtp } = form.getFieldsValue()
        const payload = {
            emailOtp: emailOtp,
            mobileOtp:mobileOtp
        }
        dispatch(verify_otp({ body: payload, navigate: navigate, isSignup: true }))
    }

    const handleInputChange = (e, setFieldValue) => {
        const value = e.target.value;
        // Allow only numeric input and limit to 6 digits
        if (/^\d{0,6}$/.test(value)) {
            setFieldValue(value);
        }
    };

    return (
        <Form onFinish={onVerifyOTP} layout='vertical' colon={false} form={form} 
        >
            <Form.Item label="Mobile OTP" name={"mobileOtp"} rules={[
                {required:true, message:"Please enter mobile otp"},
                { len: 6, message: "Mobile OTP must be 6 digits" },
                ]}>
                <Input
                    placeholder='Enter Mobile OTP'
                    maxLength={6}
                    onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ""); // Allow only numeric input
                        form.setFieldValue('mobileOtp', value);
                    }}
                />
            </Form.Item>
            <Form.Item label="Email OTP" name={"emailOtp"} rules={[
                {required:true, message:"Please enter email otp"},
                { len: 6, message: "Email OTP must be 6 digits" },
                ]}>
                <Space.Compact style={{ width: '100%' }}>
                    <Input
                        placeholder='Enter Email OTP'
                        maxLength={6}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, ""); // Allow only numeric input
                            form.setFieldValue('emailOtp', value);
                        }}
                    />
                </Space.Compact>
            </Form.Item>
            <Form.Item>
                <Button
                    shape="round"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className={`default-button-style ${styles.loginFormButton}`}
                    // onClick={onVerifyOTP}
                    loading={verifyOTPLoad}
                >
                    {verifyOTPLoad ? 'Redirecting...' : 'Verify via OTP'}
                </Button>
            </Form.Item>

            <div className="text-center">
                <p>
                    Already have an account?{' '}
                    <span onClick={goToSignInPage} className={styles.backtosignup}>
                        Click here to login
                    </span>
                </p>
            </div>
        </Form>
    );
};

export default SignupOtp;

