// import { Button, Card, Form, Input, Modal, Row } from 'antd'
// import React from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { send_password_reset_link, setIsForgetPass } from '../../features/Auth/authSlice';

// const IsForgetPasswordLink = () => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const {isForgetpass} = useSelector((state)=>state.auth)

//     const onSendResetLink = (values) => {
//        dispatch(send_password_reset_link({body:values, navigate:navigate}))
//     }

//     const onhandleClose=()=>{
//         dispatch(setIsForgetPass(false))
//     }
//     return (
//         <Modal open={isForgetpass} footer={false} onCancel={onhandleClose} onClose={onhandleClose}>
//             <Card title={'Forget password'}>
//                 <Form colon={false} layout='vertical' onFinish={onSendResetLink}>
//                     <Form.Item name={"email"} label="Enter your email Id" 
//                     rules={[
//                         { required: true, message: 'Please input your Email!' },
//                         { type: 'email', message: 'The input is not valid E-mail!' }
//                     ]}
//                     >
//                         <Input placeholder='Please enter your email id' />
//                     </Form.Item>
//                     <Row justify={'end'}>
//                         <Button htmlType='submit' className='default-button-style'>
//                             Send Reset Link
//                         </Button>
//                     </Row>
//                 </Form>
//             </Card>
//         </Modal>
//     )
// }

// export default IsForgetPasswordLink


import { Button, Card, Form, Input, Modal, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    change_password,
    send_password_reset_link,
    setIsForgetPass,
    setIsNewPassword,
} from '../../features/Auth/authSlice';

const IsForgetPasswordLink = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isForgetpass, isNewPassword,passresetlinkload,passwordResetload } = useSelector((state) => state.auth);

    // Handle sending reset password link
    const onSendResetLink = (values) => {
        dispatch(send_password_reset_link({ body: values, navigate: navigate }));
    };

    // Handle closing the forget password modal
    const onHandleCloseForget = () => {
        dispatch(setIsForgetPass(false));
    };

    // Handle changing password
    const onChangePassword = (values) => {
        dispatch(change_password({ body: values, navigate: navigate }));
    };

    // Handle closing the change password modal
    const onHandleCloseNewPassword = () => {
        dispatch(setIsNewPassword(false));
    };

    return (
        <>
            {/* Forget Password Modal */}
            <Modal
                open={isForgetpass}
                footer={false}
                onCancel={onHandleCloseForget}
                centered
            >
                <Card title={'Forget Password'}>
                    <Form colon={false} layout="vertical" onFinish={onSendResetLink}>
                        <Form.Item
                            name={"email"}
                            label="Enter your email Id"
                            rules={[
                                { required: true, message: 'Please input your Email!' },
                                { type: 'email', message: 'The input is not valid E-mail!' },
                            ]}
                        >
                            <Input placeholder="Please enter your email id" />
                        </Form.Item>
                        <Row justify={'end'}>
                            <Button loading={passresetlinkload} htmlType="submit" className="default-button-style">
                                Send Reset Link
                            </Button>
                        </Row>
                    </Form>
                </Card>
            </Modal>

            {/* Change Password Modal */}
            <Modal
                open={isNewPassword}
                footer={false}
                onCancel={onHandleCloseNewPassword}
                centered
            >
                <Card title={'Set New Password'}>
                    <Form colon={false} layout="vertical" onFinish={onChangePassword}>
                        <Form.Item
                            name={"email"}
                            label="Enter your email Id"
                            rules={[
                                { required: true, message: 'Please input your Email!' },
                                { type: 'email', message: 'The input is not valid E-mail!' },
                            ]}
                        >
                            <Input placeholder="Please enter your email id" />
                        </Form.Item>
                        <Form.Item
                            name={"password"}
                            label="Enter New Password"
                            rules={[{ required: true, message: 'Please input your new password!' }]}
                        >
                            <Input.Password placeholder="Please enter new password" />
                        </Form.Item>
                        <Form.Item
                            name={"resetPasswordToken"}
                            label="Reset Password Token"
                            rules={[
                                { required: true, message: 'Please input the reset password token!' },
                            ]}
                        >
                            <Input placeholder="Enter your reset password token" />
                        </Form.Item>
                        <Row justify={'end'}>
                            <Button loading={passwordResetload} htmlType="submit" className="default-button-style">
                                Change Password
                            </Button>
                        </Row>
                    </Form>
                </Card>
            </Modal>
        </>
    );
};

export default IsForgetPasswordLink;
