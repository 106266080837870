import React, { useState } from 'react';
import { Avatar, Button, Form, Input } from 'antd';
import styles from './style.module.scss';
import { UserOutlined, LockOutlined, CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import AuthLayout from './AuthLayout';
import Logo from '../CommonElements/Logo';
import { useSelector } from 'react-redux';
import VerificationSuccess from './VerificationSuccess';

const OtpVerification = () => {
    const { authDetails } = useSelector((state) => state.auth);
    const {userType}= useSelector((state)=>state.global)
    const [isModalOpen, setIsModalOpen]=useState(false)
    const onFinish = (values) => {
    };

    const verificationSucceed=()=>{
        setIsModalOpen(true)
    }

    return (
        <AuthLayout>
            <div className={styles.loginFormSection}>
                <Logo />
                <h2>Enter OTP</h2>
                {
                    userType === 'organization' ?
                        <p>
                            To verify enter 6 digit code send an email - <span className={styles.phoneNumber}>company email</span>
                        </p>
                        :
                        <p>To verify enter 6 digit code <span className={styles.phoneNumber}>{authDetails?.countryCode} - {authDetails?.phoneNumber}</span></p>
                }
                <Form
                    name="login_email"
                    className={styles.loginForm}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="otp"
                        rules={[{ required: true, message: 'Please input your otp!' }]}
                        className='fullwidth'
                    >
                        <Input size='large' prefix={<UserOutlined />} placeholder="Enter OTP" />
                    </Form.Item>
                    <div >
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Avatar shape='square' className='default-button-style' size={'small'} icon={<ReloadOutlined />} /> Resend OTP
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button onClick={verificationSucceed} shape='round' size='large' type="primary" htmlType="submit" className={`default-button-style ${styles.loginFormButton}`}>
                            Verify
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <VerificationSuccess isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message={"test@yopmail.com"}/>
        </AuthLayout>
    );
};

export default OtpVerification;
