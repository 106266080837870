import React, { useState } from 'react'
import styles from './style.module.scss'
import { Avatar, Button, Card, Divider, List, Row, Tag } from 'antd'
import { HeatMapOutlined, SaveOutlined, ShareAltOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import { Popover, Steps } from 'antd';

const ContainerHeight = 500;
const CandidateStatusSection = () => {

    const [current, setCurrent] = useState(0);

    const next = () => {
        if (current < 1) {
            setCurrent(current + 1);
        }
    };

    const dummyData =
    {
        "results": [{
            "gender": "male", "name": {
                "title": "Mr", "first": "Customer Support", "last": "Product Designer",
            },
            "duration": ['full-time', '0-1 year', '1-2 year'],
            "howLong": "posted 3 days ago",
            "Description": "Lorem Ipsum has been the industry's standard dummy tex a type e industry's standard dummy tex a type", "picture": { "large": "https://randomuser.me/api/portraits/men/87.jpg", "medium": "https://randomuser.me/api/portraits/med/men/87.jpg", "thumbnail": "https://randomuser.me/api/portraits/thumb/men/87.jpg" }, "nat": "IR"
        }]
    }

    const [data, setData] = useState(dummyData.results);
    const onScroll = (e) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            // appendData();
        }
    };


    const customDot = (dot, { status, index }) => (
        <Popover
            content={
                <span>
                    step {index} status: {status}
                </span>
            }
        >
            {dot}
        </Popover>
    );
    const description = 'You can hover on the dot.';
    return (
        <div>
            <div className={styles.cardStyleJob}>
                <Card>
                    <List>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="email"
                            onScroll={onScroll}
                            style={{ width: '100%' }}
                        >
                            {(item) => (
                                <>
                                    <List.Item key={item.email}>
                                        <List.Item.Meta
                                            avatar={<Avatar size={90} shape='square' src={item.picture.large} />}
                                            title={<div><a href="https://ant.design">{item.name.last}</a>
                                                <div>Figma : {item.duration?.map((ele) => <Tag>{ele}</Tag>)}
                                                </div>
                                            </div>}
                                            description={item.Description}
                                        />
                                        <div className='text-right'>
                                            {/* <div className={styles.locationSection}>
                                                <div><HeatMapOutlined /></div>
                                                <div>Location</div>
                                            </div> */}
                                            {/* <p>{item.howLong}</p> */}
                                            <div>
                                                <div className=''>
                                                    {/* <div md={5} lg={5} xs={24}><Avatar icon={<ShareAltOutlined />} /></div>
                                                    <div md={5} lg={5} xs={24}><Avatar icon={<SaveOutlined />} /></div> */}
                                                    <div className={styles.rightAlign} md={5} lg={5} xs={24}>
                                                        <Button shape='round' className={`default-button-style ${styles.rightAlign}`}>Applied Today</Button></div>
                                                </div>
                                                <div>Recuruiter active : 3 weeks ago</div>
                                            </div>
                                        </div>
                                    </List.Item>
                                    <hr />
                                    <div>
                                        <h3>Application Status</h3>
                                        <div>
                                            <Steps current={1} labelPlacement="vertical" items={[{
                                                // title: 'Applied',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'viewed',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'In Review',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'Interview Scheduled',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'Offer Extended',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            ]} />
                                        </div>
                                        <div>
                                            <h3>Job Description</h3>
                                            <div className='green-text font-bold'>23/14 application reviewd</div>
                                        </div>
                                        <div>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                                        </div>
                                    </div>
                                </>
                            )}
                            {/* <div>Hii</div> */}
                        </VirtualList>
                    </List>

                </Card>
            </div>
        </div>
    )
}

export default CandidateStatusSection