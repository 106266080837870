// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Layout } from "antd";
// import Header from "../../components/Header/Header";
// import Sidebar from "../FindJobPage/LoggedInSidebar";
// import AppContainer from "./AppContainer";
// import { useDispatch } from "react-redux";
// import { get_jobseeker } from "../../features/UserInfo/userInfoSlice";
// import { getAuthToken } from "../../utils/authHelpers";

// const AppLayout = ({ routers }) => {
//   const location = useLocation();
//   const navigate = useNavigate()
//   const dispatch = useDispatch()
//   const token = getAuthToken()
//   const [collapsed, setCollapsed] = useState(false);

//   const currentRoute = routers.find(
//     (route) => route.path === location?.pathname
//   );
//   const showSideNav = currentRoute?.SideNav || false;
//   const showTopNav = currentRoute?.nav || false;

//   return (
//     <>
//       <Layout style={{ minHeight: "100vh" }}>
//         {showTopNav && <Header />}
//         <Layout className="site-layout">
//           {token && showSideNav && <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />}
//           <AppContainer showSideNav={showSideNav}  />
//         </Layout>
//       </Layout>
//     </>
//   );
// };

// export default AppLayout;


import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import { Layout } from "antd";
import Header from "../../components/Header/Header";
import Sidebar from "../FindJobPage/LoggedInSidebar";
import AppContainer from "./AppContainer";
import { useDispatch } from "react-redux";
import { get_jobseeker } from "../../features/UserInfo/userInfoSlice";
import { getAuthToken } from "../../utils/authHelpers";

const AppLayout = ({ routers }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getAuthToken();
  const [collapsed, setCollapsed] = useState(false);

  // Use matchPath to find the current route, accounting for dynamic segments
  const currentRoute = routers.find((route) => 
    matchPath(route.path, location.pathname)
  );
  
  const showSideNav = currentRoute?.SideNav || false;
  const showTopNav = currentRoute?.nav || false;

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        {showTopNav && <Header />}
        <Layout className="site-layout">
          {token && showSideNav && (
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
          )}
          <AppContainer showSideNav={showSideNav} />
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;

