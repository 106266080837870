import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, Dropdown, Image, List, message, Row, Space, Tag } from 'antd';
import VirtualList from 'rc-virtual-list';
import { DownOutlined, HeatMapOutlined, LockOutlined, LoginOutlined, PrinterOutlined, SaveOutlined, ShareAltOutlined } from '@ant-design/icons';
import styles from '../Dashboard/style.module.scss'
import locationimg from '../../../assets/Images/location.svg'
import { getLightColorForTag, timeSincePosted } from '../../../utils/helpers';
import savedBookmardimg from '../../../assets/Images/savedBookmark.svg';
import shareIcon from '../../../assets/Images/share.svg'
import { getAuthToken } from '../../../utils/authHelpers';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ContainerHeight = 600;
const OrgJobList = ({ data }) => {
    // const [data, setData] = useState(dummyData.results);
    const token = getAuthToken();
    const onScroll = (e) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            // appendData();
        }
    };

    const handleShare = (jobId) => {
        const jobLink = `${window.location.origin}/job-details/${jobId}`;
        navigator.clipboard.writeText(jobLink)
            .then(() => {
                message.success('Job link copied to clipboard!');
            })
            .catch(() => {
                message.error('Failed to copy the job link. Please try again.');
            });
    };
    

    // const handlePrint = (job) => {
    //     const doc = new jsPDF();
    //     doc.setFontSize(18);
    //     doc.text(`Job Title: ${job.jobTitle}`, 10, 10);
    //     doc.setFontSize(12);
    //     doc.text(`Location: ${job.location}`, 10, 20);
    //     doc.text(`Salary: ₹${job.salary?.min.toLocaleString()} - ₹${job.salary?.max.toLocaleString()}`, 10, 30);
    //     doc.text(`Experience Required: ${job.totalExperienceRange?.min} - ${job.totalExperienceRange?.max} years`, 10, 40);
    //     doc.text(`Technical Skills:`, 10, 50);
    //     job.technicalSkills?.forEach((skill, index) => {
    //         doc.text(`- ${skill}`, 10, 60 + index * 10);
    //     });
    //     doc.text(`Job Description:`, 10, 70 + job.technicalSkills?.length * 10);
    //     doc.text(job.description || 'No description provided.', 10, 80 + job.technicalSkills?.length * 10, { maxWidth: 180 });
    //     doc.save(`${job.jobTitle.replace(/\s+/g, '_')}.pdf`);
    // };

    const handlePrint = (job) => {
        const doc = new jsPDF();
    
        // Title
        doc.setFontSize(18);
        doc.text(`Job Title: ${job.jobTitle}`, 10, 10);
    
        // Subtitle
        doc.setFontSize(12);
        doc.text(`Organization: ${job.organization || 'N/A'}`, 10, 20);
        doc.text(`Location: ${job.location || 'N/A'}`, 10, 30);
        doc.text(`Job Type: ${job.jobType || 'N/A'}`, 10, 40);
        doc.text(`Notice Period: ${job.noticePeriod || 'N/A'}`, 10, 50);
        doc.text(`Educational Qualification: ${job.educationalQualification || 'N/A'}`, 10, 60);
    
        // Salary
        doc.text(
            `Salary: ₹${job.salary?.min.toLocaleString()} - ₹${job.salary?.max.toLocaleString()}`,
            10,
            70
        );
    
        // Experience
        doc.text(
            `Experience Required: ${job.totalExperienceRange?.min} - ${job.totalExperienceRange?.max} years`,
            10,
            80
        );
    
        // Individual Skills Experience Limit
        doc.text('Individual Skills Experience Limit:', 10, 90);
        const skillsExpY = 100;
        Object.keys(job.individualSkillsExperienceLimit || {}).forEach((skill, index) => {
            doc.text(
                `- ${skill}: ${job.individualSkillsExperienceLimit[skill]} years`,
                10,
                skillsExpY + index * 10
            );
        });
    
        // Technical Skills
        const techSkillsY = skillsExpY + Object.keys(job.individualSkillsExperienceLimit || {}).length * 10 + 10;
        doc.text('Technical Skills:', 10, techSkillsY);
        job.technicalSkills?.forEach((skill, index) => {
            doc.text(`- ${skill}`, 10, techSkillsY + (index + 1) * 10);
        });
    
        // College Restriction
        const collegeRestrictionY = techSkillsY + job.technicalSkills.length * 10 + 20;
        doc.text(
            `College Restriction: ${job.collegeRestriction?.join(', ') || 'None'}`,
            10,
            collegeRestrictionY
        );
    
        // Certifications Required
        const certificationsY = collegeRestrictionY + 10;
        doc.text(
            `Certifications Required: ${job.certificationsRequired?.join(', ') || 'None'}`,
            10,
            certificationsY
        );
    
        // Job Description
        const descriptionY = certificationsY + 20;
        doc.text('Job Description:', 10, descriptionY);
        doc.text(job.description || 'No description provided.', 10, descriptionY + 10, { maxWidth: 180 });
    
        // Save the PDF
        doc.save(`${job.jobTitle.replace(/\s+/g, '_')}.pdf`);
    };
    

    const items = [
        {
            label: <div>Open</div>,
            key: '0',
        },
        {
            label: <div>Close</div>,
            key: '1',
        },
    ];

    console.log("data", data)

    return (
        <List>
            <VirtualList
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemKey="email"
                onScroll={onScroll}
                style={{ width: '100%' }}
            >
                {(item) => (
                    <div className={styles.listItemMeta}>
                        <List.Item className={styles.listItems} key={item.email}>
                            <List.Item.Meta
                                avatar={<Avatar className='default-button-style' size={'large'} icon={<LockOutlined />} />}
                                title={
                                    <div >
                                        <Card className={styles.jobListCards} title={<div><h3 className={styles.jobTitleStyle}>{item?.jobTitle}</h3></div>} extra={
                                            <div>
                                                <div className='flex-wrap-row-direction-gap-15'>
                                                    <Button className='dark-bg' size='small' shape='round'>{item?.jobType}</Button>
                                                    <div>
                                                        <Dropdown
                                                            menu={{
                                                                items,
                                                            }}
                                                            trigger={['click']}
                                                            className={styles.dropdownRight}
                                                        >
                                                            <a onClick={(e) => e.preventDefault()}>
                                                                <Space>
                                                                    <Avatar size={10} className='white-bg mb5' />
                                                                    <div className='mb5'>Open</div>
                                                                    <DownOutlined classID='mb5' />
                                                                </Space>
                                                            </a>
                                                        </Dropdown>
                                                    </div>
                                                    <Button size='small' icon={<Image preview={false} src={locationimg} />}>{item?.location}</Button>
                                                </div>
                                                <div className='margin-top-20 text-end'>{timeSincePosted(item?.createdAt)}</div>
                                            </div>}
                                        >
                                            <div className='margin-top-20'>
                                                Technical Skills
                                                <div className='flex-wrap-row-direction-gap-5 margin-top-20'>
                                                    {item?.technicalSkills?.map((ele, index) => (

                                                        <Button style={{ backgroundColor: getLightColorForTag(index), color: 'black' }} size='small'>
                                                            {ele}
                                                        </Button>

                                                    ))}
                                                </div>
                                            </div>
                                        </Card>
                                        <div className='flex-wrap-row-direction-gap-15 margin-top-10'>
                                            <div>Candidates Applied</div>
                                            <div><Tag color='geekblue-inverse'>{item?.applicants?.length}</Tag></div>
                                        </div>
                                        <div className='margin-top-10'>
                                            Salary: <span className='font-w-400'>₹{item?.salary?.min.toLocaleString()} - ₹{item?.salary?.max.toLocaleString()}</span>
                                        </div>
                                        <div className='margin-top-10'>
                                            Experiance Required : <span className='font-w-400'>{item?.totalExperienceRange?.min} - {item?.totalExperienceRange?.max} year</span>
                                        </div>
                                        <div className='margin-top-10'>
                                            <Row justify={'end'}>
                                                <div className='flex-wrap-row-direction-gap-15'>
                                                    <div onClick={()=>handleShare(item?._id)} className={`add-cursor ${styles.circularImageDiv}`}>
                                                        <Avatar size={'large'} className={styles.circularImage} icon={<ShareAltOutlined />} />
                                                    </div>
                                                    <div onClick={()=>handlePrint(item)} className={`add-cursor ${styles.circularImageDiv}`}>
                                                        <Avatar size={'large'} className={styles.circularImage} icon={<PrinterOutlined />} />
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                }
                            />
                        </List.Item>
                        <div>
                            <Card className={styles.cardJobDescp} title={"Job description"} extra={<div>credit by <span>Khushi</span></div>}>
                                {item?.description}
                            </Card>
                        </div>
                    </div>
                )}
            </VirtualList>
        </List>
    );
};
export default OrgJobList;

