import { Avatar, Button, Card, Col, Image, Row, Carousel, Rate, Tag, List, Grid } from 'antd';
import React from 'react';
import styles from './style.module.scss';
import { ArrowLeftOutlined, ArrowRightOutlined, GlobalOutlined, LeftOutlined, RightOutlined, StarOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import feedbackimg from '../../assets/Images/feedbackimg.png'
import quote from '../../assets/Images/Quote.svg'
import quote2 from '../../assets/Images/Quote-white.svg'

const FeedbackList = () => {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint()
    const sliderItems = [
        {
            name: 'Vizeh Robert',
            location: 'USA',
            rating: 4.5,
            feedback: 'I am thoroughly impressed with this VPN. It has exceeded my expectations, and I have encountered no issues so far. LaslesVPN remains the best in its class.',
            image: feedbackimg // update with correct path
        },
        {
            name: 'Vizeh Robert',
            location: 'USA',
            rating: 4.5,
            feedback: 'I am thoroughly impressed with this VPN. It has exceeded my expectations, and I have encountered no issues so far. LaslesVPN remains the best in its class.',
            image: 'https://cdn.pixabay.com/photo/2017/08/11/10/54/industry-2630319_640.jpg' // update with correct path
        },
        {
            name: 'Vizeh Robert',
            location: 'USA',
            rating: 4.5,
            feedback: 'I am thoroughly impressed with this VPN. It has exceeded my expectations, and I have encountered no issues so far. LaslesVPN remains the best in its class.',
            image: 'https://t3.ftcdn.net/jpg/03/18/14/08/360_F_318140882_seYmbowNlJMFQqoBGxULHUiIVB92uz0c.jpg' // update with correct path
        },
        // Add more testimonials as needed
    ];

    const carouselRef = React.useRef(null);

    const handlePrev = () => {
        carouselRef.current.prev();
    };

    const handleNext = () => {
        carouselRef.current.next();
    };

    return (
        <Content className={styles.landingPageWrapper}>
            <Row justify="center">
                <Col xs={24} xl={18} lg={18}>
                    <div className={styles.feedbackHeader}>
                        <div>
                            <h1>Trusted Happy Customer</h1>
                        </div>
                    </div>

                    <Carousel ref={carouselRef} dots={false} className={styles.carouselWrapper}>
                        {sliderItems.map((item, index) => (
                            <div key={index}>
                                <Card className={styles.testimonialCard}>
                                    <Row gutter={[16, 16]} align="middle" >
                                        <Col xs={24} sm={24} md={4} lg={6} className={styles.imageWrap}>
                                            <div className={styles.quoteLeftIcon}>
                                                <Image width={50} src={quote} alt="Quote Icon" />
                                            </div>
                                            <Image
                                                // width={screens.lg ? 320 : screens.xs ? 300 : 200}
                                                width={screens.lg ? 320 : screens.md ? 250 : '100%'}
                                                height={screens.lg ? 250 : screens.md ? 250 : '100%'}
                                                src={item.image}
                                                alt={item.name}
                                                shape="square"
                                            />
                                            {/* <div className={styles.imageWrapper}> */}

                                                <div className={styles.quoteIcon}>
                                                    <Image className={styles.quoteIconImg} width={screens.md || screens.lg ? 70 : 40} src={quote2} alt="Quote Icon" />
                                                </div>
                                            {/* </div> */}
                                        </Col>
                                        <Col sm={24} xs={24} md={20} lg={18} className={styles.feedbackContent}>
                                            <Card className={styles.cardContent} >
                                                <h1 className='green-text'>Vezeh Robert</h1>
                                                <div className={styles.ratingWrapper}>
                                                    <div><Tag icon={<GlobalOutlined />}>USA</Tag></div>
                                                    <div><Tag icon={<StarOutlined />}>4.5</Tag></div>
                                                </div>
                                                <p>
                                                    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type"
                                                </p>
                                                <div className='position-end'>
                                                    <Tag className={styles.tagWrapper}>
                                                        <Button size='small' className={styles.arrowbtnstyle} icon={<LeftOutlined />} onClick={handlePrev} />
                                                        <span className={styles.pageIndicator}>1/3</span> {/* Dynamic based on carousel */}
                                                        <Button size='small' className={styles.arrowbtnstyle} icon={<RightOutlined />} onClick={handleNext} />
                                                    </Tag>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        </Content>
    );
};

export default FeedbackList;
