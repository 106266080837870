import { Col, Form, Grid, Image, Radio, Row } from 'antd';
import React from 'react';
import rightImg from '../../assets/Images/loginimg2x.png';
import Logo from '../CommonElements/Logo';
import { setUserType } from '../../features/Global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import { setSignupStep } from '../../features/Auth/authSlice';
import { setOrgSignupStep } from '../../features/Organization/OrgAuth/OrgAuthSlice';

const { useBreakpoint } = Grid;

const AuthLayout = ({ children }) => {
    const screens = useBreakpoint();
    const dispatch = useDispatch();
    const { userType } = useSelector((state) => state.global);

    const onChange = (e) => {
        dispatch(setUserType(e.target.value));
        dispatch(setSignupStep(0))
        dispatch(setOrgSignupStep(0))
    };

    return (
        <Row className="fullwidth">
            <Col xs={24} md={12} lg={12}>
                <Row justify="center" align="middle" >
                    <Col xs={18} md={15} lg={15}>
                        <div className={styles.AuthLayoutChildren}>
                            <Logo />
                            <h2>Login</h2>
                            <div>
                                <Form.Item colon={false} label="Continue as">
                                    <Radio.Group onChange={onChange} value={userType}>
                                        <Radio value="employee">Employee</Radio>
                                        <Radio value="organization">Organization</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            {children}
                        </div>
                    </Col>
                </Row>
            </Col>
            {(screens.md || screens.lg) && (
                <Col xs={24} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Image
                        width={screens.lg ? '80%' : '70%'}
                        height={screens.lg ? 'auto' : '70%'}
                        src={rightImg}
                        preview={false}
                        alt="Login"
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                </Col>
            )}
        </Row>
    );
};

export default AuthLayout;
