const API_URL = process.env.API_URL;
//api routes
const API_ROUTES = {
    BASE_URL: process.env.REACT_APP_BACKEND_API_URL,
    AUTHENTICATION: {
      LOGIN_AUTH: "jobSeeker/auth/login",
      SIGN_UP_AUTH:"jobSeeker/auth/signUp",
      LOGOUT_AUTH: "jobSeeker/auth/google/logout",
      GENERATE_OTP:'jobSeeker/auth/generateOtp',
      VERIFY_OTP:'jobSeeker/auth/verifyOtp',
      GOOGLE_LOGIN:'jobSeeker/auth/login/google',
      RESET_PASSWORD_APPLY:'/jobSeeker/auth/sendPasswordToken',
      CHANGE_PASSWORD:'jobSeeker/auth/resetPassword'
    },
    JOBSEEKER:{
      UPDATE_USER:'jobSeeker/',
      GET_JOBSEEKER:'jobSeeker/',
      GET_JOBSEEKER_SKILLS:'jobSeeker/skills',
      UPLOAD_CV:'jobSeeker/uploadCv',
      GET_JOBS:'job/search',
      APPLY_JOB:'jobSeeker/job/apply',
      SAVE_JOBS:'jobSeeker/saveJob',
      GET_SAVE_JOBS:'jobSeeker/getSavedJob',
      GET_REFFRED_JOBS:'jobSeeker/get-referred-jobs',
      SEND_REFERE_JOB:'jobSeeker/job/refer',
      GET_RECOMMENDED_JOBS:'/jobSeeker/get-recommended-jobs',
      UNSAVE_JOB:'jobSeeker/removeSaveJob',
      GET_PROFILE_COMPLETEION_PERCENTAGE:'jobSeeker/profileCompletion'
    },
    ORGANIZATION:{
      SIGN_UP_ORG:"org/auth/orgSignUp",
      LOGIN_ORG:"org/auth/orgLogin",
      GET_ORGANIZATION:'org/',
      UPDATE_ORGANIZATION:'org/',
      REMOVE_ORGANIZATION:'org/',
      ADD_ORG_ROLES:'org/addRoles'
    },
    JOBS:{
      CREATE_JOBS:'org/job',
      UPLOAD_JOB:'org/uploadJob'
    }
  };
  
  const CONFIG = {
    TIMEOUT: 30000,
  };
  
  export {CONFIG, API_ROUTES, API_URL };
