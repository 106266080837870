import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userInfoService from '../../services/api/userInfoService';
import { message } from 'antd';
import AppSecureStorage from '../../services/storage/secureStorage';
import { get_jobseeker } from '../UserInfo/userInfoSlice';
import { unauthorizedError } from '../../utils/helpers';
import { setUserProfile } from '../Global/globalSlice';
const jobseeker = new userInfoService();
const storage = new AppSecureStorage();

export const upload_resume_api = createAsyncThunk(
    "jobseeker/upload_resume_api",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setUploadResumeload(true))
        try {
            const response = await jobseeker.Upload_CV(body)
            dispatch(setUploadResumeload(false))
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setUploadResumeload(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg)
            }
        }
    }
);

export const get_and_search_jobs = createAsyncThunk(
    "jobseeker/get_and_search_jobs",
    async ({ navigate, searchParams = "" }, { dispatch }) => {
        dispatch(setLoader(true))
        try {
            const response = await jobseeker.Get_Jobseeker_Jobs(searchParams)
            dispatch(setLoader(false))
            dispatch(setAllJobs(response.data))
            return response.data
        } catch (error) {
            dispatch(setLoader(false))
            error?.response?.data?.error && message.error(error?.response?.data?.error)
            const [{ msg }] = error.response.data
            message.error(msg)
        }
    }
);

export const apply_job_api = createAsyncThunk(
    "jobseeker/apply_job_api",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setApplyJobLoad(true))
        try {
            const response = await jobseeker.Apply_Jobs(body)
            dispatch(get_jobseeker(navigate))
            dispatch(setApplyJobLoad(false))
            message.success(response?.data?.message)
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setApplyJobLoad(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg)
            }
        }
    }
);

export const save_job_api = createAsyncThunk(
    "jobseeker/save_job_api",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setSaveJobLoader(true))
        try {
            const response = await jobseeker.Save_Jobs(body)
            dispatch(setSaveJobLoader(false))
            message.success("Job saved Successfully")
            dispatch(get_saved_jobs({navigate, userId:body?.userId, searchParams:""}))
            dispatch(setIsSavejobdone(true))
            return response.data
        } catch (error) {
            dispatch(setIsSavejobdone(false))
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setIsSavejobdone(false))
                dispatch(setSaveJobLoader(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg);
            }
        }
    }
);

export const unsave_job_api = createAsyncThunk(
    "jobseeker/unsave_job_api",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setSaveJobLoader(true))
        try {
            const response = await jobseeker.unsave_Jobs(body)
            dispatch(setSaveJobLoader(false))
            message.success("Job removed from save data Successfully")
            dispatch(get_saved_jobs({navigate, userId:body?.userId, searchParams:""}))
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setSaveJobLoader(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg);
            }
        }
    }
);

export const get_saved_jobs = createAsyncThunk(
    "jobseeker/get_saved_jobs",
    async ({ navigate, userId, searchParams }, { dispatch }) => {
        try {
            const response = await jobseeker.Get_save_Jobs(userId, searchParams)
            dispatch(setSavedJobs(response.data))
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                // error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
            }
            // message.error(msg)
        }
    }
);

export const get_reffered_jobs = createAsyncThunk(
    "jobseeker/get_reffered_jobs",
    async ({ navigate, userId, searchParams }, { dispatch }) => {
        try {
            const response = await jobseeker.Get_reffered_Jobs(userId, searchParams)
            dispatch(setRefferedJobs(response.data))
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                // error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                // message.error(msg)
            }
        }
    }
);

export const send_reffer_job_api = createAsyncThunk(
    "jobseeker/send_reffer_job_api",
    async ({ body, navigate, onclose }, { dispatch }) => {
        dispatch(setSendReferLoad(true))
        try {
            const response = await jobseeker.Send_reffer_Job(body)
            dispatch(get_jobseeker(navigate))
            dispatch(setSendReferLoad(false))
            onclose()
            message.success("Your job reffered sucessully")
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                dispatch(setSendReferLoad(false))
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                message.error(msg)
            }
        }
    }
);

export const get_recommended_jobs = createAsyncThunk(
    "jobseeker/get_recommended_jobs",
    async ({ navigate, userId, searchParams }, { dispatch }) => {
        dispatch(setRecommendedLoad(true))
        try {
            const response = await jobseeker.Get_recommended_Jobs(userId, searchParams)
            dispatch(setRecommendedJobs(response.data))
            dispatch(setRecommendedLoad(false))
            return response.data
        } catch (error) {
            dispatch(setRecommendedLoad(false))
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                // error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data
                // message.error(msg)
            }
        }
    }
);

export const get_profile_percentage = createAsyncThunk(
    "userInfo/get_profile_percentage",
    async (navigate, { dispatch }) => {
      try {
        const response = await jobseeker.Get_profile_percentage()
        const { data } = response;
        dispatch(setProfileCompletion(data))
        return response.data
      } catch (error) {
        if (error?.response && error?.response?.status === 401) {
          dispatch(setUserProfile({}))
          unauthorizedError(navigate)
        } else {
          error?.response?.data?.error && message.error(error?.response?.data?.error)
          const [{ msg }] = error.response.data;
          message.error(msg);
        }
      }
    }
  );

const initialState = {
    uploadResume: {},
    uploadResumeLoad: false,
    loading: false,
    alljobs: [],
    loader: false,
    applyJobLoad: false,
    saveJobLoader: false,
    savedJobs: [],
    isSaveJobDone: false,
    refferedJobs: [],
    sendRefferLoad: false,
    recommendedload: false,
    recommendedJobs: [],
    ProfileCompletion:''
};

const jobseekerSlice = createSlice({
    name: 'jobseeker',
    initialState,
    reducers: {
        setUploadResumeload: (state, action) => {
            state.uploadResumeLoad = action.payload
        },
        setAllJobs: (state, action) => {
            state.alljobs = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setApplyJobLoad: (state, action) => {
            state.applyJobLoad = action.payload
        },
        setSaveJobLoader: (state, action) => {
            state.saveJobLoader = action.payload
        },
        setSavedJobs: (state, action) => {
            state.savedJobs = action.payload
        },
        setIsSavejobdone: (state, action) => {
            state.isSaveJobDone = action.payload
        },
        setRefferedJobs: (state, action) => {
            state.refferedJobs = action.payload
        },
        setSendReferLoad: (state, action) => {
            state.sendRefferLoad = action.payload
        },
        setRecommendedLoad: (state, action) => {
            state.recommendedload = action.payload
        },
        setRecommendedJobs: (state, action) => {
            state.recommendedJobs = action.payload
        },
        setProfileCompletion:(state, action)=>{
            state.ProfileCompletion = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(upload_resume_api.pending, (state, action) => {
            state.loading = true;
        })
            .addCase(upload_resume_api.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(upload_resume_api.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(get_and_search_jobs.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(get_and_search_jobs.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(get_and_search_jobs.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(apply_job_api.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(apply_job_api.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(apply_job_api.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(save_job_api.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(save_job_api.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(save_job_api.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(get_reffered_jobs.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(get_reffered_jobs.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(get_reffered_jobs.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(send_reffer_job_api.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(send_reffer_job_api.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(send_reffer_job_api.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(get_recommended_jobs.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(get_recommended_jobs.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(get_recommended_jobs.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const
    { setUploadResumeload, setAllJobs, setLoader, setApplyJobLoad, setSaveJobLoader, setSavedJobs,
        setIsSavejobdone, setRefferedJobs, setSendReferLoad, setRecommendedLoad, setRecommendedJobs,setProfileCompletion
    } = jobseekerSlice.actions;
export default jobseekerSlice.reducer;
