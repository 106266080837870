import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { ArrowRightOutlined } from '@ant-design/icons';

const AddCreditDebitCard = ({ setStep, onNext }) => {
    const [isNext, setIsNext] = useState(1);
    const [formData, setFormData] = useState(null);

    const handlePaymentContinue = (values) => {
        setFormData(values);
        setIsNext(2);
    };

    const handleBack = () => {
        setIsNext(1);
    };

    return (
        <div>
            {isNext === 1 && (
                <Form
                    layout="vertical"
                    colon={false}
                    onFinish={handlePaymentContinue}
                    initialValues={
                        formData
                            ? {
                                ...formData,
                                expiryDate: formData.expiryDate
                                    ? dayjs(formData.expiryDate)
                                    : null,
                            }
                            : {}
                    }
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                label="Card Holder Name"
                                name="cardHolderName"
                                rules={[{ required: true, message: "Please enter cardholder's name!" }]}
                            >
                                <Input placeholder="Name on Card" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                label="Card Number"
                                name="cardNumber"
                                rules={[
                                    { required: true, message: "Please enter a valid card number!" },
                                    { len: 16, message: "Card number must be 16 digits!" },
                                ]}
                            >
                                <Input placeholder="1234 5678 9012 3456" maxLength={16} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                label="Expiry Date"
                                name="expiryDate"
                                rules={[{ required: true, message: "Please enter expiry date!" }]}
                            >
                                <DatePicker
                                    className="fullwidth"
                                    picker="month"
                                    format="MM/YY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                label="CVV"
                                name="cvv"
                                rules={[
                                    { required: true, message: "Please enter CVV!" },
                                    { len: 3, message: "CVV must be 3 digits!" },
                                ]}
                            >
                                <Input.Password placeholder="123" maxLength={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end" style={{ marginTop: '20px' }}>
                        <Col xs={24} md={6} lg={6}>
                            <Button icon={<ArrowRightOutlined/>} iconPosition='end' shape='round' className='fullwidth default-button-style' type="primary" htmlType="submit">
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}

            {isNext === 2 && (
                <div>
                    <Form layout="vertical" initialValues={formData}>
                        <Form.Item label="Enter OTP" name="cardOTP">
                            <Input placeholder="Enter OTP" />
                        </Form.Item>
                    </Form>
                    <div style={{ marginTop: '16px' }}>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={onNext} type="primary" style={{ marginLeft: '8px' }}>
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddCreditDebitCard;