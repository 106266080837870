import { Button, Col, DatePicker, Form, Input, Modal, Row, Card } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import { DeleteOutlined } from '@ant-design/icons';

const AddExperienceModal = ({ isExperienceModalVisible, setIsExperienceModalVisible }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userProfile } = useSelector((state) => state.global);
    const [experienceForm] = Form.useForm();
    const [experienceData, setExperienceData] = useState([{
        id: Date.now(),
        title: "",
        company: "",
        location: "",
        from: "",
        till: "",
        description: ""
    }]);

    const handleExperienceSubmit = (values) => {
        try {
            // Ensure userProfile?.experiences is an array or default to an empty array
            const existingExperiences = Array.isArray(userProfile?.experiences) ? userProfile.experiences : [];

            // Map over experienceData to create the new experience objects
            const formattedExperiences = experienceData.map((experience) => {
                const fromDate = values[`from_${experience.id}`];
                const tillDate = values[`till_${experience.id}`];

                return {
                    title: values[`title_${experience.id}`],
                    company: values[`company_${experience.id}`],
                    location: values[`location_${experience.id}`],
                    from: fromDate ? fromDate.format('YYYY-MM-DD') : "",
                    till: tillDate ? tillDate.format('YYYY-MM-DD') : "",
                    description: values[`description_${experience.id}`]
                };
            });

            // Combine existing experience data with the new ones in the payload
            const payload = { experiences: [...existingExperiences, ...formattedExperiences] };

            dispatch(update_jobseeker({ body: payload, navigate }));
            setIsExperienceModalVisible(false);
            experienceForm.resetFields();
        } catch (error) {
            console.error("Error in handleExperienceSubmit:", error); // Catch and log errors
        }
    };

    const addNewExperience = () => {
        setExperienceData([
            ...experienceData,
            { id: Date.now(), title: "", company: "", location: "", from: "", till: "", description: "" }
        ]);
    };

    const deleteExperience = (id) => {
        setExperienceData(experienceData.filter((experience) => experience.id !== id));
    };

    const handleReset = () => {
        experienceForm.resetFields();
        setExperienceData([{ id: Date.now(), title: "", company: "", location: "", from: "", till: "", description: "" }]);
    };

    return (
        <div>
            <Modal
                title="Add Experience"
                visible={isExperienceModalVisible}
                onCancel={() => setIsExperienceModalVisible(false)}
                footer={null}
                centered
                width={1000}
            >
                <Form colon={false} layout="vertical" form={experienceForm} onFinish={handleExperienceSubmit}>
                    {experienceData.map((experience, index) => (
                        <Card
                            key={experience.id}
                            title={`Experience ${index + 1}`}
                            extra={
                                <Button
                                    danger
                                    type="text"
                                    onClick={() => deleteExperience(experience.id)}
                                >
                                    <DeleteOutlined/>
                                </Button>
                            }
                            style={{ marginBottom: '16px' }}
                        >
                            <Row gutter={16}>
                                <Col xs={24} lg={12} md={12}>
                                    <Form.Item
                                        label="Job Title"
                                        name={`title_${experience.id}`}
                                        rules={[{ required: true, message: "Please enter the job title" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} md={12}>
                                    <Form.Item
                                        label="Company"
                                        name={`company_${experience.id}`}
                                        rules={[{ required: true, message: "Please enter the company name" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col xs={24} lg={12} md={12}>
                                    <Form.Item
                                        label="Location"
                                        name={`location_${experience.id}`}
                                        rules={[{ required: true, message: "Please enter the location" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={12} md={12}>
                                            <Form.Item
                                                label="From Date"
                                                name={`from_${experience.id}`}
                                                rules={[{ required: true, message: "Please select the from date" }]}
                                            >
                                                <DatePicker
                                                    className='fullwidth'
                                                    format="YYYY-MM-DD" // Full date format
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={12} md={12}>
                                            <Form.Item
                                                label="Till Date"
                                                name={`till_${experience.id}`}
                                                rules={[{ required: true, message: "Please select the till date" }]}
                                            >
                                                <DatePicker
                                                    className='fullwidth'
                                                    format="YYYY-MM-DD" // Full date format
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Description"
                                name={`description_${experience.id}`}
                                rules={[{ required: true, message: "Please enter a description" }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Card>
                    ))}
                    <Row>
                        <Col xs={24}>
                            <Button className='multiAddBtn fullwidth' type="dashed" onClick={addNewExperience}>
                                + Add New Experience
                            </Button>
                        </Col>
                    </Row>
                    <Row justify="end" className='margin-top-10' >
                        <Col>
                            <div className="flex-wrap-row-direction-gap-5">
                                <Button size="large" shape="round" onClick={handleReset}>
                                    Reset & Close
                                </Button>
                                <Button className='default-button-style' size="large" shape="round" type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default AddExperienceModal;
