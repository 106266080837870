import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    currentFilterSection: 'apply-jobs',
    blogsStatus: 'new',
    applicationStepper: 'applied',
    toastContent: '',
    toastType: '',
    collapsedData: false,
    currentSettingType: 'account-information',
    userProfile: {},
    currentSaveJobData: {},
    userType: 'employee',
    currentJob: {},
    currentJobStatus:{},
    searchParameters:{},
    internalSearchParameter:{},
    onSearchValues:{},
    currentBoostJobData:null,
    specificJobDetails:{}
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setCurrentFilterSection: (state, action) => {
            state.currentFilterSection = action.payload
        },
        setBlogStatus: (state, action) => {
            state.blogsStatus = action.payload
        },
        setApplicationStatus: (state, action) => {
            state.applicationStepper = action.payload
        },
        setToastContent: (state, action) => {
            state.toastContent = action.payload
        },
        setToastType: (state, action) => {
            state.toastType = action.payload
        },
        setCollapsedData: (state, action) => {
            state.collapsedData = action.payload
        },
        setCurrentInformation: (state, action) => {
            state.currentSettingType = action.payload
        },
        setUserProfile: (state, action) => {
            state.userProfile = action.payload
        },
        setCurrentJobData: (state, action) => {
            state.currentSaveJobData = action.payload
        },
        setUserType: (state, action) => {
            state.userType = action.payload
        },
        setCurrentJob: (state, action) => {
            state.currentJob = action.payload
        },
        setCurrentJobStatus:(state, action)=>{
            state.currentJobStatus = action.payload
        },
        setSearchParameters:(state, action)=>{
            state.searchParameters = action.payload
        },
        setInternalSearchParameter:(state, action)=>{
            state.internalSearchParameter = action.payload
        },
        setOnsearchValues:(state, action)=>{
            state.onSearchValues = action.payload
        },
        setCurrentBoostJobData:(state , action)=>{
            state.currentBoostJobData = action.payload
        },
        setSpecificJobDetails:(state, action)=>{
            state.specificJobDetails = action.payload
        }
    }
});

export const
    { setCurrentFilterSection, setBlogStatus, setApplicationStatus, setToastContent, setToastType,
        setCollapsedData, setCurrentInformation, setUserProfile, setCurrentJobData, setUserType, 
        setCurrentJob , setCurrentJobStatus, setInternalSearchParameter,setOnsearchValues,setCurrentBoostJobData,
        setSearchParameters, setSpecificJobDetails
    } = globalSlice.actions;
export default globalSlice.reducer;
