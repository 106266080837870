import { Avatar, Button, Card, Col, Grid, Image, Row, Typography } from 'antd'
import React from 'react'
import styles from './style.module.scss'
import { getAuthToken } from '../../utils/authHelpers'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentFilterSection } from '../../features/Global/globalSlice'
import userCheckIcon from '../../assets/Images/userChecked.svg'
import fileIcon from '../../assets/Images/fileIcon.svg'
import fileCancel from '../../assets/Images/fileCancel.svg'
import FilterLayoutSM from '../../components/CommonElements/FilterLayoutSM'

const { useBreakpoint } = Grid;

const TopHeaderFindJob = ({ title, content }) => {
    const screens = useBreakpoint();
    const token = getAuthToken()
    const { currentFilterSection, userProfile } = useSelector((state) => state.global)
    const { alljobs } = useSelector((state) => state.jobseeker)
    const dispatch = useDispatch()
    const onFilterSectionChange = (status) => {
        dispatch(setCurrentFilterSection(status))
    }

    const renderStatus = () => {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col lg={8} md={8} xs={24}>
                        <Card className={styles.totalCard}>
                            <div className='flex-space-between'>
                                <div>
                                    <Image preview={false} height={30} src={fileIcon} className={styles.countStyle} size={50} shape='square' />
                                    <div className={styles.totalTitle}>Total Applied Jobs</div>
                                </div>
                                <div>
                                    <div className={styles.countStyle} shape='square'>{userProfile?.appliedJobs ? userProfile?.appliedJobs?.length : 'NA'}</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={8} xs={24}>
                        <Card className={styles.totalCard}>
                            <div className='flex-space-between'>
                                <div>
                                    <Image preview={false} height={30} src={userCheckIcon} className={styles.countStyle} size={50} shape='square' />
                                    <div className={styles.totalTitle}>Total Jobs</div>
                                </div>
                                <div>
                                    <div className={styles.countStyle} shape='square'>{alljobs ? alljobs?.length : 'NA'}</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={8} xs={24}>
                        <Card className={styles.totalCard}>
                            <div className='flex-space-between'>
                                <div>
                                    <Image preview={false} height={30} src={fileCancel} className={styles.countStyle} size={50} shape='square' />
                                    <div className={styles.totalTitle}>Rejected Applications</div>
                                </div>
                                <div>
                                    <div className={styles.countStyle} shape='square'>{userProfile?.rejectedJobs ? userProfile?.rejectedJobs?.length : 0}</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            {
                token ?
                    <div className={styles.topRow}>
                        {((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="2" label="Show status" children={renderStatus()} /> : renderStatus()}
                        <div className={`flex-wrap-row-direction-gap-15 ${styles.filtersBtn}`}>
                            <Button className={currentFilterSection === 'apply-jobs' ? 'default-button-style' : ''} onClick={() => {
                                onFilterSectionChange('apply-jobs')
                            }}>Apply Jobs</Button>
                            <Button className={currentFilterSection === 'application-status' ? 'default-button-style' : ''} onClick={() => {
                                onFilterSectionChange('application-status')
                            }}>Application Status</Button>
                            <Button className={currentFilterSection === 'interview-rating' ? 'default-button-style' : ''} onClick={() => {
                                onFilterSectionChange('interview-rating')
                            }}>Interview Rating</Button>
                        </div>
                    </div>
                    :
                    <div>
                        <Card className={styles.findJobHeader}>
                            <div>{title}</div>
                            <p>{content}</p>
                        </Card>
                    </div>
            }
        </>
    )
}

export default TopHeaderFindJob