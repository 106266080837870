import { CheckCircleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSignupStep } from '../../features/Auth/authSlice';
import { org_signup, setOrgSignupStep, setOrgUserValues } from '../../features/Organization/OrgAuth/OrgAuthSlice';
import PhoneInput from 'react-phone-input-2';
import { setUserType } from '../../features/Global/globalSlice';

const OrganizationSignup = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isRsourceEmailValid, setIsRsourceEmailValid] = useState(false);
    const [userValues, setUserValues] = useState({})
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showDomainField, setShowDomainField] = useState(false);
    const { signupLoading, isGenerateOTP, signupStep, genrateOTPload } = useSelector((state) => state.auth)
    const { orgUserValues, isVerifySuccess, orgSignupLoad } = useSelector((state) => state.orgAuth)


    const emailValidator = (rule, value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(value)) {
            setIsEmailValid(true);
            return Promise.resolve();
        } else {
            setIsEmailValid(false);
            return Promise.reject('Please enter a valid Email!');
        }
    };

    const resorceEmailValidator = (rule, value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(value)) {
            setIsRsourceEmailValid(true);
            return Promise.resolve();
        } else {
            setIsRsourceEmailValid(false);
            return Promise.reject('Please enter a valid Email!');
        }
    };

    const onFinishOrg = (values) => {
        // setUserValues(values)
        
        dispatch(setOrgUserValues(values))
        // dispatch(setOrgSignupStep(1))
        console.log("values", values)
        const payload = {
            email: values?.email,
            name: values?.name,
            userEmail: values?.userEmail,
            password: values?.password,
            domain: values.domain,
            phone: values.mobile
        }
        dispatch(org_signup({ body: payload, navigate: navigate }))
    }

    const goToSignInPage = () => {
        dispatch(setUserType("employee"))
        navigate('/login');
    };

    const handleDomainChange = (value) => {
        setShowDomainField(value === 'yes');
        form.setFieldsValue({ domain: value === 'no' ? 'No' : undefined });
    };


    useEffect(() => {
        form.setFieldsValue({
            name: orgUserValues?.name,
            email: orgUserValues?.email,
            userEmail: orgUserValues?.userEmail,
            password: orgUserValues?.password,
            domain: orgUserValues?.domain,
            confirmPassword:orgUserValues?.confirmPassword,

        });
    }, [form]);

    return (
        <div >
            <Form
                name="signup_form"
                // className={styles.loginForm}
                initialValues={{ remember: true }}
                onFinish={onFinishOrg}
                form={form}
            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please input your organization Name!' }]}
                    className="fullwidth"
                >
                    <Input size="large" prefix={<UserOutlined />} placeholder="Organzation name" />
                </Form.Item>

                {/* <Form.Item
                    name="code"
                    rules={[{ required: true, message: 'Please input your organization code!' }]}
                    className="fullwidth"
                >
                    <Input size="large" prefix={<UserOutlined />} placeholder="Organzation code" />
                </Form.Item> */}
                <Form.Item
                    name="domainSelection"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Select
                        placeholder="Do you want to enter a domain?"
                        onChange={handleDomainChange}
                    >
                        <Select.Option value="yes">Yes</Select.Option>
                        <Select.Option value="no">No</Select.Option>
                    </Select>
                </Form.Item>

                {/* Domain Input Field (Conditional) */}
                {showDomainField && (
                    <Form.Item
                        name="domain"
                        rules={[
                            { required: true, message: 'Please enter your domain' },
                        ]}
                    >
                        <Input placeholder="Enter your domain" />
                    </Form.Item>
                )}

                <Form.Item
                    name="email"
                    rules={[{ required: true, validator: resorceEmailValidator }]}
                    className="fullwidth"
                >
                    <Input
                        size="large"
                        prefix={<UserOutlined />}
                        placeholder="Enter email"
                        suffix={isRsourceEmailValid ? <CheckCircleOutlined style={{ color: 'green' }} /> : null}
                    />
                </Form.Item>

                <Form.Item
                    name="userEmail"
                    rules={[{ required: true, validator: emailValidator }]}
                    className="fullwidth"
                >
                    <Input
                        size="large"
                        prefix={<UserOutlined />}
                        placeholder="Enter resources email"
                        suffix={isEmailValid ? <CheckCircleOutlined style={{ color: 'green' }} /> : null}
                    />
                </Form.Item>

                <Form.Item
                    name="mobile"
                    rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                >
                    <PhoneInput
                        country="in"
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                        inputStyle={{ width: '100%' }}
                        enableSearch
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: 'Please input your password!' },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: 'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character!', // Strong password validation
                        },
                    ]}
                >
                    <Input.Password size="large" prefix={<LockOutlined />} placeholder="Password" />
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    dependencies={['password']} rules={[
                        { required: true, message: 'Please confirm your password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password size="large" prefix={<LockOutlined />} placeholder="Confirm Password" />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button
                        shape="round"
                        size="large"
                        type="primary"
                        htmlType="submit"
                        // onClick={next}
                        loading={signupLoading}
                        className={`default-button-style ${styles.loginFormButton}`}
                    >
                        Next
                    </Button>
                    {/* } */}
                </Form.Item>

                <div className="text-center">
                    <p>
                        Already have an account?{' '}
                        <span onClick={goToSignInPage} className={styles.backtosignup}>
                            Click here to login
                        </span>
                    </p>
                </div>
            </Form>
        </div>
    )
}

export default OrganizationSignup