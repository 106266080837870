import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { Content } from 'antd/es/layout/layout'

import { Layout, Avatar, Row, Col, Button, Tag, Typography, Card, Grid, Form, Input } from 'antd';
import { ArrowDownOutlined, ArrowLeftOutlined, DeleteFilled, EditOutlined, FormOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { get_jobseeker, setIsEditProfile, update_jobseeker } from '../../../features/UserInfo/userInfoSlice';
import { useNavigate } from 'react-router-dom';
import UploadRecommendResume from './UploadRecommendResume';
import PersonalDetails from './PersonalDetails';
import FindJobPageLayout from '../../FindJobPage/FindJobPageLayout';
import CandidateResume from './CandidateResume';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid

const CandidateProfile = () => {
  const screens = useBreakpoint()
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const { isProfileEdit } = useSelector((state) => state.userInfo);
  const {userProfile} = useSelector((state)=>state.global)
  const initialData = {
    name: userProfile?.name,
    title: userProfile?.designation,
    experience: '3 years 11 months',
    location: userProfile?.currentAddress,
    email: userProfile?.email,
    phone: `${userProfile?.countryCode} - ${userProfile?.mobile}`,
    jobType: 'Full-time',
    skills: userProfile?.skills,
    visibility: 'visible'
  };
  const [activeDetail, setActiveDetail] = useState('personal')
  const [isTopProfileEdit, setIsTopProfileEdit] = useState(false);

  const onChangeDetails = (status) => {
    setActiveDetail(status)
  }

  const getColorForTag = (tag) => {
    switch (tag) {
      case 'figma':
        return '#56CDAD';  // Light green
      case 'adobe':
        return '#FEB836';  // Light yellow
      case 'illustrate':
        return '#FAFAFA';  // Light purple
      case 'photoshop':
        return '#4640DE';
      case 'googled':
        return '#000';
      default:
        return '#E1E1E1';  // Default grey for other tags
    }
  };

  const showModal = () => {
    setIsTopProfileEdit(true);
    form.setFieldsValue(initialData); // Set initial form values
  };

  const onEditClick = () => {
    dispatch(setIsEditProfile(true))
  }
  const onSaveProfile = () => {
    dispatch(setIsEditProfile(false))
    dispatch(update_jobseeker({ body: userProfile, navigate: navigate }))
  }

  // useEffect(() => {
  //   dispatch(get_jobseeker(navigate))
  // }, [])
  return (
    <FindJobPageLayout>
      <div className={!screens.sm || !screens.md ? styles.userInfoDivSm : `topRow`}>
        <Content className={!screens.sm || !screens.md ? styles.userInfoWrapperSm : styles.userInfoWrapper}>
          {/* Header Section */}
          <div className='flex-wrap-row-direction-gap-15'>
            <div onClick={()=>navigate('/recommend-candidate')}><ArrowLeftOutlined/></div>
            <h2>Candidate Information</h2>
          </div>
          <Card className={styles.userInfoCrd}>
            <div
              style={{
                // background: 'url(https://your-background-image-url)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                // padding: '40px 20px',
                borderRadius: '8px 8px 0 0',
                position: 'relative',
              }}
            >
              {/* Profile Image */}
              <header
                style={{
                  display: 'flex', alignItems: "center", justifyContent: 'center',
                  height: '100px', textAlign: 'center',
                  backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                  backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9MajxnCm2L8EpGSl-FFkwbPirPDiBVkL9zw&s)`,
                  color: 'white',
                  borderRadius: '10px',
                  fontSize: '24px',
                  fontWeight: 400
                }}
              >
                Product Designer
              </header>
              <Avatar
                size={100}
                src={userProfile?.avatar}
                style={{
                  position: 'absolute',
                  bottom: '-50px',
                  left: '20px',
                  border: '4px solid white',
                }}
              />
            </div>

            {/* Main Content */}
            <Row justify="start" style={{ paddingTop: '60px', paddingBottom: '20px', padding: '20px', lineHeight: '30px' }}>
              {/* Left Column */}
              <Col xs={24} sm={12} md={13} lg={13}>
                <Title level={3} style={{ marginBottom: '0' }}>
                  {userProfile?.name}
                </Title>
                <Text>Product Designer | 3 years 11 months</Text>
                <br />
                <Text>New Delhi, India, 110001</Text>
                <br />
                <Text>{userProfile?.email} | {userProfile?.countryCode}-{userProfile?.mobile}</Text>
                <br />
                <Text strong>Job type</Text> <Button shape='round' className='default-button-style' size='small' color="green">Full-time</Button>
              </Col>

              {/* Right Column */}
              <Col xs={24} sm={12} md={8} lg={8}>
                <Title level={5}>Top 5 key skills</Title>
                <div className='flex-wrap-row-direction-gap-5' style={{ marginBottom: '10px' }}>
                  <Button style={{ backgroundColor: getColorForTag("figma"), color: 'black' }} size='small' shape='round'>Figma</Button>
                  <Button style={{ backgroundColor: getColorForTag("adobe"), color: 'black' }} size='small' shape='round'>Adobe XD</Button>
                  <Button style={{ backgroundColor: getColorForTag("illustrate"), color: 'black' }} size='small' shape='round' >Illustrator</Button>
                  <Button style={{ backgroundColor: getColorForTag("photoshop"), color: 'white' }} size='small' shape='round'>Photoshop</Button>
                  <Button style={{ backgroundColor: getColorForTag("googled"), color: 'white' }} size='small' shape='round'>Google Docs</Button>
                </div>
                <Title level={5}>Visibility</Title>
                <Button className='default-button-style' shape='round' type="primary" size="small" style={{ marginRight: '8px' }}>
                  Profile visible to recruiters
                </Button>
                <Button shape='round' size="small">Keep it private</Button>
              </Col>

              {/* Edit Profile Button */}
              <Col xs={24} sm={24} md={2} lg={2} style={{ textAlign: 'right' }}>
                <Button
                  type="default"
                  shape="round"
                  icon={<EditOutlined />}
                  size="small"
                  style={{ position: 'relative', bottom: '10px' }}
                  className='dark-bg'
                  onClick={showModal}
                >
                  Edit Profile
                </Button>
              </Col>
            </Row>
          </Card>
          <Card className={styles.pdfSection}>
            <Card className={styles.pdfSectionCard}>
              {/* <UploadRecommendResume /> */}
              <CandidateResume/>
            </Card>
          </Card>
          <div className='flex-space-between'>
            <div className={`flex-wrap-row-direction-gap-5 ${styles.detailsBtn}`}>
              <Button className={activeDetail === 'personal' && 'default-button-style'} onClick={() => {
                onChangeDetails('personal')
              }}>Personal Details</Button>
              <Button className={activeDetail === 'education' && 'default-button-style'} onClick={() => {
                onChangeDetails('education')
              }}>Education Details</Button>
              <Button className={activeDetail === 'professional' && 'default-button-style'} onClick={() => {
                onChangeDetails('professional')
              }}>Professional details</Button>
              <Button className={activeDetail === 'skills' && 'default-button-style'} onClick={() => {
                onChangeDetails('skills')
              }}>Skills</Button>
            </div>
            <div>
              <Card className='border-none bg-transparent'>
                <div className='flex-wrap-row-direction-gap-5'>
                  <Button size='middle' shape='round' >Upload Profile</Button>
                  {
                    isProfileEdit ?
                      <Button onClick={onSaveProfile} className='default-button-style'>Save</Button>
                      :
                      <Avatar onClick={onEditClick} icon={<FormOutlined />} />
                  }
                </div>
              </Card>
            </div>
          </div>
          {activeDetail === 'personal' && <PersonalDetails />}
        </Content>
        {/* edit profile popup */}
      </div>
    </FindJobPageLayout>
  )
}

export default CandidateProfile