import React, { useState } from 'react'
import styles from './style.module.scss'
import { Avatar, Button, Card, Col, Divider, List, Result, Row, Tag } from 'antd'
import { ArrowLeftOutlined, HeatMapOutlined, LockOutlined, SaveOutlined, ShareAltOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import { Popover, Steps } from 'antd';
import { useSelector } from 'react-redux';
import { timeSincePosted } from '../../utils/helpers';

// const FillteredApplicationStatus = ({ onBack, onButtonClick, page, buttonText, loadingJobId, submitload }) => {
//     const { userProfile, currentJobStatus } = useSelector((state) => state.global);
//     const { savedJobs } = useSelector((state) => state.jobseeker)

//     const isJobApplied = userProfile?.appliedJobs?.some((job) => job?.jobId === currentJobStatus?._id);
//     const isJobSaved = savedJobs?.some((job) => job?._id === currentJobStatus?._id);
//     const currentButtonText = isJobApplied ? 'Applied' : buttonText;

//     return (
//         <div>
//             <div className={styles.cardStyleJob}>
//                 <Card title={<Button className='font-w-700' variant='text' type='text' icon={<Tag onClick={onBack}><ArrowLeftOutlined /></Tag>}
//                 >Job Status</Button>} extra={
//                     <Button
//                         shape='round'
//                         className={page === "application" || page === "recommend" ? isJobApplied ? 'disableApplyBtn' : `default-button-style ` : 'default-button-style'}
//                         size='large'
//                         onClick={() => onButtonClick(currentJobStatus)}
//                         loading={loadingJobId === currentJobStatus?._id ? submitload : false}
//                         disabled={page === "application" || page === "recommend" ? isJobApplied ? true : false : false}
//                     >
//                         {page === "application" || page === "recommend" ? currentButtonText : buttonText}
//                     </Button>
//                 }>
//                     {!currentJobStatus?.jobTitle  && !currentJobStatus ?
//                         <Result
//                             status="404"
//                             title="404"
//                             subTitle="Sorry, Data not found please select job"
//                             extra={<Button onClick={onBack} type="primary">Back to jobs</Button>}
//                         />
//                         :
//                         <>
//                             <div className='flex-space-between'>
//                                 <div className='flex-wrap-row-direction-gap-5'>
//                                     <div><Avatar className='default-button-style' size={'large'} icon={<LockOutlined />} /></div>
//                                     <div className='font-w-700 font-sz-18' >{currentJobStatus?.jobTitle}</div>
//                                 </div>
//                                 <Tag color='blue'><div>{timeSincePosted(currentJobStatus?.createdAt)}</div></Tag>
//                             </div>
//                             {/* <hr /> */}
//                             <div>
//                                 <h3>Application Status</h3>
//                                 <div>
//                                     <Steps current={1} labelPlacement="vertical" items={[{
//                                         title: 'Applied',
//                                         description: `dd/mm/yyyy | 00:00 AM`,
//                                     },
//                                     {
//                                         title: 'viewed',
//                                         description: `dd/mm/yyyy | 00:00 AM`,
//                                     },
//                                     {
//                                         title: 'In Review',
//                                         description: `dd/mm/yyyy | 00:00 AM`,
//                                     },
//                                     {
//                                         title: 'Interview Scheduled',
//                                         description: `dd/mm/yyyy | 00:00 AM`,
//                                     },
//                                     {
//                                         title: 'Offer Extended',
//                                         description: `dd/mm/yyyy | 00:00 AM`,
//                                     },
//                                     ]} />
//                                 </div>
//                                 <div>
//                                     <h3>Job Description</h3>
//                                     <div className='green-text font-bold'>{currentJobStatus?.description}</div>
//                                 </div>
//                             </div>
//                         </>
//                     }
//                 </Card>
//             </div>
//         </div>
//     )
// }
const FillteredApplicationStatus = ({ onBack, onButtonClick, page, buttonText, loadingJobId, submitload }) => {
    const { userProfile, currentJobStatus } = useSelector((state) => state.global);
    const { savedJobs } = useSelector((state) => state.jobseeker);

    // Validation for currentJobStatus
    const isCurrentJobStatusValid = currentJobStatus && typeof currentJobStatus === 'object' &&
        currentJobStatus?.jobTitle;

    const isJobApplied = userProfile?.appliedJobs?.some((job) => job?.jobId === currentJobStatus?._id);
    const isJobSaved = savedJobs?.some((job) => job?._id === currentJobStatus?._id);
    const currentButtonText = isJobApplied ? 'Applied' : buttonText;

    return (
        <div>
            <div className={styles.cardStyleJob}>
                <Card title={<Button className='font-w-700' variant='text' type='text' icon={<Tag onClick={onBack}><ArrowLeftOutlined /></Tag>}
                >Job Status</Button>} extra={
                    isCurrentJobStatusValid &&
                    <Button
                        shape='round'
                        className={page === "application" || page === "recommend" ? isJobApplied ? 'disableApplyBtn' : `default-button-style ` : 'default-button-style'}
                        size='large'
                        onClick={() => onButtonClick(currentJobStatus)}
                        loading={loadingJobId === currentJobStatus?._id ? submitload : false}
                        disabled={page === "application" || page === "recommend" ? isJobApplied ? true : false : false}
                    >
                        {page === "application" || page === "recommend" ? currentButtonText : buttonText}
                    </Button>
                }>
                    {!isCurrentJobStatusValid ? (
                        <Row justify={'center'}>
                            <Col xs={24} lg={6} md={6}>
                                <Button className='default-button-style fullwidth' onClick={onBack} type="primary">Back to jobs</Button>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <div className='flex-space-between'>
                                <div className='flex-wrap-row-direction-gap-5'>
                                    <div><Avatar className='default-button-style' size={'large'} icon={<LockOutlined />} /></div>
                                    <div className='font-w-700 font-sz-18'>{currentJobStatus?.jobTitle}</div>
                                </div>
                                <Tag color='blue'><div>{timeSincePosted(currentJobStatus?.createdAt)}</div></Tag>
                            </div>
                            <div>
                                <h3>Application Status</h3>
                                <div>
                                    <Steps current={1} labelPlacement="vertical" items={[
                                        { title: 'Applied', description: `dd/mm/yyyy | 00:00 AM` },
                                        { title: 'Viewed', description: `dd/mm/yyyy | 00:00 AM` },
                                        { title: 'In Review', description: `dd/mm/yyyy | 00:00 AM` },
                                        { title: 'Interview Scheduled', description: `dd/mm/yyyy | 00:00 AM` },
                                        { title: 'Offer Extended', description: `dd/mm/yyyy | 00:00 AM` },
                                    ]} />
                                </div>
                                <div>
                                    <h3>Job Description</h3>
                                    <div className='green-text font-bold'>{currentJobStatus?.description}</div>
                                </div>
                            </div>
                        </>
                    )}
                </Card>
            </div>
        </div>
    );
};


export default FillteredApplicationStatus