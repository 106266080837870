import { Button, Card, Col, Divider, Form, Input, Modal, Row, DatePicker, Typography, message } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import { useNavigate } from 'react-router-dom';
import { getDate, getYear } from '../../utils/helpers';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import AddCertificateModal from './AddCertificateModal';
import AddSchoolModal from './AddSchoolModal';
import AddCollegeModal from './AddCollegeModal';

const { RangePicker } = DatePicker;

const EducationDetails = ({ onNext }) => {
    const [isCollegeModalVisible, setIsCollegeModalVisible] = useState(false);
    const [isSchoolModalVisible, setIsSchoolModalVisible] = useState(false);
    const [isCertificateModalVisible, setIsCertificateModalVisible] = useState(false);
    const { userProfile } = useSelector((state) => state.global)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAddCollege = () => {
        setIsCollegeModalVisible(true);
    };

    const handleAddSchool = () => {
        setIsSchoolModalVisible(true);
    };

    const handleAddCertificate = () => {
        setIsCertificateModalVisible(true);
    };

    const handleReset = (formInstance) => {
        formInstance.resetFields();
    };

    const handleRemoveCollegebyId = (index) => {
        const updatedColleges = userProfile?.colleges?.filter((_, i) => i !== index);
        dispatch(update_jobseeker({ body: { colleges: updatedColleges } }));
    };

    const onRemoveCollege = (id) => {
        const updatedColleges = userProfile?.colleges?.filter((item) => item?._id !== id);
        const payload = {
            colleges: updatedColleges,
        };
        dispatch(update_jobseeker({ body: payload, navigate }));
        message.success('College removed successfully!');
    };

    const onRemoveSchool = (id) => {
        const updatedSchool = userProfile?.school?.filter((ele) => ele?._id !== id);
        const payload = {
            school: updatedSchool,
        };
        dispatch(update_jobseeker({ body: payload, navigate }));
        message.success('School removed successfully!');
    };

    const onRemoveCertificate= (id) => {
        const updatedCertificates = userProfile?.certifications?.filter((lang) => lang?._id !== id);
        const payload = {
            certifications: updatedCertificates,
        };
        dispatch(update_jobseeker({ body: payload, navigate }));
        message.success('Certificate removed successfully!');
    };

    return (
        <div>
            <Card title="Academic Details" extra={<Button onClick={onNext} size='middle' className='dark-bg'>Next</Button>}>
                <Row>
                    <Col xs={24} lg={24} md={24}>
                        <div className="flex-space-between">
                            <h3>Collage</h3>
                            {!userProfile?.colleges?.length ? null : <Button className="dark-bg" onClick={handleAddCollege}>Add College</Button>}
                        </div>
                        {!userProfile?.colleges?.length ?
                            <Card>
                                <Row justify={'center'}>
                                    <Col xs={24} md={12} lg={12}>
                                        <Button type='dashed' shape='round' onClick={handleAddCollege} className='fullwidth noDataAddBtn' size='large' icon={<PlusOutlined />}>
                                            Add College
                                        </Button></Col>
                                </Row>
                            </Card>
                            :
                            <> {userProfile?.colleges?.map((college, i) => {
                                return (
                                    <>
                                        <div className={`flex-space-between ${styles.removeBtn}`}>
                                            <Typography.Text className='text-uppercase' strong>{college?.name}</Typography.Text>
                                            <Button onClick={() => onRemoveCollege(college?._id)} shape='round' size='small' className='dark-bg'>Remove</Button>
                                        </div>
                                        <Card className={styles.educatinCards}>
                                            <div className='flex-space-between'>
                                                <div>
                                                    <div>{college?.specialization}</div>
                                                    <div>{college?.degree}</div>
                                                </div>
                                                <div>{getYear(college?.from)} - {getYear(college?.till)}</div>
                                            </div>
                                        </Card>
                                    </>
                                )
                            })}</>
                        }
                        <div className="flex-space-between">
                            <h3>School</h3>
                            {!userProfile?.school?.length ? null : <Button className="dark-bg" onClick={handleAddSchool}>Add School</Button>}
                        </div>
                        {!userProfile?.school?.length ?
                            <Card>
                                <Row justify={'center'}>
                                    <Col xs={24} md={12} lg={12}>
                                        <Button type='dashed' shape='round' onClick={handleAddSchool} className='fullwidth noDataAddBtn' size='large' icon={<PlusOutlined />}>
                                            Add School
                                        </Button></Col>
                                </Row>
                            </Card>
                            : <>
                                {userProfile?.school?.map((scData, i) => {
                                    return (
                                        <>
                                            <div className={`flex-space-between ${styles.removeBtn}`}>
                                                <Typography.Text className='text-uppercase' strong>{scData?.name}</Typography.Text>
                                                <Button onClick={() => onRemoveSchool(scData?._id)} shape='round' size='small' className='dark-bg'>Remove</Button>
                                            </div>
                                            <Card className={styles.educatinCards}>
                                                <div className='flex-space-between'>
                                                    <div>
                                                        <div>{scData?.city}</div>
                                                        <div>{scData?.board}</div>
                                                    </div>
                                                    <div>{getYear(scData?.from)} - {getYear(scData?.till)}</div>
                                                </div>
                                            </Card>
                                        </>
                                    )
                                })}
                            </>
                        }
                        <div className="flex-space-between">
                            <h3>Certifications</h3>
                            {!userProfile?.certifications?.length ? null : <Button className="dark-bg" onClick={handleAddCertificate}>Add Certificate</Button>}
                        </div>
                        {!userProfile?.certifications?.length ?
                            <Card>
                                <Row justify={'center'}>
                                    <Col xs={24} md={12} lg={12}>
                                        <Button type='dashed' shape='round' onClick={handleAddCertificate} className='fullwidth noDataAddBtn' size='large' icon={<PlusOutlined />}>
                                            Add certificates
                                        </Button></Col>
                                </Row>
                            </Card> : <>
                                {userProfile?.certifications?.map((certificate, i) => {
                                    return (
                                        <>
                                            <div className={`flex-space-between ${styles.removeBtn}`}>
                                                <Typography.Text className='text-uppercase' strong>{certificate?.name}</Typography.Text>
                                                <Button onClick={() => onRemoveCertificate(certificate?._id)} shape='round' size='small' className='dark-bg'>Remove</Button>
                                            </div>
                                            <Card className={styles.educatinCards}>
                                                <div className='flex-space-between'>
                                                    <div>
                                                        <div>{certificate?.institute}</div>
                                                        <div>{getDate(certificate?.obtainedOn)}</div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </>
                                    )
                                })}
                            </>
                        }
                    </Col>
                </Row>
            </Card>

            {/* College Modal */}
            <AddCollegeModal isCollegeModalVisible={isCollegeModalVisible} setIsCollegeModalVisible={setIsCollegeModalVisible} />
            {/* School Modal */}
            <AddSchoolModal isSchoolModalVisible={isSchoolModalVisible} setIsSchoolModalVisible={setIsSchoolModalVisible} />
            {/* Certificate Modal */}
            <AddCertificateModal isCertificateModalVisible={isCertificateModalVisible} setIsCertificateModalVisible={setIsCertificateModalVisible} />
        </div>
    );
};

export default EducationDetails;