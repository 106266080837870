import { ArrowLeftOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Image, List, Row, Tag } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from './style.module.scss'
import savedBookmardimg from '../../assets/Images/savedBookmark.svg'
import bookmarkImg from '../../assets/Images/inactive-bookmark.svg'
import shareIcon from '../../assets/Images/share.svg'
import locationImg from '../../assets/Images/location.svg'
import { timeSincePosted } from '../../utils/helpers'

const ShowJobDetails = ({ onBack, page, onButtonClick, loadingJobId, onSaveClick, onShareClick, buttonText, submitload }) => {
    const { currentJob, userProfile } = useSelector((state) => state.global)
    const { savedJobs } = useSelector((state) => state.jobseeker)
    const isJobApplied = userProfile?.appliedJobs?.some((job) => job?.jobId === currentJob?._id);
    const isJobSaved = savedJobs?.some((job) => job?._id === currentJob?._id);
    const currentButtonText = isJobApplied ? 'Applied' : buttonText;

    const getColorForTag = (index) => {
        const hue = (index * 110) % 280; 
        const saturation = 90;  
        const lightness = 90 + (index % 20);  
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    return (
        <Card title={<Button className='font-w-700' variant='text' type='text' icon={<Tag onClick={onBack}><ArrowLeftOutlined /></Tag>}
        >Job Description</Button>}
            extra={<Row justify={'end'}>
                <div className='flex-wrap-row-direction-gap-15'>
                    <div className={`add-cursor ${styles.circularImageDiv}`}>
                        <Image preview={false} className={styles.circularImage} width={40} height={40} src={shareIcon} onClick={() => onShareClick(currentJob)} />
                    </div>
                    {isJobSaved ?
                        <div className={`add-cursor ${styles.circularSavedImageDiv}`}>
                            <Image preview={false} className={styles.circularImage} width={40} height={40} src={savedBookmardimg} onClick={() => onSaveClick(currentJob)} />

                        </div> : <div className={`add-cursor ${styles.circularImageDiv}`}>
                            <Image preview={false} className={styles.circularImage} width={40} height={40} src={bookmarkImg} onClick={() => onSaveClick(currentJob)} />

                        </div>}

                    <div>
                        <Button
                            shape='round'
                            className={page === "application" || page === "recommend" ? isJobApplied ? 'disableApplyBtn' : `default-button-style ` : 'default-button-style'}
                            size='large'
                            onClick={() => onButtonClick(currentJob)}
                            loading={loadingJobId === currentJob?._id ? submitload : false}
                            disabled={page === "application" || page === "recommend" ? isJobApplied ? true : false : false}
                        >
                            {page === "application" || page === "recommend" ? currentButtonText : buttonText}
                        </Button>
                    </div>
                </div>
            </Row>}
        >
            <div>
                <div title={<h3 className={styles.jobTitleStyle}>{currentJob?.jobTitle}</h3>}>
                </div>
                <div>
                    <div className='flex-space-between'>
                        <div className='font-w-700'>Technical Skills</div>
                        <div className='text-end '>
                            <Button size='small' icon={<Image preview={false} src={locationImg} />}
                            >{currentJob?.location}</Button>
                            <div>{timeSincePosted(currentJob?.createdAt)}</div>
                        </div>
                    </div>
                    <div className='flex-wrap-row-direction-gap-5 margin-top-10'>
                        {currentJob?.technicalSkills?.map((ele, index) => (

                            <Button style={{ backgroundColor: getColorForTag(index), color: 'black' }} size='small'>
                                {ele}
                            </Button>

                        ))
                        }
                    </div>
                </div>
                <div className='margin-top-10 font-w-700'>Job Type : <Tag className='default-button-style'>{currentJob?.jobType}</Tag></div>
                <div className='margin-top-10 font-w-700'>Notice Period : <Tag className='default-button-style'>{currentJob?.noticePeriod}</Tag></div>
                <div className='margin-top-10 font-w-700'>Education Qualifications : <span className='font-w-400'>{currentJob?.educationalQualification}</span></div>
                <div className='margin-top-10 font-w-700'>
                    Salary: <span className='font-w-400'>₹{currentJob?.salary?.min.toLocaleString()} - ₹{currentJob?.salary?.max.toLocaleString()}</span>
                </div>
                <div className='margin-top-10 font-w-700'>
                    Experiance Required : <span className='font-w-400'>{currentJob?.totalExperienceRange?.min} - {currentJob?.totalExperienceRange?.max} year</span>
                </div>
                <div className='margin-top-10 font-w-700'>Total applicants applied : <span className='font-w-400'>{currentJob?.applicants?.length}</span></div>
                <div className='margin-top-10 font-w-700'>Certification Required</div>
                <div className='flex-wrap-row-direction-gap-5 margin-top-10'>
                    {currentJob?.certificationsRequired?.map((ele, index) => (

                        <Button style={{ backgroundColor: getColorForTag(index), color: 'black' }} size='small'>
                            {ele}
                        </Button>

                    ))
                    }
                </div>
                <div className='margin-top-10 font-w-700'>
                    Indiviual Skills Experience Required
                    <div className='margin-top-10'>
                        {currentJob?.individualSkillsExperienceLimit &&
                            Object.entries(currentJob.individualSkillsExperienceLimit).map(([key, value]) => (
                                <div className='font-w-400' key={key}>
                                    <strong>{key}</strong>: <Tag color='cyan'>{value}</Tag>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='margin-top-10 font-w-700'>College Restrictions</div>
                <div className='flex-wrap-row-direction-gap-5 margin-top-10'>
                    {currentJob?.collegeRestriction?.map((ele, index) => (

                        <Button style={{ backgroundColor: getColorForTag(index), color: 'black' }} size='small'>
                            {ele}
                        </Button>

                    ))
                    }
                </div>

                <div className='margin-top-10 font-w-700'>
                    Description
                    <div className='font-w-400'>{currentJob?.description}</div>
                </div>
            </div>

            {currentJob?.rolesAndResponsibilities &&
                <List
                    className='margin-top-10'
                    size="small"
                    header={<div>Roles And Responsibilities</div>}
                    bordered
                    dataSource={currentJob?.rolesAndResponsibilities}
                    renderItem={(roles, index) => <List.Item><Avatar size={'small'} className='default-button-style'>{index + 1}</Avatar>{' '}{roles}</List.Item>}
                />
            }
        </Card>
    )
}

export default ShowJobDetails