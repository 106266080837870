import { Alert, Button, Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { remove_organization } from '../../../features/Organization/OrganizationInfo/OrganizationInfoSlice';

const RemoveOrg = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onRemove=()=>{
        dispatch(remove_organization(navigate))
    }
    return (
        <Card title="Remove Organization">
            <Alert message="Warning: Once you delete this organization, it will be permanently removed, and you will not be able to recover it." type="warning" showIcon closable={false} />
            <Row justify={'center'}>
                <Col xs={24} lg={12} md={12}>
                    <Typography.Title level={5}><Button onClick={onRemove} type='primary' variant='filled' className='fullwidth' danger>Delete</Button></Typography.Title>
                </Col>
            </Row>
        </Card>
    );
};

export default RemoveOrg;
