import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Typography, Upload } from 'antd';
import styles from './style.module.scss';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { get_profile_percentage, upload_resume_api } from '../../features/Jobseeker/jobseekerSlice';
import { useNavigate } from 'react-router-dom';
import _axios from '../../services/api';
import { API_ROUTES } from '../../utils/constants';
import { getAuthToken } from '../../utils/authHelpers';
import { get_jobseeker } from '../../features/UserInfo/userInfoSlice';

const UploadResume = () => {
    const dispatch = useDispatch();
    const token = getAuthToken()
    const [file, setFile] = useState();
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate();
    const handleUpload = async (fileData) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('file', fileData);
        
        try {
            const response = await _axios.post(
                API_ROUTES.JOBSEEKER.UPLOAD_CV, 
                formData, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with the actual token
                        "Content-Type": "multipart/form-data", // Ensures the correct Content-Type is set for FormData
                    }
                }
            );
            setLoading(false)
            message.success("Resume uploaded successfully")
            dispatch(get_profile_percentage(navigate))
            dispatch(get_jobseeker(navigate))
            setFile(fileData)
            return response;
        } catch (err) {
            setLoading(false)
            throw err;
        }
        // setFile(fileData)
    };

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append("file", file);
    
        try {
            const response = await _axios.post(
                API_ROUTES.JOBSEEKER.UPLOAD_CV, 
                formData, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with the actual token
                        "Content-Type": "multipart/form-data", // Ensures the correct Content-Type is set for FormData
                    }
                }
            );
            return response;
        } catch (err) {
            throw err;
        }
    };
    

    const props = {
        name: 'file',
        beforeUpload(file) {
            const isPdf = file.type === 'application/pdf';
            if (!isPdf) {
                message.error('You can only upload PDF files!');
                return Upload.LIST_IGNORE; // Prevent non-PDF uploads
            }
            handleUpload(file); // Handle upload manually
            return false; // Prevent default upload behavior
        },
    };

    return (
        <div className="text-center">
            <Upload {...props} showUploadList={false}>
                <div>
                    <Button
                        className={styles.UploadResumeBtn}
                        shape="round"
                        size="large"
                        loading={loading}
                        icon={<UploadOutlined />}
                    >
                        Update Resume
                    </Button>
                </div>
            </Upload>
            <p>Supported Formats: PDF</p>
            {file && (
                    <p style={{ marginTop: '10px' }}>Uploaded File: {file.name}</p>
                  )}
            {/* {file ? <Button onClick={onSubmit}>Save Resume</Button> : null} */}
        </div>
    );
};

export default UploadResume;

