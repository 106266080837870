import _axios from "../index";
import { API_ROUTES } from "../../../utils/constants";


export default class orgAuthService {
  login = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.ORGANIZATION.LOGIN_ORG, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  SignUp = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.ORGANIZATION.SIGN_UP_ORG, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  GET_ORGANIZATION = async () => {
    try {
      const response = await _axios.get(API_ROUTES.ORGANIZATION.GET_ORGANIZATION);
      return response;
    } catch (err) {
      throw err;
    }
  };

  GoogleAuthLogin = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.GOOGLE_LOGIN, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  GenerateOTP = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.GENERATE_OTP, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  VerifyOTP = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.VERIFY_OTP, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  logout = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.AUTHENTICATION.LOGOUT_AUTH, body);
      return response;
    } catch (err) {
      throw err;
    }
  };
}
