import React from 'react'
import styles from './style.module.scss'
import FindJobPageLayout from '../FindJobPage/FindJobPageLayout'
import { Card, Calendar, Button, Checkbox, Dropdown, Menu, Space, Input, Badge } from 'antd';
import { DownOutlined, LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import CustomCalendar from './CustomCalendar';

const UserCalender = () => {
    const menu = (
        <Menu
            items={[
                { key: '1', label: 'Month' },
                { key: '2', label: 'Week' },
                { key: '3', label: 'Day' },
            ]}
        />
    );
    const customHeaderRender = ({ value, type, onChange }) => {
        return (
            <div className={styles.calendarHeader}>
                <div className={styles.calendarNav}>
                    <LeftOutlined onClick={() => onChange(value.clone().subtract(1, type))} />
                    <RightOutlined onClick={() => onChange(value.clone().add(1, type))} />
                </div>
            </div>
        );
    };
    const dateCellRender = (date) => {
        const dateString = date.format("YYYY-MM-DD");
        const isSpecialDate = ["2024-12-02", "2024-12-03"].includes(dateString); // Example
        return (
            <div className={styles.dateCell}>
                <span>{date.date()}</span>
                {isSpecialDate && (
                    <div className={styles.specialEvent}>Lorem ipsum dolor</div>
                )}
            </div>
        );
    };

    return (
        <div>
            <FindJobPageLayout>
                <Card className='topRow'>
                    <div className={styles.calendarContainer}>
                        {/* Header Section */}
                        <div className={styles.header}>
                            <div className={styles.leftSection}>
                                <Button className={styles.todayButton}>
                                    <LeftOutlined />
                                    <span>Today</span>
                                    <RightOutlined />
                                </Button>
                            </div>
                            <div className={styles.rightSection}>
                                <div className={styles.dateText}>December 2024</div>
                                <div>
                                    <Input
                                        placeholder="Interviews"
                                        prefix={<SearchOutlined />}
                                        className={styles.searchInput}
                                    />
                                    <Dropdown overlay={menu} className={styles.dropdown}>
                                        <Button>
                                            Month <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>

                        {/* Main Content */}
                        <div className={styles.mainContent}>
                            {/* Sidebar */}
                            <div className={styles.calendarContainer}>
                                <div className={styles.sidebar}>

                                    {/* Calendar Component */}
                                    <div className={styles.calendarWrapper}>
                                        <Calendar
                                            fullscreen={false}
                                            headerRender={customHeaderRender}  // Custom header to remove default month/year/date
                                        />
                                    </div>

                                    {/* Calendar Filters */}
                                    <div className={styles.calendarFilters}>
                                        <h4>My Calendar</h4>
                                        <Checkbox checked>Upcoming Interviews</Checkbox>
                                        <Checkbox checked>Tasks</Checkbox>
                                        <Checkbox>Meetings</Checkbox>
                                        <Checkbox>Backlogs</Checkbox>
                                    </div>

                                    {/* Calendar Integrations */}
                                    <div className={styles.calendarIntegrations}>
                                        <h4>Calendar Integrations</h4>
                                        <Checkbox>Microsoft</Checkbox>
                                        <Checkbox checked>Google</Checkbox>
                                    </div>
                                </div>
                            </div>


                            {/* Calendar Section */}
                            <div className={styles.calendar}>
                                <CustomCalendar />
                            </div>
                        </div>
                    </div>

                </Card>
            </FindJobPageLayout>
        </div>
    )
}

export default UserCalender