import React from 'react'
import { Content } from 'antd/es/layout/layout'
import { Button, Card, Checkbox, Col, Input, Row, Select } from 'antd'
import styles from './style.module.scss'
import { SearchOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import ApplicationStatus from '../../FindJobPage/ApplicationStatus'
import { getAuthToken } from '../../../utils/authHelpers'
import CandidateStatusSection from './CandidateStatusSection'

const CandidateJobStatus = () => {
    const token = getAuthToken()
    const { currentFilterSection } = useSelector((state) => state.global)
    return (
        <div>
            <div
            // className={token ? styles.filterJobWrapper : styles.filterJobNoToken}
            >
                <Row gutter={16} justify={'center'}>
                    <Col lg={5} md={5}>
                        <Card className={styles.topCardWrapper} title="Filter" extra={<Button color="danger" variant="link">Clear All</Button>}>
                            <ApplicationStatus />
                        </Card>

                    </Col>
                    <Col lg={19} md={19}>
                        <CandidateStatusSection />
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default CandidateJobStatus
