import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import orgAuthService from '../../../services/api/Organization/orgAuthService';
import AppSecureStorage from '../../../services/storage/secureStorage';
import { message } from 'antd';
import { setUserProfile, setUserType } from '../../Global/globalSlice';
import { unauthorizedError } from '../../../utils/helpers';
const orgAuth = new orgAuthService();
const storage = new AppSecureStorage();

export const org_signup = createAsyncThunk(
    "auth/org_signup",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setOrgSignupLoad(true))
        try {
            const response = await orgAuth.SignUp(body)
            storage.setItem('token', response?.data?.token)
            message.success(response.data.message)
            dispatch(setUserType('organization'))
            dispatch(setOrgSignupLoad(false));
            // dispatch(setIsVrifySuccess(true));
            dispatch(setOrgSignupStep(1))
            // setTimeout(() => {
            //     navigate('/');
            //     dispatch(setIsVrifySuccess(false));
            // }, 7000);

            return response.data
        } catch (error) {
            dispatch(setOrgSignupLoad(false))
            error?.response?.data?.error && message.error(error?.response?.data?.error)
            const [{ msg }] = error.response.data
            message.error(msg)
        }
    }
);

export const org_login = createAsyncThunk(
    "auth/org_login",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setLoginLoading(true))
        try {
            const response = await orgAuth.login(body)
            const { data } = response;
            storage.setItem('token', data?.token)
            dispatch(setLoginLoading(false))
            // dispatch(get_jobseeker(navigate)) //get_organization api call
            navigate('/dashboard')
            dispatch(setUserType('organization'))
            storage.setItem('currentUser', 'organization')
            dispatch(get_organization(navigate))
            message.success(response.data.msg)
            return response.data
        } catch (error) {
            error?.response?.data?.error && message.error(error?.response?.data?.error)
            const [{ msg }] = error.response.data
            dispatch(setLoginLoading(false))
            message.error(msg)
        }
    }
);

export const get_organization = createAsyncThunk(
    "orgnization/get_organization",
    async (navigate, { dispatch }) => {
        try {
            const response = await orgAuth.GET_ORGANIZATION()
            const { data } = response;
            dispatch(setUserType('organization'))
            dispatch(setUserProfile(data))
            return response.data
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(setUserProfile({}))
                unauthorizedError(navigate)
            } else {
                error?.response?.data?.error && message.error(error?.response?.data?.error)
                const [{ msg }] = error.response.data;
                message.error(msg);
            }
        }
    }
);

export const org_Google_Auth_Login = createAsyncThunk(
    "auth/Google_Auth_Login",
    async ({ body, navigate }, { dispatch }) => {
        dispatch(setOrgGoogleLoad(true))
        try {
            const response = await orgAuth.GoogleAuthLogin(body)
            storage.setItem('token', response.data?.token)
            console.log("respose goolge api", response.data)
            message.success(response.data.message)
            if (response) {
                dispatch(setOrgGoogleLoad(false))
                navigate('/dashboard')
            }
            return response.data
        } catch (error) {
            dispatch(setOrgGoogleLoad(false))
            const [{ msg }] = error.response.data
            message.error(msg)
        }
    }
);

const initialState = {
    orgUserDetails: {},
    orgSignupLoad: false,
    isOrgGenerateOtp: false,
    loading: false,
    isVerifySuccess: false,
    orgSignupStep: 0,
    orgUserValues: {},
    loginLoading: false,
    orgGoogleLoad: false
};

const orgAuthSlice = createSlice({
    name: 'orgAuth',
    initialState,
    reducers: {
        setOrgSignupLoad: (state, action) => {
            state.orgSignupLoad = action.payload
        },
        setIsOrgGenerateOtp: (state, action) => {
            state.isOrgGenerateOtp = action.payload
        },
        setOrgSignupStep: (state, action) => {
            state.orgSignupStep = action.payload
        },
        setIsVrifySuccess: (state, action) => {
            state.isVerifySuccess = action.payload
        },
        setOrgUserValues: (state, action) => {
            state.orgUserValues = action.payload
        },
        setLoginLoading: (state, action) => {
            state.loginLoading = action.payload
        },
        setOrgGoogleLoad: (state, action) => {
            state.orgGoogleLoad = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(org_signup.pending, (state, action) => {
            state.loading = true;
        })
            .addCase(org_signup.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
            })
            .addCase(org_signup.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(org_login.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(org_login.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
            })
            .addCase(org_login.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(get_organization.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(get_organization.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
            })
            .addCase(get_organization.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const
    { setOrgSignupLoad, setIsOrgGenerateOtp, setOrgSignupStep, setIsVrifySuccess,
        setOrgUserValues, setLoginLoading, setOrgGoogleLoad
    } = orgAuthSlice.actions;
export default orgAuthSlice.reducer;
