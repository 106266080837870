import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserInfoService from '../../services/api/userInfoService';
import AppSecureStorage from '../../services/storage/secureStorage';
import { message } from 'antd'
import AuthService from '../../services/api/authService';
import { unauthorizedError } from '../../utils/helpers';
import { setUserProfile, setUserType } from '../Global/globalSlice';
import { get_profile_percentage } from '../Jobseeker/jobseekerSlice';

const userInfo = new UserInfoService();
const storage = new AppSecureStorage();
const auth = new AuthService();

export const get_jobseeker = createAsyncThunk(
  "userInfo/get_jobseeker",
  async (navigate, { dispatch }) => {
    try {
      const response = await userInfo.Get_Jobseeker()
      const { data } = response;
      dispatch(setUserType('employee'))
      dispatch(setUserProfile(data))
      return response.data
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        dispatch(setUserProfile({}))
        unauthorizedError(navigate)
      } else {
        error?.response?.data?.error && message.error(error?.response?.data?.error)
        const [{ msg }] = error.response.data;
        message.error(msg);
      }
    }
  }
);

export const get_jobseeker_skills = createAsyncThunk(
  "userInfo/get_jobseeker_skills",
  async (navigate, { dispatch }) => {
    try {
      const response = await userInfo.Get_Jobseeker_Skills()
      const { data } = response;
      dispatch(setEmployeeSkills(data))
      return response.data
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        dispatch(setUserProfile({}))
        unauthorizedError(navigate)
      } else {
        error?.response?.data?.error && message.error(error?.response?.data?.error)
        const [{ msg }] = error.response.data;
        message.error(msg);
      }
    }
  }
);

export const update_jobseeker = createAsyncThunk(
  "userInfo/update_jobseeker",
  async ({ body, navigate }, { dispatch }) => {
    try {
      const response = await userInfo.Update_Jobseeker(body)
      const { data } = response;
      dispatch(get_jobseeker(navigate))
      dispatch(setUserProfile(data))
      message.success(response.data.msg)
      dispatch(get_profile_percentage(navigate))
      return response.data
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        dispatch(setUserProfile({}))
        unauthorizedError(navigate)
      } else {
        error?.response?.data?.error && message.error(error?.response?.data?.error)
        const [{ msg }] = error.response.data
        message.error(msg);
      }
    }
  }
);

export const genrate_login_otp_user_Info = createAsyncThunk(
  "userInfo/genrate_login_otp",
  async ({ body, navigate, isMobile, isEmail }, { dispatch }) => {
    dispatch(setIsGenerateOTPLoad(true))
    try {
      const response = await auth.GenerateOTP(body)
      const { data } = response;
      message.success(data.message)
      dispatch(setIsGenerateOTPLoad(false))
      if (isEmail) {
        dispatch(setverifyType(''))
        dispatch(setIsEmailVerify(true))
        // dispatch(setverifyType(''))
      }
      if (isMobile) {
        dispatch(setverifyType(''))
        dispatch(setIsMobileVerify(true))
      }
      return response.data
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        dispatch(setUserProfile({}))
        unauthorizedError(navigate)
      } else {
        dispatch(setverifyType(''))
        dispatch(setIsGenerateOTPLoad(false))
        error?.response?.data?.error && message.error(error?.response?.data?.error)
        const [{ msg }] = error.response.data
        message.error(msg);
      }
    }
  }
);

export const verify_otp__user_Info = createAsyncThunk(
  "auth/verify_otp",
  async ({ body, navigate }, { dispatch }) => {
    dispatch(setIsOTPSubmit(true))
    try {
      const response = await auth.VerifyOTP(body)
      const { data } = response;
      message.success(data.message)
      // storage.setItem('token', data?.token)
      dispatch(setIsOTPSubmit(false))
      dispatch(setIsEmailVerify(false))
      dispatch(setIsMobileVerify(false))
      return response.data
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        dispatch(setUserProfile({}))
        unauthorizedError(navigate)
      } else {
        error?.response?.data?.error && message.error(error?.response?.data?.error)
        dispatch(setIsOTPSubmit(false))
        const [{ msg }] = error.response.data
        message.error(msg)
      }
      // message.error(error.)
    }
  }
);


const initialState = {
  // userProfile: {},
  isLoginPage: true,
  loading: true,
  isProfileEdit: false,
  isEmailVerify: false,
  isMobileVerify: false,
  verifyLoading: false,
  emailVerified: false,
  mobileVerified: false,
  isGenerateOTPLoad: false,
  verifyType: '',
  isOtpSubmit: false,
  employeeSkills: [],
  isLanguageAdd: false
};


const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    // setUserProfile:(state, action)=>{
    //     state.userProfile = action.payload
    // },
    setIsEditProfile: (state, action) => {
      state.isProfileEdit = action.payload
    },
    setIsMobileVerify: (state, action) => {
      state.isMobileVerify = action.payload
    },
    setIsEmailVerify: (state, action) => {
      state.isEmailVerify = action.payload
    },
    setVerifyLoading: (state, action) => {
      state.verifyLoading = action.payload
    },
    setIsGenerateOTPLoad: (state, action) => {
      state.isGenerateOTPLoad = action.payload
    },
    setverifyType: (state, action) => {
      state.verifyType = action.payload
    },
    setIsOTPSubmit: (state, action) => {
      state.isOtpSubmit = action.payload
    },
    setEmployeeSkills: (state, action) => {
      state.employeeSkills = action.payload
    },
    setIsLanguageAdd: (state, action) => {
      state.isLanguageAdd = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(update_jobseeker.pending, (state, action) => {
      state.loading = true;
    })
      .addCase(update_jobseeker.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(update_jobseeker.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(genrate_login_otp_user_Info.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(genrate_login_otp_user_Info.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(genrate_login_otp_user_Info.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(verify_otp__user_Info.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verify_otp__user_Info.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(verify_otp__user_Info.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(get_jobseeker.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(get_jobseeker.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(get_jobseeker.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(get_jobseeker_skills.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(get_jobseeker_skills.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(get_jobseeker_skills.rejected, (state, action) => {
        state.loading = false;
      })
  }
});

export const { setIsEditProfile, setIsEmailVerify, setIsMobileVerify,
  setVerifyLoading, setIsGenerateOTPLoad, setverifyType, setIsOTPSubmit, setEmployeeSkills,
  setIsLanguageAdd } = userInfoSlice.actions;
export default userInfoSlice.reducer;
