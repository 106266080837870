import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Checkbox, Col, Divider, Form, Grid, Input, Modal, Radio, Row, Select, Space, Upload, message } from 'antd';
import { UserOutlined, LockOutlined, CheckCircleOutlined, UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './style.module.scss';
import AuthLayout from './AuthLayout';
import Logo from '../CommonElements/Logo';
import StepsVerification from './StepsVerification';
import SignupOtp from './SignupOtp';
import { genrate_login_otp, Google_Auth_Login, setIsGenerateOTP, setSignupStep, signup_user } from '../../features/Auth/authSlice';
import OrganizationSignup from './OrganizationSignup';
import OrganizationNextStep from './OrganizationNextStep';
import GoogleAuth from './GoogleAuth';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { setOrgSignupStep } from '../../features/Organization/OrgAuth/OrgAuthSlice';
import { setUserType } from '../../features/Global/globalSlice';
import { getAuthToken } from '../../utils/authHelpers';

const { Option } = Select;
const { useBreakpoint } = Grid

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let currentUser = getAuthToken();
  const screens = useBreakpoint()
  const [form] = Form.useForm();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [userValues, setUserValues] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [value, setValue] = useState('employee');
  const [experienceType, setExperienceType] = useState(''); // State to track selected experience type
  const { signupLoading, isGenerateOTP, signupStep, genrateOTPload } = useSelector((state) => state.auth);
  const { userType } = useSelector((state) => state.global)
  const { orgSignupStep } = useSelector((state) => state.orgAuth);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');

  const next = () => {
    const { name, email, experience, password } = form.getFieldsValue()
    const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`; // Get the first part as country code
    const mobile = phoneNumber.slice(-10);
    try {
      const payload = {
        name: name,
        email: email,
        password: password,
        mobile: mobile,
        countryCode: countryCode, // Include selected country code
        candidateType: experience
      };
      dispatch(signup_user({ body: payload }))
      // dispatch(setSignupStep(1))

    } catch (error) {

    }
  };

  const onFinish = (values) => {
    setUserValues(values);
    next();
  };

  const handleExperienceChange = (value) => {
    setExperienceType(value); // Track the selected experience type
  };

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      setFileList([file]);
      setFileName(file.name); // Set the selected file in the state
      return false;  // Prevent automatic upload
    },
    onRemove: (file) => {
      setFileList([]);  // Clear file list on remove
    },
  };

  const handleGoogleLoginSuccess = (response) => {
    const payload = {
      clientId: response?.clientId,
      credentials: response?.credential
    }
    // Send response token to the backend to verify and log the user in
    dispatch(Google_Auth_Login({ body: payload, navigate: navigate }))
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google Login Failed:', error);
  };

  const goBackToFirstStep = () => {
    if (signupStep === 1) {
      dispatch(setSignupStep(0))
    } if (orgSignupStep === 1) {
      dispatch(setOrgSignupStep(0))
    }
  }

  const onGenerateOTP = () => {
    // Get the form values for mobile and email
    const { email } = userValues

    const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`;
    const mobile = phoneNumber.slice(-10);

    // Validate that mobile and email exist
    if (!mobile || !email) {
      message.error('Please enter your mobile and ensure email is provided');
      return;
    }

    // Prepare payload for OTP generation
    const payload = {
      email: email,
      mobile: mobile,
      countryCode: countryCode, // Include country code
    };

    dispatch(genrate_login_otp({ body: payload, navigate: navigate, currentStep: signupStep }))
  };

  return (
    <AuthLayout>
      <div 
      // className={currentUser === 'organization' || (signupStep === 1 || orgSignupStep === 1) ? styles.signupFormSectionOrg : styles.signupFormSection}
      >
        {/* <Logo />
        <div className='flex-wrap-row-direction-gap-15'>
          {(signupStep === 1 || orgSignupStep === 1) && (
            <div onClick={goBackToFirstStep}>
              <ArrowLeftOutlined />
            </div>
          )}
          <h2 className={styles.signupHaeding}>SignUp</h2>
        </div> */}
        {/* <div>
          <Form.Item colon={false} label="Continue as">
            <Radio.Group onChange={(e) => {
              dispatch(setSignupStep(0))
              dispatch(setOrgSignupStep(0))
              dispatch(setUserType(e.target.value))
              // setValue(e.target.value)
            }} value={userType}>
              <Radio value="employee">Employee</Radio>
              <Radio value="organization">Organization</Radio>
            </Radio.Group>
          </Form.Item>
        </div> */}
        <div className={styles.stepperLogo}>
          <Row>
            <Col>
              <StepsVerification />
            </Col>
          </Row>
        </div>
        {
          signupStep === 0 && orgSignupStep === 0 ? (
            <>
              {userType === 'organization' ? (
                <OrganizationSignup />
              ) : (
                <Form
                  name="signup_form"
                  // className={styles.loginForm}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  form={form}
                >
                  <Form.Item name="name" className="fullwidth" rules={[{ required: true, message: 'Please input your name!' }]} >
                    <Input size="large" prefix={<UserOutlined />} placeholder="Name" />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    className="fullwidth"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                  >
                    <Input
                      size="large"
                      prefix={<UserOutlined />}
                      placeholder="Email"
                      suffix={isEmailValid ? <CheckCircleOutlined style={{ color: 'green' }} /> : null}
                    />
                  </Form.Item>

                  <Form.Item
                    name="mobile"
                    rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                  >
                    <PhoneInput
                      country="in"
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputStyle={{ width: '100%' }}
                      enableSearch
                    />
                  </Form.Item>

                  <Form.Item name="experience" rules={[{ required: true, message: 'Please select experience!' }]}>
                    <Select
                      defaultValue="select"
                      className={styles.selectField}
                      onChange={handleExperienceChange}
                    >
                      <Option value="select">--Select experience--</Option>
                      <Option value="fresher">Fresher</Option>
                      <Option value="experienced">Experienced</Option>
                      <Option value="gap">Gap</Option>
                    </Select>
                  </Form.Item>

                  {/* Conditionally render experience fields based on experienceType */}
                  {experienceType === 'experienced' && (
                    <Form.Item name="experienceDetails" label="Experience">
                      <Input.Group compact>
                        <Form.Item name="years" noStyle>
                          <Input style={{ width: '50%' }} placeholder="Years" />
                        </Form.Item>
                        <Form.Item name="months" noStyle>
                          <Input style={{ width: '50%' }} placeholder="Months" />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  )}

                  {experienceType === 'gap' && (
                    <Form.Item name="gapDetails" label="Gap Duration">
                      <Input.Group compact>
                        <Form.Item name="gapYears" noStyle>
                          <Input style={{ width: '50%' }} placeholder="Years" />
                        </Form.Item>
                        <Form.Item name="gapMonths" noStyle>
                          <Input style={{ width: '50%' }} placeholder="Months" />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  )}

                  <Form.Item name="password" rules={[
                    { required: true, message: 'Please input your password!' },
                    {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message: 'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character!', // Strong password validation
                    },
                  ]}>
                    <Input.Password size="large" prefix={<LockOutlined />} placeholder="Password" />
                  </Form.Item>

                  <Form.Item name="confirmPassword" dependencies={['password']} rules={[
                    { required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                      },
                    }),
                  ]}>
                    <Input.Password size="large" prefix={<LockOutlined />} placeholder="Confirm Password" />
                  </Form.Item>

                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      shape="round"
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={signupLoading}
                      className={`default-button-style ${styles.loginFormButton}`}
                    >
                      Sign Up
                    </Button>
                  </Form.Item>

                  <div className="text-center">
                    <p>
                      Already have an account?{' '}
                      <span onClick={() => {
                        dispatch(setUserType("employee"))
                        navigate('/login')
                      }
                      } className={styles.backtosignup}>
                        Click here to login
                      </span>
                    </p>
                  </div>

                  <Divider>OR</Divider>
                  <p className="text-center">Sign up via Google</p>
                  {/* <GoogleAuth /> */}
                  <div className={`${styles.googleAuthWrapper} custom-google-button`}>
                    <div className={styles.googleAuthBtnUP}>
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        onError={handleGoogleLoginFailure}
                        shape="circle"
                        className="googleAuth"
                        // theme="filled_black"
                        // useOneTap
                        width={300}
                        size='large'
                      />
                    </div>
                  </div>
                </Form>
              )}
            </>
          ) : (
            <>
              {orgSignupStep === 1 ? <OrganizationNextStep formDataorg={form} /> : <SignupOtp setUserValues={setUserValues} userValues={userValues} />}
            </>
          )
        }
      </div>
      <Modal closable={false} width={300} centered footer={false} title="Click here to generate otp" open={isGenerateOTP}>
        <Button loading={genrateOTPload} onClick={() => { onGenerateOTP() }} className='fullwidth default-button-style'>Generate OTP</Button>
      </Modal>
    </AuthLayout>
  );
};

export default SignUp;