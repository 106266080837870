import { Button, Col, DatePicker, Form, Input, Modal, Row, Card } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import { DeleteOutlined } from '@ant-design/icons';

const AddSchoolModal = ({ isSchoolModalVisible, setIsSchoolModalVisible }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userProfile } = useSelector((state) => state.global);
    const [schoolForm] = Form.useForm();
    const [schoolData, setSchoolData] = useState([{ id: Date.now(), name: "", city: "", board: "", cgpaOrPercentage: "", from: "", till: "" }]);

    const handleSchoolSubmit = (values) => {
        try {
            // Ensure userProfile?.school is an array or default to an empty array
            const existingSchools = Array.isArray(userProfile?.school) ? userProfile.school : [];

            // Map over schoolData to create the new school objects
            const formattedSchools = schoolData.map((school) => {
                const fromDate = values[`from_${school.id}`];
                const tillDate = values[`till_${school.id}`];

                return {
                    name: values[`name_${school.id}`],
                    city: values[`city_${school.id}`],
                    board: values[`board_${school.id}`],
                    cgpaOrPercentage: Number(values[`cgpaOrPercentage_${school.id}`]),
                    from: fromDate ? fromDate.format('YYYY-MM-DD') : "", // Ensure valid format
                    till: tillDate ? tillDate.format('YYYY-MM-DD') : ""  // Ensure valid format
                };
            });

            // Combine existing school data with the new ones in the payload
            const payload = { school: [...existingSchools, ...formattedSchools] };

            console.log("Payload to dispatch:", payload); // Debugging log to verify payload

            dispatch(update_jobseeker({ body: payload, navigate }));
            setIsSchoolModalVisible(false);
            schoolForm.resetFields();
        } catch (error) {
            console.error("Error in handleSchoolSubmit:", error); // Catch and log errors
        }
    };


    const addNewSchool = () => {
        setSchoolData([
            ...schoolData,
            { id: Date.now(), name: "", city: "", board: "", cgpaOrPercentage: "", from: "", till: "" }
        ]);
    };

    const deleteSchool = (id) => {
        setSchoolData(schoolData.filter((school) => school.id !== id));
    };

    const handleReset = () => {
        schoolForm.resetFields();
        setSchoolData([{ id: Date.now(), name: "", city: "", board: "", cgpaOrPercentage: "", from: "", till: "" }]);
    };

    return (
        <div>
            <Modal
                title="Add School"
                visible={isSchoolModalVisible}
                onCancel={() => setIsSchoolModalVisible(false)}
                footer={null}
                centered
            >
                <Form colon={false} layout="vertical" form={schoolForm} onFinish={handleSchoolSubmit}>
                    {schoolData.map((school, index) => (
                        <Card
                            key={school.id}
                            title={`School ${index + 1}`}
                            extra={
                                <Button
                                    danger
                                    type="text"
                                    onClick={() => deleteSchool(school.id)}
                                >
                                    <DeleteOutlined/>
                                </Button>
                            }
                            style={{ marginBottom: '16px' }}
                        >
                            <Form.Item
                                label="School Name"
                                name={`name_${school.id}`}
                                rules={[{ required: true, message: "Please enter the school name" }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="City"
                                name={`city_${school.id}`}
                                rules={[{ required: true, message: "Please enter the city" }]}
                            >
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col xs={24} lg={12} md={12}>
                                    <Form.Item
                                        label="Board"
                                        name={`board_${school.id}`}
                                        rules={[{ required: true, message: "Please enter the board" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} md={12}>
                                    <Form.Item
                                        label="CGPA/Percentage"
                                        name={`cgpaOrPercentage_${school.id}`}
                                        rules={[{ required: true, message: "Please enter CGPA or Percentage" }]}
                                    >
                                        <Input type="number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} lg={12} md={12}>
                                    <Form.Item
                                        label="From Date"
                                        name={`from_${school.id}`}
                                        rules={[{ required: true, message: "Please select the from date" }]}
                                    >
                                        <DatePicker
                                            className='fullwidth'
                                            format="YYYY-MM-DD" // Full date format
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} md={12}>
                                    <Form.Item
                                        label="Till Date"
                                        name={`till_${school.id}`}
                                        rules={[{ required: true, message: "Please select the till date" }]}
                                    >
                                        <DatePicker
                                            className='fullwidth'
                                            format="YYYY-MM-DD" // Full date format
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Card>
                    ))}
                    <Row justify="space-between" style={{ marginTop: "1rem" }}>
                        <Col xs={24}>
                            <Button className='multiAddBtn fullwidth' type="dashed" onClick={addNewSchool}>
                                + Add New School
                            </Button>
                        </Col>
                    </Row>
                    <Row justify="end" style={{ marginTop: "1rem" }}>
                        <Col>
                            <div className="flex-wrap-row-direction-gap-5">
                                <Button size="large" shape="round" onClick={handleReset}>
                                    Reset & Close
                                </Button>
                                <Button className='default-button-style' size="large" shape="round" type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default AddSchoolModal;




