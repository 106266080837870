import { Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react'
import { update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const AddCollegeModal = ({ isCollegeModalVisible, setIsCollegeModalVisible }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [collegeForm] = Form.useForm();
    const { userProfile } = useSelector((state) => state.global);
    const [collegeData, setCollegeData] = useState([]);
    const [collegeFormData, setCollegeFormData] = useState([
        {id: Date.now(), name: '', city: '', degree: '', specialization: '', cgpaOrPercentage: '', from: "", till: "" },
    ]);


    const handleCollegeFormChange = (index, field, value) => {
        const updatedForms = [...collegeFormData];
        updatedForms[index][field] = value;
        setCollegeFormData(updatedForms);
    };

    const handleAddCollegeForm = () => {
        setCollegeFormData([
            ...collegeFormData,
            { name: '', city: '', degree: '', specialization: '', cgpaOrPercentage: '', from: '', till: '' },
        ]);
    };

    const handleRemoveCollege = (index) => {
        const updatedForms = collegeFormData.filter((_, i) => i !== index);
        setCollegeFormData(updatedForms);
    };
    return (
        <div>
            <Modal
                title="Add College"
                visible={isCollegeModalVisible}
                onCancel={() => setIsCollegeModalVisible(false)}
                footer={null}
                centered
                width={1000}
            >
                <Form
                    colon={false}
                    layout="vertical"
                    form={collegeForm}
                    onFinish={(values) => {
                        try {
                            // Ensure userProfile?.colleges is an array or default to an empty array
                            const existingColleges = Array.isArray(userProfile?.colleges) ? userProfile.colleges : [];
                    
                            // Map over collegeFormData to create new college objects
                            const formattedColleges = collegeFormData.map((college, index) => {
                                const fromDate = values[`from_${index}`];
                                const tillDate = values[`till_${index}`];
                    
                                return {
                                    name: values[`name-${index}`],
                                    city: values[`city-${index}`],
                                    degree: values[`degree-${index}`],
                                    specialization: values[`specialization-${index}`],
                                    cgpaOrPercentage: Number(values[`cgpaOrPercentage-${index}`]),
                                    from: fromDate ? fromDate.format('YYYY-MM-DD') : "", // Format the date
                                    till: tillDate ? tillDate.format('YYYY-MM-DD') : ""  // Format the date
                                };
                            });
                    
                            // Combine existing college data with the new ones in the payload
                            const payload = { colleges: [...existingColleges, ...formattedColleges] };
                    
                            console.log("Payload to dispatch:", payload); // Debugging log to verify payload
                    
                            dispatch(update_jobseeker({ body: payload, navigate }));
                            setCollegeData([...collegeData, ...formattedColleges]);
                            setIsCollegeModalVisible(false);
                            collegeForm.resetFields();
                            setCollegeFormData([{ id: Date.now(), name: '', city: '', degree: '', specialization: '', cgpaOrPercentage: '', from: '', till: '' }]);
                        } catch (error) {
                            console.error("Error in onFinish:", error); // Catch and log errors
                        }
                    }}
                >
                    {collegeFormData.map((form, index) => (
                        <div key={index} className='margin-top-10'>
                            <Card title={<div>{`College ${index + 1}`}</div>} extra={<Button danger type="danger"
                                onClick={() => handleRemoveCollege(index)}><DeleteOutlined /></Button>}>

                                <Row gutter={16}>
                                    <Col xs={24} lg={12} md={12}>
                                        <Form.Item
                                            label="College Name"
                                            name={`name-${index}`}
                                            rules={[{ required: true, message: 'Please enter the college name' }]}
                                        >
                                            <Input
                                                value={form.name}
                                                onChange={(e) => handleCollegeFormChange(index, 'name', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12} md={12}>
                                        <Form.Item
                                            label="City"
                                            name={`city-${index}`}
                                            rules={[{ required: true, message: 'Please enter the city' }]}
                                        >
                                            <Input
                                                value={form.city}
                                                onChange={(e) => handleCollegeFormChange(index, 'city', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} lg={12} md={12}>
                                        <Form.Item
                                            label="CGPA/Percentage"
                                            name={`cgpaOrPercentage-${index}`}
                                            rules={[{ required: true, message: 'Please enter CGPA/Percentage' }]}
                                        >
                                            <Input
                                                type="number"
                                                value={form.cgpaOrPercentage}
                                                onChange={(e) => handleCollegeFormChange(index, 'cgpaOrPercentage', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12} md={12}>
                                        <Form.Item
                                            label="Degree"
                                            name={`degree-${index}`}
                                            rules={[{ required: true, message: 'Please enter the degree' }]}
                                        >
                                            <Input
                                                value={form.degree}
                                                onChange={(e) => handleCollegeFormChange(index, 'degree', e.target.value)}
                                            />
                                        </Form.Item></Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} lg={12} md={12}>
                                        <Form.Item
                                            label="Specialization"
                                            name={`specialization-${index}`}
                                            rules={[{ required: true, message: 'Please enter the specialization' }]}
                                        >
                                            <Input
                                                value={form.specialization}
                                                onChange={(e) => handleCollegeFormChange(index, 'specialization', e.target.value)}
                                            />
                                        </Form.Item></Col>
                                    <Col xs={24} lg={12} md={12}>
                                        {/* <Form.Item
                                            label="Duration"
                                            name={`dates-${index}`}
                                            rules={[{ required: true, message: 'Please select the duration' }]}
                                        >
                                            <RangePicker
                                                className='fullwidth'
                                                picker="year"
                                                format="YYYY"
                                                value={form.dates}
                                                onChange={(dates) => handleCollegeFormChange(index, 'dates', dates)}
                                            />
                                        </Form.Item> */}
                                        <Row gutter={16}>
                                            <Col xs={24} lg={12} md={12}>
                                                <Form.Item
                                                    label="From Date"
                                                    name={`from_${index}`}
                                                    rules={[{ required: true, message: "Please select the from date" }]}
                                                >
                                                    <DatePicker
                                                        className='fullwidth'
                                                        format="YYYY-MM-DD" // Full date format
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12} md={12}>
                                                <Form.Item
                                                    label="Till Date"
                                                    name={`till_${index}`}
                                                    rules={[{ required: true, message: "Please select the till date" }]}
                                                >
                                                    <DatePicker
                                                        className='fullwidth'
                                                        format="YYYY-MM-DD" // Full date format
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    ))}
                    <Button
                        type="dashed"
                        onClick={handleAddCollegeForm}
                        className='margin-top-10 fullwidth multiAddBtn'
                    >
                        + Add Another College
                    </Button>

                    <Row justify="end" className='margin-top-10'>
                        <Col>
                            <div className="flex-wrap-row-direction-gap-5">
                                <Button
                                    size="large"
                                    shape="round"
                                    onClick={() => {
                                        setIsCollegeModalVisible(false);
                                        setCollegeFormData([{ name: '', city: '', degree: '', specialization: '', cgpaOrPercentage: '', dates: [] }]);
                                    }}
                                >
                                    Reset & Close
                                </Button>
                                <Button
                                    size="large"
                                    shape="round"
                                    type="primary"
                                    className="default-button-style"
                                    htmlType="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default AddCollegeModal