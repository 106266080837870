import { API_ROUTES } from "../../utils/constants";
import _axios from "./index"; // Import the simplified _axios module

export default class AuthService {

  Get_Jobseeker = async () => {
    try {
      const response = await _axios.get(API_ROUTES.JOBSEEKER.GET_JOBSEEKER);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Update_Jobseeker = async (body) => {
    try {
      const response = await _axios.patch(API_ROUTES.JOBSEEKER.UPDATE_USER, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Get_Jobseeker_Skills = async () => {
    try {
      const response = await _axios.get(API_ROUTES.JOBSEEKER.GET_JOBSEEKER_SKILLS);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Upload_CV = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.JOBSEEKER.UPLOAD_CV, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Get_Jobseeker_Jobs = async (searchParams) => {
    try {
      const response = await _axios.get(`${API_ROUTES.JOBSEEKER.GET_JOBS}?${searchParams}`);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Apply_Jobs = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.JOBSEEKER.APPLY_JOB, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Save_Jobs = async (body) => {
    try {
      const response = await _axios.post(API_ROUTES.JOBSEEKER.SAVE_JOBS, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Get_save_Jobs = async (userId, searchParams) => {
    try {
      const url = `${API_ROUTES.JOBSEEKER.GET_SAVE_JOBS}?userId=${userId}${searchParams ? `&${searchParams}` : ""}`;
      const response = await _axios.get(url);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Get_reffered_Jobs = async (userId, searchParams) => {
    try {
      // Construct the URL with proper query parameter concatenation
      const url = `${API_ROUTES.JOBSEEKER.GET_REFFRED_JOBS}?userId=${userId}${searchParams ? `&${searchParams}` : ""}`;
      const response = await _axios.get(url);
      return response;
    } catch (err) {
      throw err;
    }
  };


  Send_reffer_Job = async (body) => {
    try {
      const response = await _axios.post(`${API_ROUTES.JOBSEEKER.SEND_REFERE_JOB}`, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Get_recommended_Jobs = async (userId, searchParams) => {
    try {
      // Construct the URL with proper query parameter concatenation
      const url = `${API_ROUTES.JOBSEEKER.GET_RECOMMENDED_JOBS}?userId=${userId}${searchParams ? `&${searchParams}` : ""}`;
      const response = await _axios.get(url);
      return response;
    } catch (err) {
      throw err;
    }
  };

  unsave_Jobs = async (body) => {
    try {
      const response = await _axios.delete(API_ROUTES.JOBSEEKER.UNSAVE_JOB, body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  Get_profile_percentage = async () => {
    try {
      const response = await _axios.get(API_ROUTES.JOBSEEKER.GET_PROFILE_COMPLETEION_PERCENTAGE);
      return response;
    } catch (err) {
      throw err;
    }
  };

}