import React, { useState } from "react";
import { Table, Input, Select, DatePicker, Button, Tag, Card, Typography, Row, Col, Breadcrumb, Radio } from "antd";
import { SearchOutlined, DownOutlined, UpOutlined, DeleteOutlined, BackwardOutlined, BackwardFilled, ArrowLeftOutlined, AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import styles from "./style.module.scss";
import FindJobPageLayout from "../../FindJobPage/FindJobPageLayout";
import { getAuthToken } from "../../../utils/authHelpers";
import { Link, useNavigate, useParams } from "react-router-dom";
import CandidateJobStatus from "./CandidateJobStatus";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const CandidateDetails = () => {
    const token = getAuthToken();
    const navigate = useNavigate()
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [toggleBtn, setToggleBtn] = useState('list');
    const [filters, setFilters] = useState({
        search: "",
        applicationTracking: "",
        dateRange: null,
        status: "",
    });
    const { id, name } = useParams()

    const data = [
        {
            key: "1",
            jobId: "2024001",
            jobName: "Product designer",
            company: "Figma",
            applicationTracking: "New",
            date: "01-09-2024 to 31-09-2024",
            status: "Active",
            performance: {
                jobPosting: 4,
                applicationRates: 4,
                candidateQuality: 4,
            },
            feedback: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
            recruiter: "Recruiter name",
        },
        {
            key: "2",
            jobId: "2024002",
            jobName: "UI/UX Designer",
            company: "Adobe",
            applicationTracking: "Shortlisted",
            date: "01-10-2024 to 31-10-2024",
            status: "Hold",
        },
    ];

    const columns = [
        {
            title: "S.no",
            dataIndex: "key",
            key: "key",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Job ID",
            dataIndex: "jobId",
            key: "jobId",
            render: (text) => <Link to={`/candidate-details/${text}`}>{text}</Link>,
        },
        {
            title: "Job Name",
            dataIndex: "jobName",
            key: "jobName",
        },
        {
            title: "Company",
            dataIndex: "company",
            key: "company",
        },
        {
            title: "Application Tracking",
            dataIndex: "applicationTracking",
            key: "applicationTracking",
            render: (status) => {
                const colorMap = {
                    New: "green",
                    Shortlisted: "purple",
                    Interviewed: "orange",
                    Rejected: "red",
                    Accepted: "blue",
                };
                return <Tag color={colorMap[status] || "default"}>{status}</Tag>;
            },
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => {
                const colorMap = {
                    Active: "green",
                    Hold: "gold",
                    Draft: "gray",
                    Closed: "red",
                };
                return <Tag color={colorMap[status] || "default"}>{status}</Tag>;
            },
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <>
                    {/* Expand/Collapse Icon */}
                    {expandedRowKeys.includes(record.key) ? (
                        <UpOutlined
                            onClick={() => toggleExpand(record.key)}
                            style={{ cursor: "pointer", color: "#1890ff" }}
                        />
                    ) : (
                        <DownOutlined
                            onClick={() => toggleExpand(record.key)}
                            style={{ cursor: "pointer", color: "#1890ff" }}
                        />
                    )}
                </>
            ),
        },
    ];

    const toggleExpand = (key) => {
        setExpandedRowKeys((prevKeys) =>
            prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
        );
    };

    return (
        <FindJobPageLayout>
            <div className={token ? styles.filterJobWrapper : styles.filterJobNoToken}>
                <div className={styles.jobStatusContainer}>
                    {/* Search Filters */}
                    <Card>
                        <div className={styles.searchFilters}>
                            <Input
                                placeholder="Job ID, name, company"
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className={`fullwidth ${styles.searchBox}`}
                            />
                            <Select
                                placeholder="Application tracking"
                                onChange={(value) => setFilters({ ...filters, applicationTracking: value })}
                                className={`fullwidth ${styles.searchBox}`}
                            >
                                <Option value="New">New</Option>
                                <Option value="Shortlisted">Shortlisted</Option>
                                <Option value="Interviewed">Interviewed</Option>
                                <Option value="Rejected">Rejected</Option>
                                <Option value="Accepted">Accepted</Option>
                            </Select>
                            <RangePicker
                                onChange={(dates) => setFilters({ ...filters, dateRange: dates })}
                                className={`fullwidth ${styles.searchBox}`}
                            />
                            <Select
                                placeholder="Status"
                                onChange={(value) => setFilters({ ...filters, status: value })}
                                className={`fullwidth ${styles.searchBox}`}
                            >
                                <Option value="Active">Active</Option>
                                <Option value="Hold">Hold</Option>
                                <Option value="Draft">Draft</Option>
                                <Option value="Closed">Closed</Option>
                            </Select>
                            <Button
                                type="primary"
                                size="large"
                                className="default-button-style fullwidth"
                                icon={<SearchOutlined />}
                                onClick={() => console.log("Filters applied:", filters)}
                            >
                                Search
                            </Button>
                        </div>
                    </Card>
                    <Card
                        className={styles.recommentTableHeading}
                        title={
                            <>
                                <div className="flex-wrap-row-direction-gap-15">
                                    <div onClick={() => {
                                        navigate('/job-status')
                                    }}>
                                        <ArrowLeftOutlined />
                                    </div>
                                    <Breadcrumb
                                        separator=">"
                                        items={[
                                            {
                                                title: 'List of Jobs',
                                            },
                                            {
                                                title: `${name}(${id})`,
                                                href: '',
                                            },
                                            {
                                                title: 'List of Candidate',
                                                href: '/job-status',
                                            },
                                        ]}
                                    />
                                </div>
                            </>
                        }
                        extra={
                            <div>
                                <div className={styles.actionButtons}>
                                    {/* <Button
                                        className="default-button-style"
                                        type="primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Invite
                                    </Button>
                                    <Button
                                        iconPosition="start"
                                        icon={<DeleteOutlined />}
                                        className="dark-bg"
                                        type="danger"
                                    >
                                        Delete
                                    </Button> */}
                                    <Button
                                        iconPosition="start"
                                        icon={<DeleteOutlined />}
                                        className="dark-bg"
                                        type="danger"
                                    >
                                        Delete
                                    </Button>
                                    <Radio.Group value={toggleBtn} onChange={(e) => setToggleBtn(e.target.value)}>
                                        <Radio.Button className={toggleBtn === "list" ? 'default-button-style' : ''} value="list"><BarsOutlined /></Radio.Button>
                                        <Radio.Button className={toggleBtn === "details" ? 'default-button-style' : ''} value="details"><AppstoreOutlined /></Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                        }
                    />
                    {
                        toggleBtn === 'list' ?
                            <Table
                                columns={columns}
                                expandable={{
                                    expandedRowKeys,
                                    onExpand: (expanded, record) =>
                                        setExpandedRowKeys((prevKeys) =>
                                            expanded ? [...prevKeys, record.key] : prevKeys.filter((key) => key !== record.key)
                                        ),
                                    expandedRowRender: (record) => (
                                        <>
                                            {/* Expanded Content */}
                                            <div >
                                                <Card className={styles.performanceAnalytics}>
                                                    <Text strong>Performance Analytics</Text>
                                                    <Row justify="start">
                                                        <Col className={styles.candiateRow} span={5}>
                                                            <Card className={styles.performanceQuality}>
                                                                <Text strong>{record.performance?.jobPosting}/5</Text>
                                                                <p>Job Posting Performance</p>
                                                            </Card></Col>
                                                        <Col className={styles.candiateRow} span={5}>
                                                            <Card className={styles.performanceQuality}>
                                                                <Text strong> {record.performance?.applicationRates}/5</Text>
                                                                <p>Application Rates</p>
                                                            </Card>
                                                        </Col>
                                                        <Col className={styles.candiateRow} span={5}>
                                                            <Card className={styles.performanceQuality}>
                                                                <Text strong>{record.performance?.candidateQuality}/5</Text>
                                                                <p>Candidate Quality</p>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <div className={styles.feedbackNotes} >
                                                        <Card className={styles.cardJobDescp} title={"Feedback & Notes"} extra={<p>
                                                            <Text type="secondary">(Feedback by: <Text strong>{record.recruiter}</Text>) <span>{' '}<Button size="middle" className="dark-bg">Add / Edit</Button></span></Text>
                                                        </p>}>
                                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, More…
                                                        </Card>
                                                    </div>
                                                </Card>
                                            </div>
                                        </>
                                    ),
                                    expandIcon: () => null, // Hide the default expand icon completely
                                }}
                                dataSource={data}
                                className={styles.jobStatusTable}
                                style={{ marginTop: 16 }}
                                pagination={{ pageSize: 5 }}
                            />
                            :
                            <CandidateJobStatus/>
                    }
                </div>
            </div>
        </FindJobPageLayout>
    );
};

export default CandidateDetails;

