import React, { useEffect, useState } from 'react'
import { Content } from 'antd/es/layout/layout'
import { Button, Card, Checkbox, Col, Grid, Input, Row, Select } from 'antd'
import styles from './style.module.scss'
import { SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import FindJobPageLayout from '../../FindJobPage/FindJobPageLayout'
import TopHeaderFindJob from '../../FindJobPage/TopHeaderFindJob'
import FilterSection from '../../../components/CommonElements/FilterSection'
import ApplicationStatus from '../../FindJobPage/ApplicationStatus'
import FilterredApplyJobs from '../../FindJobPage/FilterredApplyJobs'
import FillteredApplicationStatus from '../../FindJobPage/FillteredApplicationStatus'
import InterviewRating from '../../FindJobPage/InterviewRating'
import { getAuthToken } from '../../../utils/authHelpers'
import OrgTopHeader from '../OrgTopHeader/OrgTopHeader'
import DashboardContent from './DashboardContent'
import { get_organization } from '../../../features/Organization/OrgAuth/OrgAuthSlice'
import { useNavigate } from 'react-router-dom'
import { get_and_search_jobs } from '../../../features/Jobseeker/jobseekerSlice'
import { applyFiltersAllPages } from '../../../utils/helpers'
import FilterLayoutSM from '../../../components/CommonElements/FilterLayoutSM'


const {useBreakpoint} = Grid;
const Dashboard = () => {
  const screens = useBreakpoint();
  const token = getAuthToken();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentFilterSection, internalSearchParameter, searchParameters, onSearchValues } = useSelector((state) => state.global);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState({});

  const applyFilters = () => {
    applyFiltersAllPages(internalSearchParameter, searchParameters, onSearchValues, filters, dispatch, navigate)
  };
  // useEffect(() => {
  //   dispatch(get_organization(navigate))
  // }, [])

  const renderFilter = () => {
    return (
      <>
        <FilterSection
          postedAtOptions={[
            { value: '1', label: 'Jack' },
            { value: '2', label: 'Lucy' },
            { value: '3', label: 'Tom' },
          ]}
          jobTypeOptions={[
            { label: 'Full-time', value: 'full-time' },
            { label: 'Part-time', value: 'part-time' },
            { label: 'Internship', value: 'internship' },
            { label: 'Freelance', value: 'freelance' },
          ]}
          experienceOptions={[
            { label: 'Fresher', value: 'fresher' },
            { label: '1+', value: '1+' },
            { label: '2+ to 4+', value: '2+ to 4+' },
            { label: '6+ to 8+', value: '8+ to 10+' },
            { label: '10+ to 15+', value: '15+ to 25+' },
          ]}
          salaryOptions={[
            { label: 'Under $1000', value: 'under_1000' },
            { label: '$1k-$2k', value: '1k-2k' },
            { label: '$3k-$5k', value: '3k-5k' },
            { label: '$6k-$9k', value: '6k-9k' },
          ]}
          showSalaryRange={true}
          showExperience={true}
          isFilterApplied={isFilterApplied}
          applyFilters={applyFilters}
        // apiCall={get_saved_jobs}
        // userId={userProfile?._id}
        setIsFilterApplied={setIsFilterApplied}
        />
      </>
    )
  }

  return (
    <div>
      <FindJobPageLayout>
        <OrgTopHeader />
        <div className={token ? styles.filterJobWrapper : styles.filterJobNoToken}>

          <Row justify={'center'}>
            <Col xs={24} lg={5} md={24}>
             { ((screens.xs || screens.md || screens.sm) && !screens.lg) ? <FilterLayoutSM uniqueKey="1" label="Add Filters" children={renderFilter()} /> : renderFilter()}
            </Col>
            <Col xs={24} lg={19} md={24}>
              <DashboardContent />
            </Col>
          </Row>

        </div>

      </FindJobPageLayout>
    </div>
  )
}

export default Dashboard
