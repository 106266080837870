import React, { useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Grid, theme } from 'antd';
import styles from './style.module.scss';

const { useBreakpoint } = Grid;

const FilterLayoutSM = ({ children, label, uniqueKey }) => {
  const { token } = theme.useToken();
  const [activeKey, setActiveKey] = useState(null); // Manages active panel for this instance
  const panelStyle = {
    marginBottom: 8,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const handlePanelChange = (key) => {
    setActiveKey(activeKey === key ? null : key); // Toggle the panel
  };

  return (
    <Collapse
      bordered={false}
      activeKey={activeKey} // Active key for this instance
      onChange={handlePanelChange}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className={styles.filterSM}
    >
      <Collapse.Panel 
        key={uniqueKey} 
        header={<div className='green-text'>{label}</div>} 
        style={panelStyle}
      >
        <p>{children}</p>
      </Collapse.Panel>
    </Collapse>
  );
};

export default FilterLayoutSM;
