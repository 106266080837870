// import React, { useEffect, useState } from 'react'
// import styles from './style.module.scss'
// import { Button, Card, Col, Input, Row, Select } from 'antd'
// import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
// import axios from 'axios'
// import SearchBar from '../../../components/CommonElements/SearchBar'
// import OrgJobList from '../OrgJobList/OrgJobList'
// import { JobsData } from '../../../components/dummyData/DummyData'
// import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import { get_and_search_jobs } from '../../../features/Jobseeker/jobseekerSlice'


// const DashboardContent = () => {
//     const [jobs, setJobs] = useState([]);
//     const { alljobs } = useSelector((state) => state.jobseeker);
//     const {userProfile} = useSelector((state)=>state.global)
//     const dispatch = useDispatch();
//     const navigate = useNavigate()

//     console.log({userProfile:userProfile , allJobs:alljobs})

//     useEffect(() => {
//         dispatch(get_and_search_jobs({ navigate, searchParams: "" }))
//     }, []);

//     const handleApplyClick = (job) => {
//         console.log('Apply clicked for', job);
//         // Handle apply logic here
//     };

//     const handleShareClick = (job) => {
//         console.log('Share clicked for', job);
//         // Handle share logic here
//     };

//     const handleSaveClick = (job) => {
//         console.log('Save clicked for', job);
//         // Handle save logic here
//     };



//     return (
//         <div>
//             <div className={styles.cardStyleJob}>
//                 <Card className={styles.searchCards}>
//                     <SearchBar
//                         showYearsSelect={false}
//                         jobPlaceholder="Product Designer"
//                         locationPlaceholder="Delhi, India"
//                         yearsPlaceholder="5 years"
//                         locationOptions={[
//                             { value: '1', label: 'Not Identified' },
//                             { value: '2', label: 'Closed' },
//                             { value: '3', label: 'Communicated' },
//                         ]}
//                         yearsOptions={[
//                             { value: '1', label: '1-2 years' },
//                             { value: '2', label: '3-5 years' },
//                             { value: '3', label: '6+ years' },
//                         ]}
//                         onSearch={() => console.log('Search button clicked')}
//                         searchBtnText="Search"
//                     />

//                 </Card>
//                 <div className={styles.counterResults}>jobs</div>
//             </div>
//             <div className={styles.cardStyleJob}>
//                 <OrgJobList
//                     data={jobs}
//                     buttonText="Apply Now"
//                     onButtonClick={handleApplyClick}
//                     onShareClick={handleShareClick}
//                     onSaveClick={handleSaveClick}
//                 />
//             </div>
//         </div>
//     )
// }

// export default DashboardContent

import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { Card } from 'antd';
import SearchBar from '../../../components/CommonElements/SearchBar';
import OrgJobList from '../OrgJobList/OrgJobList';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_and_search_jobs } from '../../../features/Jobseeker/jobseekerSlice';

const DashboardContent = () => {
    const [filteredJobs, setFilteredJobs] = useState([]);
    const { alljobs } = useSelector((state) => state.jobseeker);
    const { userProfile } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch all jobs
        dispatch(get_and_search_jobs({ navigate, searchParams: "" }));
    }, [dispatch, navigate]);

    useEffect(() => {
        // Filter jobs based on the userProfile ID
        if (alljobs && userProfile?._id) {
            const matchedJobs = alljobs.filter(job => job.organization === userProfile._id);
            setFilteredJobs(matchedJobs);
        }
    }, [alljobs, userProfile]);

    const handleApplyClick = (job) => {
        console.log('Apply clicked for', job);
        // Handle apply logic here
    };

    const handleShareClick = (job) => {
        console.log('Share clicked for', job);
        // Handle share logic here
    };

    const handleSaveClick = (job) => {
        console.log('Save clicked for', job);
        // Handle save logic here
    };

    return (
        <div>
            <div className={styles.cardStyleJob}>
                <Card className={styles.searchCards}>
                    <SearchBar
                        showYearsSelect={false}
                        jobPlaceholder="Product Designer"
                        locationPlaceholder="Delhi, India"
                        yearsPlaceholder="5 years"
                        locationOptions={[
                            { value: '1', label: 'Not Identified' },
                            { value: '2', label: 'Closed' },
                            { value: '3', label: 'Communicated' },
                        ]}
                        yearsOptions={[
                            { value: '1', label: '1-2 years' },
                            { value: '2', label: '3-5 years' },
                            { value: '3', label: '6+ years' },
                        ]}
                        onSearch={() => console.log('Search button clicked')}
                        searchBtnText="Search"
                    />
                </Card>
                <div className={styles.counterResults}>
                    {filteredJobs?.length} jobs found
                </div>
            </div>
            <div className={styles.cardStyleJob}>
                <OrgJobList
                    data={filteredJobs}
                    buttonText="Apply Now"
                    onButtonClick={handleApplyClick}
                    onShareClick={handleShareClick}
                    onSaveClick={handleSaveClick}
                />
            </div>
        </div>
    );
};

export default DashboardContent;
