import { Avatar, Button, Card, Col, Grid, Image, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Content } from 'antd/es/layout/layout';
import { ArrowRightOutlined } from '@ant-design/icons';
import EmployeeList from './EmployeList';
import defaultCodeImg from '../../assets/Images/blackCodeImg.svg';
import featureImg from '../../assets/Images/featureImg.png';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSearchParameters } from '../../features/Global/globalSlice';
import { getAuthToken } from '../../utils/authHelpers';
import { get_and_search_jobs } from '../../features/Jobseeker/jobseekerSlice';

const EmployeCategory = () => {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = getAuthToken();

    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [activeFilter, setActiveFilter] = useState('All');

    useEffect(() => {
        // Mock data for categories including Non-Tech
        const fetchedCategories = [
            { id: 1, title: 'Web Developer', description: 'Build and maintain websites and web applications.', type: 'Technology' },
            { id: 2, title: 'Product Manager', description: 'Oversee product development and ensure success.', type: 'Management' },
            { id: 3, title: 'Design Market', description: 'Explore opportunities in the design industry.', type: 'Non-Tech' },
            { id: 4, title: 'Software Engineer', description: 'Develop, test, and maintain software systems.', type: 'Engineer' },
            { id: 5, title: 'UI/UX Designer', description: 'Create user-friendly and visually appealing designs.', type: 'Non-Tech' },
            { id: 6, title: 'Frontend Developer', description: 'Specialize in building user-facing interfaces.', type: 'Technology' },
            { id: 7, title: 'Backend Developer', description: 'Work on server-side logic and database management.', type: 'Technology' },
            { id: 8, title: 'Full Stack Developer', description: 'Handle both frontend and backend development tasks.', type: 'Technology' },
            // Non-Tech roles
            { id: 9, title: 'Marketing Specialist', description: 'Develop marketing strategies to promote products.', type: 'Non-Tech' },
            { id: 10, title: 'Content Writer', description: 'Create compelling content for blogs, articles, and websites.', type: 'Non-Tech' },
            { id: 11, title: 'HR Manager', description: 'Manage recruitment, employee relations, and HR policies.', type: 'Non-Tech' },
            { id: 12, title: 'Customer Support', description: 'Provide support and resolve customer inquiries.', type: 'Non-Tech' },
        ];
    
        setCategories(fetchedCategories);
        setFilteredCategories(fetchedCategories); // Initialize with all categories
    }, []);
    
    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
        if (filter === 'All') {
            setFilteredCategories(categories); // Show all categories
        } else {
            setFilteredCategories(categories.filter((category) => category.type === filter));
        }
    };

    const onSearchByCategory = (categoryTitle) => {
            const searchParams = `searchString=${categoryTitle}`;
            const navigationPage = token ? '/searched-jobs' : '/find-job';
    
            // Dispatch actions with properly constructed payload
            dispatch(setSearchParameters(searchParams));
            dispatch(get_and_search_jobs({ navigate, searchParams }));
    
            // Navigate to the appropriate page
            navigate(navigationPage);
    };


    return (
        <Content className={styles.landingPageWrapper}>
            <div>
                <Row justify={'center'}>
                    <Col xs={24} xl={18} lg={18}>
                        <div>
                            <h1>Explore by category</h1>
                            <div className="flex-space-between">
                                <div className="flex-wrap-row-direction-gap-5">
                                    <Button
                                        className={`${styles.categoryBtn} ${activeFilter === 'All' ? styles.active : ''}`}
                                        shape="round"
                                        size="middle"
                                        onClick={() => handleFilterChange('All')}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        className={`${styles.categoryBtn} ${activeFilter === 'Technology' ? styles.active : ''}`}
                                        shape="round"
                                        size="middle"
                                        onClick={() => handleFilterChange('Technology')}
                                    >
                                        Technology
                                    </Button>
                                    <Button
                                        className={`${styles.categoryBtn} ${activeFilter === 'Engineer' ? styles.active : ''}`}
                                        shape="round"
                                        size="middle"
                                        onClick={() => handleFilterChange('Engineer')}
                                    >
                                        Engineer
                                    </Button>
                                    <Button
                                        className={`${styles.categoryBtn} ${activeFilter === 'Non-Tech' ? styles.active : ''}`}
                                        shape="round"
                                        size="middle"
                                        onClick={() => handleFilterChange('Non-Tech')}
                                    >
                                       Non-Tech
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.categoryWrapper}>
                            {/* Dynamic Category Cards */}
                            <Row gutter={[16, 16]} className={styles.searchJobRow}>
                                {filteredCategories.map((category) => (
                                    <Col onClick={()=>onSearchByCategory(category.title)} key={category.id} xs={24} xl={6} lg={6} md={6} className={styles.searchContent}>
                                        <Card className={` ${styles.categoryCards}`}>
                                            {/* <Avatar src={defaultCodeImg} className={styles.catecoryIcon} size={60} /> */}
                                            <h3>{category.title}</h3>
                                            <p>{category.description}</p>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>

                            <Row gutter={16} className={styles.categoryColWrapper}>
                                <Col xs={24} xl={12} lg={12} md={12}>
                                    <Image
                                        width={screens.lg ? 500 : screens.md ? 350 : screens.sm ? 500 : '100%'}
                                        height={screens.lg ? 500 : screens.md ? 500 : screens.sm ? 500 : '100%'}
                                        src={featureImg}
                                        preview={{
                                            src: featureImg,
                                        }}
                                        style={{
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Col>
                                <Col xs={24} xl={12} lg={12} md={12}>
                                    <h1>Our Features</h1>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
                                    <EmployeeList />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </Content>
    );
};

export default EmployeCategory;
