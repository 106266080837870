// import React, { useState } from 'react';
// import { Button, Modal, Typography } from 'antd';
// import { CheckCircleOutlined } from '@ant-design/icons';
// import './style.module.scss'; // Import your styles if needed.
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { setIsSavejobdone } from '../../features/Jobseeker/jobseekerSlice';

// const SuccessSaveJobPopup = ({ visible, onClose }) => {
//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const { currentSaveJobData } = useSelector((state) => state.global)
//     <LoadingOutlined />
//     return (
//         <Modal
//             open={visible}
//             footer={null}
//             closable={false}
//             centered
//             bodyStyle={{
//                 textAlign: 'center',
//                 padding: '24px',
//             }}
//         >
//             <div className="success-popup">
//                 <CheckCircleOutlined
//                     style={{
//                         fontSize: '64px',
//                         color: '#52c41a',
//                         animation: 'scale-up 0.3s ease-in-out',
//                     }}
//                 />
//                 <Typography.Title level={3} >Job saved</Typography.Title>
//                 <Typography.Title level={4} italic>
//                     {currentSaveJobData?.jobTitle}
//                 </Typography.Title>
//                 <div>
//                     <Button
//                         type='link'
//                         variant='link'
//                         onClick={() => {
//                             dispatch(setIsSavejobdone(false))
//                             navigate('/find-job/save-jobs')
//                         }}
//                         className='green-text'
//                     >
//                         Go to saved jobs
//                     </Button>
//                 </div>
//             </div>
//         </Modal>
//     );
// };

// export default SuccessSaveJobPopup;
import React, { useState, useEffect } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './style.module.scss'; // Import your styles if needed.
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSavejobdone } from '../../features/Jobseeker/jobseekerSlice';

const SuccessSaveJobPopup = ({ visible, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentSaveJobData } = useSelector((state) => state.global);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (visible) {
            // Start with loading for 5 seconds
            setIsLoading(true);
            const timer = setTimeout(() => {
                setIsLoading(false);
            }, 2000);

            // Cleanup timer on unmount or if visible changes
            return () => clearTimeout(timer);
        }
    }, [visible]);

    const handleClose=()=>{
        dispatch(setIsSavejobdone(false));
    }

    return (
        <Modal
            open={visible}
            footer={null}
            closable={true}
            centered
            onCancel={handleClose}
            onClose={handleClose}
            width={300}
            title={ isLoading ? 'Saving Job...' : 'Job saved'}
        >
            <div className="success-popup text-center">
                {isLoading ? (
                    <Spin indicator={<LoadingOutlined className='green-text' spin />} size="large" />
                ) : (
                    <CheckCircleOutlined
                        className={styles.checkCirclestyle}
                    />
                )}
                {!isLoading && (
                    <Typography.Title level={4} italic>
                        {currentSaveJobData?.jobTitle}
                    </Typography.Title>
                )}
                {!isLoading && (
                    <div>
                        <Button
                            type="link"
                            variant="link"
                            onClick={() => {
                                dispatch(setIsSavejobdone(false));
                                navigate('/find-job/save-jobs');
                            }}
                            className="green-text"
                        >
                            Go to saved jobs
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default SuccessSaveJobPopup;
