import React, { useEffect, useState } from "react";
import { Modal, Input, Slider, Button, Tabs, Form, Select, Col, Row, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { create_job } from "../../../features/Organization/Jobs/jobSlice";
import { ArrowRightOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import { setCurrentBoostJobData } from "../../../features/Global/globalSlice";

const CreateBoostJobDetails = ({ onNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [form] = Form.useForm();
  const [salaryRange, setSalaryRange] = useState([50000, 100000]);
  const [totalExperienceRange, settotalExperienceRange] = useState([1, 5]);
  const [rolesAndResponsibilities, setRolesAndResponsibilities] = useState([""]);
  const [certifications, setCertifications] = useState([""]);
  const [colleges, setColleges] = useState([""]);
  const [customSkills, setCustomSkills] = useState([]);
  const { employeeSkills } = useSelector((state) => state.userInfo);
  const [fields, setFields] = useState([{ id: Date.now(), skillName: "", experience: "" }]);
  const [skills, setSkills] = useState({});
  const { currentBoostJobData } = useSelector((state) => state.global)
  const [formErrors, setFormErrors] = useState([])

  const addField = () => {
    setFields([...fields, { id: Date.now(), skillName: "", experience: "" }]);
  };

  const handleInputChange = (id, name, value) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, [name]: value } : field
      )
    );
  };

  const removeField = (index) => {
    setFields((prevFields) =>
      prevFields.filter((_, idx) => idx !== index) // Remove the field at the given index
    );
  };

  const handleAddField = (setter, array) => setter([...array, ""]);
  const handleRemoveField = (setter, array, index) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    setter(newArray);
  };

  const handleFieldChange = (setter, array, index, value) => {
    const newArray = [...array];
    newArray[index] = value;
    setter(newArray);
  };

  const handleAddSkill = (value) => {
    if (value && !customSkills.includes(value)) {
      setCustomSkills([...customSkills, value]);
    }
  };

  const onCreateJob = async () => {
    try {
      const individualSkillsExperienceLimit = fields.reduce((acc, field) => {
        acc[field.skillName] = field.experience;
        return acc;
      }, {});
      let values = form.getFieldsValue()
      let errors = await form.validateFields()
      console.log("errors", errors)
      const payload = {
        jobTitle: values.jobTitle,
        description: values.description,
        totalExperienceRange: {
          min: values.minExperience,
          max: values.maxExperience,
        },
        location: values.location,
        noticePeriod: values.noticePeriod,
        technicalSkills: values.technicalSkills,
        educationalQualification: values.educationalQualification,
        individualSkillsExperienceLimit: individualSkillsExperienceLimit,
        salary: { min: salaryRange[0], max: salaryRange[1] },
        totalExperienceRange: { min: totalExperienceRange[0], max: totalExperienceRange[1] },
        jobType: values.jobType,
        collegeRestriction: colleges.filter((college) => college.trim()),
        rolesAndResponsiblities: rolesAndResponsibilities.filter((role) => role.trim()),
        certificationsRequired: certifications.filter((cert) => cert.trim()),
        isBoosted: true,
      };
      // console.log("payload", payload)
      // dispatch(create_job({ body: payload, navigate: navigate, onClose: onNext }));
      dispatch(setCurrentBoostJobData(payload))
      onNext()
      // message.success("Click on next to proceess the next step")
    } catch (error) {
      console.error("Validation Error:", error);

      // Extract and store errors in the state
      const errorFields = error.errorFields || [];
      const errorMessages = errorFields.map((field) => ({
        name: field.name.join("."),
        errors: field.errors,
      }));
      setFormErrors(errorMessages);

      message.error("Please fix the errors in the form before proceeding.");
    }
  };
  console.log("formersos", formErrors)
  const handleNext = async () => {
    try {
      await form.validateFields(); // Validate fields in the current tab
      setActiveTab((prev) => (parseInt(prev) + 1).toString());
    } catch (error) {
    }
  };

  const handlePrevious = () => {
    setActiveTab((prev) => (parseInt(prev) - 1).toString());
  };

  useEffect(() => {
    const skillsArray = employeeSkills?.data?.map((skill) => skill?.name) || [];
    setCustomSkills(skillsArray);
  }, [employeeSkills]);

  return (
    <Form layout="vertical" form={form} onFinish={onCreateJob}>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={8} md={8} >
          <Form.Item
            label="Job Title"
            name="jobTitle"
            rules={[{ required: true, message: "Job title is required!" }]}
          >
            <Input placeholder="Job title" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8} md={8} >
          <Form.Item label="Job Type" name="jobType" rules={[{ required: true, message: "Job type is required!" }]}>
            <Select placeholder="Select job type">
              <Option value="Full-time">Full-time</Option>
              <Option value="Part-time">Part-time</Option>
              <Option value="Internship">Internship</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8} md={8} >
          <Form.Item label="Notice Period" name="noticePeriod">
            <Input placeholder="Notice Period" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} md={12} >
          <Form.Item
            label="Location"
            name="location"
            rules={[{ required: true, message: "Location is required!" }]}
          >
            <Input placeholder="Location" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12} md={12}>
          <Form.Item label="Salary Range">
            <Row gutter={16}>
              <Col xs={24} lg={12} md={12}>
                <Form.Item
                  name="minSalary"
                  rules={[{ required: true, message: "Minimum salary is required!" }]}
                >
                  <Input
                    placeholder="Min Salary"
                    type="number"
                    value={salaryRange[0]}
                    onChange={(e) => setSalaryRange([+e.target.value, salaryRange[1]])}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} md={12}>
                <Form.Item
                  name="maxSalary"
                  rules={[{ required: true, message: "Maximum salary is required!" }]}
                >
                  <Input
                    placeholder="Max Salary"
                    type="number"
                    value={salaryRange[1]}
                    onChange={(e) => setSalaryRange([salaryRange[0], +e.target.value])}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} md={12} >
          <Form.Item
            label="Education Qualification"
            name="educationalQualification"
            rules={[{ required: true, message: "Educational Qualification title is required!" }]}
          >
            <Input placeholder="Bachelor's degree in Computer Science or related field" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12} md={12} >
          <Form.Item label="Experience Range">
            <Row gutter={16}>
              <Col xs={24} lg={12} md={12}>
                <Form.Item
                  name="minExperience"
                  rules={[{ required: true, message: "Minimum experience is required!" }]}
                >
                  <Input
                    placeholder="Min Experience"
                    type="number"
                    value={totalExperienceRange[0]}
                    onChange={(e) =>
                      settotalExperienceRange([+e.target.value, totalExperienceRange[1]])
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} md={12}>
                <Form.Item
                  name="maxExperience"
                  rules={[{ required: true, message: "Maximum experience is required!" }]}
                >
                  <Input
                    placeholder="Max Experience"
                    type="number"
                    value={totalExperienceRange[1]}
                    onChange={(e) =>
                      settotalExperienceRange([totalExperienceRange[0], +e.target.value])
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} md={12} >
          <div>{fields.map((field, index) => (
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={10} md={10} >
                <Form.Item label={index === 0 ? "Skill Name" : ""}>
                  <Input
                    placeholder="Enter skill name"
                    value={field.skillName}
                    onChange={(e) =>
                      handleInputChange(field.id, "skillName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={10} md={10} >
                <Form.Item label={index === 0 ? "Experience (years)" : ""}>
                  <Input
                    placeholder="Enter experience required"
                    type="number"
                    min={0}
                    value={field.experience}
                    onChange={(e) =>
                      handleInputChange(field.id, "experience", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={2} md={2} >
                <Button className={index === 0 && 'margin-top-30'} danger onClick={() => removeField(index)}><DeleteOutlined /></Button>
              </Col>
            </Row>
            // </div>
          ))}</div>
        </Col>
        <Col xs={24} lg={12} md={12} >
          <div><Form.Item>
            <Button icon={<PlusOutlined />} className="margin-top-30 multiAddBtn" type="dashed" onClick={addField} block>
              Add New Skill
            </Button>
          </Form.Item></div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} md={12} >
          <Form.Item label="Technical Skills" name="technicalSkills" rules={[{ required: true, message: "Please select at least one language" }]}>
            <Select
              mode="multiple"
              placeholder="Select or add skills"
              onDeselect={(value) => setCustomSkills(customSkills.filter((skill) => skill !== value))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div style={{ display: "flex", padding: 8 }}>
                    <Input
                      style={{ flex: "auto" }}
                      placeholder="Add skill"
                      onPressEnter={(e) => handleAddSkill(e.target.value)}
                    />
                  </div>
                </>
              )}
            >
              {customSkills.map((skill) => (
                <Select.Option key={skill} value={skill}>
                  {skill}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12} md={12} >
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Description is required!" }]}
          >
            <Input.TextArea placeholder="Job description" rows={1} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} md={12} >
          <Form name="rolesAndResponsibilitiesForm" form={form}>
            {rolesAndResponsibilities.map((role, index) => (
              <Form.Item
                key={index}
                name={`role_${index}`} // Unique name for each input
                rules={[{ required: true, message: `Role and Responsibility ${index + 1} is required.` }]}
                style={{ marginBottom: "10px", minHeight: "40px", }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Input
                    style={{ flex: 1 }}
                    placeholder={`Add Role and Responsibility ${index + 1}`}
                    value={role}
                    onChange={(e) =>
                      handleFieldChange(setRolesAndResponsibilities, rolesAndResponsibilities, index, e.target.value)
                    }
                  />
                  {rolesAndResponsibilities.length > 1 && (
                    <Button
                      danger
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() =>
                        handleRemoveField(setRolesAndResponsibilities, rolesAndResponsibilities, index)
                      }
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </Form.Item>
            ))}
          </Form>
        </Col>
        <Col xs={24} lg={12} md={12} >
          <Button
            type="dashed"
            className="multiAddBtn fullwidth"
            onClick={() => handleAddField(setRolesAndResponsibilities, rolesAndResponsibilities)}
          >
            Add Role and Responsibility
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} md={12} >
          {certifications.map((cert, index) => (
            <div key={index} style={{ display: "flex", gap: "8px", alignItems: "center", marginBottom: "8px" }}>
              <Input
                placeholder={`Add Certification ${index + 1}`}
                value={cert}
                onChange={(e) => handleFieldChange(setCertifications, certifications, index, e.target.value)}
              />
              {certifications.length > 1 && (
                <Button danger onClick={() => handleRemoveField(setCertifications, certifications, index)}>
                  Remove
                </Button>
              )}
            </div>
          ))}
        </Col>
        <Col xs={24} lg={12} md={12} >
          <Button className="multiAddBtn fullwidth" type="dashed" onClick={() => handleAddField(setCertifications, certifications)}>
            Add Certification
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="margin-top-10">
        <Col xs={24} lg={12} md={12} >
          {colleges.map((college, index) => (
            <div key={index} style={{ display: "flex", gap: "8px", alignItems: "center", marginBottom: "8px" }}>
              <Input
                placeholder={`Add College Restriction${index + 1}`}
                value={college}
                onChange={(e) => handleFieldChange(setColleges, colleges, index, e.target.value)}
              />
              {colleges.length > 1 && (
                <Button danger onClick={() => handleRemoveField(setColleges, colleges, index)}>
                  Remove
                </Button>
              )}
            </div>
          ))}
        </Col>
        <Col xs={24} lg={12} md={12}>
          <Button className="multiAddBtn fullwidth" type="dashed" onClick={() => handleAddField(setColleges, colleges)}>
            Add College Restriction
          </Button>
        </Col>
      </Row>

      <Row justify="end" style={{ marginTop: '20px' }}>
        <Col xs={24} md={6} lg={6}>
          <Button iconPosition="end" icon={<ArrowRightOutlined />} className='fullwidth default-button-style' onClick={onCreateJob} size="large" shape="round" type="primary" htmlType="submit">
            Submit & Next
          </Button>
        </Col>
      </Row>
    </Form >
  );
};
export default CreateBoostJobDetails;