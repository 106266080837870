import React, { useState, useEffect } from 'react';
import { Select, Checkbox, Card, Avatar, Tag, Button } from 'antd';
import { useSelector } from 'react-redux';
import Meta from 'antd/es/card/Meta';
import styles from './style.module.scss';
import { timeSincePosted } from '../../utils/helpers';
import { LockOutlined } from '@ant-design/icons';

const ApplicationStatus = () => {
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]); // State to store filtered jobs
    const { userProfile, currentJobStatus } = useSelector((state) => state.global);
    const { alljobs } = useSelector((state) => state.jobseeker);

    useEffect(() => {
        if (userProfile?.appliedJobs && alljobs) {
            const filtered = alljobs.filter((job) =>
                userProfile.appliedJobs.some((appliedJob) => appliedJob?.jobId === job?._id)
            );
            setFilteredJobs(filtered);
        }
    }, [userProfile, alljobs]);

    const handleStatusChange = (checkedValues) => {
        setSelectedStatuses(checkedValues);
    };

    const statusOptions = [
        { label: 'All', value: 'all' },
        { label: 'Applied', value: 'applied' },
        { label: 'Viewed', value: 'viewed' },
        { label: 'In Review', value: 'in_review' },
        { label: 'Interview Scheduled', value: 'interview_scheduled' },
        { label: 'Offer Extended', value: 'offer_extended' },
        { label: 'Rejected', value: 'rejected' },
    ];

    console.log("currentJobStatus", currentJobStatus)


    return (
        <div>
            {/* <h3>Status</h3>
            <Select
                mode="multiple"
                value={selectedStatuses}
                onChange={handleStatusChange}
                placeholder="Select Status"
                style={{ width: '100%' }}
                dropdownRender={() => (
                    <Checkbox.Group
                        options={statusOptions}
                        value={selectedStatuses}
                        onChange={handleStatusChange}
                    />
                )}
            /> */}
            <div className='scrollable-div'>
                <Card className={styles.topCardWrapperStatus} title="Filter" extra={<Button danger type='text'>Clear</Button>}>
                    {filteredJobs.map((job) => (
                        <Card key={job.id} className={styles.appStsCardWrapper}>
                            <Meta
                                avatar={<Avatar className={styles.jobTitles}><Avatar className='default-button-style' size={'small'} icon={<LockOutlined />} /></Avatar>}
                                title={<div>{job?.jobTitle || "NA"}</div>}
                                description={job.description || "No description available"}
                            />
                            <div className={styles.appStsCard}>
                                <Button className={`font-size-12 ${styles.actionBtn}`} shape='round'>
                                    Applied
                                </Button>
                                {/* <div className='font-size-12'>Active {job.activePeriod || "N/A"}</div> */}
                            </div>
                        </Card>
                    ))}
                </Card>
            </div>
        </div>
    );
};

export default ApplicationStatus;
