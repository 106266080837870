import React, { useState, useEffect, useMemo } from "react";
import { Avatar, Button, Grid, Image, Menu, Space, theme } from "antd";
import { MenuOutlined, DownOutlined } from "@ant-design/icons";
import headerStyle from './style.module.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoginPage, setLoggedInUser } from "../../features/Auth/authSlice";
import logoImg from '../../assets/Images/EpLogo.jpeg';
import { getAuthToken, getCurrentUser } from "../../utils/authHelpers";
import AppSecureStorage from "../../services/storage/secureStorage";
import ProfileCard from "../ProfileCard.js/ProfileCard";
import GlobalSearch from "../CommonElements/GlobalSearch";

import activeUserInfoIcon from '../../assets/Images/active-user.svg';
import inactivebriefcaseIcon from '../../assets/Images/inactive-briefcase.svg';
import activebriefcaseIcon from '../../assets/Images/active-recommended.svg';

import inactivebookmarkIcon from '../../assets/Images/inactive-bookmark.svg';
import activebookmarkIcon from '../../assets/Images/bookmarkActive.svg';

import inactiveblogIcon from '../../assets/Images/blogsicon.svg';
import activeblogIcon from '../../assets/Images/active-blogs.svg';

import inactiveReffPeople from '../../assets/Images/reffrence-people.svg';
import activeReffPeople from '../../assets/Images/active-reff-people.svg';

import inactiveSetting from '../../assets/Images/inactive-setting.svg';
import activeSetting from '../../assets/Images/active-setting.svg';

import dashboardIcon from '../../assets/Images/inactive-dashboard.svg';
import activedashboardIcon from '../../assets/Images/active-dashboard.svg';
import inactiveUserInfoIcon from '../../assets/Images/user-gear.svg';

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LineChartOutlined,
    AliwangwangOutlined,
    DeploymentUnitOutlined,
    UsergroupAddOutlined,
    ApartmentOutlined,
    FileTextOutlined,
    ProfileFilled,
    MoneyCollectOutlined,
    UploadOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const storage = new AppSecureStorage()

const Header = () => {
    const { token } = useToken();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const screens = useBreakpoint();
    let currentUser = getCurrentUser()
    const loggedInToken = getAuthToken();
    const { user } = useSelector((state) => state.auth);
    const { userProfile } = useSelector((state) => state.global)
    const [activeKey, setActiveKey] = useState('1');
    const location = useLocation(); // Get the current location

    const [current, setCurrent] = useState(location.pathname); // Set initial state based on URL path

    const styles = {
        container: {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            margin: "0 auto",
            maxWidth: token.screenXLMax,
            padding: screens.md || screens.lg ? `0px ${token.paddingLG}px` : `0px ${token.padding}px`
        },
        logo: {
            display: "block",
            height: token.sizeLG,
            left: screens.xs ? '29%' : "15%",
            position: screens.md || screens.lg ? "static" : "absolute",
            top: "50%",
            transform: screens.md || screens.lg ? " " : "translate(-50%, -50%)"
        },
        menu: {
            backgroundColor: "transparent",
            borderBottom: "none",
            lineHeight: screens.sm ? "4rem" : "3.5rem",
            marginLeft: screens.md || screens.lg ? "0px" : `-${token.size}px`,
            width: screens.md || screens.lg ? "inherit" : token.sizeXXL,
            fontWeight: 500
        },
        afterLoginMenu: {
            backgroundColor: "transparent",
            borderBottom: "none",
            // lineHeight: screens.sm ? "4rem" : "3.5rem",
            marginLeft: screens.md || screens.lg ? "0px" : `-${token.size}px`,
            width: screens.md || screens.lg ? "inherit" : token.sizeXXL,
            fontWeight: 500
        },
        menuContainer: {
            alignItems: "center",
            display: "flex",
            gap: token.size,
            width: "70%"
        }
    };

    const goToLoginPage = () => {
        navigate('/login');
    };

    const goToSignUpPage = () => {
        dispatch(setIsLoginPage(false));
        navigate('/sign-up');
    };

    const menuItems = [
        {
            label: "Home",
            key: "/",
        },
        {
            label: !screens.md || !screens.lg ? <span onClick={() => navigate('/find-job')}> Find Job </span> :
                (
                    <span onClick={() => { navigate('/find-job/application-status') }}>
                        Find Job
                         {/* <Avatar size={"small"} className={headerStyle.downHeaderIcon} icon={<DownOutlined />} /> */}
                    </span>
                ),
            key: loggedInToken ? '/find-job/application-status' : "/find-job",
            // children: [
            //     {
            //         label: "Application status",
            //         key: "/find-job/application-status", // This key will be used to navigate
            //     },
            //     {
            //         label: "user Info",
            //         key: "/find-job/user-info",
            //     },
            //     {
            //         label: "Recommended Jobs",
            //         key: "/find-job/recommended-jobs",
            //     },
            //     {
            //         label: "Save blogs",
            //         key: "/find-job/save-jobs",
            //     },
            //     {
            //         label: "Blogs",
            //         key: "/find-job/blogs",
            //     },
            //     {
            //         label: "Reference People",
            //         key: "/find-job/reference-people",
            //     },
            //     {
            //         label: "Setting",
            //         key: "/find-job/setting",
            //     },
            // ],
        },
        // {
        //     label: !screens.md || !screens.lg ? <span>Company</span> :
        //         (
        //             <span>
        //                 Company <Avatar size={"small"} className={headerStyle.downHeaderIcon} icon={<DownOutlined />} />
        //             </span>
        //         ),
        //     key: "company",
        //     children: [
        //         {
        //             label: "Company 1",
        //             key: "/company/company1",
        //         },
        //         {
        //             label: "Company 2",
        //             key: "/company/company2",
        //         },
        //     ],
        // },
        // {
        //     label: !screens.md || !screens.lg ? <span>Services</span> :
        //         (
        //             <span>
        //                 Services <Avatar size={"small"} className={headerStyle.downHeaderIcon} icon={<DownOutlined />} />
        //             </span>
        //         ),
        //     key: "services",
        //     children: [
        //         {
        //             label: "Service 1",
        //             key: "/services/service1",
        //         },
        //         {
        //             label: "Service 2",
        //             key: "/services/service2",
        //         },
        //     ],
        // },
        // {
        //     label: "Contact Us",
        //     key: "/contact-us",
        // },
    ];

    const OrgmenuItems = useMemo(() => {
        return [
            {
                label: 'Dashboard',
                path: '/dashboard',
                key: 'dashboard',
                goToPage: () => navigate('/dashboard'),
                img: activeKey === '1' ? activedashboardIcon : dashboardIcon,
            },
            {
                key: 'organization-info',
                label: 'Organization Info',
                goToPage: () => navigate('/organization-info'),
                path: '/organization-info',
                img: activeKey === '2' ? activeUserInfoIcon : inactiveUserInfoIcon,
            },
            {
                key: 'job-status',
                label: 'Job Status',
                goToPage: () => navigate('/job-status'),
                path: '/job-status',
                img: activeKey === '3' ? activebriefcaseIcon : inactivebriefcaseIcon,
            },
            {
                key: 'recommend-candidate',
                label: 'Recommend Candidate',
                goToPage: () => navigate('/recommend-candidate'),
                path: '/recommend-candidate',
                img: activeKey === '4' ? activebookmarkIcon : inactivebookmarkIcon,
            },
            {
                key: 'analytics',
                label: 'Analytics',
                goToPage: () => navigate('/analytics'),
                path: '/analytics',
                img: activeKey === '5' ? activeblogIcon : inactiveblogIcon,
            },
            {
                key: 'organization-settings',
                label: 'Setting',
                goToPage: () => navigate('/organization-settings'),
                path: '/organization-settings',
                img: activeKey === '7' ? activeSetting : inactiveSetting,
            },
        ].filter(Boolean);
    }, [activeKey]);

    let headerMenus = currentUser === "organization" ? OrgmenuItems : menuItems

    // Manually handle parent clicks (e.g., for Find Job)
    const handleParentClick = (parentKey) => {
        if (parentKey === "findJob") {
            navigate('/find-job'); // Navigate to the parent route directly
        }
    };

    const handleLogout = () => {
        dispatch(setLoggedInUser(null))
        storage.clearStorage();
        navigate('/');
    };

    const getSelectedKey = () => {
        const findActiveKey = (items) => {
            for (let item of items) {
                if (item.path === location?.pathname) {
                    return item.key;
                }
                if (item.children) {
                    const childKey = findActiveKey(item.children);
                    if (childKey) return childKey;
                }
            }
            return null;
        };

        // If the location is '/searched-jobs', do not return a selected key
        if (location?.pathname === "/searched-jobs") {
            return null; // Or handle it with a special case as needed
        }

        // Default to '1' if no active key is found
        return findActiveKey(headerMenus) || "1";
    };

    // Update the active menu item when the route changes
    useEffect(() => {
        setCurrent(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        const selectedKey = getSelectedKey();
        setActiveKey(selectedKey);
    }, [location]);

    const onClick = (e) => {
        setCurrent(e.key); // Set the current active menu item
        navigate(e.key); // Navigate to the corresponding route
    };

    // Other logic remains the same

    return (
        <nav className={loggedInToken ? headerStyle.headerLog : headerStyle.header}>
            <div style={styles.container}>
                <div style={styles.menuContainer}>
                    {screens.xs ? null :
                        <div style={styles.logo}>
                            <div onClick={() => navigate('/')} className={`flex-center add-cursor `}>
                                {/* <div className={headerStyle.logoWrapperdiv}><Image height={30} width={30} preview={false} className={headerStyle.logoIcon} shape='square' src={logoImg} /></div> */}
                                <Avatar className={headerStyle.logoIcon} shape="square" size={40} src={<Image height={40} width={40} src={logoImg} alt="avatar" />} />
                                {screens.xs ? <div className={headerStyle.logoHeading}>Ep...</div> : <div className={headerStyle.logoHeading}>Eployr</div>}
                            </div>
                        </div>
                    }

                    {
                        // !loggedInToken &&
                        (!loggedInToken || (loggedInToken && window.location.pathname === '/')) &&
                        <Menu
                            style={!loggedInToken ? styles.menu : styles.afterLoginMenu}
                            mode="horizontal"
                            // className={`flex-center ${styles.menuHeader}`} //for more option
                            className={`flex-end ${styles.menuHeader}`}
                            items={headerMenus}
                            onClick={onClick}
                            selectedKeys={[current]} // Always use the `current` state to determine the active menu item
                            overflowedIndicator={
                                <Button type="text" icon={<MenuOutlined />}></Button>
                            }
                        />
                    }
                </div>
                <Space>
                {currentUser === "organization" ? null :  <GlobalSearch />}
                    {loggedInToken ? (
                        // <Button onClick={handleLogout} className="default-button-style">
                        //     Log Out
                        // </Button>
                        <Button size="large">
                            <ProfileCard />
                        </Button>
                    ) : (
                        <>
                            <Button shape="round" size="large" className="active-border" onClick={goToLoginPage} type="text">
                                Log in
                            </Button>
                            <Button shape="round" size="large" className="default-button-style" onClick={goToSignUpPage} type="primary">
                                Sign up
                            </Button>
                        </>
                    )}
                </Space>
            </div>
        </nav>
    );
};
export default Header