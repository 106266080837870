import React from 'react'
import styles from './style.module.scss'
import { Avatar, Image } from 'antd'
import logoImg from '../../assets/Images/EpLogo.jpeg'
import { useNavigate } from 'react-router-dom'

const Logo = () => {
    const navigate = useNavigate();

    const gotohome=()=>{
        navigate('/')
        window.scrollTo(0, 0);
    }
    return (
        <div onClick={gotohome} className={`add-cursor ${styles.loginLogo}`}>
           <Avatar shape="square" size={40} src={<Image preview={false} height={40} width={40} src={logoImg} alt="avatar" />} />
            <div className={styles.logoHeading}>Eployr</div>
        </div>
    )
}

export default Logo