import { Tabs, Card, Button, Avatar } from 'antd';
import { FormOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const SettingTabs = ({ activeDetail, onChangeDetails, isProfileEdit, onSaveProfile, onEditClick }) => (
    <div className='flex-space-between'>
        <Tabs 
            activeKey={activeDetail} 
            onChange={onChangeDetails} 
            tabPosition="top" 
            type="line"
        >
            <TabPane tab="Account Information" key="account-information" />
            <TabPane tab="Profile Settings" key="profile-setting" />
            <TabPane tab="Notification Preferences" key="notification-pref" />
            <TabPane tab="Job Preferences" key="job-pref" />
            <TabPane tab="Privacy & Security" key="privacy-security" />
            <TabPane tab="Application Preferences" key="app-prefrences" />
            <TabPane tab="Subscription & Alerts" key="sub-and-alerts" />
            <TabPane tab="Language & Region" key="lang-and-region" />
            <TabPane tab="Account Deactivation or Deletion" key="deletion" />
        </Tabs>

        <Card className='border-none bg-transparent'>
            <div className='flex-wrap-row-direction-gap-5'>
                <Button size='middle' shape='round'>Upload Profile</Button>
                {isProfileEdit ? (
                    <Button onClick={onSaveProfile} className='default-button-style'>Save</Button>
                ) : (
                    <Avatar onClick={onEditClick} icon={<FormOutlined />} />
                )}
            </div>
        </Card>
    </div>
);

export default SettingTabs;
