import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import { Content } from 'antd/es/layout/layout'

import { Layout, Avatar, Row, Col, Button, Tag, Typography, Card, Grid, Form, Input } from 'antd';
import { ArrowDownOutlined, DeleteFilled, EditOutlined, FormOutlined, UserOutlined } from '@ant-design/icons';
// import UploadResume from './UploadResume';
// import PersonalDetails from './PersonalDetails';
// import EducationDetails from './EducationDetails';
// import ProfessionalDetails from './ProfessionalDetails';
// import SkillsSection from './SkillsSection';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentInformation } from '../../../features/Global/globalSlice';
import { setIsEditProfile, update_jobseeker } from '../../../features/UserInfo/userInfoSlice';
import { useNavigate } from 'react-router-dom';
import UploadOrgResume from './UploadOrgResume';
import PersonalDetails from '../../UserInfoPage/PersonalDetails';
import ProfileEditor from '../../../components/DialogBoxs/ProfileEditor';
import FindJobPageLayout from '../../FindJobPage/FindJobPageLayout';
import AboutCompany from './OrgSections/AboutCompany';
import OrgProfileEditor from './OrgProfileEdit';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid

const OrganizationInfo = () => {
    const screens = useBreakpoint()
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const { isProfileEdit, currentSettingType } = useSelector((state) => state.userInfo)
    const {userProfile} = useSelector((state)=>state.global)
    const initialData = {
        name: userProfile?.name,
        title: userProfile?.designation,
        experience: '3 years 11 months',
        location: userProfile?.currentAddress,
        email: userProfile?.email,
        phone: `${userProfile?.countryCode} - ${userProfile?.mobile}`,
        jobType: 'Full-time',
        skills: userProfile?.skills,
        visibility: 'visible'
    };
    const [activeDetail, setActiveDetail] = useState('about-company')
    const [isTopProfileEdit, setIsTopProfileEdit] = useState(false);

    const scrollContainerRef = useRef(null);
    let isDragging = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
        isDragging = true;
        startX = e.pageX - scrollContainerRef.current.offsetLeft;
        scrollLeft = scrollContainerRef.current.scrollLeft;
    };

    const handleMouseLeave = () => {
        isDragging = false;
    };

    const handleMouseUp = () => {
        isDragging = false;
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 1; // Adjust scroll speed if needed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const onChangeDetails = (status) => {
        setActiveDetail(status)
        dispatch(setCurrentInformation(status))
    }

    const getColorForTag = (tag) => {
        switch (tag) {
            case 'figma':
                return '#56CDAD';  // Light green
            case 'adobe':
                return '#FEB836';  // Light yellow
            case 'illustrate':
                return '#FAFAFA';  // Light purple
            case 'photoshop':
                return '#4640DE';
            case 'googled':
                return '#000';
            default:
                return '#E1E1E1';  // Default grey for other tags
        }
    };

    const showModal = () => {
        setIsTopProfileEdit(true);
        form.setFieldsValue(initialData); // Set initial form values
    };

    const onEditClick = () => {
        dispatch(setIsEditProfile(true))
    }
    const onCloseEditProfile=()=>{
        setIsTopProfileEdit(false)
    }
    const onSaveProfile = () => {
        dispatch(setIsEditProfile(false))
        dispatch(update_jobseeker({ body: userProfile, navigate: navigate }))
    }

    // useEffect(() => {
    //   dispatch(get_jobseeker(navigate))
    // }, [])
    return (
        <FindJobPageLayout>
            <div className={!screens.sm || !screens.md ? styles.userInfoDivSm : `topRow`}>
                <Content className={!screens.sm || !screens.md ? styles.userInfoWrapperSm : styles.userInfoWrapper}>
                    {/* Header Section */}
                    <Card className={styles.userInfoCrd}>
                        <div
                            style={{
                                // background: 'url(https://your-background-image-url)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                // padding: '40px 20px',
                                borderRadius: '8px 8px 0 0',
                                position: 'relative',
                            }}
                        >
                            {/* Profile Image */}
                            <header
                                // style={{
                                //     display: 'flex', alignItems: "center", justifyContent: 'center',
                                //     height: '100px', textAlign: 'center',
                                //     backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                                //     backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9MajxnCm2L8EpGSl-FFkwbPirPDiBVkL9zw&s)`,
                                //     color: 'white',
                                //     borderRadius: '10px',
                                //     fontSize: '24px',
                                //     fontWeight: 400
                                // }}
                                style={{
                                  display: 'flex',
                                  alignItems: "center",
                                  justifyContent: 'center',
                                  height: '100px',
                                  textAlign: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: '100% 100%, 30px 30px, 30px 30px, 30px 30px', // Adjust the size of circles
                                  backgroundBlendMode: 'overlay',
                                  background: `
                                    linear-gradient(45deg,  #0D3621 , #231F20 , #280201), /* Linear gradient with 3 colors */
                                    radial-gradient(circle at 10px 10px, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0, /* Small circles */
                                    radial-gradient(circle at 20px 20px, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0, /* Small circles */
                                    radial-gradient(circle at 30px 30px, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0 /* Small circles */
                                  `,
                                  color: 'white',
                                  borderRadius: '10px',
                                  fontSize: '24px',
                                  fontWeight: 400
                                }}
                            >
                                {userProfile?.name || ''}
                            </header>
                            <Avatar
                                size={100}
                                src={userProfile?.avatar}
                                icon={!userProfile?.avatar && <UserOutlined />}
                                style={{
                                    position: 'absolute',
                                    bottom: '-50px',
                                    left: '20px',
                                    border: '4px solid white',
                                }}
                            />
                        </div>

                        {/* Main Content */}
                        <Row justify="start" style={{ paddingTop: '60px', paddingBottom: '20px', padding: '20px', lineHeight: '30px' }}>
                            {/* Left Column */}
                            <Col xs={24} sm={12} md={13} lg={13}>
                                <Title level={3} style={{ marginBottom: '0' }}>
                                    {userProfile?.name || 'Company name : NA'}
                                </Title>
                                <br />
                                <Text>{userProfile?.address || "Address : NA"}</Text>
                                <br />
                                <Text>{userProfile?.email || "Company email : NA"}</Text>
                                <br />
                                <Text>{userProfile?.phone || "Phone : NA"}</Text>
                               
                            </Col>

                            {/* Right Column */}
                            <Col xs={24} sm={12} md={8} lg={8} className='mt40'>
                            <Text className='mt40' strong>Number of Employees : <Tag color='green-inverse' >{userProfile?.numberOfEmployees || 'NA'}</Tag></Text>
                            <br/>
                            {/* <Text className='mt40' strong>Company type : <Tag color='cyan-inverse' >{userProfile?.type || 'NA'}</Tag></Text> */}
                            {/* <br/> */}
                            <Text className='mt40' strong>Website URL : <Tag color='blue-inverse' >{userProfile?.website || 'NA'}</Tag></Text>
                            </Col>
                            {/* <Col xs={24} sm={12} md={8} lg={8}>
                                <Title level={5}>Industries</Title>
                                <div className='flex-wrap-row-direction-gap-5' style={{ marginBottom: '10px' }}>
                                    <Button style={{ backgroundColor: getColorForTag("figma"), color: 'black' }} size='middle' shape='round'>Automotive</Button>
                                    <Button style={{ backgroundColor: getColorForTag("adobe"), color: 'black' }} size='middle' shape='round'>Edutech</Button>
                                    <Button style={{ backgroundColor: getColorForTag("illustrate"), color: 'black' }} size='middle' shape='round' >Retail</Button>
                                    <Button style={{ backgroundColor: getColorForTag("photoshop"), color: 'white' }} size='middle' shape='round'>Phintech</Button>
                                    <Button style={{ backgroundColor: getColorForTag("googled"), color: 'white' }} size='middle' shape='round'>Design</Button>
                                    <Button style={{ backgroundColor:  getColorForTag("googled"), color: 'white' }} size='middle' shape='round' >Information Technology</Button>
                                </div>
                                <Title level={5}>Company Permit</Title>
                                <div className='flex-wrap-row-direction-gap-5'>
                                <Button  shape='round'  size="small">
                                    India
                                </Button>
                                <Button shape='round' size="small">Us</Button>
                                <Button shape='round' size="small">Canada</Button>
                                </div>
                                
                            </Col> */}

                            {/* Edit Profile Button */}
                            <Col xs={24} sm={24} md={2} lg={2} style={{ textAlign: 'right' }}>
                                <Button
                                    type="default"
                                    shape="round"
                                    icon={<EditOutlined />}
                                    size="large"
                                    style={{ position: 'relative', bottom: '10px' }}
                                    className='dark-bg'
                                    onClick={showModal}
                                >
                                    Edit Profile
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                   
                    <div className={styles.flexBox}>
                        <div className='scrollable-buttons-container'
                         ref={scrollContainerRef}
                         onMouseDown={handleMouseDown}
                         onMouseLeave={handleMouseLeave}
                         onMouseUp={handleMouseUp}
                         onMouseMove={handleMouseMove}
                        >
                            <div className={`${styles.rowFlex} ${styles.detailsBtn}`}>
                                <Button className={activeDetail === 'about-company' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('about-company')
                                }}>About company</Button>
                                <Button className={activeDetail === 'employees' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('employees')
                                }}>Employees</Button>
                                {/* <Button className={activeDetail === 'news-updates' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('news-updates')
                                }}>News & updates</Button> */}
                                <Button className={activeDetail === 'job-listing' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('job-listing')
                                }}>Job Listing</Button>
                                {/* <Button className={activeDetail === 'culture-benefits' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('culture-benefits')
                                }}>Culture & benefits</Button>
                                <Button className={activeDetail === 'company-media' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('company-media')
                                }}>Company Media</Button>
                                <Button className={activeDetail === 'employee-testimonials' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('employee-testimonials')
                                }}>Employee Testimonials</Button>
                                <Button className={activeDetail === 'social-media' && 'default-button-style'} onClick={() => {
                                    onChangeDetails('social-media')
                                }}>Social media links</Button> */}
                                
                            </div>
                        </div>
                    </div>
                     
                    {activeDetail === 'about-company' && <AboutCompany />}
                    {activeDetail === 'employees' && (
                      <Card title={"Employees Details"}>
                        <Typography.Text strong level={5}>Number Of Employees : <Tag color='gold-inverse' >{userProfile?.numberOfEmployees}</Tag> </Typography.Text>
                      </Card>
                    )}
                </Content>
            </div>
            <OrgProfileEditor isTopProfileEdit={isTopProfileEdit} onClose={()=>onCloseEditProfile()} setIsTopProfileEdit={setIsEditProfile}  />
        </FindJobPageLayout>
    )
}

export default OrganizationInfo