// AppContainer.js
import React, { useEffect } from "react";
import { Layout } from "antd";
import { Routes, Route, useNavigate } from "react-router-dom";
import routes from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { get_jobseeker, get_jobseeker_skills } from "../../features/UserInfo/userInfoSlice";
import { get_organization } from "../../features/Organization/OrgAuth/OrgAuthSlice";
import { getAuthToken, getCurrentUser } from "../../utils/authHelpers";
import { setUserType } from "../../features/Global/globalSlice";
import AppSecureStorage from "../../services/storage/secureStorage";
// import "./container.scss";
const { Content } = Layout;

const AppContainer = ({ showSideNav }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const storage = new AppSecureStorage()
  const token = getAuthToken()
  let currentUser = getCurrentUser()


  useEffect(() => {
    if (token) {
      if (currentUser === 'organization') {
        dispatch(setUserType('organization'))
        dispatch(get_organization(navigate))
        dispatch(get_jobseeker_skills(navigate))
      }
      if (currentUser === 'employee') {
        dispatch(setUserType('employee'))
        dispatch(get_jobseeker(navigate))
        dispatch(get_jobseeker_skills(navigate))
      }
    }
  }, [])

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: showSideNav && "24px 16px",
        flex: showSideNav && 1,
      }}
    >
      <Routes>
        {routes.routes.map((list, index) => (
          <Route
            key={index}
            path={list.path}
            element={
              <Layout>
                {list.element}
              </Layout>
            }
          />
        ))}
      </Routes>
    </Content>
  );
};

export default AppContainer;
