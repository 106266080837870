import { Avatar, Button, Card, Col, Image, Input, Row, Select } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React from 'react'
import styles from './style.module.scss'
import { SearchOutlined, SwapRightOutlined, UserOutlined } from '@ant-design/icons'
import path1img from '../../assets/Images/pathDownIcon.svg'
import path2img from '../../assets/Images/pathTopIcon.svg'
import personAdd from '../../assets/Images/personAdd.svg'
import brifrCaseIcon from '../../assets/Images/briefcase.svg'
import brifcaseSuccessIcon from '../../assets/Images/briefcaseSuccess.svg'
import SearchBar from '../../components/CommonElements/SearchBar'
import { getAuthToken } from '../../utils/authHelpers'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get_and_search_jobs } from '../../features/Jobseeker/jobseekerSlice'
import { setSearchParameters } from '../../features/Global/globalSlice'


const EmployeeSection = () => {
    const token = getAuthToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { onSearchValues } = useSelector((state) => state.global);
    console.log("onSearchValues", onSearchValues);

    const fetchedCategories = [
        { id: 1, title: 'Web Developer', description: 'Build and maintain websites and web applications.', type: 'Technology' },
        { id: 2, title: 'Product Manager', description: 'Oversee product development and ensure success.', type: 'Management' },
        { id: 3, title: 'Design Market', description: 'Explore opportunities in the design industry.', type: 'Design Market' },
        { id: 4, title: 'Software Engineer', description: 'Develop, test, and maintain software systems.', type: 'Engineer' },
        { id: 5, title: 'UI/UX Designer', description: 'Create user-friendly and visually appealing designs.', type: 'Design Market' },
        { id: 6, title: 'Frontend Developer', description: 'Specialize in building user-facing interfaces.', type: 'Technology' },
        { id: 7, title: 'Backend Developer', description: 'Work on server-side logic and database management.', type: 'Technology' },
        { id: 8, title: 'Full Stack Developer', description: 'Handle both frontend and backend development tasks.', type: 'Technology' },
    ];
    const onSearch = () => {
        // const navigationPage = token ? '/searched-jobs' : '/find-job';
        // navigate(navigationPage);
        const filteredValues = Object.fromEntries(
            Object.entries(onSearchValues).filter(([_, value]) => value && value?.trim() !== "")
        );
        const searchParams = new URLSearchParams(filteredValues).toString();
        console.log('searchParams:', searchParams);
        if (Object.keys(filteredValues).length > 0) {
            const navigationPage = token ? '/searched-jobs' : '/find-job';
            dispatch(setSearchParameters(searchParams));
            dispatch(get_and_search_jobs({ navigate, searchParams }));
            navigate(navigationPage);
        } else {
            console.warn("No valid search parameters provided.");
        }
    }

    const onSearchByCategory = (categoryTitle) => {
        const searchParams = `searchString=${categoryTitle}`;
        const navigationPage = token ? '/searched-jobs' : '/find-job';
        dispatch(setSearchParameters(searchParams));
        dispatch(get_and_search_jobs({ navigate, searchParams }));
        navigate(navigationPage);
    };

    return (
        <Content className={styles.employeWrapper}>
            <Row justify={'center'}>
                <Col xs={24} xl={20} lg={20}>
                    <Card className={styles.searchCard}>
                        <SearchBar
                            showYearsSelect={false}
                            jobPlaceholder="Search job by name"
                            jobName='searchString'
                            locationPlaceholder="Search job by location"
                            locationName="location"
                            yearsPlaceholder="5 years"
                            locationOptions={[
                                { value: '1', label: 'Not Identified' },
                                { value: '2', label: 'Closed' },
                                { value: '3', label: 'Communicated' },
                            ]}
                            yearsOptions={[
                                { value: '1', label: '1-2 years' },
                                { value: '2', label: '3-5 years' },
                                { value: '3', label: '6+ years' },
                            ]}
                            onSearch={onSearch}
                            searchBtnText="Search"
                        />

                    </Card>

                    <div className={styles.employeContent}>
                        <div className='flex-wrap-row-direction-gap-5'>
                            <div className='text-white font-sz-18 .font-w-700'> Popular : </div>
                            {fetchedCategories?.map((ele, index) => {
                                return (
                                    <>
                                        <Button onClick={()=>onSearchByCategory(ele.title)} key={index} shape='round' size='small'>{ele?.title}</Button>
                                    </>
                                )
                            })}
                        </div>

                        <div className='text-center'>
                            <div className={styles.empContentHeading}>How employe work</div>
                            <Row justify={'center'}>
                                <Col xs={24} lg={14} md={14}>
                                    <p className={`text-white ${styles.empDescp}`}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={24} xl={18} lg={18}>
                    <Row className={styles.searchJobRow}>
                        <Col xs={24} xl={6} lg={6} md={6} className={styles.searchContent}>
                            <Card className={`text-center text-white ${styles.workFlow}`}>
                                <Avatar size={70} src={personAdd} className={styles.blank_circle} />
                                <h3>Create Account</h3>
                                <p className='text-white'>Lorem Ipsum is simply dummy text of the printing . </p>
                            </Card>
                        </Col>
                        <Col xs={24} xl={3} lg={3} md={3} className={styles.searchContent}>
                            <div className={`text-center text-white `}>
                                <Image preview={false} className='text-center' shape='square' size={400} src={path1img} />
                            </div>
                            {/* <Image className={styles.arrowImage} src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_-4r3ZFRMpv45N1TSTkexLy_bYHNE1BraFw&s'} /> */}

                        </Col>
                        <Col xs={24} xl={6} lg={6} md={6} className={styles.searchContent}>
                            <Card className={`text-center text-white ${styles.workFlow}`}>
                                <Avatar size={70} src={brifrCaseIcon} className={styles.blank_circle} />
                                <h3>Create Account</h3>
                                <p className='text-white'>Lorem Ipsum is simply dummy text of the printing. </p>
                            </Card>
                        </Col>
                        <Col xs={24} xl={3} lg={3} md={3} className={styles.searchContent}>
                            <div className={`text-center text-white`}>
                                <Image preview={false} shape='square' size={200} src={path2img} />
                            </div>
                            {/* <Image className={styles.arrowImage} src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_-4r3ZFRMpv45N1TSTkexLy_bYHNE1BraFw&s'} /> */}

                        </Col>
                        <Col xs={24} xl={6} lg={6} md={6} className={styles.searchContent}>
                            <Card className={`text-center text-white ${styles.workFlow}`}>
                                <Avatar size={70} src={brifcaseSuccessIcon} className={styles.blank_circle} />
                                <h3>Create Account</h3>
                                <p className='text-white'>Lorem Ipsum is simply dummy text of the printing . </p>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Content>
    )
}

export default EmployeeSection