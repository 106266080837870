// import { Button, Form, message } from 'antd';
// import React, { useEffect, useState } from 'react'
// import PhoneInput from 'react-phone-input-2';
// import { useDispatch, useSelector } from 'react-redux';
// import { genrate_login_otp_user_Info, setverifyType } from '../../features/UserInfo/userInfoSlice';
// import { useNavigate } from 'react-router-dom';

// const PhoneNumberInput = ({ setCountdown, setIsResendDisabled, phoneNumber, setPhoneNumber }) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { userProfile } = useSelector((state) => state.global);
//     // const [phoneNumber, setPhoneNumber] = useState('');
//     const { verifyType } = useSelector((state) => state.userInfo);

//     const [form] = Form.useForm();

//     useEffect(() => {
//         if (userProfile?.countryCode && userProfile?.mobile) {
//             const mobileNumber = `${userProfile.countryCode}${userProfile.mobile}`;
//             setPhoneNumber(mobileNumber); // Ensure this is a string
//         }
//     }, [userProfile]);

//     console.log("phonechat", phoneNumber)

//     return (
//         <div>
//             <div className="phone-input-wrapper">
//                 <PhoneInput
//                     country={'in'}
//                     value={phoneNumber}
//                     onChange={(phone) => {
//                         setPhoneNumber(String(phone)); // Ensure the value is always a string
//                         form.setFieldsValue({ mobile: phone });
//                     }}
//                     inputStyle={{ width: "100%" }}
//                     enableSearch={true}
//                     disabled={userProfile?.isMobileVerified}
//                 />
//                 {userProfile?.isMobileVerified ? (
//                     <Button type="text" className="green-text">
//                         Verified
//                     </Button>
//                 ) : (
//                     <Button
//                         className="verify-button"
//                         onClick={() => {
//                             try {
//                                 let payload;

//                                 if (userProfile?.mobile && userProfile?.countryCode) {
//                                     payload = {
//                                         mobile: userProfile.mobile,
//                                         countryCode: userProfile.countryCode,
//                                     };
//                                 } else {
//                                     const sanitizedPhone = String(phoneNumber || "").replace(/[^0-9+]/g, "");
//                                     const mobile = sanitizedPhone.slice(-10);
//                                     const countryCode = sanitizedPhone.replace(mobile, "").trim();

//                                     if (!/^\d{10}$/.test(mobile)) {
//                                         message.error("Phone number must be 10 digits");
//                                         return;
//                                     }

//                                     if (!countryCode.startsWith("+") || countryCode.length < 2) {
//                                         message.error("Invalid country code");
//                                         return;
//                                     }

//                                     payload = { mobile:mobile, countryCode:` + ${countryCode}` };
//                                 }

//                                 dispatch(setverifyType("mobile"));
//                                 dispatch(
//                                     genrate_login_otp_user_Info({
//                                         body: payload,
//                                         navigate,
//                                         currentStep: 0,
//                                         isMobile: true,
//                                         isEmail: false,
//                                     })
//                                 );

//                                 setCountdown(300);
//                                 setIsResendDisabled(true);
//                             } catch (error) {
//                                 console.error("Error processing phone number:", error);
//                                 message.error("Failed to process phone number.");
//                             }
//                         }}
//                         size="middle"
//                         shape="round"
//                         danger
//                         type="primary"
//                         loading={verifyType === "mobile"}
//                     >
//                         Verify
//                     </Button>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default PhoneNumberInput;

import { Button, Space } from 'antd';
import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { genrate_login_otp_user_Info, setverifyType } from '../../features/UserInfo/userInfoSlice';
import { useNavigate } from 'react-router-dom';
import { setUserProfile } from '../../features/Global/globalSlice';

const PhoneNumberInput = ({ setCountdown, setIsResendDisabled, phoneNumber, setPhoneNumber }) => {
    const { userProfile } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Handler for phone number change
    const handleChange = (value) => {
        setPhoneNumber(value);
        setCountdown(30); // Example: Reset countdown when phone number changes
        setIsResendDisabled(true); // Disable resend initially when phone number is updated
        // dispatch(setUserProfile({ ...userProfile, phoneNumber: value }));
    };

    useEffect(() => {
        if (userProfile) {
            const phone = `${userProfile.countryCode || ''}${userProfile.mobile || ''}`;
            setPhoneNumber(phone);
        }
    }, [userProfile]);

    const verifyMobile = () => {
        dispatch(setverifyType("mobile"));
        const countryCode = phoneNumber?.startsWith('+')
            ? phoneNumber?.slice(0, phoneNumber?.length - 10) // No need to add another '+' if it's already there
            : `+${phoneNumber?.slice(0, phoneNumber?.length - 10)}`; // Add '+' if not present
        const mobile = phoneNumber?.slice(-10);
        const payload = {
            countryCode: countryCode,
            mobile: mobile
        }
        dispatch(
            genrate_login_otp_user_Info({
                body: payload,
                navigate,
                currentStep: 0,
                isMobile: true,
                isEmail: false,
            })
        );

        setCountdown(300);
        setIsResendDisabled(true);
    }


    return (
        <div>
            <Space>
                <div className='margin-top-10'>
                    <PhoneInput
                        country={'in'} // Default country, you can change it as needed
                        value={phoneNumber}
                        onChange={handleChange}
                        inputStyle={{
                            width: '100%',
                            padding: '20px 25px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            paddingLeft: '45px',
                            // marginLeft:'20px'
                        }}
                        containerStyle={{ marginBottom: '10px' }}
                    />
                </div>
                <Button shape='round' className='fullwidth' onClick={verifyMobile} size='large' danger type='primary'>Verify</Button>
            </Space>
        </div>
    );
};

export default PhoneNumberInput;