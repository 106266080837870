import { Button, Card, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { add_organization_roles } from '../../../features/Organization/OrganizationInfo/OrganizationInfoSlice';
import { get_organization } from '../../../features/Organization/OrgAuth/OrgAuthSlice';
import { Option } from 'antd/es/mentions';

const AddUserRole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { userProfile } = useSelector((state) => state.global)

  const onAddUser = (values) => {
    const payload = {
      orgId: userProfile?._id,
      userId: values?.userId,
      role: values?.role
    }
    dispatch(add_organization_roles({ body: payload, navigate: navigate }))
    form.resetFields()
  }

  useEffect(() => {
    dispatch(get_organization(navigate))
  }, [])

  return (
    <Card title="Add Role">
      <Form form={form} layout='vertical' colon={false} onFinish={onAddUser}>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12} md={12}>
            <Form.Item name={"userId"} label="Enter User ID" rules={[{ required: true, message: "Please enter user id" }]}>
              <Input placeholder='Enter user Id for add Role EX : Jw_Sud_exp_mte8r46' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6} md={6}>
            <Form.Item
              name="role"
              label="Select Role"
              rules={[{ required: true, message: 'Please Select Role' }]}
            >
              <Select size='middle' placeholder="Select Role">
                <Option value="admin">admin</Option>
                <Option value="user">User</Option>
                <Option value="employee">Employee</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6} md={6}>
            <Form.Item>
              <Button htmlType='submit' className='default-button-style margin-top-30 fullwidth'>Submit</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default AddUserRole