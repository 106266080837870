import React from 'react';
import { Spin } from 'antd';
import styles from './style.module.scss' // Create this file for custom styles if needed.

const ScreenFreezLoader = ({ loading, text = "Loading..." }) => {
  if (!loading) return null;

  return (
    <div className={'screenfreezloader'}>
      <Spin size="large" tip={text} />
    </div>
  );
};

export default ScreenFreezLoader;
