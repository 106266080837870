import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Input, Row, Select, Button, DatePicker, Divider, Modal, message, Space } from 'antd';
import styles from './style.module.scss';
import { CalendarOutlined, EditOutlined, ExportOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { genrate_login_otp_user_Info, setIsEmailVerify, setverifyType, verify_otp__user_Info } from '../../../features/UserInfo/userInfoSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
// import AddLanguage from './AddLanguage';
import dayjs from 'dayjs';
import { formatDOBDate } from '../../../utils/helpers';
import { setUserProfile } from '../../../features/Global/globalSlice';
// import AddSocialLinks from './AddSocialLinks';

const { Option } = Select;

const PersonalDetails = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isEmailVerify, isMobileVerify, verifyLoading, isGenerateOTPLoad, verifyType, isOtpSubmit } = useSelector((state) => state.userInfo);
  const {userProfile} = useSelector((state)=>state.global)
  const { verifyOTPLoad, genrateOTPload } = useSelector((state) => state.auth);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailLoader, setEmailLoader] = useState(genrateOTPload);
  const [mobileLoader, setMobileLoader] = useState(genrateOTPload);
  const [countdown, setCountdown] = useState(300); // 5 minutes = 300 seconds
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [isVisible, setIsVisivle] = useState(false)
  const [defaultCountry, setDefaultCountry] = useState('in');
  const [isEdit, setIsEdit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false)


  const onEditfield = () => {
    setIsEdit(true)
  }

  useEffect(() => {
    if (userProfile?.mobile) {
      const mobile = userProfile.mobile;
      const countryCode = userProfile.countryCode || '+91';

      setPhoneNumber(mobile);
      setDefaultCountry(countryCode.slice(1).toLowerCase());
    }
  }, [userProfile]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const onValuesChange = (changedValues, allValues) => {
    const { dob, ...rest } = allValues;
    const userData = {
      ...rest,
      dob: dob ? dayjs(dob).format('YYYY-MM-DD') : dob,
    };
    dispatch(setUserProfile(userData));
  };

  const onEmailVerify = () => {
    const { email } = form.getFieldsValue();
    if (!email) {
      message.error('Please enter your email');
      return;
    }
    const payload = { email };
    dispatch(setverifyType('email'))
    dispatch(genrate_login_otp_user_Info({ body: payload, navigate, isMobile: false, isEmail: true }));
    setCountdown(300);
    setIsResendDisabled(true);
  };

  const onMobileVerify = () => {
    const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`;
    const mobile = phoneNumber.slice(-10);
    if (!mobile) {
      message.error('Please enter your mobile number');
      return;
    }
    const payload = { mobile, countryCode };
    dispatch(setverifyType('mobile'))
    dispatch(genrate_login_otp_user_Info({ body: payload, navigate, currentStep: 0, isMobile: true, isEmail: false }));
    setCountdown(300); // Reset countdown to 5 minutes
    setIsResendDisabled(true);
  };

  const onSubmitOTP = () => {
    const { emailOtp, mobileOtp } = form.getFieldsValue();
    const mobileOtpPayload = { mobileOtp };
    const payload = { emailOtp };
    dispatch(verify_otp__user_Info({ body: isEmailVerify ? payload : mobileOtpPayload, navigate }));
  };

  const onResendOTP = () => {
    isEmailVerify ? onEmailVerify() : onMobileVerify();
  };

  useEffect(() => {
    const data = formatDOBDate(userProfile?.dob)
    const formattedDate = dayjs(userProfile?.dob).format('YYYY-MM-DD');
    if (userProfile) {
      form.setFieldsValue({
        name: userProfile.name,
        email: userProfile.email,
        dob: dayjs(formattedDate),
        mobile: userProfile.mobile,
      });
    }
  }, [userProfile, form]);

  const onClose = () => {
    setIsVisivle(false)
  }

  const onSave = () => {

  }

  const onOpenLanguage = () => {
    setIsVisivle(true)
  }

  const showAddSocial = () => {
    setIsModalVisible(true);
  };

  return (
    <div>
      <Card>
        <div>
          <div className='flex-space-between'>
            <div>
              <h3>Personal Details</h3>
            </div>
            <div>
              {
                isEdit ?
                  <Button
                    type="default"
                    shape="round"
                    icon={<EditOutlined />}
                    size="large"
                    style={{ position: 'relative', bottom: '10px' }}
                    className='dark-bg'
                    // onClick={onEditfield}
                  >
                    Save & Next
                  </Button>
                  :
                  <Button
                    type="default"
                    shape="round"
                    icon={<EditOutlined />}
                    size="large"
                    style={{ position: 'relative', bottom: '10px' }}
                    className='dark-bg'
                    onClick={onEditfield}
                  >
                    Edit Profile
                  </Button>
              }
            </div>
          </div>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onValuesChange}
          >
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Full Name"
                  name="name"
                  rules={[{ required: true, message: 'Please enter your full name' }]}
                >
                  <Input disabled size='large' suffix={<CalendarOutlined />}
                    placeholder="Amit Kumar"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your email' },
                    { type: 'email', message: 'Please enter a valid email' },
                  ]}
                
                >
                  <Input disabled ={userProfile?.isEmailVerified} suffix={
                    userProfile?.isEmailVerified ? <Button type='text' className='green-text'>verified</Button> :
                      <Button loading={verifyType === 'email'} onClick={onEmailVerify} size='middle' shape='round' danger type='primary'>Verify</Button>
                  } placeholder="amitkumar@gmail.com" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  name="mobile"
                  className='fullwidth'
                  label="Phone Number"
                  rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                >
                  <div className="phone-input-wrapper">
                    <PhoneInput
                      country={defaultCountry}
                      value={userProfile?.mobile ? userProfile?.mobile : phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputStyle={{ width: '100%' }}
                      enableSearch={true}
                      disabled={userProfile?.isMobileVerified}
                    />
                    {userProfile?.isMobileVerified ?
                      <Button type='text' className='green-text'>verified</Button>
                      :
                      <Button
                        className="verify-button"
                        onClick={onMobileVerify}
                        size='middle'
                        shape='round'
                        danger
                        type='primary'
                        loading={verifyType === 'mobile'}
                      >
                        Verify
                      </Button>
                    }

                  </div>
                </Form.Item>


              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  style={{ width: '100%' }}
                  label={''}
                  name="password"
                  rules={[{ required: true, message: 'Please enter your password' }]}
                >
                  <label><div className={`flex-space-between ${styles.customeLabel}`}>
                    <div>Password</div>
                    <Button size='small' className='border-none' type='link' danger>Change Password</Button>

                  </div></label>
                  <Input.Password size='large' placeholder="********" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Date of Birth"
                  name="dob"
                  rules={[{ required: true, message: 'Please select your date of birth' }]}
                >
                  <DatePicker disabled={isEdit ? false : true} size='large' suffixIcon={<CalendarOutlined />} placeholder="dd/mm/yyyy" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[{ required: true, message: 'Please select your gender' }]}
                >
                  <Select disabled size='large'>
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Work Permit"
                  name="workPermit"
                  rules={[{ required: true, message: 'Please select your work permit' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' mode="multiple" placeholder="Select work permits">
                    <Option value="Indian">Indian</Option>
                    <Option value="Other">Other</Option>
                    <Option value="US">US</Option>
                    <Option value="UK">UK</Option>
                    <Option value="Canada">Canada</Option>
                  </Select>
                </Form.Item>

              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Address Line 1"
                  name="currentAddress"
                  rules={[{ required: true, message: 'Please enter your address' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large'>
                    <Option value="male">XYZ</Option>
                    <Option value="female">ABC</Option>
                    <Option value="other">XYZ</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Address Line 2"
                  name="permanentAddress"
                  rules={[{ required: true, message: 'Please enter your address' }]}
                >
                  <Input disabled={isEdit ? false : true} size='large' placeholder="New Delhi, India, 110001" />
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <h3>Personalized experiance</h3>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Job alerts"
                  name="jobalerts"
                  rules={[{ required: true, message: 'Please select your job alerts' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' placeholder="Select work permits">
                    <Option value="enable">Enable</Option>
                    <Option value="disable">Disable</Option>

                  </Select>
                </Form.Item>

              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Email Notifications"
                  name="email-notifications"
                  rules={[{ required: true, message: 'Please select notification' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' placeholder="Select work permits">
                    <Option value="enable">Enable</Option>
                    <Option value="disable">Disable</Option>

                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Privacy settings"
                  name="privacy-settings"
                  rules={[{ required: true, message: 'Please select privacy' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' placeholder="Select work permits">
                    <Option value="Profile-visible-recruiters">Profile visible to recruiters</Option>
                    <Option value="private">Private</Option>

                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <div className='flex-space-between'>
                  <h3>Language</h3>
                  <div><Button onClick={onOpenLanguage} className='dark-bg'>Add</Button></div>
                </div>
                <div className={`flex-space-between ${styles.removeBtn}`}>
                  <div>English</div>
                  <Button shape='round' size='small' className='dark-bg'>Remove</Button>
                </div>
                <Form.Item
                  // label={}
                  name="english"
                  rules={[{ required: true, message: 'Please select your proficiency' }]}
                >
                  <Select size='large' placeholder="Select level">
                    <Option value="level1">Level 1 (Basic)</Option>
                    <Option value="level2">Level 2 (Intermediate)</Option>
                    <Option value="level3">Level 3 (Fluent)</Option>
                  </Select>
                </Form.Item>


                <div className={`flex-space-between ${styles.removeBtn}`}>
                  <div>Hindi</div>
                  <Button shape='round' size='small' className='dark-bg'>Remove</Button>
                </div>
                <Form.Item
                  // label="Hindi"
                  name="hindi"
                  rules={[{ required: true, message: 'Please select your proficiency' }]}
                >
                  <Select size='large' placeholder="Select level">
                    <Option value="level1">Level 1 (Basic)</Option>
                    <Option value="level2">Level 2 (Intermediate)</Option>
                    <Option value="level3">Level 3 (Fluent)</Option>
                  </Select>
                </Form.Item>


                <div className={`flex-space-between ${styles.removeBtn}`}>
                  <div>French</div>
                  <Button shape='round' size='small' className='dark-bg'>Remove</Button>
                </div>
                <Form.Item
                  // label="French"
                  name="hindi"
                  rules={[{ required: true, message: 'Please select your proficiency' }]}
                >
                  <Select size='large' placeholder="Select level">
                    <Option value="level1">Level 1 (Basic)</Option>
                    <Option value="level2">Level 2 (Intermediate)</Option>
                    <Option value="level3">Level 3 (Fluent)</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <AddLanguage visible={isVisible} onClose={onClose} onSave={onSave} /> */}

              <Col lg={12} md={12} xs={24}>
                <div className='flex-space-between'>
                  <h3>Social Links</h3>
                  <div><Button onClick={showAddSocial} className='dark-bg'>Add</Button></div>
                </div>
                <Form.Item
                  label="Behance"
                  name="behance"
                  rules={[{ required: true, message: 'Please select your proficiency' }]}
                >
                  <Input className='text-link' defaultValue={"https://www.behance.net/amit007fd"} suffix={<ExportOutlined />} />
                </Form.Item>

                <Form.Item
                  label="LinkedIn"
                  name="linkedin"
                  rules={[{ required: true, message: 'Please select your proficiency' }]}
                >
                  <Input className='text-link' defaultValue={"https://www.behance.net/amit007fd"} suffix={<ExportOutlined />} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Modal width={300} centered footer={false} title={isEmailVerify ? "Email Verification" : "Mobile Number Verification"} open={isEmailVerify || isMobileVerify}>
              <Form.Item name={isEmailVerify ? "emailOtp" : 'mobileOtp'} label={"Enter OTP"}>
                <Input placeholder='Enter your otp here' />
              </Form.Item>
              <Button loading={verifyOTPLoad}
                onClick={() => { onSubmitOTP() }}
                className='fullwidth default-button-style'>Submit</Button>
            </Modal> */}
            <Modal
              width={300}
              centered
              footer={false}
              title={isEmailVerify ? 'Email Verification' : 'Mobile Number Verification'}
              open={isEmailVerify || isMobileVerify}
            >
              <Form.Item name={isEmailVerify ? 'emailOtp' : 'mobileOtp'} label="Enter OTP">
                <Input placeholder="Enter your OTP here" />
              </Form.Item>
              <div className='error-text' style={{ marginBottom: '20px', lineHeight: '1px' }}>
                OTP expires in  <span>{formatTime(countdown)}</span>
              </div>
              <div className={styles.otpverificationDiv}>
                <Button
                  loading={isOtpSubmit}
                  onClick={onSubmitOTP}
                  className="fullwidth default-button-style"
                >
                  Submit
                </Button>
                <Button
                  onClick={onResendOTP}
                  loading={verifyType === 'email' || verifyType === 'mobile'}
                  disabled={isResendDisabled}
                  className="fullwidth default-button-style"
                >
                  Resend OTP
                </Button>
              </div>
            </Modal>
          </Form>
        </div>
      </Card>
      {/* <AddSocialLinks setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} /> */}
    </div>
  );
};

export default PersonalDetails;
