import { Button, Form, Input, Modal, Row, Col, DatePicker, Select } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';

const { RangePicker } = DatePicker;
const employmentTypes = ["Full Time", "Part Time", "Contract"]; // Add other options if needed

const AddProfessionalDetailsModal = ({ visible, onCancel, onAdd }) => {
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        onAdd(values);
        form.resetFields();
    };

    return (
        <Modal
            title="Add Professional Details"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            centered
            width={700}
        >
            <Form colon={false} layout='vertical' form={form} onFinish={handleSubmit}>
                <Row gutter={16}>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Company Name"
                            name="company"
                            rules={[{ required: true, message: 'Please enter company name' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Role"
                            name="role"
                            rules={[{ required: true, message: 'Please enter your role' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please enter job description' }]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Projects"
                            name="projects"
                        >
                            <Input placeholder="E.g., project 1, project 2" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Skills"
                            name="skills"
                        >
                            <Input placeholder="E.g., figma, adobe xd" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Employment Duration"
                            name="employmentDuration"
                            rules={[{ required: true, message: 'Please select employment duration' }]}
                        >
                            <RangePicker picker="month" format="MMM YYYY" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Employment Type"
                            name="employmentType"
                            rules={[{ required: true, message: 'Please select employment type' }]}
                        >
                            <Select placeholder="Select employment type">
                                {employmentTypes.map(type => (
                                    <Select.Option key={type} value={type}>{type}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Experience Duration"
                            name="experienceDuration"
                        >
                            <Input placeholder="E.g., 2 years 1 month" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} sm={24}>
                        <Form.Item
                            label="Notice Period"
                            name="noticePeriod"
                        >
                            <Input placeholder="E.g., 2 months" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="end">
                    <Col>
                        <div className='flex-wrap-row-direction-gap-5'>
                            <Button
                                size="large"
                                shape="round"
                                onClick={onCancel}
                            >
                                Reset & Close
                            </Button>
                            <Button
                                size="large"
                                shape="round"
                                type="primary"
                                htmlType="submit"
                                className='default-button-style'
                            >
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddProfessionalDetailsModal;

