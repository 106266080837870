export const AppRoutes = {
    HOME: "/",
    LOGIN:"/login",
    SIGN_UP:"/sign-up",
    OTP_VERIFICATION:"/otp-verification",
    FIND_JOB:"/find-job",
    APPLICATION_STATUS:'/find-job/application-status',
    USER_INFO:"/find-job/user-info",
    JOB_DETAILS:"/job-details",
    SIGN_UP_OTP_VERIFICATION:'/sign-up/otp-verification',
    RECOMENDED_JOBS:'/find-job/recommended-jobs',
    SAVE_JOBS:'/find-job/save-jobs',
    BLOGS:'/find-job/blogs',
    REFERENCE_PEOPLE:'/find-job/reference-people',
    SETTING:'/find-job/setting',
    DASHBOARD:'/dashboard',
    ORGANIZATION_INFO:'/organization-info',
    JOB_STATUS:'/job-status',
    RECOMMEND_CANDIDATE:'/recommend-candidate',
    ANALYTICS:'/analytics',
    ORG_SETTINGS:'/organization-settings',
    CANDIDATE_DETAILS:'/candidate-details/:name/:id',
    RECOMMEND_CANDIDATE_PROFILE:'/candidate-profile/:id',
    JOBLISTING:'/searched-jobs',
    FIND_JOB_CALENDER:'/find-job/calender',
    SPECIFIC_JOB_DETAILS:'/job-details/:id',
    NOTEXIST:'*'
  };