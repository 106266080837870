import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/Auth/authSlice';
import globalReducer from '../features/Global/globalSlice';
import userInfoReducer from '../features/UserInfo/userInfoSlice'
import orgAuthReducer from '../features/Organization/OrgAuth/OrgAuthSlice';
import orgInfoReducer from '../features/Organization/OrganizationInfo/OrganizationInfoSlice'
import jobseekerReducer from '../features/Jobseeker/jobseekerSlice';
import JobReducer from '../features/Organization/Jobs/jobSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    global:globalReducer,
    userInfo:userInfoReducer,
    orgAuth:orgAuthReducer,
    orgInfo:orgInfoReducer,
    jobseeker:jobseekerReducer,
    jobs:JobReducer
  },
});
