import { get_and_search_jobs } from "../features/Jobseeker/jobseekerSlice";
import AppSecureStorage from "../services/storage/secureStorage";

const storage = new AppSecureStorage();
export const unauthorizedError = (navigate) => {
  navigate("/");
  storage.clearStorage();
};

// helper.js

export function formatDOBDate(dateString) {
  const date = new Date(dateString);

  const options = {
    day: '2-digit',
    month: 'short',  // This will give abbreviated month names, like "Aug"
    year: 'numeric'
  };

  return date.toLocaleDateString('en-GB', options);
}

// dateHelper.js
export const getYear = (dateString) => {
  if (!dateString) return ""; // Handle cases where the input is null or undefined
  const date = new Date(dateString);
  return isNaN(date.getFullYear()) ? "" : date.getFullYear(); // Ensure valid year
};

// Function to get the full date in YYYY-MM-DD format
export const getDate = (dateString) => {
  if (!dateString) return ""; // Handle cases where the input is null or undefined

  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return ""; // Return an empty string if the date is invalid
  }

  // Extract and return the date in YYYY-MM-DD format
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1 and pad with 0 if needed
  const day = String(date.getDate()).padStart(2, '0'); // Pad the day with a leading zero if it's a single digit

  return `${year}-${month}-${day}`; // Return the formatted date
};


// export const timeSincePosted = (createdAt) => {
//   const createdDate = new Date(createdAt);
//   const now = new Date();
//   const diffInMs = now - createdDate;
//   const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

//   if (diffInDays === 0) return 'Posted today';
//   if (diffInDays === 1) return 'Posted 1 day ago';
//   return `Posted ${diffInDays} days ago`;
// };

export const timeSincePosted = (createdAt) => {
  if (!createdAt) {
    // If there's no date, return an empty string or a default message
    return '';
  }

  const createdDate = new Date(createdAt);

  // Check if the date is invalid
  if (isNaN(createdDate)) {
    return '';
  }

  const now = new Date();
  const diffInMs = now.getTime() - createdDate.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays <= 0) return 'Posted today';
  if (diffInDays === 1) return 'Posted 1 day ago';
  return `Posted ${diffInDays} days ago`;
};


export const getLightColorForTag = (index) => {
  const hue = (index * 110) % 280;
  const saturation = 90;
  const lightness = 90 + (index % 20);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const parseSearchParams = (searchParams) => {
  const params = new URLSearchParams(searchParams);
  const parsedParams = {};
  params.forEach((value, key) => {
    parsedParams[key] = value;
  });
  return parsedParams;
};

// export const onSearchJobs = (onSearchValues, dispatch, navigate, apiCall) => {
//   const filteredValues = Object.keys(onSearchValues).reduce((acc, key) => {
//     // Check if the value exists and is a string before calling trim()
//     if (onSearchValues[key] && typeof onSearchValues[key] === 'string' && onSearchValues[key].trim() !== "") {
//       acc[key] = onSearchValues[key];
//     }
//     // Optionally handle non-string values here (if necessary)
//     return acc;
//   }, {});

//   const searchParams = new URLSearchParams(filteredValues).toString();
//   // dispatch(get_and_search_jobs({ navigate, searchParams }));
//   // Dispatch the provided API call dynamically
//   if (apiCall && typeof apiCall === "function") {
//     dispatch(apiCall({ navigate, searchParams }));
//   } else {
//     console.error("Invalid API call function provided!");
//   }
// };

export const onSearchJobs = (onSearchValues, dispatch, navigate, apiCall, userId = null) => {
  console.log("usetid", userId)
  const filteredValues = Object.keys(onSearchValues).reduce((acc, key) => {
    const value = onSearchValues[key];
    if (value && typeof value === "string" && value.trim() !== "") {
      acc[key] = value.trim(); // Include valid string values
    }
    return acc;
  }, {});

  // Convert filtered search parameters to query string
  const searchParams = new URLSearchParams(filteredValues).toString();

  // Dispatch the API call dynamically, with or without userId
  if (apiCall && typeof apiCall === "function") {
    if (userId) {
      // API requires userId
      dispatch(apiCall({ navigate, userId, searchParams }));
    } else {
      // API does not require userId
      dispatch(apiCall({ navigate, searchParams }));
    }
  } else {
    console.error("Invalid API call function provided!");
  }
};



export const applyFiltersAllPages = (internalSearchParameter, searchParameters, onSearchValues, filters, dispatch, navigate, apiCall, userId = null) => {
  // Helper function to parse existing search parameters into an object
  const parseSearchParams = (paramString) => {
    const params = new URLSearchParams(paramString);
    const result = {};
    for (let [key, value] of params.entries()) {
      result[key] = value;
    }
    return result;
  };

  // Parse existing search parameters if available
  const parsedInternalParams = internalSearchParameter
    ? parseSearchParams(internalSearchParameter)
    : {};
  const parsedSearchParams = searchParameters
    ? parseSearchParams(searchParameters)
    : {};
  const parsedonSearchParams = onSearchValues
    ? parseSearchParams(onSearchValues)
    : {};

  // Merge parsed parameters with current filters
  const combinedFilters = {
    ...parsedInternalParams,
    ...parsedSearchParams,
    ...parsedonSearchParams,
    ...filters,
  };

  // Create the final search string
  const searchParams = new URLSearchParams(combinedFilters).toString();
  console.log("Final Search Params:", searchParams); // Debugging purpose
  // dispatch(get_and_search_jobs({ navigate: navigate, searchParams: searchParams }));
  if (apiCall && typeof apiCall === "function") {
    if (userId) {
      // API requires userId
      dispatch(apiCall({ navigate, userId, searchParams }));
    } else {
      // API does not require userId
      dispatch(apiCall({ navigate, searchParams }));
    }
  } else {
    console.error("Invalid API call function provided!");
  }
};

export const clientId = ()=>{
  console.log("GOOGLE_CLIENT_ID", process.env.GOOGLE_CLIENT_ID)
  return process.env.GOOGLE_CLIENT_ID
}