import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Card, Col, Image, List, message, Modal, Row, Tag } from 'antd';
import VirtualList from 'rc-virtual-list';
import { HeatMapOutlined, LockOutlined, SaveOutlined, ShareAltOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import bookmarkImg from '../../assets/Images/inactive-bookmark.svg'
import shareIcon from '../../assets/Images/share.svg'
import locationImg from '../../assets/Images/location.svg'
import { timeSincePosted } from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import savedBookmardimg from '../../assets/Images/savedBookmark.svg'
import ShowJobDetails from './ShowJobDetails';
import { setCurrentFilterSection, setCurrentJob, setCurrentJobStatus } from '../../features/Global/globalSlice';
import { Link, useNavigate } from 'react-router-dom'
import { getAuthToken } from '../../utils/authHelpers';
import { get_profile_percentage } from '../../features/Jobseeker/jobseekerSlice';

const ContainerHeight = 650;

const JobsList = ({
    data = [],
    buttonText = 'Apply',
    onButtonClick,
    onShareClick,
    onSaveClick,
    onUnSaveClick,
    submitload,
    loadingJobId,
    page
}) => {
    const [isMore, setIsMore] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const token = getAuthToken();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const onScroll = (e) => {
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            // handle additional scroll logic if needed
        }
    };
    const { userProfile } = useSelector((state) => state.global)
    const { savedJobs, ProfileCompletion } = useSelector((state) => state.jobseeker)
    const getColorForTag = (index) => {
        // Generate a light color based on the index of the skill
        const hue = (index * 110) % 280;  // Use a unique hue for each skill, 137 is a prime number for diversity
        const saturation = 90;  // Keep saturation moderate to avoid overly saturated colors
        const lightness = 90 + (index % 20);  // Ensure lightness stays high for a pastel-like effect

        // Return the color in HSL format
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const onShowMore = (jobdata) => {
        setIsMore(true)
        dispatch(setCurrentJob(jobdata))
    }

    const onBack = () => {
        setIsMore(false)
    }

    const handleApply = (job) => {
        const profilePercentage = parseFloat(ProfileCompletion?.percentage || '0');
        if (profilePercentage >= 60) {
            onButtonClick(job);
        } else {
            setIsModalVisible(true);
            message.error('First, complete your profile to apply for jobs.');
        }
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(()=>{
        if(token){
            dispatch(get_profile_percentage(navigate))
        }
    },[token])


    return (
        <List>
            {isMore ? <ShowJobDetails onBack={onBack} page={page} onButtonClick={onButtonClick} loadingJobId={loadingJobId}
                onSaveClick={onSaveClick} onShareClick={onShareClick} buttonText={buttonText} submitload={submitload} /> :
                <VirtualList
                    data={data}
                    height={ContainerHeight}
                    itemHeight={47}
                    itemKey="email"
                    onScroll={onScroll}
                    style={{ width: '100%' }}
                >
                    {(item) => {
                        const isJobApplied = userProfile?.appliedJobs?.some((job) => job?.jobId === item?._id);
                        const isJobSaved = savedJobs?.some((job) => job?._id === item?._id);
                        const currentButtonText = isJobApplied ? 'Applied' : buttonText;

                        const handleShare = async (job) => {
                            console.log("jobsdata", job)
                            try {
                                // Construct the job link. Adjust this URL structure as necessary.
                                const jobLink = `${window.location.origin}/job-details/${job?._id}`;
                                await navigator.clipboard.writeText(jobLink);
                                message.success('Job link copied to clipboard!');
                                if (onShareClick) {
                                    onShareClick(job);
                                }
                            } catch (error) {
                                message.error('Failed to copy the job link.');
                            }
                        };

                        return (
                            <List.Item className={styles.listItems} key={item.email}>
                                <List.Item.Meta
                                    avatar={<Avatar className='default-button-style' size={'large'} icon={<LockOutlined />} />}
                                    title={
                                        <div>
                                            <Card className={styles.jobListCards} title={<Link onClick={() => {
                                                dispatch(setCurrentFilterSection('application-status'))
                                                dispatch(setCurrentJobStatus(item))
                                            }}><h3 className={styles.jobTitleStyle}>{item?.jobTitle}</h3></Link>} extra={<div className='text-end'><Button size='small' icon={<Image preview={false} src={locationImg} />}>{item?.location}</Button>
                                                <div>{timeSincePosted(item?.createdAt)}</div></div>}>
                                                Technical Skills
                                                <div className='flex-wrap-row-direction-gap-5 margin-top-10'>
                                                    {item?.technicalSkills?.map((ele, index) => (

                                                        <Button style={{ backgroundColor: getColorForTag(index), color: 'black' }} size='small'>
                                                            {ele}
                                                        </Button>

                                                    ))}
                                                </div>
                                            </Card>
                                            <div className='margin-top-10'>Job Type : <Tag className='default-button-style'>{item?.jobType}</Tag></div>
                                            <div className='margin-top-10'>Notice Period : <Tag className='default-button-style'>{item?.noticePeriod}</Tag></div>
                                            <div className='margin-top-10'>Education Qualifications : <span className='font-w-400'>{item?.educationalQualification}</span></div>
                                            <div className='margin-top-10'>
                                                Salary: <span className='font-w-400'>₹{item?.salary?.min.toLocaleString()} - ₹{item?.salary?.max.toLocaleString()}</span>
                                            </div>
                                            <div className='margin-top-10'>
                                                Experiance Required : <span className='font-w-400'>{item?.totalExperienceRange?.min} - {item?.totalExperienceRange?.max} year</span>
                                            </div>
                                            <div className='margin-top-10'>
                                                Description
                                                <div className='font-w-400'>{item?.description}</div>
                                            </div>
                                            <div className='margin-top-10'>
                                                <Tag className='add-cursor' color='blue' onClick={() => onShowMore(item)}>More...</Tag>
                                            </div>
                                            <div className='margin-top-10'>
                                                <Row justify={'end'}>
                                                    <div className='flex-wrap-row-direction-gap-15'>
                                                        <div className={`add-cursor ${styles.circularImageDiv}`}>
                                                            <Image preview={false} className={styles.circularImage} width={40} height={40} src={shareIcon} onClick={() => handleShare(item)} />
                                                        </div>
                                                        {(token && isJobSaved) ?
                                                            <div className={`add-cursor ${styles.circularSavedImageDiv}`}>
                                                                <Image preview={false} className={styles.circularImage} width={40} height={40} src={savedBookmardimg} onClick={() => onUnSaveClick(item)} />

                                                            </div> : <div className={`add-cursor ${styles.circularImageDiv}`}>
                                                                <Image preview={false} className={styles.circularImage} width={40} height={40} src={bookmarkImg} onClick={() => onSaveClick(item)} />

                                                            </div>}

                                                        <div>
                                                            <Button
                                                                shape='round'
                                                                className={page === "application" || page === "recommend" ? isJobApplied ? 'disableApplyBtn' : `default-button-style ` : 'default-button-style'}
                                                                size='large'
                                                                onClick={() => handleApply(item)}
                                                                loading={loadingJobId === item?._id ? submitload : false}
                                                                disabled={page === "application" || page === "recommend" ? isJobApplied ? true : false : false}
                                                            >
                                                                {page === "application" || page === "recommend" ? currentButtonText : buttonText}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )
                    }}
                </VirtualList>
            }
            {/* Modal for profile completion */}
            <Modal
                title={<div>Complete Your Profile <Tag color='red-inverse'>{ProfileCompletion?.percentage}%</Tag></div>}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                centered
                footer={[
                    // <Button key="cancel" onClick={handleModalCancel}>
                    //     Cancel
                    // </Button>,
                    <Button className='default-button-style' key="profile" type="primary">
                        <Link to="/find-job/user-info">Go to Profile</Link>
                    </Button>,
                ]}
            >
                <Alert showIcon type='warning' message="Your profile is incomplete. Please complete it to apply for jobs. A minimum of 60% completion is required to proceed with job applications." />
            </Modal>
        </List>
    );
};

export default JobsList;
