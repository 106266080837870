import { Button, Checkbox, Form, Input, Modal, Select, Space, Upload, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'antd/es/mentions';
import { CheckCircleOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { org_signup, setIsVrifySuccess, setOrgSignupStep, setOrgUserValues } from '../../features/Organization/OrgAuth/OrgAuthSlice';
import { setUserType } from '../../features/Global/globalSlice';
import _axios from '../../services/api';
import { API_ROUTES } from '../../utils/constants';
import { getAuthToken } from '../../utils/authHelpers';
import AppSecureStorage from '../../services/storage/secureStorage';

const storage = new AppSecureStorage()

const OrganizationNextStep = ({ formDataorg }) => {
    const token = getAuthToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [fileName, setFileName] = useState('');
    const [orgLogoName, setOrgLogoName] = useState('');
    const [orgLogoList, setOrgLogoList] = useState([]);
    const [isJDload, setIsJDload] = useState(false);
    const { signupLoading, isGenerateOTP, signupStep, genrateOTPload } = useSelector((state) => state.auth)
    const { orgUserValues, isVerifySuccess, orgSignupLoad } = useSelector((state) => state.orgAuth)

    const goToSignInPage = () => {
        navigate('/login');
    };

    const handleInputClick = () => {
        document.getElementById('file-upload-input').click();
    };

    const handleOrgLogoInputClick = () => {
        document.getElementById('file-org-logo').click();
    };

    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            setFileList([file]);
            setFileName(file.name); // Set the selected file in the state
            return false;  // Prevent automatic upload
        },
        onRemove: (file) => {
            setFileList([]);  // Clear file list on remove
        },
    };


    const orgLogoProps = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            setOrgLogoList([file]);
            setOrgLogoName(file.name); // Set the selected file in the state
            return false;  // Prevent automatic upload
        },
        onRemove: (file) => {
            setOrgLogoList([]);  // Clear file list on remove
        },
    };

    const onSubmitLogoandJD = async () => {
        setIsJDload(true)
        if (fileList.length === 0) {
            setIsJDload(false)
            console.error("Please upload a JD file.");
            return;
        }

        // Create FormData for file upload
        const formData = new FormData();
        formData.append("file", fileList[0]); // Append only the file
        try {
            const response = await _axios.post(
                API_ROUTES.JOBS.UPLOAD_JOB, // Replace with your API endpoint
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Replace `token` with your token variable
                        "Content-Type": "multipart/form-data", // Required for FormData
                    },
                }
            );
            if (response.status === 200) {
                console.log("File uploaded successfully:", response.data);
                message.success("File uploaded successfully")
                dispatch(setIsVrifySuccess(true));
                setIsJDload(false)
                setTimeout(() => {
                    dispatch(setOrgUserValues({}))
                    dispatch(setOrgSignupStep(0))
                    storage.clearStorage()
                    navigate('/');
                    dispatch(setIsVrifySuccess(false));
                }, 7000);
            }
        } catch (error) {
            setIsJDload(false)
            console.error("Error uploading file:", error);
            if (error?.response?.data?.error) {
                message.error(error?.response?.data?.error)
                storage.clearStorage()
                navigate('/');
            }
            error?.response?.data?.msg && message.error(error?.response?.data?.msg)
            error?.response?.data?.message && message.error(error?.response?.data?.message)
        }
    };


    const gotodashboard = () => {
        navigate('/dashboard');
        dispatch(setIsVrifySuccess(false));
    }


    return (
        <div>
            <Form
                // className={styles.loginForm} 
                onFinish={onSubmitLogoandJD}>
                <Form.Item>
                    <Input
                        suffix={
                            <Space.Compact style={{ width: '100%', margin: '0px' }}>
                                <Upload
                                    {...props}
                                    id="file-upload-input"
                                    fileList={fileList}
                                    showUploadList={false}
                                    style={{ display: 'none' }}
                                >
                                    <Button className='fullwidth' icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Space.Compact>
                        }
                        placeholder={fileName ? fileName : 'JD upload (optional)'}
                        onClick={handleInputClick}
                        readOnly
                    />
                </Form.Item>

                <Form.Item>
                    <Input
                        suffix={
                            <Space.Compact style={{ width: '100%', margin: '0px' }}>
                                <Upload
                                    {...orgLogoProps}
                                    id="file-org-logo"
                                    fileList={orgLogoList}
                                    showUploadList={false}
                                    style={{ display: 'none' }}
                                >
                                    <Button className='fullwidth' icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Space.Compact>
                        }
                        placeholder={orgLogoName ? orgLogoName : 'Organization logo (optional)'}
                        onClick={handleOrgLogoInputClick}
                        readOnly
                    />
                </Form.Item>

                <div className='flex-space-between'>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    <a className={`error-text ${styles.loginFormForgot}`} href="">
                        Forgot password?
                    </a>
                </div>

                <Form.Item>
                    <Button
                        shape="round"
                        size="large"
                        type="primary"
                        htmlType="submit"
                        // onClick={next}
                        loading={isJDload}
                        className={`default-button-style ${styles.loginFormButton}`}
                    >
                        Submit
                    </Button>
                    {/* } */}
                </Form.Item>
                <div className="text-center">
                    <p>
                        Already have an account?{' '}
                        <span onClick={goToSignInPage} className={styles.backtosignup}>
                            Click here to login
                        </span>
                    </p>
                </div>
            </Form>

            {/* verifySuccess modal */}
            <Modal closable={false} centered width={600} footer={false} open={isVerifySuccess}>
                <div>Your signup request has been submitted. A representative from <Typography.Text onClick={gotodashboard} className='green-text' italic underline strong>“Kavish Technologies”</Typography.Text> will review and approve it shortly.</div>
                <br />
                <div>Please allow some time for this process to be completed.</div>
                <Button className='fullwidth dark-bg margin-top-10' size='large' shape='round' loading={isVerifySuccess}>Redirecting...</Button>
                {/* <Button type='dashed' variant='dashed' size='large' shape='round' className={`margin-top-10 fullwidth ${styles.loginModalBtn}`} onClick={goToOrgLogin}>Click here to login</Button> */}
            </Modal>
        </div>
    )
}

export default OrganizationNextStep