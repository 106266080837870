import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Input, Row, Select, Button, DatePicker, Divider, Modal, message, Space, Tag, Badge, List, Typography } from 'antd';
import styles from './style.module.scss';
import { CalendarOutlined, EditOutlined, ExportOutlined, GithubOutlined, LinkedinOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { genrate_login_otp_user_Info, setIsEmailVerify, setIsLanguageAdd, setIsMobileVerify, setverifyType, update_jobseeker, verify_otp__user_Info } from '../../features/UserInfo/userInfoSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import AddLanguage from './AddLanguage';
import { formatDOBDate } from '../../utils/helpers';
import dayjs from 'dayjs';
import AddSocialLinks from './AddSocialLinks';
import { setUserProfile } from '../../features/Global/globalSlice';
import PhoneNumberInput from './PhoneNumberInput';
import { get_profile_percentage } from '../../features/Jobseeker/jobseekerSlice';

const { Option } = Select;

const PersonalDetails = ({ onNext }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isEmailVerify, isMobileVerify, verifyLoading, isGenerateOTPLoad, verifyType, isOtpSubmit, isLanguageAdd } = useSelector((state) => state.userInfo);
  const { userProfile } = useSelector((state) => state.global)
  const { verifyOTPLoad, genrateOTPload } = useSelector((state) => state.auth);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailLoader, setEmailLoader] = useState(genrateOTPload);
  const [mobileLoader, setMobileLoader] = useState(genrateOTPload);
  const [countdown, setCountdown] = useState(300); // 5 minutes = 300 seconds
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [isVisible, setIsVisivle] = useState(false)
  const [defaultCountry, setDefaultCountry] = useState('in');
  const [isEdit, setIsEdit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSociallink, setCurrentSociallink] = useState('')


  const onEditfield = () => {
    setIsEdit(true)
  }

  // useEffect(() => {
  //   if (userProfile?.mobile) {
  //     const mobile = userProfile.mobile;
  //     const countryCode = userProfile.countryCode || '+91';

  //     setPhoneNumber(mobile);
  //     setDefaultCountry(countryCode.slice(1).toLowerCase());
  //   }
  // }, [userProfile]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // const onValuesChange = (changedValues, allValues) => {

  //   const { dob, ...rest } = allValues;
  //   const userData = {
  //     ...rest,
  //     dob: dob ? dayjs(dob).format('YYYY-MM-DD') : dob,
  //   };
  //   dispatch(setUserProfile(userData));
  // };

  const onValuesChange = (changedValues, allValues) => {
    const { dob, ...rest } = allValues;
    const phone = `${userProfile.countryCode || ''}${userProfile.mobile || ''}`;
  
    // Preserve phoneNumber state
    const userData = {
      ...rest,
      phoneNumber:phone, // Preserve the current phoneNumber
      dob: dob ? dayjs(dob).format('YYYY-MM-DD') : dob,
    };
  
    // dispatch(setUserProfile(userData));
  };
  

  const onEmailVerify = () => {
    const { email } = form.getFieldsValue();
    if (!email) {
      message.error('Please enter your email');
      return;
    }
    const payload = { email };
    dispatch(setverifyType('email'))
    dispatch(genrate_login_otp_user_Info({ body: payload, navigate, isMobile: false, isEmail: true }));
    setCountdown(300);
    setIsResendDisabled(true);
  };

  const onMobileVerify = () => {
    const countryCode = `+${phoneNumber.slice(0, phoneNumber?.length - 10)}`;
    const mobile = phoneNumber.slice(-10);
    if (!mobile) {
      message.error('Please enter your mobile number');
      return;
    }
    const payload = { mobile, countryCode };
    dispatch(setverifyType('mobile'))
    dispatch(genrate_login_otp_user_Info({ body: payload, navigate, currentStep: 0, isMobile: true, isEmail: false }));
    setCountdown(300); // Reset countdown to 5 minutes
    setIsResendDisabled(true);
  };

  const onSubmitOTP = () => {
    const { emailOtp, mobileOtp } = form.getFieldsValue();
    const mobileOtpPayload = { mobileOtp };
    const payload = { emailOtp };
    dispatch(verify_otp__user_Info({ body: isEmailVerify ? payload : mobileOtpPayload, navigate }));
  };

  const onResendOTP = () => {
    isEmailVerify ? onEmailVerify() : onMobileVerify();
  };

  useEffect(() => {
    const data = formatDOBDate(userProfile?.dob)
    const formattedDate = userProfile?.dob ? dayjs(userProfile?.dob).format('YYYY-MM-DD') : null;
    let mobileNumber = userProfile?.countryCode + userProfile?.mobile
    if (userProfile) {
      form.setFieldsValue({
        name: userProfile.name,
        email: userProfile.email,
        dob: userProfile?.dob ? dayjs(formattedDate) : null,
        mobile: mobileNumber,
        linkedin: userProfile?.linkedin,
        github: userProfile?.github,
        behance: userProfile?.behance,
        workPermit: userProfile?.workPermit,
        currentAddress: userProfile?.currentAddress,
        permanentAddress: userProfile?.permanentAddress
      });
    }
  }, [userProfile]);

  const onClose = () => {
    setIsVisivle(false)
  }

  const onSave = () => {

  }

  const onOpenLanguage = () => {
    setIsVisivle(true)
  }

  const showAddSocial = () => {
    setIsModalVisible(true);
  };

  const onSaveAndNext = () => {
    const countryCode = phoneNumber?.startsWith('+')
      ? phoneNumber.slice(0, phoneNumber.length - 10) // No need to add another '+' if it's already there
      : `+${phoneNumber.slice(0, phoneNumber.length - 10)}`; // Add '+' if not present
    const mobile = phoneNumber?.slice(-10);
    dispatch(setIsLanguageAdd(false))
    form.validateFields().then(values => {
      // Format the DOB value
      const payload = {
        email: values?.email,
        workPermit: values?.workPermit,
        permanentAddress: values?.permanentAddress,
        dob: values?.dob,
        currentAddress: values?.currentAddress,
        countryCode: countryCode,
        mobile: mobile
      }
      if (values.dob) {
        values.dob = moment(values.dob).format('YYYY-MM-DD'); // Format DOB
      }
      values.countryCode = countryCode;
      values.mobile = mobile;
      // Remove the fields you don't want in the payload (e.g., name, gender, dob)
      delete values.name;
      delete values.gender;
      const errors = form.getFieldsError();
      // Check if any errors exist
      const hasError = errors.some(field => field.errors?.length > 0);

      if (hasError) {
        message.error("Please fix the errors before proceeding.");
      } else {
        dispatch(setIsLanguageAdd(false))
        dispatch(update_jobseeker({ body: payload, navigate: navigate }));
        onNext()
        dispatch(get_profile_percentage(navigate))
        // Proceed to next action (e.g., save the data, go to the next page, etc.)
      }
    }).catch(() => {
      // message.error("Please fill out all the required fields2.");
    });
    // }
  };

  const proficiencyColors = {
    Beginner: 'red',
    Intermediate: 'orange',
    Expert: 'green'
  }

  const onRemoveLanguage = (id) => {
    // Filter out the language to remove
    const updatedLanguages = userProfile?.languages?.filter((lang) => lang?._id !== id);

    // Prepare the payload
    const payload = {
      languages: updatedLanguages, // Update the languages field
    };

    // Dispatch the action to update the user's profile
    dispatch(update_jobseeker({ body: payload, navigate }));

    // Optionally, show a success message
    message.success('Language removed successfully!');
  };

  
  return (
    <div>
      <Card>
        <div>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onValuesChange}
          // onFinish={onSaveAndNext}
          >
            <div className='flex-space-between'>
              <div>
                <h3>Personal Details</h3>
              </div>
              <div>
                {
                  isEdit ?
                    <Button
                      type="default"
                      icon={<EditOutlined />}
                      size="middle"
                      style={{ position: 'relative', bottom: '10px' }}
                      className='dark-bg'
                      htmlType='submit'
                      onClick={() => onSaveAndNext()}
                    >
                      Save & Next
                    </Button>

                    :
                    <Tag
                      type="default"
                      icon={<EditOutlined />}
                      className={`dark-bg ${styles.editPDBtn}`}
                      onClick={onEditfield}
                      htmlType='button'
                    >
                      Edit Profile
                    </Tag>
                }
              </div>
            </div>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Full Name"
                  name="name"
                // rules={[{ required: true, message: 'Please enter your full name' }]}
                >
                  <Input disabled size='large' suffix={<CalendarOutlined />}
                    placeholder="Amit Kumar"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                // rules={[
                //   { required: true, message: 'Please enter your email' },
                //   { type: 'email', message: 'Please enter a valid email' },
                // ]}

                >
                  <Input disabled={userProfile?.isEmailVerified} suffix={
                    userProfile?.isEmailVerified ? <Button type='text' className='green-text'>verified</Button> :
                      <Button loading={verifyType === 'email'} onClick={onEmailVerify} size='middle' shape='round' danger type='primary'>Verify</Button>
                  } placeholder="example@gmail.com" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Work Permit"
                  name="workPermit"
                  rules={[{ required: true, message: 'Please select your work permit' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' mode="multiple" placeholder="Select work permits">
                    <Option value="Indian">Indian</Option>
                    <Option value="Other">Other</Option>
                    <Option value="US">US</Option>
                    <Option value="UK">UK</Option>
                    <Option value="Canada">Canada</Option>
                  </Select>
                </Form.Item>

              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Permanent Address"
                  name="permanentAddress"
                  rules={[{ required: true, message: 'Please enter your address' }]}
                >
                  <Input disabled={isEdit ? false : true} size='large' placeholder="New Delhi, India, 110001" />
                </Form.Item>
              </Col>
              {/* <Col lg={12} md={12} xs={24}>
                <Form.Item
                  style={{ width: '100%' }}
                  label={''}
                  name="password"
                // rules={[{ required: false, message: 'Please enter your password' }]}
                >
                  <label><div className={`flex-space-between ${styles.customeLabel}`}>
                    <div>Password</div>
                    <Button size='small' className='border-none' type='link' danger>Change Password</Button>

                  </div></label>
                  <Input size='large' placeholder="********" />
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Date of Birth"
                  name="dob"
                  rules={[{ required: true, message: 'Please select your date of birth' }]}
                >
                  <DatePicker disabled={isEdit ? false : true} size='large' suffixIcon={<CalendarOutlined />} placeholder="dd/mm/yyyy" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Current Address"
                  name="currentAddress"
                  rules={[{ required: true, message: 'Please enter your current address' }]}
                >
                  <Input disabled={isEdit ? false : true} size='large' placeholder="Delhi India, 110001" />
                </Form.Item>
              </Col>
              {/* <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Gender"
                  name="gender"
                // rules={[{ required: true, message: 'Please select your gender' }]}

                >
                  <Select disabled size='large'>
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>

            <Row gutter={[24, 24]}>
              <Col lg={16} md={16} xs={24}>
                <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} setCountdown={setCountdown} setIsResendDisabled={setIsResendDisabled} />
              </Col>
            </Row>
            {/* <Row gutter={[24, 24]}>
              
            </Row> */}
            {/* <h3>Personalized experiance</h3>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Job alerts"
                  name="jobalerts"
                  rules={[{ required: true, message: 'Please select your job alerts' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' placeholder="Select work permits">
                    <Option value="enable">Enable</Option>
                    <Option value="disable">Disable</Option>

                  </Select>
                </Form.Item>

              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Email Notifications"
                  name="email-notifications"
                  rules={[{ required: true, message: 'Please select notification' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' placeholder="Select work permits">
                    <Option value="enable">Enable</Option>
                    <Option value="disable">Disable</Option>

                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Privacy settings"
                  name="privacy-settings"
                  rules={[{ required: true, message: 'Please select privacy' }]}
                >
                  <Select disabled={isEdit ? false : true} size='large' placeholder="Select work permits">
                    <Option value="Profile-visible-recruiters">Profile visible to recruiters</Option>
                    <Option value="private">Private</Option>

                  </Select>
                </Form.Item>
              </Col>
            </Row> */}

            <Divider />
            <Row gutter={[24, 24]}>
              <Col lg={12} md={12} xs={24}>
                <div className="flex-space-between">
                  <h3>Language</h3>
                  {!userProfile?.languages?.length ? null :
                    <div>
                      <Button onClick={onOpenLanguage} className="dark-bg">Add</Button>
                    </div>
                  }
                </div>
                {!userProfile?.languages?.length ? (
                  <Card className={styles.languageAddbtn}>
                    <Button
                      disabled={!isEdit}
                      onClick={onOpenLanguage}
                      size="large"
                      className="fullwidth"
                      icon={<PlusOutlined />}
                    >
                      Please Add Language
                    </Button>
                  </Card>
                )
                  : <>
                    {userProfile?.languages?.map((lang) => (
                      <Card
                        key={lang.language}
                        className={`${styles.langcard}`}
                        title={lang?.language}
                        extra={
                          <Button onClick={() => onRemoveLanguage(lang?._id)} shape="round" size="small" className="dark-bg">Remove</Button>
                        }
                      >
                        <Row>
                          <Col xs={24} lg={8} md={8}>
                            <List>
                              <List.Item>
                                <List.Item.Meta
                                  title="Read"
                                  description={
                                    <Tag color={proficiencyColors[lang?.proficiency?.read]}>
                                      {lang?.proficiency?.read}
                                    </Tag>
                                  }
                                />
                              </List.Item>
                            </List>
                          </Col>
                          <Col xs={24} lg={8} md={8}>
                            <List>
                              <List.Item>
                                <List.Item.Meta
                                  title="Write"
                                  description={
                                    <Tag color={proficiencyColors[lang?.proficiency?.write]}>
                                      {lang?.proficiency?.write}
                                    </Tag>
                                  }
                                />
                              </List.Item>
                            </List>
                          </Col>
                          <Col xs={24} lg={8} md={8}>
                            <List>
                              <List.Item>
                                <List.Item.Meta
                                  title="Speak"
                                  description={
                                    <Tag color={proficiencyColors[lang?.proficiency?.speak]}>
                                      {lang?.proficiency?.speak}
                                    </Tag>
                                  }
                                />
                              </List.Item>
                            </List>
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </>
                }
              </Col>
              <AddLanguage visible={isVisible} onClose={onClose} onSave={onSave} />

              <Col lg={12} md={12} xs={24}>
                <div className='flex-space-between'>
                  <h3>Social Links</h3>
                  {userProfile?.github || userProfile.linkedin ? <div><Button onClick={showAddSocial} className='dark-bg'>Add</Button></div> : null}
                </div>
                {
                  userProfile?.behance && <Form.Item
                    label="Behance"
                    name="behance"
                    rules={[{ required: true, message: 'Please select your proficiency' }]}
                  >
                    <Input disabled={isEdit ? false : true} className='text-link' defaultValue={"https://www.behance.net/amit007fd"} suffix={<ExportOutlined />} />
                  </Form.Item>
                }
                {
                  userProfile?.github ? <Form.Item
                    label="github"
                    name="github"
                    rules={[{ required: true, message: 'Please select your proficiency' }]}
                  >
                    <Input disabled={isEdit ? false : true} className='text-link' defaultValue={"https://www.behance.net/amit007fd"} suffix={<ExportOutlined />} />
                  </Form.Item>
                    :
                    <Card className='margin-top-10'>
                      <Row justify={'center'}>
                        <Col xs={24}>
                          <Button type='dashed' shape='round' onClick={() => {
                            showAddSocial()
                            setCurrentSociallink('github')
                          }} className='fullwidth noDataAddBtn' size='large' icon={<GithubOutlined />}>
                            Add Github Link
                          </Button></Col>
                      </Row>
                    </Card>
                }
                {userProfile?.linkedin ?
                  <Form.Item
                    label="LinkedIn"
                    name="linkedin"
                    className='margin-top-10'
                    rules={[{ required: true, message: 'Please select your proficiency' }]}
                  >
                    <Input disabled={isEdit ? false : true} className='text-link' defaultValue={"https://www.behance.net/amit007fd"} suffix={<ExportOutlined />} />
                  </Form.Item>
                  :
                  <Card className='margin-top-10'>
                    <Row justify={'center'}>
                      <Col xs={24}>
                        <Button type='dashed' shape='round' onClick={() => {
                          showAddSocial()
                          setCurrentSociallink('linkedin')
                        }} className='fullwidth noDataAddBtn' size='large' icon={<LinkedinOutlined />}>
                          Add Linkedin Link
                        </Button></Col>
                    </Row>
                  </Card>
                }
              </Col>
            </Row>
            <Modal
              width={300}
              centered
              footer={false}
              onCancel={() => {
                // window.location.reload()
                setIsEmailVerify(false)
                dispatch(setIsMobileVerify(false))
                dispatch(setIsEmailVerify(false))
              }}
              title={isEmailVerify ? 'Email Verification' : 'Mobile Number Verification'}
              open={isEmailVerify || isMobileVerify}
            >
              <Form.Item name={isEmailVerify ? 'emailOtp' : 'mobileOtp'} label="Enter OTP">
                <Input placeholder="Enter your OTP here" />
              </Form.Item>
              <div className='error-text' style={{ marginBottom: '20px', lineHeight: '1px' }}>
                OTP expires in  <span>{formatTime(countdown)}</span>
              </div>
              <div className={styles.otpverificationDiv}>
                <Button
                  loading={isOtpSubmit}
                  onClick={onSubmitOTP}
                  className="fullwidth default-button-style"
                >
                  Submit
                </Button>
                <Button
                  onClick={onResendOTP}
                  loading={verifyType === 'email' || verifyType === 'mobile'}
                  disabled={isResendDisabled}
                  className="fullwidth default-button-style"
                >
                  Resend OTP
                </Button>
              </div>
            </Modal>
          </Form>
        </div>
      </Card>
      <AddSocialLinks currentSociallink={currentSociallink} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
    </div>
  );
};

export default PersonalDetails;
