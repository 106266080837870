import { createBrowserRouter } from "react-router-dom";
import React from 'react';
import { useRoutes } from 'react-router-dom';
import HomePage from '../pages/Homepage/HomePage';
import { AppRoutes } from './AppRoutes';
import Login from '../components/Auth/Login';
import LandingPage from '../pages/LandingPage/LandingPage';
import SignUp from '../components/Auth/SignUp';
import OtpVerification from '../components/Auth/OtpVerification';
import MainLayout from '../components/Layout/MainLayout';
import FindJobPageWithFilter from '../pages/FindJobPage/FindJobPageWithFilter';
import UserInfo from '../pages/UserInfoPage/UserInfo';
import JobDetails from '../pages/FindJobPage/JobDetails';
import SignupOtp from '../components/Auth/SignupOtp';
import RecommendedJobs from '../pages/RecommendedJobs/RecommendedJobs';
import SavedJobs from '../pages/SaveJobs/SavedJobs';
import Blogs from '../pages/Blogs/Blogs';
import RefrencePeople from '../pages/ReferencePeople/ReferencePeople';
import { getAuthToken } from "../utils/authHelpers";
import Setting from "../pages/SettingPage/Setting";
import Dashboard from "../pages/Organizations/Dashboard/Dashboard";
import OrganizationInfo from "../pages/Organizations/OrganizationInfo/OrganizationInfo";
import JobStatus from "../pages/Organizations/JobStatus/JobStatus";
import Analytics from "../pages/Organizations/Analytics/Analytics";
import OrgSettings from "../pages/Organizations/Settings/Settings";
import CandidateDetails from "../pages/Organizations/JobStatus/CandidateDetails";
import CandidateProfile from "../pages/Organizations/RecommendCandidate/CandidateProfile";
import RecommendCandidate from "../pages/Organizations/RecommendCandidate/RecommendCandidate";
import FindJobApplicationPage from "../pages/FindJobPage/FindJobApplicationPage";
import SearchedJobs from "../components/CommonElements/SearchedJobs";
import UserCalender from "../pages/Calender/UserCalender";
import PageNotFound from "../components/PageNotFound/PageNotFound";
// const Chat = React.lazy(() => import("./chatPage/ChatPage"));
const Redirect = React.lazy(() => import("../components/redirect/Redirect"));
const token = getAuthToken()
const routes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    SideNav: false,
    nav:true
  },
    //  {
    //   path: AppRoutes.HOME,
    //   element:<LandingPage />,
    //   SideNav: false,
    // },
    {
      path: AppRoutes.LOGIN,
      element:  <Login/>,
      SideNav: false,
      nav:false
    },
    {
      path: AppRoutes.SIGN_UP,
      element:  <SignUp/>,
      SideNav: false,
      nav:false
    },
    {
      path: AppRoutes.OTP_VERIFICATION,
      element:  <OtpVerification/>,
      SideNav: false,
      nav:false
    },
    {
      path: AppRoutes.FIND_JOB,
      element: <FindJobPageWithFilter/>,
      SideNav: token ? true : false,
      nav:true,
    },
    {
      path: AppRoutes.APPLICATION_STATUS,
      element: <FindJobApplicationPage/>,
      SideNav: true,
      nav:true
    },
    {
      path: AppRoutes.USER_INFO,
      element: <UserInfo/>,
      SideNav: true,
      nav:true
    },
    {
      path: AppRoutes.SPECIFIC_JOB_DETAILS,
      element: <JobDetails/>,
      SideNav: token ? true : false,
      nav:true
    },
    {
      path: AppRoutes.SIGN_UP_OTP_VERIFICATION,
      element: <SignupOtp/>,
      SideNav: false,
      nav:true
    },
    {
      path: AppRoutes.RECOMENDED_JOBS,
      element: <RecommendedJobs/>,
      SideNav: true,
      nav:true
    },
    {
      path: AppRoutes.SAVE_JOBS,
      element: <SavedJobs/>,
      SideNav: true,
      nav:true
    },
    {
      path: AppRoutes.BLOGS,
      element: <Blogs/>,
      SideNav: true,
      nav:true
    },
    {
      path: AppRoutes.REFERENCE_PEOPLE,
      element: <RefrencePeople/>,
      SideNav: true,
      nav:true
    },
    {
      path: AppRoutes.SETTING,
      element: <Setting/>,
      SideNav: true,
      nav:true
    },

    {
      path: AppRoutes.DASHBOARD,
      element: <Dashboard/>,
      SideNav: true,
      nav:true,
    },
    {
      path: AppRoutes.ORGANIZATION_INFO,
      element: <OrganizationInfo/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.JOB_STATUS,
      element: <JobStatus/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.RECOMMEND_CANDIDATE,
      element: <RecommendCandidate/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.ANALYTICS,
      element: <Analytics/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.ORG_SETTINGS,
      element: <OrgSettings/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.CANDIDATE_DETAILS,
      element: <CandidateDetails/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.RECOMMEND_CANDIDATE_PROFILE,
      element: <CandidateProfile/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.FIND_JOB_CALENDER,
      element: <UserCalender/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.JOBLISTING,
      element: <SearchedJobs/>,
      SideNav:true,
      nav:true,
    },
    {
      path: AppRoutes.NOTEXIST,
      element: <PageNotFound/>,
      SideNav:false,
      nav:false,
    },
]);

export default routes;
