import React, { useState } from "react";
import { Table, Input, Select, Button, Tag, Checkbox, Card } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./style.module.scss";
import FindJobPageLayout from "../../FindJobPage/FindJobPageLayout";
import { getAuthToken } from "../../../utils/authHelpers";
import { Link } from "react-router-dom";

const { Option } = Select;

const RecommendCandidate = () => {
    const token = getAuthToken();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    // Sample Data
    const data = [
        {
            key: "1",
            id: "100001",
            name: "Amit Kumar",
            company: "Accenture India",
            experience: "4.5+ years",
            education: "01-09-2024 to 31-09-2024",
            relevance: "Matched",
        },
        {
            key: "2",
            id: "100002",
            name: "Rahul Sharma",
            company: "TCS",
            experience: "3+ years",
            education: "01-06-2024 to 31-06-2024",
            relevance: "Not-Matched",
        },
        {
            key: "3",
            id: "100003",
            name: "Priya Singh",
            company: "Wipro",
            experience: "5+ years",
            education: "01-05-2024 to 31-05-2024",
            relevance: "Matched",
        },
    ];

    // Table Columns
    const columns = [
        {
            title: (
                <Checkbox
                    indeterminate={indeterminate}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    checked={checkAll}
                />
            ),
            dataIndex: "checkbox",
            render: (_, record) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.key)}
                    onChange={(e) => handleRowSelection(e.target.checked, record.key)}
                />
            ),
        },
        {
            title: "Candidate Id",
            dataIndex: "id",
            render: (text) => <Link to={`/candidate-profile/${text}`}>{text}</Link>,
        },
        {
            title: "Candidate Name",
            dataIndex: "name",
        },
        {
            title: "Current Company",
            dataIndex: "company",
        },
        {
            title: "Experience",
            dataIndex: "experience",
        },
        {
            title: "Education",
            dataIndex: "education",
        },
        {
            title: "Relevance",
            dataIndex: "relevance",
            render: (text) =>
                text === "Matched" ? (
                    <Tag color="green">Matched</Tag>
                ) : (
                    <Tag color="red">Not-Matched</Tag>
                ),
        },
    ];

    const handleRowSelection = (isSelected, key) => {
        const updatedKeys = isSelected
            ? [...selectedRowKeys, key]
            : selectedRowKeys.filter((rowKey) => rowKey !== key);

        setSelectedRowKeys(updatedKeys);
        setIndeterminate(
            updatedKeys.length > 0 && updatedKeys.length < data.length
        );
        setCheckAll(updatedKeys.length === data.length);
    };

    const handleSelectAll = (isSelected) => {
        if (isSelected) {
            const allKeys = data.map((item) => item.key);
            setSelectedRowKeys(allKeys);
            setIndeterminate(false);
            setCheckAll(true);
        } else {
            setSelectedRowKeys([]);
            setIndeterminate(false);
            setCheckAll(false);
        }
    };

    return (
        <FindJobPageLayout>
            <div className={token ? styles.filterJobWrapper : styles.filterJobNoToken}>
                <div className={styles.jobStatusContainer}>
                    {/* Search Filters */}
                    <div>
                        <Card>
                            <div className={styles.searchFilters}>
                                <Input
                                    placeholder="Candidate Id, name, experience, skills"
                                    prefix={<SearchOutlined />}
                                    className={`fullwidth ${styles.searchBox}`}
                                />
                                <Select
                                    placeholder="Experience"
                                    className={`fullwidth ${styles.searchBox}`}
                                >
                                    <Option value="0-2">0-2 Years</Option>
                                    <Option value="2-5">2-5 Years</Option>
                                    <Option value="5+">5+ Years</Option>
                                </Select>
                                <Select
                                    placeholder="Relevance"
                                    className={`fullwidth ${styles.searchBox}`}
                                >
                                    <Option value="matched">Matched</Option>
                                    <Option value="not-matched">Not-Matched</Option>
                                </Select>

                                <Button
                                    size="large"
                                    className={`fullwidth default-button-style ${styles.searchBox}`}
                                    type="primary"
                                >
                                    Search
                                </Button>
                            </div>
                        </Card>

                        <div className={styles.tableWrapper}>
                            <Card
                                className={styles.recommentTableHeading}
                                title="List of Candidates"
                                extra={
                                    <div>
                                        <div className={styles.actionButtons}>
                                            <Button
                                                className="default-button-style"
                                                type="primary"
                                                style={{ marginRight: "10px" }}
                                            >
                                                Invite
                                            </Button>
                                            <Button
                                                iconPosition="start"
                                                icon={<DeleteOutlined />}
                                                className="dark-bg"
                                                type="danger"
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                }
                            />
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                                rowKey="key"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FindJobPageLayout>
    );
};

export default RecommendCandidate;
