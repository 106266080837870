import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import QRCode from "react-qr-code";

const AddQRScanner = ({ setStep, onNext }) => {
    const handlePaymentContinue = () => {
        onNext()
    }
    return (
        <div>
            <div className="margin-top-10 text-center">
                <p>Scan the QR Code below to make the payment:</p>
                <div className="margin-top-10"> <QRCode value="https:paymentgateway.com/unique-code" /></div>
                <div>
                    <Row justify="end" style={{ marginTop: '20px' }}>
                        <Col xs={24} md={6} lg={6}>
                            <Button
                                shape='round'
                                icon={<ArrowRightOutlined />}
                                iconPosition='end'
                                type="primary"
                                onClick={() => handlePaymentContinue()}
                                className='fullwidth default-button-style margin-top-10'
                            >
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default AddQRScanner