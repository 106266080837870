import { Col, Grid, Image, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React from 'react'
import styles from './style.module.scss'
import bannerTopImg from '../../assets/Images/bannerTopImg.png'

const HomePage = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <Content className={styles.landingPageWrapper}>
      <div className={styles.homepageWrapper}>
        <Row >
          <Col xs={24} xl={14} lg={14}>
            <div className={styles.contentWrapper}>
              <div className={styles.bannerHeading}>
                Discover <span className={styles.highlitedText}> 5000+ Opportunities</span> for Your Next Career Move!
              </div>
              <Row>
                <Col xs={24} lg={18} md={18}>
                  <p className={styles.bannerParagrah}>Unlock a world of possibilities with more than 500+ job openings across diverse industries and skill sets. Whether you're just starting out or looking to take the next big step in your career, we’ve got you covered.</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} xl={7} lg={7}>
            <Image
              width={screens.sm ? 500 : '100%'}
              height={screens.sm ? 500 : '100%'}
              src={bannerTopImg}
              preview={{
                src: bannerTopImg,
              }}
              style={{
                objectFit: 'cover', 
              }}
            />
          </Col>
        </Row>
      </div>
    </Content>
  )
}

export default HomePage