import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AppLayout from './pages/LandingPage/AppLayout';
import routes from './routes';
import AppSecureStorage from './services/storage/secureStorage';
// const storage = new AppSecureStorage()

function App() {

  // useEffect(()=>{
  //   storage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QzQHlvcG1haWwuY29tIiwicm9sZSI6ImpvYlNlZWtlciIsIm5hbWUiOiJ0ZXN0MyIsInVzZXJJZCI6IjY3MzlmOTM4ODNlMjk0MGFmMDkzZGI4MyIsImlhdCI6MTczMzE0NDM2NCwiZXhwIjoxNzMzMTUxNTY0fQ.bHcD-22mU2snmFxxgpu9C31vMtIfcIYge-CMg3lje1o')
  // },[])

  return (
    <BrowserRouter>
      <Suspense fallback={"Loading..."}>
        <AppLayout routers={routes.routes.map((item) => item)} />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

