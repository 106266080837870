import React, { useState } from 'react'
import styles from './style.module.scss'
import { Avatar, Button, Card, Col, Divider, List, Row, Tag } from 'antd'
import JobList from './JobList'
import { ArrowLeftOutlined, HeatMapOutlined, LockOutlined, SaveOutlined, ShareAltOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import { Popover, Steps } from 'antd';
import { useSelector } from 'react-redux';
import { timeSincePosted } from '../../utils/helpers';

const ContainerHeight = 500;
const InterviewRating = ({ onBack, onButtonClick, page, buttonText, loadingJobId, submitload }) => {
    const { userProfile, currentJobStatus } = useSelector((state) => state.global);
    const { savedJobs } = useSelector((state) => state.jobseeker);

    // Validation for currentJobStatus
    const isCurrentJobStatusValid = currentJobStatus && typeof currentJobStatus === 'object' &&
        currentJobStatus?.jobTitle;

    const isJobApplied = userProfile?.appliedJobs?.some((job) => job?.jobId === currentJobStatus?._id);
    const isJobSaved = savedJobs?.some((job) => job?._id === currentJobStatus?._id);
    const currentButtonText = isJobApplied ? 'Applied' : buttonText;

    const [current, setCurrent] = useState(0);

    const next = () => {
        if (current < 1) {
            setCurrent(current + 1);
        }
    };

    const dummyData =
    {
        "results": [{
            "gender": "male", "name": {
                "title": "Mr", "first": "Customer Support", "last": "Product Designer",
            },
            "duration": ['full-time', '0-1 year', '1-2 year'],
            "howLong": "posted 3 days ago",
            "Description": "Lorem Ipsum has been the industry's standard dummy tex a type e industry's standard dummy tex a type", "picture": { "large": "https://randomuser.me/api/portraits/men/87.jpg", "medium": "https://randomuser.me/api/portraits/med/men/87.jpg", "thumbnail": "https://randomuser.me/api/portraits/thumb/men/87.jpg" }, "nat": "IR"
        }]
    }

    const [data, setData] = useState(dummyData.results);
    const onScroll = (e) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            // appendData();
        }
    };


    const customDot = (dot, { status, index }) => (
        <Popover
            content={
                <span>
                    step {index} status: {status}
                </span>
            }
        >
            {dot}
        </Popover>
    );
    const description = 'You can hover on the dot.';
    return (
        <div>
            <div className={styles.cardStyleJob}>
                <div>
                    <div className={styles.cardStyleJob}>
                        <Card title={<Button className='font-w-700' variant='text' type='text' icon={<Tag onClick={onBack}><ArrowLeftOutlined /></Tag>}
                        >Job Status</Button>} extra={
                            isCurrentJobStatusValid &&
                            <Button
                                shape='round'
                                className={page === "application" || page === "recommend" ? isJobApplied ? 'disableApplyBtn' : `default-button-style ` : 'default-button-style'}
                                size='large'
                                onClick={() => onButtonClick(currentJobStatus)}
                                loading={loadingJobId === currentJobStatus?._id ? submitload : false}
                                disabled={page === "application" || page === "recommend" ? isJobApplied ? true : false : false}
                            >
                                {page === "application" || page === "recommend" ? currentButtonText : buttonText}
                            </Button>
                        }>
                            {!isCurrentJobStatusValid ? (
                                <Row justify={'center'}>
                                    <Col xs={24} lg={6} md={6}>
                                        <Button className='default-button-style fullwidth' onClick={onBack} type="primary">Back to jobs</Button>
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <div className='flex-space-between'>
                                        <div className='flex-wrap-row-direction-gap-5'>
                                            <div><Avatar className='default-button-style' size={'large'} src={userProfile?.avatar} /></div>
                                            <div className='font-w-700 font-sz-18'>{currentJobStatus?.jobTitle}</div>
                                        </div>
                                        <Tag color='blue'><div>{timeSincePosted(currentJobStatus?.createdAt)}</div></Tag>
                                    </div>
                                    <div>
                                        <h3>Application Status</h3>
                                        <div>
                                        <Steps current={1} labelPlacement="vertical" items={[{
                                                // title: 'Applied',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'viewed',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'In Review',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'Interview Scheduled',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'Offer Extended',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            ]} />
                                        </div>
                                        <div>
                                            <h3>Job Description</h3>
                                            <div className='green-text font-bold'>{currentJobStatus?.description}</div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Card>
                    </div>
                </div>
                {/* <Card>
                    <List>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="email"
                            onScroll={onScroll}
                            style={{ width: '100%' }}
                        >
                            {(item) => (
                                <>
                                    <List.Item key={item.email}>
                                        <List.Item.Meta
                                            avatar={<Avatar size={90} shape='square' src={item.picture.large} />}
                                            title={<div><a href="https://ant.design">{item.name.last}</a>
                                                <div>Figma : {item.duration?.map((ele) => <Tag>{ele}</Tag>)}
                                                </div>
                                            </div>}
                                            description={item.Description}
                                        />
                                        <div className='text-right'>
                               
                                            <div>
                                                <div className=''>
                                                   
                                                    <div className={styles.rightAlign} md={5} lg={5} xs={24}>
                                                        <Button shape='round' className={`default-button-style ${styles.rightAlign}`}>Applied Today</Button></div>
                                                </div>
                                                <div>Recuruiter active : 3 weeks ago</div>
                                            </div>
                                        </div>
                                    </List.Item>
                                    <hr />
                                    <div>
                                        <h3>Application Status</h3>
                                        <div>
                                            <Steps current={1} labelPlacement="vertical" items={[{
                                                // title: 'Applied',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'viewed',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'In Review',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'Interview Scheduled',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            {
                                                // title: 'Offer Extended',
                                                // description: `dd/mm/yyyy | 00:00 AM`,
                                            },
                                            ]} />
                                        </div>
                                        <div>
                                            <h3>Job Description</h3>
                                            <div className='green-text font-bold'>23/14 application reviewd</div>
                                        </div>
                                        <div>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                                        </div>
                                    </div>
                                </>
                            )}
                        </VirtualList>
                    </List>

                </Card> */}
            </div>
        </div>
    )
}

export default InterviewRating