import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Row, Col, Select } from 'antd';
import PhoneInput from 'react-phone-input-2';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Update_organization } from '../../../features/Organization/OrganizationInfo/OrganizationInfoSlice';
import { Option } from 'antd/es/mentions';

const OrgProfileEditor = ({ setIsTopProfileEdit, isTopProfileEdit, onClose }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [defaultCountry, setDefaultCountry] = useState('in');
    const [phoneNumber, setPhoneNumber] = useState('');
    const {userProfile} = useSelector((state)=>state.global)

    // Initial profile data for the form fields
    const initialData = {
        name: userProfile?.name,
        // password: '',
        email: userProfile?.email,
        phone: userProfile?.phone,
        type: userProfile?.type,
        website:userProfile?.website,
        domain:userProfile?.domain,
        numberOfEmployees:userProfile?.numberOfEmployees,
        description:userProfile?.description
    };

    useEffect(() => {
        form.setFieldsValue(initialData);
        setPhoneNumber(userProfile?.phone)
    }, [form , userProfile]);

    // Handle form submission
    const onUpdateProfile = () => {
        form.validateFields()
            .then((values) => {
                const { name, email, type, website, domain, numberOfEmployees, description } = values;
                const payload = {
                    name,
                    type,
                    email,
                    phone: phoneNumber,
                    website,
                    description,
                    domain,
                    numberOfEmployees
                };

                // Dispatch API call
                dispatch(Update_organization({ body: payload, navigate, onClose: onClose }));
                setIsTopProfileEdit(false);
            })
            .catch((error) => {
                console.error('Validation Failed:', error);
            });
    };

    // Handle cancel (close modal)
    const handleCancel = () => {
        setIsTopProfileEdit(false);
    };

    useEffect(()=>{
        
    },[])

    return (
        <>
            <Modal
                title="Edit Profile"
                visible={isTopProfileEdit}
                onCancel={onClose}
                footer={
                    <div className={'modal-footer-buttons'}>
                        <Button onClick={onClose} variant="outlined" type="primary" danger>
                            Cancel
                        </Button>
                        <Button onClick={onUpdateProfile} htmlType="submit" className="default-button-style">
                            Save
                        </Button>
                    </div>
                }
                centered
                width={800}
            >
                <Form onFinish={onUpdateProfile} form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: 'Please enter your password' }]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[{ required: true, message: 'Please enter your email' }]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="phone"
                                label="Phone Number"
                                rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                            >
                                <PhoneInput
                                    country={defaultCountry}
                                    value={phoneNumber}
                                    onChange={(phone) => setPhoneNumber(phone)}
                                    inputStyle={{ width: '100%' }}
                                    enableSearch={true}
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="type"
                                label="Company Type"
                                rules={[{ required: true, message: 'Please enter company type' }]}
                            >
                                <Select size='middle' placeholder="Select your company type">
                                    <Option value="small">Small</Option>
                                    <Option value="middle">Middle</Option>
                                    <Option value="large">Large</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="website"
                                label="Website Url"
                                rules={[{ required: true, message: 'Please enter company wibsite url' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="numberOfEmployees"
                                label="Number of employees"
                                rules={[{ required: true, message: 'Please enter employees' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} md={12}>
                            <Form.Item
                                name="domain"
                                label="Domain"
                                rules={[{ required: true, message: 'Please enter the domain' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} md={24}>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{ required: true, message: 'Please enter description' }]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default OrgProfileEditor;
