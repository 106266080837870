import React, { useState } from 'react';
import { Modal, Select, Button, Form, Row, Col, Typography, Divider, Input, Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsLanguageAdd, update_jobseeker } from '../../features/UserInfo/userInfoSlice';
import styles from './style.module.scss';

const { Option } = Select;
const { Text } = Typography;

const AddLanguage = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {userProfile} = useSelector((state)=>state.global)

  const handleSave = () => {
    dispatch(setIsLanguageAdd(false))
    form
      .validateFields()
      .then((values) => {
        // Assuming userProfile.languages contains already added languages
        const existingLanguages = userProfile.languages || [];
  
        // Map new languages from form values
        const newLanguages = values.languages.map(({ language, readLevel, writeLevel, speakLevel }) => ({
          language,
          proficiency: {
            read: readLevel || 'Beginner',
            write: writeLevel || 'Beginner',
            speak: speakLevel || 'Beginner',
          },
        }));
  
        // Combine existing and new languages
        const combinedLanguages = [...existingLanguages, ...newLanguages];
  
        // Create payload
        const payload = { languages: combinedLanguages };
  
        // Dispatch action with payload
        dispatch(update_jobseeker({ body: payload, navigate }));
  
        // Close the modal
        onClose();
      })
      .catch((info) => {
        console.log('Validation Failed:', info);
      });
  };
  

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      title={<Text strong>Add Languages</Text>}
      className={styles.modal}
      width={500}
    >
      <Form form={form} layout="vertical" initialValues={{ languages: [{}] }}>
        <Form.List name="languages">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey }) => (
                <Card
                  key={key}
                  title={`Language ${name + 1}`}
                  extra={
                    <DeleteOutlined
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => remove(name)}
                    />
                  }
                  className={`margin-top-10 ${styles.languageCard}`}
                >
                  <Form.Item
                    label="Language"
                    name={[name, 'language']}
                    fieldKey={[fieldKey, 'language']}
                    rules={[{ required: true, message: 'Please enter a language' }]}
                  >
                    <Input placeholder="Enter language name" />
                  </Form.Item>

                  <Row className={styles.typeHeading}>
                    <Col xs={12}>Type</Col>
                    <Col xs={12}>Level</Col>
                  </Row>
                  {['Read', 'Write', 'Speak'].map((type) => (
                    <Row key={type} align="middle" gutter={8}>
                      <Col span={12}>
                        <Form.Item>
                          <Text>{type}</Text>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, `${type.toLowerCase()}Level`]}
                          fieldKey={[fieldKey, `${type.toLowerCase()}Level`]}
                          rules={[{ required: true, message: `Please select ${type} level` }]}
                        >
                          <Select placeholder="Select level">
                            <Option value="Beginner">Beginner</Option>
                            <Option value="Intermediate">Intermediate</Option>
                            <Option value="Expert">Expert</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Card>
              ))}
              <Button
                type="dashed"
                onClick={() => add()}
                block
                style={{ marginBottom: '1rem', marginTop: '1rem' }}
              >
                + Add New Language
              </Button>
            </>
          )}
        </Form.List>

        {/* Action Buttons */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            size="large"
            shape="round"
            onClick={handleReset}
            style={{
              width: '45%',
              backgroundColor: '#f5f5f5',
              color: '#000',
            }}
          >
            Reset
          </Button>
          <Button
            size="large"
            shape="round"
            type="primary"
            onClick={handleSave}
            style={{
              width: '45%',
              backgroundColor: '#00a65a',
              borderColor: '#00a65a',
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddLanguage;

