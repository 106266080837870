import React from 'react';
import { DownOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Image, Space, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AppSecureStorage from '../../services/storage/secureStorage';
import { setLoggedInUser } from '../../features/Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import logoutImg from '../../assets/Images/log-out.svg'
import { getCurrentUser } from '../../utils/authHelpers';

const ProfileCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storage = new AppSecureStorage()
  let currentUser = getCurrentUser()
  const { userProfile, userType } = useSelector((state) => state.global)
  const handleLogout = () => {
    dispatch(setLoggedInUser(null))
    storage.clearStorage();
    navigate('/');
  };
  const naviagteSetting = currentUser === 'organization' ? '/organization-settings' : '/find-job/setting'
  const items = [
    {
      label: <div onClick={() => navigate(naviagteSetting)} className='flex-space-between fullwidth'>
        <div size='large' className='fullwidth'>Setting</div>
        <div><SettingOutlined /></div>
      </div>,
      key: '0',
    },
    { type: 'divider' },
    {
      label: <div className='flex-space-between fullwidth'>
        <div onClick={handleLogout} size='large' className='fullwidth'>Logout</div>
        <div><LogoutOutlined /></div>
      </div>,
      key: '0',
    },
  ];

  const truncatedName = userProfile?.name?.length > 8
    ? `${userProfile.name.slice(0, 8)}...`
    : userProfile?.name;

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Avatar icon={!userProfile?.avatar && <UserOutlined />} src={userProfile?.avatar} />
          {/* <Tooltip title={userProfile?.name}> */}
           {truncatedName}
           {/* </Tooltip> */}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
}
export default ProfileCard;