import CryptoJS from "crypto-js";
import SecureStorage from "secure-web-storage";

export default class AppSecureStorage extends SecureStorage {
  constructor() {
    let doc = {
      localStorage: {},
    };
    if (typeof window !== "undefined") {
      doc = window;
    }
    super(doc.localStorage, {
      hash: function hash(key) {
        key = CryptoJS.SHA256(
          key,
          process.env.REACT_APP_STORAGE_ENCRYPTION_KEY
        );
        return key.toString();
      },
      encrypt: function encrypt(data) {
        if (!data) return null; // or some default handling
        data = CryptoJS.AES.encrypt(
          data,
          process.env.REACT_APP_STORAGE_ENCRYPTION_KEY
        );
        data = data.toString();
        return data;
      },
      
      decrypt: function decrypt(data) {
        if (!data) return null; // handle null or undefined case
        data = CryptoJS.AES.decrypt(
          data,
          process.env.REACT_APP_STORAGE_ENCRYPTION_KEY
        );
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      },      
    });
  }
  
  setItem(key, value) {
    super.setItem(key, value);
  }
  
  getItem(key) {
    return super.getItem(key);
  }
  

  remove(key) {
    this.removeItem(key);
  }

  clearStorage() {
    this.clear();
  }
}
